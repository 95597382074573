import GetLastDateActionCellModulesGetters from './getters.js';
import GetLastDateActionCellModulesMutations from './mutations.js';
import GetLastDateActionCellModulesAction from './actions.js';

export default {
  namespaced: true,

  state: {
    lastDateCell: ''
  },
  getters: GetLastDateActionCellModulesGetters,
  mutations: GetLastDateActionCellModulesMutations,
  actions: GetLastDateActionCellModulesAction
};
