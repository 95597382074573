<template>
  <q-item
    v-for="link in items"
    :key="link.title"
    outline
    clickable
    :class="'menuBtn p-desktop ' + activeLink(link.routeNames)"
    rounded
    @click="pushLink(link.link)"
    :active="link === link.link"
    active-class="my-menu-link"
  >
    <q-item-section class="content">
      {{ link.title }}

      <q-icon v-if="link.icon" :name="link.icon" size="sm" />
      <img
        v-else
        :src="link.iconSvg !== '' ? link.iconSvg : link.icon"
        alt=""
        srcset=""
      />
      <!-- <svg v-else class="on-right">
        <use :xlink:href="link.iconSvg !== '' ? link.iconSvg : link.icon"></use>
      </svg> -->
    </q-item-section>
  </q-item>

  <div v-if="mobile" class="q-mt-xs q-mb-lg">
    <q-list>
      <q-item
        clickable
        v-ripple
        v-for="link in items"
        :class="
          'menuMobile separatorLight activeLink no-padding ' +
            activeLink(link.routeName)
        "
        :key="link.title"
        :to="link.link"
        :active="link === link.link"
        active-class="my-menu-mobile-link"
      >
        <q-item-section class="title">{{ link.title }}</q-item-section>
        <q-item-section avatar>
          <q-icon
            class="iconMenu"
            v-if="link.icon"
            :name="link.icon"
            size="sm"
          />
          <img
            v-else
            :src="link.iconSvg !== '' ? link.iconSvg : link.icon"
            alt=""
            srcset=""
          />
          <!-- <svg v-else class="iconMenu">
            <use
              :xlink:href="link.iconSvg !== '' ? link.iconSvg : link.icon"
            ></use>
          </svg> -->
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
export default {
  name: 'PrimaryMenu',
  props: {
    items: {
      type: Object,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();

    return {
      link: ref('inbox')
    };
  },
  methods: {
    activeLink(routeNames) {
      return routeNames
        ? routeNames.filter(routeName => {
            return this.$route.name === routeName;
          }).length > 0
          ? 'my-menu-link'
          : ''
        : '';
    },
    impersonate() {
      let user = null;
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));

      if (rememberMe === false) {
        sessionStorage.setItem('token_rollback', sessionStorage.getItem('token'));
        sessionStorage.setItem('user_rollback', sessionStorage.getItem('user'));
        user = JSON.parse(sessionStorage.getItem('user'));
      } else {
        localStorage.setItem('token_rollback', localStorage.getItem('token'));
        localStorage.setItem('user_rollback', localStorage.getItem('user'));
        user = JSON.parse(localStorage.getItem('user'));
      }
      if (user.role_id === 1) {
        let tryLocation = false;
        let indexGroup = 0
        // user.location_group.forEach(async location_group => {
        this.tryImpersonate(tryLocation, user, rememberMe, indexGroup);
        // });
      }
    },
    tryImpersonate(tryLocation, user, rememberMe, index) {
if (!tryLocation) {
            Api.impersonate(user.location_group[index].location.id).then(data => {
              let response = data.data;
              if (!response.error) {
                tryLocation = true;
                if (rememberMe === false) {
                  sessionStorage.setItem('token', JSON.stringify(response.token));
                  sessionStorage.setItem('user', JSON.stringify(response.user));
                  sessionStorage.setItem('impersonate_activated', true);
                } else {
                  localStorage.setItem('token', JSON.stringify(response.token));
                  localStorage.setItem('user', JSON.stringify(response.user));
                  localStorage.setItem('impersonate_activated', true);
                }

                this.$store.commit('user/user', response.user);
                this.$q.notify({
                  message: `Chargement du site réussi`,
                  color: 'positive'
                });
                this.$router.push({ name: 'dashboard_operator' });
              } else {
                if(user.location_group[index+1] != null) {
                  this.tryImpersonate(tryLocation, user, rememberMe, index + 1)
                }
              }
            });
          }
    },
    leaveImpersonate(link) {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        sessionStorage.setItem('token', sessionStorage.getItem('token_rollback'));
        sessionStorage.setItem('user', sessionStorage.getItem('user_rollback'));
        sessionStorage.setItem('impersonate_activated', false);
        this.$store.commit('user/user', JSON.parse(sessionStorage.getItem('user_rollback')));
      } else {
        localStorage.setItem('token', localStorage.getItem('token_rollback'));
        localStorage.setItem('user', localStorage.getItem('user_rollback'));
        localStorage.setItem('impersonate_activated', false);
        this.$store.commit('user/user', JSON.parse(localStorage.getItem('user_rollback')));
      }
      this.$router.push({ path: link });
    },
    pushLink(link) {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      let user = null;
      let is_impersonate = null;
      if (rememberMe === false) {
        is_impersonate = JSON.parse(sessionStorage.getItem('impersonate_activated'));
        user = JSON.parse(sessionStorage.getItem('user'));
      } else {
        is_impersonate = JSON.parse(localStorage.getItem('impersonate_activated'));
        user = JSON.parse(localStorage.getItem('user'));
      }

      if (
        link === '/operator/dashboard' &&
        user.role_id === 1 &&
        !is_impersonate
      ) {
        this.impersonate();
      } else if (user.role_id === 2 && is_impersonate) {
        this.leaveImpersonate(link);
      } else {
        this.$router.push({ path: link });
      }
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
