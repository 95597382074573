export default {
  GET_SPECIES(state, payload) {
    state.storedSpecies = payload;
  },
  GET_SPECIES_BY_LOCATION_NAME(state, payload) {
    state.storedSpeciesByLocationName = payload;
  },
  GET_SPECIES_BY_LOCATION(state, payload) {
    state.storedSpeciesByLocation = payload;
  },
  GET_NEW_SPECIES_BY_LOCATION(state, payload) {
    state.newSpeciesByLocation = payload;
  }
};
