<template>
  <Loader v-if="isLoading" />

  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    :op="op"
    :okCancel="true"
    @submit="valideUpdate()"
    :title="'Modification des informations de ' + op.firstname + ' ' + op.lastname"
    okLabel="Valider"
    size="Light"
    name="updateOperator"
    ref="popup"
    @click.stop
  >
    <div class="content">
      <InputForm
        icon
        nameIcon="user"
        prefixIcon="far"
        type="text"
        :placeholder="op.firstname"
        v-model="info.firstname"
        :value="info.firstname"
        inputModal="inputIcon"
        @change="info.firstname"
      />

      <InputForm
        icon
        nameIcon="user"
        prefixIcon="far"
        type="text"
        :placeholder="op.lastname"
        v-model="info.lastname"
        :value="info.lastname"
        inputModal="inputIcon"
      />
      <InputForm
        icon
        nameIcon="envelope"
        prefixIcon="far"
        type="email"
        :placeholder="op.email"
        v-model="info.email"
        :value="info.email"
        inputModal="inputIcon"
      />
      <select v-model="info.location_id">
        <option :value="-1" hidden selected>{{ info.location_name }}</option>
        <option
          v-for="location in GET_ACTION_LOCATION"
          :key="location.id"
          :value="location.location_id"
          >{{ location.location.name }}</option
        >
      </select>
      <!-- 
      <div>
        <p>
          Envoyer une demande de réinitialisation <br />de mot de passe à
          l'opérateur.
        </p>

        <BtnresetPassword
          title="Changement de mot de passe"
          label="Changement de mot de passe"
          textBtn="Changement de mot de passe"
          textBtnLoader="Envoie en cours"
          active-loader
          styleBtn="contained"
          colorBtn="warning"
          class="contentBtn"
          typeBtn="button"
          icon
          iconCircle
          ref="btn1"
          nameIcon="paper-plane"
          @click="resetPassword"
        />
      </div> -->

      <div class="success" v-if="isSuccess">
        Modifications effectuées avec succès.
      </div>
      <div class="success" v-if="isSuccessPassword">
        Envoie effectuées avec succès.
      </div>
      <div class="error" v-if="isError">
        {{ msg.msgError }}
      </div>
    </div>
  </AddModal>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import InputForm from '@/components/atoms/Form/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import BtnresetPassword from '@/components/atoms/BtnLoader';

export default {
  name: 'Operators',
  components: {
    // BtnresetPassword,
    InputForm,
    AddModal,
    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    op: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      addOp: false,
      isSuccessPassword: false,
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      modalStore: ModalStore
    };
  },
  computed: {
    ...mapGetters('listLocation', ['GET_ACTION_LOCATION']),
    info: {
      get() {
        return this.op;
      },
      set(value) {
        this.$emit('update:op', value);
      }
    }
  },
  methods: {
    resetPassword() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isSuccessPassword = true;
        this.isLoading = false;
        this.$refs.btn1.activeLoader = false;
        setTimeout(() => {
          this.isSuccessPassword = false;
        }, 2000);
      }, 5000);
    },
    // desactivatedOperator(id) {
    //   Api.disableOperators(id)
    //     .then(({ data }) => {
    //       if (data) {
    //         this.$store.dispatch('listOperator/LoadOperators');
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    valideUpdate() {
      let data = {
        firstname: this.info.firstname,
        lastname: this.info.lastname,
        email: this.info.email,
        location_id: this.info.location_id
      };
      Api.editOperator(this.info.id, data)
        .then(({ data }) => {
          if (data) {
            this.isLoading = true;
            this.isError = false;
            this.isSuccess = true;
            setTimeout(() => {
              this.isLoading = false;
              this.isSuccess = false;
              this.$refs.popup.hide();
            }, 3000);
          }
          this.$store.dispatch('listLocation/LoadLocation');
        })
        .catch(error => {
          this.isError = error;
          this.msg['msgError'] = `${error.response.data.message}`;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
