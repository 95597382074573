<template>
  <q-dialog v-model="open" persistent  ref="popup">
    <q-card :style="`min-width: ${size}px; padding: 4px;`">
      <q-form ref="formValidation" @submit="submitForm">
        <q-card-section class="cell_infos">
          <div>
            <div class="row items-center q-pb-none">
              <div class="text-h6">{{ title }}</div>
              <q-space />
              <q-btn
                icon="close"
                flat
                round
                dense
                v-close-popup
                class="close-btn"
              />
            </div>
            <div>
              <div
                v-if="cell_desk"
                :class="['top_info', { noMarginBot: noMarginBot }]"
              >
                <span class="silo">{{ silo }}</span>
                <span class="cell">{{ cell }}</span>
                <span class="species">{{ species }}</span>
              </div>
              <div v-if="cell_info" class="bot_info">
                <div class="group_container">
                  <div class="group_info">
                    <span class="silo">{{ label_date }}</span>
                    <span class="cell">{{ date }}</span>
                  </div>
                  <div class="group_info">
                    <span class="silo">Tonnage actuel</span>
                    <span class="cell">{{ current_volume }} tonnes</span>
                  </div>
                  <div class="group_info">
                    <span class="silo">{{ label_volume_2 }}</span>
                    <span class="cell">{{ max_volume }} tonnes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <slot></slot>

          <div v-if="infoGrainModal" class="info_grain_container">
            <div class="text">
              <p>
                Sélectionnez les informations ayant un impact sur la ventilation
                de la cellule ou bien utilisez le champ texte pour consigner
                toute autre information au remplissage de la cellule.
              </p>
            </div>
            <div class="info_grains">
              <div
                class="grain"
                v-for="grain in actionGrainsList"
                :key="grain.name"
              >
                <input
                  style="display: none;"
                  type="checkbox"
                  :id="`checkbox${grain.uuid}`"
                  @click="checkboxClick(grain)"
                />
                <label class="info_grain" :for="`checkbox${grain.uuid}`">
                  {{ grain.name }}
                </label>
              </div>
            </div>
          </div>
          <div v-if="commentModal" class="comment_container">
            <InputForm
              @modelSelected="setComment"
              style="max-heigth: 100px;"
              type="textarea"
              class="species_item"
              titreInput="Commentaire"
            />
          </div>
        </q-card-section>
        <q-card-actions align="left" class="text-primary">
          <q-btn
            v-if="displaySendDataButton"
            @click="submitForm"
            unelevated
            rounded
            style="background: #36A139; color: #FFFFFF;"
            label="Envoyer les données"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import ModalStore from './ModalStore';
import { uuid } from 'vue-uuid';
import InputForm from '@/components/atoms/Inputs/InputForm';

export default {
  name: 'Modal',
  // emits: ['update:close', 'update:refuse', 'update:confirm'],
  emits: ['submit', 'update:isOpen'],
  components: {
    InputForm
  },
  props: {
    name: { type: String, required: true },
    title: { type: String, required: false },
    isOpen: { type: Boolean, default: false },
    okOnly: { type: Boolean, default: false },
    cancelOnly: { type: Boolean, default: false },
    okCancel: { type: Boolean, default: false },
    cancelLabel: { type: String, default: 'Annuler' },
    okLabel: { type: String, default: 'Ok' },
    enableOkBtn: { type: Boolean, default: false },
    commentModal: { type: Boolean, default: false },
    infoGrainModal: { type: Boolean, default: false },
    size: { type: String, default: 'xs' },
    closeBtnCenter: { type: Boolean, default: false },
    widthPlus: { type: Boolean, default: false },
    cell_desk: { type: Boolean, default: false },
    cell_info: { type: Boolean, default: false },
    silo: { type: String, default: '' },
    cell: { type: String, default: '' },
    species: { type: String, default: '' },
    label_date: { type: String, default: '' },
    date: { type: String, default: '' },
    current_volume: { type: Number, default: null },
    max_volume: { type: Number, default: null },
    actionGrains: { type: Array },
    displaySendDataButton: { type: Boolean, default: true },
    activeFormValidation: { type: Boolean, default: false },
    noMarginBot: { type: Boolean, default: false },
    label_volume_2: { type: String, default: ''}
  },
  data() {
    return {
      store: ModalStore,
      // open: false,
      uuid: uuid.v4(),
      actionGrainsList: null,
      checkboxlist: [],
      comment: ''
    };
  },
  computed: {
    open: {
      get: function () {
        return this.isOpen;
      },
      set: function (nv) {
        return this.$emit('update:isOpen', nv);
      }
    }
  },
  methods: {
    submitForm() {
      if (this.activeFormValidation) {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            if (this.commentModal && this.infoGrainModal) {
              this.$emit('submit', this.checkboxlist, this.comment);
            } else if (this.commentModal) {
              this.$emit('submit', null, this.comment);
            } else if (this.infoGrainModal) {
              this.$emit('submit', this.checkboxlist);
            } else {
              this.$emit('submit');
            }
          }
        });
      } else {
        if (this.commentModal && this.infoGrainModal) {
          this.$emit('submit', this.checkboxlist, this.comment);
        } else if (this.commentModal) {
          this.$emit('submit', null, this.comment);
        } else if (this.infoGrainModal) {
          this.$emit('submit', this.checkboxlist);
        } else {
          this.$emit('submit');
        }
      }
    },
    setComment(c) {
      this.comment = c;
    },
    checkboxClick(grain) {
      if (this.checkboxlist.length > 0) {
        let here = false;
        this.checkboxlist.forEach(value => {
          if (value == grain.name) {
            here = true;
          }
        });
        if (here) {
          this.checkboxlist.splice(this.checkboxlist.indexOf(grain.name), 1);
        } else {
          this.checkboxlist.push(grain.name);
        }
      } else {
        this.checkboxlist.push(grain.name);
      }
    },
    show() {
      // this.open = true;
      document.body.classList.add('is-open');
      window.onclick = e => {
        if (e.target.matches('.ModalBackDrop')) {
          // localStorage.removeItem('validationModal');
          // this.open = false;
          window.onclick = null;
        }
      };
    },
    hide() {
      this.$emit('close');
      // this.open = false;
      document.body.classList.remove('is-open');
    },
    refuse() {
      this.$emit('refuse');
      this.hide();
    },
    accept() {
      this.$emit('confirm');
      this.hide();
    }
  },
  mounted() {
    this.store.add(this);
  },
  unmounted() {
    this.store.remove(this);
  },
  watch: {
    actionGrains() {
      let newArray = [];
      this.actionGrains.forEach(value => {
        let newObj = {};
        newObj.name = value;
        newObj.uuid = uuid.v4();
        newArray.push(newObj);
      });
      this.actionGrainsList = newArray;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
