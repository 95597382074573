<template>
  <div class="items-center q-py-md col-12 col-xs-12 contentPeriod InputForm">
    <div class="title">{{ titreInput }}</div>
    <div class="subTitle">{{ subTitle }}</div>
    <q-input
    v-if="label"
      :step="step"
      class="input_style"
      v-model="modelSelected"
      color="green"
      outlined
      :rules="[val => checkValue(val) || ruleMessage]"
      :type="type"
      :label="label"
    >
      <template v-if="prependIcon" v-slot:prepend>
        <img :src="prependIcon" alt="" />
      </template>
      <template v-if="tonnage" v-slot:append>
        <span>tonnes</span>
      </template>
      <template v-else-if="temp" v-slot:append>
        <span>°C</span>
      </template>
    </q-input>
    <q-input
    v-else
      :step="step"
      class="input_style"
      v-model="modelSelected"
      color="green"
      outlined
      :rules="[val => checkValue(val) || ruleMessage]"
      :type="type"
    >
      <template v-if="prependIcon" v-slot:prepend>
        <img :src="prependIcon" alt="" />
      </template>
      <template v-if="tonnage" v-slot:append>
        <span>tonnes</span>
      </template>
      <template v-else-if="temp" v-slot:append>
        <span>°C</span>
      </template>
    </q-input>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'InputForm',
  emits: ['model-selected'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    step: {
      type: Number,
      default: 0
    },
    optionList: {
      type: Array
    },
    currentOption: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    titreInput: {
      type: String,
      default: ''
    },
    rule: {
      type: Array
    },
    valueSelected: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    tonnage: {
      type: Boolean,
      default: false
    },
    temp: {
      type: Boolean,
      default: false
    },
    ruleCondition: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: null
    },
    maxTransisalage: {
      type: Array
    },
    prependIcon: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      modelSelected: ref(''),
      ruleMessage: ref('')
    };
  },
  watch: {
    modelSelected(newValue) {
      this.$emit('model-selected', newValue);
    },
    currentOption(n) {
      this.modelSelected = n;
    }
  },
  methods: {
    checkValue(val) {
      let pass = true;
      this.ruleCondition.split(' ').forEach(value => {
        if (value === 'required') {
          this.ruleMessage = pass ? 'Champ requis' : this.ruleMessage;
          pass = pass ? !!val : false;
        } else if (value === 'max') {
          this.ruleMessage = pass ? 'Valeur trop élevée' : this.ruleMessage;
          pass = pass ? val <= this.max : false;
        } else if (value === 'notZero') {
          this.ruleMessage = pass
            ? 'La valeur ne peut pas être zéro'
            : this.ruleMessage;
          pass = pass ? !(val == 0) : false;
        } else {
          pass = pass ? true : false;
        }
      });
      return pass;
    }
  },
  created() {
    this.modelSelected = this.valueSelected;
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
