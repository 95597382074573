<template>
  <div class="mesSilos">
    <template v-if="loading">
      <InnerLoader />
    </template>
    <template v-else>
      <div class="bg-w">
        <table class="table table-responsive table-striped">
          <thead class="thead-dark">
            <tr>
              <th
                align="left"
                v-for="column in columns"
                v-bind:key="column.value"
                @click="order(column.sortName, column.sortType)"
              >
                <svg
                  v-if="column.sortIcon"
                  :class="sortColumn === column.sortName ? orderType : ''"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.83334 10.4763L12.3033 6.00634L13.4817 7.18467L7.00001 13.6663L0.518341 7.18467L1.69667 6.00634L6.16667 10.4763V0.333008H7.83334V10.4763Z"
                    fill="white"
                  />
                </svg>
                {{ column.value }}
              </th>
            </tr>
          </thead>
          <!-- PAGINATION -->
          <!--<tfoot class="tfoot-dark">
            
            <tr>
              <td colspan="11">
                <div class="pagination">
                  <span
                    :class="[`pages`, { selected: page === currentPage }]"
                    @click="changePage(page)"
                    v-for="page in pages"
                    v-bind:key="page"
                  >
                    {{ page }}
                  </span>
                </div>
              </td>
            </tr>
          </tfoot>-->
          <tbody class="table-striped">
            <tr v-for="location in locationPage" :key="location">
              <td
                class="color_gen"
                :style="
                  `--genColor: ${levelColor(
                    Math.max(riskColor(location.maxRiskScore).lvl, tempColor(location.max_temp_in_cell).lvl)
                  )}`
                "
              >
                {{ location.name }}
              </td>
              <td v-if="columns.length > 7">
                {{ location.species_tonnages }} T
              </td>
              <td
                :class="[
                  { color_temp: tempColor(location.max_temp_in_cell).lvl > 1 }
                ]"
                :style="
                  `--tempColor: ${tempColor(location.max_temp_in_cell).color}`
                "
              >
                {{ location.max_temp_in_cell }}
              </td>
              <td>{{ location.moy_temp_in_cell }}</td>
              <td>{{ location.min_temp_in_cell }}</td>
              <td
                :class="[
                  { color_risk: riskColor(location.maxRiskScore).lvl > 1 }
                ]"
                :style="
                  `--riskColor: ${riskColor(location.maxRiskScore).color}`
                "
              >
                {{ location.maxRiskScore }}
              </td>
              <td>{{ location.last_input != null ? day(location.last_input) : '-' }}</td>
              <td class="impersonateButton" @click="impersonate(location.id)">
                VOIR >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import InnerLoader from '@/components/atoms/InnerLoader';
import getLevelsColor from '@/Mixins/getLevelsColor';

export default {
  name: 'listeContainer',
  components: {
    InnerLoader
  },
  props: {
    columns: { type: Object },
    locations: { type: Object }
  },
  mixins: [getLevelsColor],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      sortColumn: 'name',
      orderType: 'string',
      sortType: 'asc',
      loading: false,
      filtredLocation: []
    };
  },

  computed: {
    pages() {
      return 1;
    },
    locationPage() {
      return this.filtredLocation;
      /* return this.filtredLocation.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      }); */
    }
  },
  watch: {
    locations() {
      this.filtredLocation = this.locations;
      this.order(this.sortColumn, this.orderType, true);
    }
  },
  methods: {
    impersonate(locId) {
      this.$emit('impersonate', locId);
    },
    day(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D/MM/YY');
    },
    order(column, type, dontChangeOrder = false) {
      if (this.sortColumn === column && !dontChangeOrder) {
        this.sortType = this.sortType === 'asc' ? 'desc' : 'asc';
      }
      switch (type) {
        case 'string':
          this.filtredLocation.sort((a, b) =>
            a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
          );
          break;
        case 'date':
          this.filtredLocation.sort((a, b) =>
            a[column]
              ? b[column]
                ? Date.parse(a[column]) - Date.parse(b[column])
                : 1
              : -1
          );
          break;
        case 'float':
          this.filtredLocation.sort((a, b) => {
            if (column === 'cjours') {
              if (b[column] === null) {
                b[column] = 1;
              } else if (a[column] === null) {
                a[column] = 1;
              }
              if (this.orderType === 'desc') {
                return parseFloat(a[column]) > parseFloat(b[column])
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column])
                  ? -1
                  : 0;
              } else {
                return parseFloat(a[column]) > parseFloat(b[column])
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column])
                  ? -1
                  : 0;
              }
            } else {
              const x = a[column] == null ? 0 : a[column]
              const y = b[column] == null ? 0 : b[column]
              return parseFloat(x) == parseFloat(y) ? 0 : parseFloat(x) > parseFloat(y) ? 1 : -1
              // if (this.orderType === 'desc') {
              //   return parseFloat(x) > parseFloat(y) ||
              //     y === null ||
              //     y === 0
              //     ? 1
              //     : parseFloat(y) > parseFloat(x) ||
              //       x === null ||
              //       x === 0
              //     ? -1
              //     : 0;
              // } else {
              //   return parseFloat(x) > parseFloat(y) ||
              //     x === null ||
              //     x === 0
              //     ? 1
              //     : parseFloat(y) > parseFloat(x) ||
              //       y === null ||
              //       y === 0
              //     ? -1
              //     : 0;
              // }
            }
          });
          break;
        default:
          this.filtredLocation.sort((a, b) =>
            a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
          );
          break;
      }
      this.sortColumn = column;
      if (this.sortType == 'desc') {
        this.filtredLocation.reverse();
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
