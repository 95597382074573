import Api from '@/classes/api/Api';

export default {
  async LoadOperators({ commit }) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'));
    if (user.role_id == 1 || user.role_id == 3) {
      await Api.getOperators()
        .then(({ data }) => {
          if (data) {
            commit('GET_ACTION_LIST_OPERATOR', data);
            // console.log('GET_ACTION_LIST_OPERATOR', data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      console.log('not authorize');
    }
  }
};
