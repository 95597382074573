import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import Notify from 'quasar/src/plugins/Notify.js';;
import iconSet from 'quasar/icon-set/fontawesome-v5';

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify
  },
  config: {
    /* */
  },
  iconSet: iconSet,
  extras: ['fontawesome-v5'],
  framework: {
    iconSet: 'fontawesome-v5' // Quasar icon set
  }
};
