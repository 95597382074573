import Api from '@/classes/api/Api';

export default {
  async loadFillings({ commit }) {
    await Api.getAllFillings()
      .then(({ data }) => {
        if (data) {
          commit('GET_FILLINGS', data);
          // console.log('GET_FILLINGS', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
