<template>
  <div class="cfg gen actif">
    <h2>Générale</h2>

    <dl>
      <dt>nom de l'application</dt>
      <dd>{{ GET_APP_SETTINGS.name }}</dd>
      <dt>Id</dt>
      <dd>{{ GET_APP_SETTINGS.id }}</dd>
      <dt>Bienvenue</dt>
      <dd class="a" v-html="GET_APP_SETTINGS.welcome"></dd>
      <dt>Consigne</dt>
      <dd>
        <h2>Pour bien préparer la saisie des enregistrements</h2>
        <p class="text">
          Afin de pouvoir réaliser la saisie des enregistrements et assurer la
          fiabilité des Indicateurs de performance, nous vous conseillons de
          préparer les informations suivantes : cellule ventilée, sa capacité
          (pleine et actuelle), le ventilateur et sa puissance (plaque moteur),
          la température moyenne au remplissage de la cellule, la température
          avant et après chaque ventilation, la consigne du thermostat si
          présent, les temps de ventilation, le coût du kilowattheure.
        </p>
      </dd>
      <dt>Coût du kWh</dt>
      <dd>{{ GET_APP_SETTINGS.kwh_cost }} €</dd>
      <dt>Consigne 1</dt>
      <dd>{{ GET_APP_SETTINGS.ipeo_min }} h</dd>
      <dt>Consigne 2</dt>
      <dd>{{ GET_APP_SETTINGS.ipeo_max }} h</dd>
      <dt>Couleur principale</dt>
      <dd>
        <span
          class="cp"
          :style="{ backgroundColor: GET_APP_SETTINGS.primary_color }"
        ></span
        >{{ GET_APP_SETTINGS.primary_color }}
      </dd>
      <dt>Couleur alternative</dt>
      <dd>
        <span
          class="cp"
          :style="{ backgroundColor: GET_APP_SETTINGS.secondary_color }"
        ></span
        >{{ GET_APP_SETTINGS.secondary_color }}
      </dd>
      <dt>Actions infos grain</dt>
      <dd>
        <span
          class="action"
          v-for="grain in GET_ACTION_GRAIN"
          :key="grain.id"
          >{{ grain.name }}</span
        >
      </dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'General',

  computed: {
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    ...mapGetters('setting', ['GET_APP_SETTINGS'])
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
