export default {
  methods: {
    levelColor(level) {
      const lvl = parseInt(level);

      return lvl >= 5
        ? '#C12937'
        : lvl >= 4
        ? '#F17E14'
        : lvl >= 3
        ? '#F1B314'
        : lvl >= 2
        ? '#8ACE46'
        : lvl >= 1
        ? '#36A139'
        : '';
    },
    riskColor(risk) {
      const riskLevel = parseInt(risk);

      return riskLevel >= 5
        ? { color: '#C12937', lvl: 5 }
        : riskLevel >= 4
        ? { color: '#F17E14', lvl: 4 }
        : riskLevel >= 3
        ? { color: '#F1B314', lvl: 3 }
        : riskLevel >= 2
        ? { color: '#8ACE46', lvl: 2 }
        : riskLevel >= 1
        ? { color: '#36A139', lvl: 1 }
        : { color: '', lvl: 0 };
    },
    tempColor(temp) {
      const tempLevel = parseInt(temp);

      return tempLevel >= 25
        ? { color: '#C12937', lvl: 5 }
        : tempLevel >= 20
        ? { color: '#F17E14', lvl: 4 }
        : tempLevel >= 15
        ? { color: '#F1B314', lvl: 3 }
        : tempLevel >= 10
        ? { color: '#8ACE46', lvl: 2 }
        : tempLevel < 10
        ? { color: '#36A139', lvl: 1 }
        : false;
    },
    perfColor(temp) {
      const tempLevel = parseInt(temp);

      return tempLevel >= 25
        ? { color: '#C12937', lvl: 5 }
        : tempLevel >= 20
        ? { color: '#F17E14', lvl: 4 }
        : tempLevel >= 15
        ? { color: '#F1B314', lvl: 3 }
        : tempLevel >= 10
        ? { color: '#8ACE46', lvl: 2 }
        : tempLevel < 10
        ? { color: '#36A139', lvl: 1 }
        : false;
    }
  }
};
