<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      activeFormValidation
      size="960"
      closeBtnCenter
      v-if="cellObject && cellObject.status != 'vide'"
      name="transilage"
      title="Transilage"
      cell_desk
      :silo="siloInfo"
      :cell="cellObject.name"
      :species="
        cellObject.species && cellObject.species.name
          ? cellObject.species.name : 
          cellObject.stored_species && cellObject.stored_species.name
            ? cellObject.stored_species.name
            : ''
      "
      cell_info
      :label_date="'Date de dernière saisie'"
      :date="cellObject.lastSaisie ? cellObject.lastSaisie : '-'"
      :current_volume="parseInt(cellObject.current_volume)"
      :max_volume="possibleTransilage ? parseInt(cellObject.volume) : '0'"
      label_volume_2="Capacité Cellule de destination"
      :actionGrains="actionGrains"
      commentModal
      infoGrainModal
      @submit="addTransilage"
    >
      <div class="date_container">
        <InputDate
          class="date"
          :last_input="cellLastInput"
          titleDate="Date de l’opération *"
          :isRange="false"
          :dayValue="dayValue"
          @valuePickerUniq="valuedayUniq"
        />
        <InputOption
          v-if="destinationCells"
          :optionList="destinationCells"
          disableOption="disable"
          @modelSelected="destinationCellSelected"
          class="date"
          titreInput="Cellule de destination *"
          :modelSelectedDefault="true"
          :valueSelected="destCellSelected"
        />

        <InputForm
          @modelSelected="selectedTonnage"
          type="number"
          class="date"
          tonnage
          :titreInput="`Volume vidangé (max: ${maxEmptyingVolume}t) *`"
          :max="maxEmptyingVolume"
          ruleCondition="required max notZero"
        />
      </div>
    </AddModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';

import dayjs from 'dayjs';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import InputForm from '@/components/atoms/Inputs/InputForm';
import InputDate from '@/components/atoms/Inputs/InputDate';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import InputOption from '@/components/atoms/Inputs/InputOption';

export default {
  name: 'AddTransilage',
  props: {
    isOpen: { type: Boolean, default: false },
    cellId: {
      type: [String, Number]
    },
    siloInfo: {
      type: String
    },
    cellName: {
      type: String
    },
    cellObject: { type: Object, default: null },
    emptyCell: { type: [Object, String] }
  },
  components: {
    InputDate,
    InputOption,
    InputForm,
    AddModal,
    Loader
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  data() {
    return {
      possibleTransilage: true,
      destCellSelected: null,
      destCellSelectedObject: null,
      tonnage: null,
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: true,
      currentTab: 'transilage',
      tabs: {
        transilage: 'Transilage',
        grain: 'Grain'
      },
      transilageDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      dayValue: new Date().toISOString().substr(0, 10),
      errorDate: false,
      errorSendDate: false,
      transilageCellDest: '',
      transilageVolume: 0,
      cellResult: [],
      infos_grain: [],
      infos_grain_id: [],
      info_cell_description: ''
    };
  },
  computed: {
    ...mapGetters('silos', ['GET_CELL']),
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    cellLastInput() {
      return this.cellObject?.lastSaisieTimestamp
        ? this.destCellSelected
          ? this.cellObject.lastSaisieTimestamp <=
            this.destCellSelected.lastSaisieTimestamp
            ? new Date(this.destCellSelected.lastSaisieTimestamp * 1000)
            : new Date(this.cellObject.lastSaisieTimestamp * 1000)
          : new Date(this.cellObject.lastSaisieTimestamp * 1000)
        : null;
    },
    maxEmptyingVolume() {
      return this.destCellSelectedObject
        ? this.cellObject.current_volume < ((this.destCellSelectedObject.volume+(this.destCellSelectedObject.volume*30/100)) - this.destCellSelectedObject.current_volume)
          ? this.cellObject.current_volume 
          : ((this.destCellSelectedObject.volume+(this.destCellSelectedObject.volume*30/100)) - this.destCellSelectedObject.current_volume)
        : this.cellObject.current_volume;
    },
    destCell() {
      return this.emptyCell
        ? Object.entries(this.emptyCell).map(c => {
            return c[1].name;
          })[0].name
        : '';
    },
    actionGrains() {
      if (this.GET_ACTION_GRAIN) {
        return this.GET_ACTION_GRAIN.map(value => {
          return value.name;
        });
      } else {
        return [];
      }
    },
    destinationCells() {
      if (this.emptyCell) {
        return Object.entries(this.emptyCell).map(c => {
          c[1].value = c[1].name;
          c[1].label =
            c[1].current_volume < (c[1].volume+(c[1].volume*30/100))
              ? c[1].name
              : c[1].name + ' (Remplie)';
          c[1].disable = !(c[1].current_volume < (c[1].volume+(c[1].volume*30/100)));
          return c[1];
        });
      } else {
        return null;
      }
    }
  },
  watch: {
    emptyCell() {
      this.destCellSelected = this.destinationCells.filter(c => !c.disable)[0] ? this.destinationCells.filter(c => !c.disable)[0] : 'Transilage Impossible';
      this.destCellSelectedObject = this.destinationCells.filter(c => !c.disable)[0] ? this.destinationCells.filter(c => !c.disable)[0] : 'Transilage Impossible';
      if (this.destCellSelected === 'Transilage Impossible')
        this.possibleTransilage = false;
    }
  },
  methods: {
    dayInfo(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D MMMM YYYY');
    },
    timestamp(date) {
      return date ? dayjs(date).unix() : 0;
    },
    checkLastDateCapture(cell) {
      let dateVenti = '';
      if (cell.cell_ventilation && cell.cell_ventilation.length > 0) {
        dateVenti =
          cell.cell_ventilation[cell.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (cell.emptying && cell.emptying.length > 0) {
        dateEmpty = cell.emptying[cell.emptying.length - 1].date;
      }
      let datefillings = '';
      if (cell.fillings && cell.fillings.length > 0) {
        datefillings = cell.fillings[cell.fillings.length - 1].date;
      }
      let dateinfoCell = '';
      if (cell.cell_details && cell.cell_details.length > 0) {
        dateinfoCell = cell.cell_details[cell.cell_details.length - 1].date;
      }

      if (
        cell.cell_ventilation &&
        cell.cell_ventilation.length < 0 &&
        cell.emptying &&
        cell.emptying.length < 0 &&
        cell.fillings &&
        cell.fillings.length < 0 &&
        cell.cell_details &&
        cell.cell_details.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings, dateinfoCell);
      const arrSort = arr.sort();
      return this.dayInfo(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? arrSort[arrSort.length - 1]
        : 0;
    },
    valuedayUniq(e) {
      this.dayValue = e;
    },
    selectedTonnage(nv) {
      this.tonnage = nv;
    },
    destinationCellSelected(nv) {
      this.destCellSelected = nv;
      if(this.destCellSelected != null) {
        typeof this.destCellSelected !== 'string' ? this.destCellSelected.lastSaisieTimestamp = this.checkLastDateCapture(this.destCellSelected) != 0 ? this.timestamp(this.checkLastDateCapture(this.destCellSelected)) : 0 : false;
      this.destCellSelectedObject = Object.entries(this.emptyCell)
        .map(c => {
          return c[1];
        })
        .filter(value => {
          return value.name === nv.name;
        })[0];
      }
    },
    dayNow(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY');
    },
    handleClicks(obj, id) {
      const index = this.infos_grain.indexOf(obj);
      if (index > -1) {
        this.infos_grain.splice(index, 1);
        this.infos_grain_id.splice(index, 1);
      } else {
        this.infos_grain.push(obj);
        this.infos_grain_id.push(id);
      }
    },
    setDate(evt, date) {
      if (evt < this.checkDateInput(date) || evt > this.maxDate) {
        this.errorDate = true;
        this.transilageDate = '';
        // this.modalStore.showModal('confirmationDate');
      } else {
        this.errorSendDate = false;
        this.transilageDate = evt;
        this.errorDate = false;
      }
    },
    infosGrainEnable(grain) {
      const index = this.infos_grain.indexOf(grain);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    checkDate(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();
      return this.datejs(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejs(arrSort[arrSort.length - 1])
        : undefined;
    },
    checkDateInput(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      let dateinfoCell = '';
      if (date.infos_cell && date.infos_cell.length > 0) {
        dateinfoCell = date.infos_cell[date.infos_cell.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0 &&
        date.infos_cell &&
        date.infos_cell.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings, dateinfoCell);
      const arrSort = arr.sort();
      return this.datejsInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejsInput(arrSort[arrSort.length - 1])
        : undefined;
    },
    displayDateInput() {
      let dateVenti = '';
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length > 0
      ) {
        dateVenti = this.cellObject.cell_ventilation[
          this.cellObject.cell_ventilation.length - 1
        ].date;
      }
      let dateEmpty = '';
      if (this.cellObject.emptying && this.cellObject.emptying.length > 0) {
        dateEmpty = this.cellObject.emptying[
          this.cellObject.emptying.length - 1
        ].date;
      }
      let datefillings = '';
      if (this.cellObject.fillings && this.cellObject.fillings.length > 0) {
        datefillings = this.cellObject.fillings[
          this.cellObject.fillings.length - 1
        ].date;
      }
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length < 0 &&
        this.cellObject.emptying &&
        this.cellObject.emptying.length < 0 &&
        this.cellObject.fillings &&
        this.cellObject.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();

      return this.datejsInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejsInput(arrSort[arrSort.length - 1])
        : new Date().toISOString().slice(0, 10);
    },
    verifMaxVolume(evt) {
      if (
        parseInt(
          (this.transilageCellDest.volume * 30) / 100 +
            this.transilageCellDest.volume
        ) -
          this.transilageCellDest.current_volume >=
        this.cellObject.current_volume
      ) {
        if (parseInt(evt.target.value) > this.cellObject.current_volume) {
          this.transilageVolume = this.cellObject.current_volume;
        }
      } else {
        let cell_destination_can_receive =
          (this.transilageCellDest.volume * 30) / 100 +
          this.transilageCellDest.volume -
          this.transilageCellDest.current_volume;
        if (parseInt(evt.target.value) > cell_destination_can_receive) {
          this.transilageVolume = cell_destination_can_receive;
        }
      }
    },
    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY ');
    },
    datejsInput(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    addTransilage(actionGrains, comment) {
      // this.isLoading = true;
      // this.$refs.btn1.activeLoader = true;
      if (!this.possibleTransilage) {
        this.$q.notify({
          message:
            "Impossible de transiler, il n'y a pas de cellule de destination disponible",
          color: 'negative'
        });
        return;
      }
      let parsedActionGrains = actionGrains.map(actionGrainString => {
        return this.GET_ACTION_GRAIN.filter(actionGrain => {
          return actionGrain.name == actionGrainString;
        })[0].id;
      });
      let cell_id_dest = null;
      if (this.destCellSelected == null) {
        cell_id_dest = this.destCell;
      } else {
        cell_id_dest = this.destCellSelected;
      }
      let cell_destination_id = this.emptyCell.filter(value => {
        return value.name === cell_id_dest.name;
      })[0].id;

      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
      let data = {
        date: this.dayValue.split('-')[1]
          ? `${this.dayValue.split('-')[0]}/${this.dayValue.split('-')[1]}/${
              this.dayValue.split('-')[2]
            }`
          : `${this.dayValue.split('/')[0]}-${this.dayValue.split('/')[1]}-${
              this.dayValue.split('/')[2]
            }`,
        cell_destination_id: cell_destination_id,
        user_id: user.id,
        volume: this.tonnage,
        cell_id: this.cellId,
        stored_species_id: this.cellObject.stored_species.id,
        subspecies: this.cellObject.subspecies,
        infos_cell: parsedActionGrains,
        infos_cell_description: comment
      };
      // console.log(data)
      if (this.transilageDate == '' || this.transilageDate == undefined) {
        this.isLoading = false;
        // this.$refs.btn1.activeLoader = false;
        this.errorSendDate = false;
      } else {
        Api.vidangeCell(data)
          .then(({ data }) => {
            if (data) {
              this.$store.dispatch('listFillings/loadFillings');
              this.$store.dispatch('silos/loadSilo');
              //  this.$store.dispatch('silos/loadFans');
              // this.$store.dispatch('silos/loadCell');
              this.$store.dispatch('indicator/loadIndicatorLocation');
              this.$store.dispatch('inputLocation/loadInputLocation');
              this.isLoading = false;
              this.isErrorMsg = false;
              this.transilageDate = '';
              this.transilageCellDest = '';
              // ModalStore.hideModal('transilage');

              this.$emit('update:isOpen', false);
              // this.$refs.btn1.activeLoader = false;
              this.$emit('done');

              this.$q.notify({
                message: `${data.message}`,
                color: 'positive'
              });
            }
          })
          .catch(error => {
            this.isLoading = false;
            // this.isErrorMsg = true;
            // this.errorSendDate = true;
            // this.$refs.btn1.activeLoader = false;
            this.$q.notify({
              message: `${error}`,
              color: 'negative'
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
