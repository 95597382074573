<template>
  <div class="box">
    <div class="titleGroup">
      <div :class="['title', { manager: manager }]" @click="hideShowModal">
        <svg
          v-if="!manager"
          :class="[{ flipSvg: !showBox }]"
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4.47656L10.125 0.351562L11.3033 1.5299L6 6.83323L0.696671 1.5299L1.875 0.351562L6 4.47656Z"
            fill="#36A139"
          />
        </svg>
        <h2 class="mt-4 mb-4">{{ titleBox }}</h2>
      </div>
      <q-select
        v-if="filter && showBox"
        outlined
        v-model="filterValue"
        :options="options"
      />
    </div>

    <div v-if="showBox">
      <ExportButton v-if="showExportButton"></ExportButton>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ExportButton from '@/components/atoms/ExportButton';

export default {
  name: 'BoxBase',
  components: {
    ExportButton
  },
  props: {
    filter: { type: Boolean, default: false },
    titleBox: {
      type: String
    },
    showExportButton: {
      type: Boolean,
      default: false
    },
    manager: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    //
  },
  watch: {
    filterValue(nv) {
      this.$emit('changeFilter', nv);
    }
  },
  data() {
    return {
      filterValue: 'Tous niveaux d’alertes',
      options: ['Tous niveaux d’alertes', 'Alerte niveau 1 et plus', 'Alerte niveau 2 et plus', 'Alerte niveau 3 et plus', 'Alerte niveau 4 et plus', 'Alerte niveau 5'],
      showBox: true
    };
  },
  methods: {
    hideShowModal() {
      if (!this.manager) {
        this.showBox = !this.showBox;
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
