export default {
  GET_SILOS(state, payload) {
    state.silosList = payload;
  },
  GET_CELL(state, payload) {
    state.cellList = payload;
  },
  GET_FANS(state, payload) {
    state.fansList = payload;
  }
};
