<template>
  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    activeFormValidation
    name="fan"
    size="500"
    title="Ajout de ventilateur"
    @submit="addNewFan"
  >
    <Loader v-if="isLoading" />
    <div class="fan_container">
      <InputOption
        v-if="silos"
        :optionList="silos"
        @modelSelected="selectSilo"
        titreInput="Silo du ventilateur*"
        :modelSelectedDefault="true"
        :valueSelected="silos[0]"
      />
      <InputForm
        @modelSelected="fanNameSelected"
        titreInput="Nom du ventilateur"
        ruleCondition="required"
      />
      <InputForm
        @modelSelected="fanPowerSelected"
        :titreInput="`Puissance (kw)*`"
        type="number"
        ruleCondition="required"
      />

      <div class="mt-2 mb-3">
        <InputRadio
          v-model="addFan.typeFan"
          label=" Air ambiant"
          group="fantype"
          val="Air ambiant"
        />

        <InputRadio
          v-model="addFan.typeFan"
          label="Air réfrigéré"
          group="fantype"
          val="Air réfrigéré"
        />
      </div>

      <p v-if="isErrorMsg" class="message">
        {{ errMsgText }}
      </p>
    </div>
  </AddModal>
</template>

<script>
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import InputRadio from '@/components/atoms/Form/InputRadio';
import InputForm from '@/components/atoms/Inputs/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import InputOption from '@/components/atoms/Inputs/InputOption';
import { mapGetters } from 'vuex';

export default {
  name: 'AddFan',
  emits: ['fanAdded'],
  components: {
    InputForm,
    InputOption,
    InputRadio,
    AddModal,
    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    key: {
      type: Number
    }
  },
  data() {
    return {
      siloId: null,
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      errMsgText: '',
      addFan: {
        fanName: '',
        addFanPower: '',
        typeFan: 'Air ambiant'
      }
    };
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    silos() {
      if (this.GET_SILOS) {
        return this.GET_SILOS.filter(silo => {
          silo.label = silo.name;
          silo.value = silo.name;
          return true;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    selectSilo(nv) {
      this.siloId = nv.id;
    },
    fanNameSelected(nv) {
      this.addFan.fanName = nv;
    },
    fanPowerSelected(nv) {
      this.addFan.addFanPower = nv;
    },
    keyEvent() {
      this.addNewFan(this.silo.id);
    },
    addNewFan() {
      if (this.addFan.fanName === '' || this.addFan.fanName === null) {
        this.isErrorMsg = true;
        this.errMsgText = 'Veuillez indiquer un Nom pour votre ventilateur..';
      } else if (
        this.addFan.addFanPower === '' ||
        this.addFan.addFanPower === null
      ) {
        this.isErrorMsg = true;
        this.errMsgText = 'Une puissance doit être renseignée !';
      } else if (this.addFan.fanName && this.addFan.fanName.length > 0) {
        // this.isLoading = true;

        let data = {
          name: this.addFan.fanName,
          power: this.addFan.addFanPower,
          type: this.addFan.typeFan,
          silo_id: this.siloId === null ? this.silos[0] : this.siloId
        };

        Api.addfan(data)
          .then(({ data }) => {
            this.isLoading = true;
            if (data) {
              this.isErrorMsg = false;
              // ModalStore.hideModal('fan');
              this.addFan.fanName = '';
              this.addFan.addFanPower = '';
              this.isLoading = false;
              this.$emit('fan-added');
              this.$q.notify({
                message: 'Création du ventilateur réussi',
                color: 'positive'
              });
            }
          })
          .catch(error => {
            if (error.response.status == 409) {
              this.isLoading = false;
              this.isErrorMsg = true;
              this.errMsgText = 'Ce nom de ventilateur existe déja';
              this.$q.notify({
                message: 'Ce nom de ventilateur existe déja\nsalut',
                color: 'negative'
              });
            }
          });

          this.$emit('update:isOpen', false);
      }
    },
    resetMsg() {
      if (this.addFan.fanName.length > 1) {
        this.isErrorMsg = false;
      }
    }
  },
  watch: {
    addFan(value) {
      this.addFan.fanName = value;
      this.resetMsg(value);
    },
    key() {
      this.$store.dispatch('silos/loadSilo');
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
