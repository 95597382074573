<template>
  <div id="login_form" class="page">
    <Meta pageTitle="OptiSilo | Création de mot de passe" />

    <div class=" container containerForm">
      <div class="presentation">
        <LogoApp subSecondary="Silo" size="logo_xl" colorApp="silo" />
        <img src="/img/logo-solutionsplus.png" style="transform:scale(0.8)" />
      </div>
      <div class="loginForm">
        <h3>Créer votre compte</h3>
        <p>Entrez votre mot de passe</p>
        <form id="myform" v-on:submit.prevent="onSubmit()">
          <InputForm
            v-if="!invalidToken"
            icon
            nameIcon="key"
            prefixIcon="fas"
            type="password"
            placeholder="Mot de passe"
            v-model="password"
            :value="password"
            :errorMsg="msg.password"
            inputModal="inputIcon"
          />
          <InputForm
            v-if="!invalidToken"
            inputModal="inputIcon"
            icon
            nameIcon="key"
            prefixIcon="fas"
            type="password"
            placeholder="confirmation mot de passe"
            v-model.lazy="checkPassword"
            :value="checkPassword"
            :errorMsg="msg.checkPassword"
            :helperMsg="msg.passwordInvalid"
            :successMsg="msg.passwordSuccess"
          />
                    <br />
          <div class="success" v-if="isSuccess">
            Mot de passe créé avec succès.
          </div>
          <div class="error" v-if="isError && invalidToken">
            {{ msg.msgError }}
          </div>
          <div class="error" v-if="invalidToken">
            Si vous avez oublié votre mot de passe, rapprochez vous de votre
            manager.
          </div>
          <div class="error" v-if="notSamePasswords">
            <p>Les mots de passe ne sont pas identiques.</p>
          </div>
          <div class="error" v-if="passwordSize">
            <p>Merci d'indiquer un mot de passe à 6 caractères minimum.</p>
          </div>
          <br />
          <BtnSubmit
            v-if="!invalidToken"
            title="Envoyer"
            label="Envoyer"
            textBtn="Envoyer"
            textBtnLoader="envoie en cours"
            active-loader
            styleBtn="contained"
            colorBtn="warning"
            class="contentBtn"
            typeBtn="submit"
            icon
            iconCircle
            nameIcon="paper-plane"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Form/InputForm';
import Meta from '@/components/atoms/Meta';
import LogoApp from '@/components/atoms/LogoApp';
export default {
  name: 'ValidPasswordSignIn',
  components: {
    LogoApp,
    BtnSubmit,
    InputForm,
    Meta
  },
  data() {
    return {
      password: '',
      checkPassword: '',
      msg: [],
      isSuccess: false,
      isError: false,
      invalidToken: false
    };
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== '' && this.checkPassword !== '';
    },
    passwordSize() {
      if (this.password.length < 6) {
        return this.password;
      } else {
        return false;
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.password === '') {
        this.msg['password'] = "Merci d'indiquer un mot de passe.";
      }

      if (this.checkPassword === '') {
        this.msg['checkPassword'] = 'Merci de confirmer votre mot de passe.';
      } else {
        let data = {
          password: this.password,
          password_confirm: this.checkPassword,
          token: this.$route.params.token
        };

        Api.validPassword(data)
          .then(({ data }) => {
            if (!data) {
              this.isSuccess = true;
              setTimeout(() => {
                this.isSuccess = false;
                this.$router.push({ name: 'login' });
              }, 2000);
            }
          })
          .catch(error => {
            this.isError = error;
            this.msg['msgError'] = `${error.response.data.message}`;
          });
      }
    }
  },
  mounted() {
    Api.signInTokenOperator(this.$route.params.token)
      .then(({ data }) => {
        if (data.message === 'ok') {
          this.invalidToken = false;
        } else {
          this.invalidToken = true;
          this.msg['msgError'] = data.message;
          setTimeout(() => {
            this.isError = false;
            this.invalidToken = false;
            this.$router.push({ name: 'login' });
          }, 3000);
        }
      })
      .catch(error => {
        this.isError = error;
        this.msg['msgError'] = `${error.response.data.message}`;
      });
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bellota:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap');
</style>

<style lang="scss" src="./style.scss" scoped></style>
