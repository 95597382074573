import GetListTempOperatorGetters from './getters.js';
import GetListTempOperatorMutations from './mutations.js';
import GetListTempOperatorAction from './actions.js';

export default {
  namespaced: true,

  state: {
    operator_temp: []
  },
  getters: GetListTempOperatorGetters,
  mutations: GetListTempOperatorMutations,
  actions: GetListTempOperatorAction
};
