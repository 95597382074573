<template>
  <section class="ecos-ong wrap cfg">
    <h2 style="text-align: center;">Configuration</h2>
    <div>
      <ul
        class="lst-cfg"
        style="display: flex;"
      >
        <li
          style="margin-right: 70px;"
          v-for="item in menu"
          :key="item"
          @click="
            item.name === 'gen'
              ? generalClick(item)
              : item.name === 'spe'
              ? speciesclick(item)
              : item.name === 'elm'
              ? elementclick(item)
              : item.name === 'ope'
              ? operatorClick(item)
              : item.name === 'alertes'
              ? alertesClick(item)
              : ''
          "
          :class="{ actif: item.isActif }"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
    <AlertesSettings v-if="showAlertes"></AlertesSettings>
    <General v-show="showGeneral" />
    <!-- <Species v-show="showSpecies" :roleId="roleId" /> -->
    <ElementsSite v-show="showElement" />
    <Operators v-if="showOperators" />
  </section>
</template>

<script>
// import Api from '@/classes/api/Api';
import { mapGetters } from 'vuex';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import General from '@/components/atoms/Configure/General';
// import Species from '@/components/atoms/Configure/Species';
import ElementsSite from '@/components/atoms/Configure/ElementsSite';
import Operators from '@/components/atoms/Configure/Operators';
import AlertesSettings from '@/components/atoms/Configure/AlertesSettings';

export default {
  name: 'ViewConfiguration',
  props: {
    roleId: {
      type: Number
    }
  },
  components: {
    ElementsSite,
    General,
    // Species,
    Operators,
    AlertesSettings
  },
  data() {
    return {
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      showGeneral: false,
      showOperators: false,
      showSpecies: false,
      showElement: false,
      showAlertes: true,

      menu:
        this.roleId == 2
          ? [
              { id: '0', label: 'Générale', name: 'gen', isActif: true },
              // {
              //   id: '1',
              //   label: 'Liste des espèces',
              //   name: 'spe',
              //   isActif: false
              // },
              {
                id: '2',
                label: 'Éléments du site',
                name: 'elm',
                isActif: false
              }
            ]
          : [
              // { id: '0', label: 'Opérateurs', name: 'ope', isActif: true },
              { id: '0', label: 'Alertes', name: 'alertes', isActif: true },
              // {
              //   id: '2',
              //   label: 'Liste des espèces',
              //   name: 'spe',
              //   isActif: false
              // }
            ]
    };
  },
  // mounted() {
  //   if (this.roleId == 2) {
  //     this.$store.dispatch('species/loadSpecies');
  //     this.$store.dispatch('campaign/loadCampaign');
  //     this.$store.dispatch('grain/loadActionGrain');
  //   }
  // },
  computed: {
    ...mapGetters('species', ['GET_SPECIES']),
    ...mapGetters('campaign', ['GET_CAMPAIGN']),
    ...mapGetters('grain', ['GET_ACTION_GRAIN'])
  },
  methods: {
    generalClick(item) {
      this.showAlertes = false;
      this.showGeneral = true;
      this.showElement = false;
      this.showSpecies = false;
      this.isActifGen = true;
      this.menu.forEach(el => {
        el.isActif = false;
      });
      item.isActif = true;
    },
    speciesclick(item) {
      this.showAlertes = false;
      this.showSpecies = true;
      this.showGeneral = false;
      this.showElement = false;
      this.showOperators = false;
      this.menu.forEach(el => {
        el.isActif = false;
      });
      item.isActif = true;
    },
    operatorClick(item) {
      this.showAlertes = false;
      this.showGeneral = false;
      this.showElement = false;
      this.showSpecies = false;
      this.showOperators = true;
      this.menu.forEach(el => {
        el.isActif = false;
      });
      item.isActif = true;
    },
    elementclick(item) {
      this.showAlertes = false;
      this.showElement = true;
      this.showSpecies = false;
      this.showGeneral = false;
      this.menu.forEach(el => {
        el.isActif = false;
      });
      item.isActif = true;
    },
    alertesClick(item) {
      this.showAlertes = true;
      this.showOperators = false;
      this.showElement = false;
      this.showSpecies = false;
      this.showGeneral = false;
      this.menu.forEach(el => {
        el.isActif = false;
      });
      item.isActif = true;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
