<template>
  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    activeFormValidation
    name="cell"
    size="650"
    title="Ajouter une cellule"
    @submit="addNewCell"
  >
    <Loader v-if="isLoading" />
    <div class="cell_container">
      <InputOption
        v-if="silos"
        :optionList="silos"
        @modelSelected="selectSilo"
        titreInput="Silo de la cellule *"
        :modelSelectedDefault="true"
        :valueSelected="silos[0]"
      />
      <InputForm
        @modelSelected="cellNameSelected"
        titreInput="Nom de la cellule *"
        ruleCondition="required"
      />
      <InputForm
        @modelSelected="cellVolumeSelected"
        :titreInput="`Volume maximum de la cellule en tonne *`"
        ruleCondition="required"
        type="number"
      />

      <template v-if="siloId !== 0">
        <label>Ventilateurs de rattachement</label>
        <div
          class="addCell row-cols-sm-1 row-cols-md-4 mt-2 mb-3"
          :class="[
            {
              'cols w-100 text-center error': fans_can_be_selected.length === 0,
              row: fans_can_be_selected.length > 0
            }
          ]"
        >
          <div
            :class="[
              {
                chooseVent: fans_can_be_selected.length > 0,
                'w-100 text-center': fans_can_be_selected.length === 0
              }
            ]"
          >
            <template v-if="fans_can_be_selected.length > 0">
              <div
                v-for="(fan, indxFan) in fans_can_be_selected"
                :key="indxFan"
                :data-label="indxFan"
              >
                <label :for="fan.id">{{ fan.name }}</label>
                <input
                  type="checkbox"
                  :id="fan.id"
                  :value="fan"
                  v-model="addCell.cellFanId"
                />
              </div>
            </template>
            <template v-else>
              Aucun ventilateur n’est commun à l’ensemble des cellules
              sélectionnées
            </template>
          </div>
        </div>
      </template>
    </div>
  </AddModal>
</template>

<script>
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import InputForm from '@/components/atoms/Inputs/InputForm';
import InputOption from '@/components/atoms/Inputs/InputOption';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import { mapGetters } from 'vuex';

export default {
  name: 'AddCell',
  emits: ['cellAdded'],
  components: {
    InputForm,
    AddModal,
    InputOption,
    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    siloData: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('indicator', ['GET_INDICATOR_LOCATION']),
    ...mapGetters('silos', ['GET_SILOS']),
    silos() {
      if (this.GET_SILOS) {
        return this.GET_SILOS.filter(silo => {
          silo.label = silo.name;
          silo.value = silo.name;
          return true;
        });
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      siloId: 0,
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      errMsgText: '',
      fans_can_be_selected: [],
      addCell: {
        cellName: '',
        cellVolume: '',
        cellDescrip: '',
        cellStatus: 'vide',
        cellFanId: []
      }
    };
  },
  methods: {
    selectSilo(nv) {
      this.siloId = nv.id;
    },
    cellNameSelected(nv) {
      this.addCell.cellName = nv;
    },
    cellVolumeSelected(nv) {
      this.addCell.cellVolume = nv;
    },
    keyEvent() {
      this.addNewCell();
    },
    addNewCell() {
      if (
        this.addCell.cellName === '' ||
        this.addCell.cellName === null ||
        this.addCell.cellName.replace(/\s/g, '') === ''
      ) {
        this.isErrorMsg = true;
        this.errMsgText = 'Veuillez indiquer un Nom pour votre ventilateur..';
      } else if (
        this.addCell.cellFanId === '' ||
        this.addCell.cellFanId === null ||
        this.addCell.cellFanId.length === 0
      ) {
        this.isErrorMsg = true;
        this.errMsgText = "Aucun ventilateur n'est sélectionné !";
      } else if (
        this.addCell.cellVolume === '' ||
        this.addCell.cellVolume === null
      ) {
        this.isErrorMsg = true;
        this.errMsgText = 'Veuillez indiquer une quantité de stockage !';
      } else if (this.addCell.cellName && this.addCell.cellName.length > 0) {
        

        // this.isLoading = true;
        let data = {
          name: this.addCell.cellName,
          volume: this.addCell.cellVolume,
          fan_id: this.addCell.cellFanId,
          description: this.addCell.cellDescrip,
          status: this.addCell.cellStatus,
          silo_id: this.siloId
        };

        Api.addCell(data)
          .then(({ data }) => {
            this.isLoading = true;
            if (data) {
              this.isErrorMsg = false;
              // ModalStore.hideModal('cell');
              this.$emit('update:isOpen', false);
              this.addCell.cellName = '';
              this.addCell.cellVolume = '';
              this.addCell.cellFan = '';
              this.isLoading = false;
              this.$emit('cell-added');
              this.$q.notify({
                message: 'Création de la cellule réussi',
                color: 'positive'
              });
            }
          })
          .catch(error => {
            if (error.response.status == 409) {
              this.isLoading = false;
              this.isErrorMsg = true;
              this.$q.notify({
                message: 'Ce nom de cellule existe déja !',
                color: 'negative'
              });
            }
          });

          this.$emit('update:isOpen', false);
      }
    },
    resetMsg() {
      if (this.addCell.cellName.length > 1) {
        this.isErrorMsg = false;
      }
    }
  },
  watch: {
    addCell(value) {
      this.addCell.cellName = value;
      this.resetMsg(value);
    },
    key() {
      this.$store.dispatch('silos/loadSilo');
    },
    siloId(n) {
      Api.getFansBySilo(n).then(data => {
        this.fans_can_be_selected =
          data.data && data.data.fans ? data.data.fans : [];
          this.addCell.cellFanId = [];
      });
    }
  }

  // async created() {
  //   setTimeout(() => {
  //     this.dataloaded = true;
  //   }, 3000);
  // }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
