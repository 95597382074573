<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="ConfigAlerts">
      <div class="contentForm" v-if="alerts[0].configVue">
        <div>
          <div class="exitButton">
            <button @click="alerts[0].configVue = false"></button>
          </div>
          <div>
            <label
              >La durée autorisée sans saisie est de:
              {{ alerts[0].threshold }} jours</label
            >
            <form
              id="myform"
              class="myForm"
              v-on:submit.prevent="onSubmit()"
              enctype="multipart/form-data"
            >
              <div class="dateSelection">
                <InputForm
                  icon
                  size="xs"
                  nameIcon="calendar-day"
                  prefixIcon="fas"
                  type="text"
                  placeholder="Jours"
                  v-model="alerts[0].threshold"
                  :value="alerts[0].threshold"
                  :errorMsg="errorMsg"
                  inputModal="inputIcon"
                />
                <p>Jours</p>
              </div>
              <label>L'envoi de l'alerte:</label>
              <div class="dateSelection">
                <label for="selectedDateType">Se fera</label>
                <select
                  class="dateTypeLabel"
                  v-model="alerts[0].selectedDateType"
                  id="selectedDateType"
                >
                  <option value="quotidiennement">quotidiennement</option>
                  <option value="hebdomadairement">hebdomadairement</option>
                </select>
              </div>
              <div
                class="dateSelection"
                v-if="alerts[0].selectedDateType === 'quotidiennement'"
              >
                <label class="dateLabel" for="selectedTimeOption">À</label>
                <InputForm
                  type="time"
                  size="xs"
                  v-model="alerts[0].selectedTimeOption"
                  :value="alerts[0].selectedTimeOption"
                  :errorMsg="errorMsg"
                  inputModal="inputIcon"
                />
              </div>

              <div
                class="dateSelection"
                v-else-if="alerts[0].selectedDateType === 'hebdomadairement'"
              >
                <label class="dateLabel" for="selectedDayOption">Le</label>
                <select
                  class="date"
                  v-model="alerts[0].selectedDayOption"
                  id="selectedDayOption"
                >
                  <option value="lundi">Lundi</option>
                  <option value="mardi">Mardi</option>
                  <option value="mercredi">Mercredi</option>
                  <option value="jeudi">Jeudi</option>
                  <option value="vendredi">Vendredi</option>
                  <option value="samedi">Samedi</option>
                  <option value="dimanche">Dimanche</option>
                </select>
                <label class="dateLabel" for="selectedTimeOption">à</label>
                <InputForm
                  type="time"
                  size="xs"
                  v-model="alerts[0].selectedTimeOption"
                  :value="alerts[0].selectedTimeOption"
                  :errorMsg="errorMsg"
                  inputModal="inputIcon"
                />
              </div>
              <!-- <input type="time" v-model="selectedTimeOption" id="selectedTimeOption"> -->
              <div class="Btn">
                <BtnSubmit
                  title="Confirmer"
                  label="Confirmer"
                  textBtn="Confirmer"
                  textBtnLoader="Confirmer"
                  active-loader
                  styleBtn="contained"
                  colorBtn="warning"
                  class="contentBtn"
                  typeBtn="submit"
                  @click="updateAlert(alerts[0])"
                />
              </div>
              <div class="success" v-if="isSuccess">
                Un email a été envoyé à l'opérateur.
              </div>
              <div class="error" v-if="isError">
                {{ msg.msgError }}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="contentForm" v-if="alerts[1].configVue">
        <div>
          <div class="exitButton">
            <button @click="alerts[1].configVue = false"></button>
          </div>
          <div>
            <label
              >La température maximum (Tmax) autorisée est de:
              {{ temperatureMax }}°C</label
            >
            <form
              id="myform"
              class="myForm"
              v-on:submit.prevent="onSubmit()"
              enctype="multipart/form-data"
            >
              <div class="dateSelection">
                <InputForm
                  icon
                  size="xs"
                  nameIcon="calendar-day"
                  prefixIcon="fas"
                  type="text"
                  placeholder="Jours"
                  v-model="alerts[1].threshold"
                  :value="alerts[1].threshold"
                  :errorMsg="errorMsg"
                  inputModal="inputIcon"
                />
                <p>°C</p>
              </div>
              <div>
                <label>L'envoi de l'alerte:</label>
                <div class="dateSelection">
                  <label for="selectedDateType">Se fera</label>
                  <select
                    class="dateTypeLabel"
                    v-model="alerts[1].selectedDateType"
                    id="selectedDateType"
                  >
                    <option value="quotidiennement">quotidiennement</option>
                    <option value="hebdomadairement">hebdomadairement</option>
                  </select>
                </div>
                <div
                  class="dateSelection"
                  v-if="alerts[1].selectedDateType === 'quotidiennement'"
                >
                  <label class="dateLabel" for="selectedTimeOption">À</label>
                  <InputForm
                    type="time"
                    size="xs"
                    v-model="alerts[1].selectedTimeOption"
                    :value="alerts[1].selectedTimeOption"
                    :errorMsg="errorMsg"
                    inputModal="inputIcon"
                  />
                </div>

                <div
                  class="dateSelection"
                  v-else-if="alerts[1].selectedDateType === 'hebdomadairement'"
                >
                  <label class="dateLabel" for="selectedDayOption">Le</label>
                  <select
                    class="date"
                    v-model="alerts[1].selectedDayOption"
                    id="selectedDayOption"
                  >
                    <option value="lundi">Lundi</option>
                    <option value="mardi">Mardi</option>
                    <option value="mercredi">Mercredi</option>
                    <option value="jeudi">Jeudi</option>
                    <option value="vendredi">Vendredi</option>
                    <option value="samedi">Samedi</option>
                    <option value="dimanche">Dimanche</option>
                  </select>
                  <label class="dateLabel" for="selectedTimeOption">à</label>
                  <InputForm
                    type="time"
                    size="xs"
                    v-model="alerts[1].selectedTimeOption"
                    :value="alerts[1].selectedTimeOption"
                    :errorMsg="errorMsg"
                    inputModal="inputIcon"
                  />
                </div>
                <!-- <input type="time" v-model="selectedTimeOption" id="selectedTimeOption"> -->
              </div>
              <div class="Btn">
                <BtnSubmit
                  title="Confirmer"
                  label="Confirmer"
                  textBtn="Confirmer"
                  textBtnLoader="Confirmer"
                  active-loader
                  styleBtn="contained"
                  colorBtn="warning"
                  class="contentBtn"
                  typeBtn="submit"
                  @click="updateAlert(alerts[1])"
                />
              </div>
              <div class="success" v-if="isSuccess">
                Un email a été envoyé à l'opérateur.
              </div>
              <div class="error" v-if="isError">
                {{ msg.msgError }}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="titleHeaders">
        <h4>Liste des alertes</h4>
        <span class="addOperator"> </span>
      </div>
      <div class=" tableContent table-responsive-sm ">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th
                v-for="(value, i) in Object.entries(columns)"
                :key="i"
                scope="col"
              >
                {{ value[1].name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="alert in alerts" :key="alert.id">
              <td>
                <label class="switch">
                  <input
                    type="checkbox"
                    @click="switchClick(alert)"
                    v-model="alert.active"
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                {{ alert.alerteType }}
              </td>
              <td>
                {{ alert.selectedDateType == 'hebdomadairement' ? "Tous les " + alert.selectedDayOption + "s à " + alert.selectedTimeOption : "Tous les jours à " + alert.selectedTimeOption }}
              </td>
               <td>
                {{ alert.id == 1 ? alert.threshold + " jours sans saisie" : alert.threshold + " °C de température max. dépassé" }}
              </td>
              <td class="tooltips">
                <icon
                  size="lg"
                  :icon="['fas', 'cog']"
                  class="iconTooltip"
                  @click="alert.id == 1 ? updateModalNoSaisieModal() : updateModalTemperatureTooHotModal()"
                />
                <span class="tooltiptext">Configurer l'alerte</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ConfigureAlertNoSaisie 
      :isOpen="configureAlertNoSaisieModal"
      @update:isOpen="nv => configureAlertNoSaisieModal = nv"
      :alert="alerts[0]" 
      :user_prop="user"
      @updateAlertsActive="updateAlertsActive"
      name="configureAlertNoSaisie"
      @click.stop
    />
    <ConfigureAlertTemperatureTooHot 
      :isOpen="ConfigureAlertTemperatureTooHotModal"
      @update:isOpen="nv => ConfigureAlertTemperatureTooHotModal = nv"
      :alert="alerts[1]" 
      :user_prop="user" 
      @updateAlertsActive="updateAlertsActive"
      name="ConfigureAlertTemperatureTooHot" 
      @click.stop
    />
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import { mapGetters } from 'vuex';
import Loader from '@/components/atoms/Loader';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Form/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import ConfigureAlertNoSaisie from '@/components/molecules/ConfigureAlertNoSaisie';
import ConfigureAlertTemperatureTooHot from '@/components/molecules/ConfigureAlertTemperatureTooHot';

export default {
  name: 'AlertesSettings',
  components: {
    Loader,
    InputForm,
    BtnSubmit,
    ConfigureAlertNoSaisie,
    ConfigureAlertTemperatureTooHot
  },
  data: function() {
    return {
      configureAlertNoSaisieModal: false,
      ConfigureAlertTemperatureTooHotModal: false,
      closeAddOp: 'closeAddOp',
      addOp: false,
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      modalStore: ModalStore,
      columns: [
        {
          name: 'Activée',
          field: 'active'
        },
        {
          name: "Type d'alerte",
          field: 'alerteType'
        },
        {
          name: "Envoi de l'alerte",
          field: 'sendAlerte'
        },
        {
          name: 'Délai maximum',
          field: 'delaiMax'
        },
        {
          name: 'Configuration',
          field: 'alerteConfig'
        }
      ],
      alerts: [
        {
          id: 1,
          alerteType: 'Durée autorisée sans saisie',
          active: false,
          configVue: false,
          threshold: '14',
          selectedDateType: 'hebdomadairement',
          selectedDayOption: 'lundi',
          selectedTimeOption: '10:00',
          envoi_value: '',
          type_envoi: 1
        },
        {
          id: 2,
          alerteType: 'Température du grain trop élevée',
          active: false,
          configVue: false,
          threshold: '30',
          selectedDateType: 'hebdomadairement',
          selectedDayOption: 'lundi',
          selectedTimeOption: '10:00',
          envoi_value: '',
          type_envoi: 1
        }
      ],
      op: []
    };
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    onSubmit() {
      // console.log();
    },
    updateModalNoSaisieModal() {
      this.configureAlertNoSaisieModal = true;
      // this.modalStore.showModal('configureAlertNoSaisie');
    },
    updateModalTemperatureTooHotModal() {
      this.ConfigureAlertTemperatureTooHotModal = true;
      // this.modalStore.showModal('ConfigureAlertTemperatureTooHot');
    },
    showConfig(alerteKey) {
      this.alerts.forEach(alerts => {
        alerts.configVue = false;
      });
      this.alerts[alerteKey - 1].configVue = true;
    },
    async switchClick(alert) {
      this.isLoading = true;
      
      if (alert.active === false) {
        await Api.addAlertToUser(this.user.id, {
          alert_name: alert.alerteType,
          day:
            alert.selectedDateType === 'hebdomadairement'
              ? null
              : alert.selectedDayOption,
          heure: alert.selectedTimeOption,
          type_envoi: alert.type_envoi,
          sending_mode:
            alert.selectedDateType === 'hebdomadairement' ? false : true,
          threshold: parseInt(alert.threshold, 10)
        }).then(() => {
          this.updateAlertsActive();
        });
      } else {
        await Api.removeAlertToUser(this.user.id, {
          alert_name: alert.alerteType
        }).then(() => {
          this.updateAlertsActive();
        });
      }
      this.isLoading = false;
    },
    async updateAlertsActive() {
      this.isLoading = true;
      this.configureAlertNoSaisieModal = false;
      this.ConfigureAlertTemperatureTooHotModal = false;
      await Api.getAlertOfUser(this.user.id).then(alerts_taken => {
        this.isError = false;
        if (alerts_taken.data.length > 0) {
          alerts_taken.data.forEach(alert_taken => {
            const alert_find = this.alerts.filter(front_alert => {
              return front_alert.alerteType === alert_taken.name;
            });
            if (alert_find[0] != undefined) {
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].active = true;
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].threshold = alert_taken.pivot.threshold;
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].selectedDateType = alert_taken.pivot.sending_mode
                ? 'quotidiennement'
                : 'hebdomadairement';
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].selectedDayOption =
                alert_taken.pivot.day == null ? 'lundi' : alert_taken.pivot.day;
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].selectedTimeOption = alert_taken.pivot.heure;
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].type_envoi = alert_taken.pivot.type_envoi;
              this.alerts[
                this.alerts.findIndex(alert => {
                  return alert == alert_find[0];
                })
              ].envoi_value = alert_taken.pivot.envoi_value;
            }
          });
        } else {
          this.alerts.forEach(alert => (alert.active = false));
        }
      });
      this.isLoading = false;
    },
    async updateAlert(alert) {
      this.isLoading = true;
      if (alert.active === false) {
        await Api.addAlertToUser(this.user.id, {
          alert_name: alert.alerteType,
          day:
            alert.selectedDateType === 'hebdomadairement'
              ? null
              : alert.selectedDayOption,
          heure: alert.selectedTimeOption,
          sending_mode:
            alert.selectedDateType === 'hebdomadairement' ? false : true,
          threshold: parseInt(alert.threshold, 10)
        }).then(() => {
          this.updateAlertsActive();
        });
      }
      Api.updateAlertToUser(this.user.id, {
        alert_name: alert.alerteType,
        day: alert.selectedDayOption,
        heure: alert.selectedTimeOption,
        sending_mode:
          alert.selectedDateType === 'hebdomadairement' ? false : true,
        threshold: parseInt(alert.threshold, 10)
      })
        .then(data => {
          this.$q.notify({
            message: data.message,
            color: 'positive'
          });
          this.updateAlertsActive();
          this.isLoading = false;
        })
        .catch(e => {
          console.log(e.response);
          this.isLoading = false;
        });
    }
  },
  watch: {
    //
  },
  async mounted() {
    await this.updateAlertsActive();
  },
  created() {
    this.$store.dispatch('listOperator/LoadOperators');
    this.$store.dispatch('listLocation/LoadLocation');
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
