import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home';
import Api from '@/classes/api/Api';
import Login from '@/components/molecules/Login';
// import Register from '@/components/molecules/Register';
import Forgot from '@/components/molecules/Forgot';
import Reset from '@/components/molecules/Reset';
import NotFound from '@/components/molecules/NotFound';
// import UserAccount from '@/components/molecules/UserAccount';
import DashboardOperator from '@/views/dashboard/operator';
import DashboardSupervisor from '@/views/dashboard/supervisor';
import ConfigurationSupervisor from '@/views/configuration/supervisor';
import ValidPasswordSignIn from '@/components/molecules/ValidPasswordSignIn';
import store from '../store';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      let user = store.getters['user/user'];
      if (to.name !== 'login' && user) next({ name: 'dashboard_operator' });
      else next({ name: 'login' });
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      let user = store.getters['user/user'];
      if (to.name == 'login' && user) next({ name: 'dashboard_operator' });
      else next();
    }
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: Register
  // },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot
  },
  {
    path: '/reset/:token',
    name: 'reset',
    component: Reset
  },
  {
    path: '/inscription/operator/:token',
    name: 'valid-password',
    component: ValidPasswordSignIn
  },
  {
    path: '/operator/dashboard',
    name: 'dashboard_operator',
    component: DashboardOperator,
    beforeEnter: async (to, from, next) => {
      let user = store.getters['user/user'];
      if (to.name !== 'login' && !user) {
        next({ name: 'login' });
      } else if (user.role_id == 2) {
        console.log("operateur")
        next();
      } else if (user.role_id == 1 || user.role_id == 3) {
        console.log("manager")
        next({ name: 'dashboard_supervisor' });
      }
    }
  },
  {
    path: '/supervisor/dashboard',
    name: 'dashboard_supervisor',
    component: DashboardSupervisor,
    beforeEnter: (to, from, next) => {
      let user = store.getters['user/user'];
      if (to.name !== 'login' && !user) {
        next({ name: 'login' });
      } else if (user.role_id == 1) {
        next();
      } else if (user.role_id == 2) {
        next();
      }
      // else console.log("CONNECTION");
    }
  },
  {
    path: '/supervisor/config',
    name: 'config_supervisor',
    component: ConfigurationSupervisor,
    beforeEnter: (to, from, next) => {
      let user = store.getters['user/user'];
      console.log(user)
      if (to.name !== 'login' && !user) {
        next({ name: 'login' });
      } else if (user.role_id != 1) {
        next({ name: 'dashboard_operator' });
      } else {
        next();
      }
      // else console.log("CONNECTION");
    }
  },

  // {
  //   path: '/edit/:id',
  //   name: 'userAccount',
  //   component: UserAccount,
  //   beforeEnter: (to, from, next) => {
  //     let user = store.getters['user/user'];
  //     if (to.name !== 'login' && !user) next({ name: 'login' });
  //     else next();
  //   }
  // },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: '404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  if (to.query.access_token) {
    sessionStorage.setItem('token', JSON.stringify(to.query.access_token));
    localStorage.setItem('rememberMe', false);
  };
  let result;
  await Api.checkToken().then(data => {
    result = data.data;
  });
  if ((to.name !== 'login' && to.name !== 'reset') && (result && !result.success)) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    if (rememberMe === false) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('user_rollback');
      sessionStorage.removeItem('token_rollback');
      sessionStorage.removeItem('impersonate_activated');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_rollback');
      localStorage.removeItem('token_rollback');
      localStorage.removeItem('impersonate_activated');
    }
    next({ name: 'login' });
  } else {
    store.commit('user/user', result.user);
    sessionStorage.setItem('user', JSON.stringify(result.user));
  }
  next();
});

export default router;
