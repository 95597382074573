<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      closeBtnCenter
      name="multiVentilationCellsFan"
      @click.stop
      size="1280"
      :enableBackground="true"
      fieldBackground="name"
      :widthPlus="nameTab === 'cellule'"
      :displaySendDataButton="false"
      title="Ventilation Multicellules"
    >
      <section class="ecos-ong wrap ">
        <Tabs :tabs="tabs" :active="currentTab" @change-tab="statusTab">
          <template #cellule>
            <div class="wrap2">
              <p class="gauche small">
                <!--{{ cellsNotEmpty(cellsId).length }} cellules remplies sont
                actuellement associées à ce ventilateur. <br />-->La saisie de
                données de ventilation multi-cellules implique la saisie de la
                température après ventilation de chaque cellule concernée.
              </p>

        <InputOption
          :optionList="silos_for_filter"
          @modelSelected="cellsNotEmpty"
          titreInput="Filtrer par silo"
          :modelSelectedDefault="true"
          valueSelected="Tous les silos"
          style="width: 33%;"
        />
              <div class="silo_cells" v-if="cellsNotEmpty(null).length > 0 && !reload">
                <div
                  class="cell"
                  v-for="(item, i) in cellsNotEmpty(null)"
                  :key="i"
                  :data-label="i"
                >
                  <span>
                    <label class="control control-checkbox">
                      <input
                        type="checkbox"
                        :value="item"
                        v-model="cells_to_ventilate"
                      />
                      <div class="control_indicator"></div>
                    </label> </span
                  ><span>
                    <b>{{ item.name }}</b> - {{ item.species_id ? item.species.name + ' ' + item.sub_species.name : item.stored_species.name }}
                    <br />
                    <span v-if="item.lastSaisie">
                      Dernière opération : {{ item.lastSaisie }}
                      <br />
                    </span>
                    <span v-if="item.dureeCumul">
                      Durée cumulée :{{ item.dureeCumul }} h
                      <br />
                    </span>
                    <span>
                      °C Max :
                      {{ item.tmi_max ? item.tmi_max : '-' }}
                      °C
                    </span>
                  </span>
                </div>
              </div>
              <div v-else>
                Aucune cellule n'est disponible pour ce silo.
              </div>
            </div>
          </template>
          <template #ventilation>
            <div class="wrap2">
              <q-form @submit="addMultiVent()">
                <dl>
                  <dt class="vent_used">
                    Ventilateurs utilisés
                  </dt>
                  <dd class="chooseVent" v-if="fans_can_be_selected.length > 0">
                    <div
                      v-for="(fan, indxFan) in fans_can_be_selected"
                      :key="indxFan"
                      :data-label="indxFan"
                    >
                      <label :for="fan.id" class="control control-checkbox"
                        >{{ fan.name }}
                        <input
                          type="checkbox"
                          :id="fan.id"
                          :value="fan"
                          v-model="fans_selected"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                  </dd>
                  <dd class="noVent" v-else>
                    Aucun ventilateur n’est commun à l’ensemble des cellules
                    sélectionnées
                  </dd>
                  <div class="row">
                    <div class="col-4">
                      <dd class="ventDd">
                        <InputDate
                          class="date"
                          :last_input="cellLastInput"
                          titleDate="Date de l’opération"
                          :isRange="false"
                          :dayValue="multiVentiDate"
                          @valuePickerUniq="setMultiVentiDate"
                        />
                        <span v-if="errorDate" class="errorDate"
                          >La date doit être entre comprise entre
                          {{ day(checkDateInput()) }} et
                          {{ day(maxDate) }}
                        </span>
                      </dd>
                    </div>
                    <div class="col-4">
                      <dd class="ventDd">
                        <InputForm
                          inputModal="classic"
                          type="number"
                          titreInput="Durée de ventilation depuis la dernière opération *"
                          :min="0"
                          :max="999"
                          :valueSelected="multiVentiTime"
                          @modelSelected="changeMultiVentiTime"
                        />
                      </dd>
                    </div>
                    <div class="col-4">
                      <dd class="ventDd">
                        <InputForm
                          inputModal="classic"
                          type="number"
                          titreInput="Consigne Thermostat *"
                          :min="-999"
                          :max="80"
                          :temp="true"
                          :valueSelected="multiVentiThemo"
                          @modelSelected="changeMultiVentiThemo"
                        />
                      </dd>
                    </div>
                  </div>
                  <ul class="lst-cell" v-if="cellsId && cellsId.length > 0">
                    <li class="specieDefault">
                      <b>Cellule</b>
                      <b>Tonnage Actuel</b>
                      <b>Dernière opération</b>
                      <b>Etat ventilation</b>
                      <b>°C Min</b>
                      <b>°C Moy</b>
                      <b>°C Max</b>
                    </li>
                    <template
                      v-for="(item, i) in cellsNotEmpty(null)"
                      :key="i"
                      :data-label="i"
                    >
                      <li v-if="cells_to_ventilate.includes(item)">
                        <div class="titre-cell">
                          <span class="titre">
                            {{ item.name }} - {{ item.species_id ? item.species.name + ' ' + item.sub_species.name : item.stored_species.name }}
                          </span>
                        </div>

                        <!-- TONNAGE  -->
                        <dd>
                          <InputForm
                            type="number"
                            :max="item.volume + (item.volume * 30 / 100)"
                            ruleCondition="required max notZero"
                            @modelSelected="changeTonnage($event, item)"
                            :valueSelected="getCellInputValue(item.id)"
                            :step="0.01"
                          />
                        </dd>

                        <dd v-if="item.cell_ventilation[
                              item.cell_ventilation.length - 1
                            ]">
                        {{
                          day(
                            item.cell_ventilation[
                              item.cell_ventilation.length - 1
                            ].date
                          )
                        }}
                      </dd>
                      <dd v-else>-</dd>
                      <dd>
                        Durée cumulée
                        <span
                          >{{
                            item.dureeCumul
                          }}
                          h</span
                        ><br />
                        <span>
                          Thermostat
                          {{
                            item.cell_ventilation[
                              item.cell_ventilation.length - 1
                            ]
                              ? item.cell_ventilation[
                              item.cell_ventilation.length - 1
                            ].thermostat
                              : '-'
                          }}
                          °C</span
                        >
                      </dd>
                        <!-- T2 Mini -->
                        <dd>
                          <InputForm
                            :read="!verifyIfCellWasSelected(item)"
                            :readonly="!verifyIfCellWasSelected(item)"
                            inputModal="classic"
                            type="number"
                            :max="999"
                            :valueSelected="item.tmi_min"
                            @modelSelected="changeTemperature($event, item, 'T2Min')"
                            :step="0.01"
                          />
                        </dd>
                        <!-- T2 Moy -->
                        <dd>
                          <InputForm
                            :read="!verifyIfCellWasSelected(item)"
                            :readonly="!verifyIfCellWasSelected(item)"
                            inputModal="classic"
                            type="number"
                            :max="999"
                            :valueSelected="item.tmi"
                            @modelSelected="changeTemperature($event, item, 'T2')"
                            :step="0.01"
                          />
                        </dd>

                        <!-- T2 Max -->
                        <dd>
                          <InputForm
                            :read="!verifyIfCellWasSelected(item)"
                            :readonly="!verifyIfCellWasSelected(item)"
                            inputModal="classic"
                            type="number"
                            :max="999"
                            :valueSelected="item.tmi_max"
                            @modelSelected="changeTemperature($event, item, 'T2Max')"
                            :step="0.01"
                          />
                        </dd>
                      </li>
                    </template>
                  </ul>
                  <div class="btn-stuff">
                    <p v-if="msg" class="mesg">
                      Vos données de ventilation sont enregistrées
                    </p>
                    <p class="message">
                      <span v-if="!cells_to_ventilate.length">
                        <u></u>Au moins une cellule doit comporter une température
                        après ventilation (t2) et (t2-Max).</span
                      >
                      <span v-if="fans_selected.length === 0">
                      Au moins un ventilateur doit être sélectionné
                      </span>

                      <br />
                    </p>
                    <span v-if="errorSendDate" class="errorDate"
                      >{{ msgError }}
                    </span>
                    <p>
                      <BtnAdd
                        v-if="btnEnvoi"
                        title="Envoyer les données"
                        textBtn="Envoyer les données"
                        textBtnLoader="Envoie en cours"
                        :styleBtn="isLoading ? 'outlined' : 'contained'"
                        colorBtn="warning"
                        class="contentBtn"
                        typeBtn="submit"
                        activeLoader
                        ref="btn1"
                        :disabled="
                          fans_selected.length > 0 && cells_to_ventilate.length > 0 ? false : true
                        "
                      />
                      <BtnAdd
                        tabindex="6"
                        title="FERMER"
                        textBtn="FERMER"
                        styleBtn="contained"
                        colorBtn="success"
                        typeBtn="button"
                        id="envoiDV"
                        v-if="btnOk"
                        @click="closeModal"
                      />
                    </p>
                  </div>
                </dl>
              </q-form>              
            </div>
          </template>
        </Tabs>
      </section>
    </AddModal>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import Api from '@/classes/api/Api';
import Tabs from '@/components/atoms/Tabs';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import BtnAdd from '@/components/atoms/BtnLoader';
import InputForm from '@/components/atoms/Inputs/InputForm';
import InputDate from '@/components/atoms/Inputs/InputDate';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import InputOption from '@/components/atoms/Inputs/InputOption';

export default {
  name: 'AddMultiVentilationCellsFan',
  props: {
    isOpen: { type: Boolean, default: false },
    cellsId: {
      type: Object
    }
  },
  components: {
    // ModalHeader,
    // AlerteModal,
    InputForm,
    InputDate,
    InputOption,
    AddModal,
    BtnAdd,
    Loader,
    Tabs
  },
  data() {
    return {
      reload: false,
      msgError: '',
      closeAlerte: true,
      errorDate: false,
      errorSendDate: false,
      isLoading: false,
      btnEnvoi: true,
      btnOk: false,
      fieldBackground: { type: String },
      enableBackground: { type: Boolean, default: false },
      modalStore: ModalStore,
      isErrorMsg: false,
      msg: false,
      currentTab: 'cellule',
      tabs: {
        cellule: 'Cellules Ventilées',
        ventilation: 'Ventilation'
      },
      multiVentiDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      multiVentiTmaxt1: 0,
      ventTemperatureBefore: 0,
      ventTemperatureAfter: 0,
      multiVentiTmaxt2: 0,
      multiVentiTime: 0,
      multiVentiThemo: 0,
      cells_to_ventilate: [],
      fans_can_be_selected: [],
      cells_to_ventilate_filtred: [],
      cellObjectById: [],
      fans_selected: [],
      cellInput: [],
      cellTonnageChange: [],
      cellTonnageInput: [],
      resultId: '',
      goodDate: '',
      nameTab: 'cellule',
      silo_id: -1,
      silos_for_filter: []
    };
  },

  watch: {
    isOpen() {
      this.cells_to_ventilate = []
      this.cellTonnageChange = []
    },
    async cells_to_ventilate() {
      this.resultId = this.cells_to_ventilate.map(d => d.id);
      if (this.cells_to_ventilate.length === 0) {
        //Pas de cellules pas de fans
        this.fans_can_be_selected = [];
      } else if (this.cells_to_ventilate.length === 1) {
        //Ajout de la premiere cellule donc on verifie pas les fans
        this.fans_can_be_selected = this.cells_to_ventilate[0].fans;
      } else {
        this.cells_to_ventilate.forEach(c => {
          // On prend les fanid a verifier de la cellule en cours
          let idFanToCheck = c.fans.map(f => f.id);
          // On prend les fanid deja stocké des autres celulles
          //Intersection des deux arrays
          const filteredArray = idFanToCheck.filter(value => {
            return idFanToCheck.includes(value);
          });
          //On reattribue les objets fans par rapport aux IDS
          this.fans_can_be_selected = this.fans_can_be_selected.filter(f =>
            filteredArray.includes(f.id)
          );
        });
      }
      let precheckedFan = [];
      let fanIdToCheck = [];
      //On va chercher si la cellule coché fait partie de la derniere saisie pour la pré-coché
      this.fans_can_be_selected.forEach(() => {
        this.cellsId.forEach(c => {
          // Si on a un seul ventilo, on va le cocher
          if (this.resultId.includes(c.id) && this.fans_can_be_selected.length == 1) {
            fanIdToCheck.push(
              this.fans_can_be_selected[0].id
            );
          // Sinon on va checker les ventilos utilisés sur la dernière saisie et les précocher
          } else {
            if (
              this.resultId.includes(c.id) &&
              c.cell_ventilation &&
              c.cell_ventilation.length > 0
            ) {
              if (c.cell_ventilation[c.cell_ventilation.length - 1].code) {
                //La derniere est une multi on va chercher tous les fans utilisées pour cette multi
                let tmp = c.cell_ventilation.filter(v => {
                  return (
                    v.code ==
                    c.cell_ventilation[c.cell_ventilation.length - 1].code
                  );
                });
                if (tmp.length > 0) {
                  tmp.forEach(cv => {
                    fanIdToCheck.push(cv.fan_id);
                  });
                }
              } else {
                fanIdToCheck.push(
                  c.cell_ventilation[c.cell_ventilation.length - 1].fan_id
                );
              }
            }
          }
          fanIdToCheck = [...new Set(fanIdToCheck)];
          precheckedFan = this.fans_can_be_selected.filter(f => {
            return fanIdToCheck.includes(f.id);
          });
        });
      });
      this.fans_selected = precheckedFan;
      if (this.resultId.length > 0) {
        await this.$store.dispatch(
          `lastDateCell/loadDateLastActionCell`,
          this.resultId
        );
      } else {
        return '';
      }
    }
  },
  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    ...mapGetters('time', ['GET_CUMUL']),
    ...mapGetters('silos', ['GET_CELL']),
    ...mapGetters('lastDateCell', ['GET_LAST_DATE_ACTION_CELL']),
    cellLastInput() {
      let cells = this.cells_to_ventilate
      return cells[0]
        ? new Date(
            cells.sort((a, b) => {
              return (
                b.lastSaisieTimestamp * 1000 - a.lastSaisieTimestamp * 1000
              );
            })[0].lastSaisieTimestamp * 1000
          )
        : null;
    }
  },
  created() {
    const cellsNotEmpty = this.cellsNotEmpty(null);
    if (cellsNotEmpty) {
      cellsNotEmpty.forEach(cell => {
        this.cellTonnageInput.push({
          cell_id: cell.id,
          input: cell.current_volume
        });
      });
    }
    Api.getSilo().then(({ data }) => {
      data[0].silo.forEach(silo => {
        this.silos_for_filter.push({
          value: silo.id,
          label: silo.name
        });
      });
    });
    this.silos_for_filter.push({
      value: -1,
      label: 'Tous les silos'
    });
  },
  updated() {
    const cellsNotEmpty = this.cellsNotEmpty(null);
    if (cellsNotEmpty) {
      cellsNotEmpty.forEach(cell => {
        this.cellTonnageInput.push({
          cell_id: cell.id,
          input: cell.current_volume
        });
      });
    }
    this.reloadCells();
  },
  methods: {
    reloadCells() {
      this.reload = true;
      this.reload = false;
    }, 
    setMultiVentiDate(nv) {
      this.multiVentiDate = nv;
    },
    changeMultiVentiTime(nv) {
      this.multiVentiTime = nv;
    },
    changeMultiVentiThemo(nv) {
      this.multiVentiThemo = nv;
    },
    filterBySiloId(cells) {
      if (this.silo_id != -1) {
        const cellsNotEmpty = cells;
        let vm = this;
        if (cellsNotEmpty) {
          return cellsNotEmpty.filter(cell => {
            return cell.silo_id == vm.silo_id;
          });
        }
      }
      return cells;
    },
    getCellInputValue(cell_id) {
      const cell = this.cellTonnageInput.filter(cell => {
        return cell.cell_id == cell_id;
      });
      return cell[0] ? cell[0].input : [];
    },
    setCellInputValue(cell_id, newValue) {
      const cell = this.cellTonnageInput.filter(cell => {
        return cell.cell_id == cell_id;
      });
      if (cell.length > 0) {
        const isSameObject = element => element.cell_id == cell_id;
        this.cellTonnageInput[
          this.cellTonnageInput.findIndex(isSameObject)
        ].input = newValue;
      } else {
        const isSameObject = element => element.cell_id == cell_id;
        this.cellTonnageInput.splice(
          this.cellTonnageInput.findIndex(isSameObject),
          1,
          { cell_id: cell_id, input: newValue }
        );
      }
    },
    createArr() {
      let newArrSilo = [];
      this.GET_SILOS.map(silo => {
        this.silo_id = silo.id;
        newArrSilo.push(this.silo_id);
      });
      newArrSilo.map(id => {
        this.$store.dispatch('time/loadCumul', id);
      });
    },
    statusTab(evt) {
      this.nameTab = evt;
      return this.nameTab;
    },
    setDate(evt) {
      if (evt < this.checkDateInput(this.goodDate)) {
        this.errorDate = true;
        this.multiVentiDate = '';
        // this.modalStore.showModal('confirmationDate');
      } else {
        this.errorDate = false;
        this.errorSendDate = false;
        this.multiVentiDate = evt;
        this.errorDate = false;
      }
    },

    checkDateInput() {
      if (this.GET_LAST_DATE_ACTION_CELL.length > 0) {
        this.goodDate = this.datejsInput(
          this.GET_LAST_DATE_ACTION_CELL[0].date
        );
        return this.goodDate;
      } else {
        return '';
      }
    },

    day(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D MMMM YYYY');
    },
    dayNumeric(date) {
      return dayjs(date)
        .locale('fr')
        .format(' DD/MM/YYYY');
    },
    datejsInput(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    closeModal() {
      // ModalStore.hideModal('multiVentilationCellsFan');
      this.$emit('update:isOpen', false);
      this.btnOk = false;
      this.msg = false;
      this.isErrorMsg = false;
      this.multiVentiDate = new Date().toISOString().substr(0, 10);
      this.multiVentiTime = 0;
      this.multiVentiThemo = 0;
    },

    getTemperature(cell, temp) {
      const checkIfCellExistInCellsToVentilate = obj => obj.id === cell.id;
      const indexCellInArray = this.cells_to_ventilate.findIndex(
        checkIfCellExistInCellsToVentilate
      );
      if (temp == 'T1Max' && indexCellInArray == -1) {
        return cell.tmi_max ? cell.tmi_max : 0;
      } else if (temp == 'T2Min' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after_min
          ? this.cells_to_ventilate[indexCellInArray].temperature_after_min
          : 0;
      } else if (temp == 'T2' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after
          ? this.cells_to_ventilate[indexCellInArray].temperature_after
          : 0;
      } else if (temp == 'T2Min' && indexCellInArray == -1) {
        return 0;
      } else if (temp == 'T2' && indexCellInArray == -1) {
        return 0;
      } else if (temp == 'T2Max' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after_max
          ? this.cells_to_ventilate[indexCellInArray].temperature_after_max
          : 0;
      } else if (temp == 'T2Max' && indexCellInArray == -1) {
        return 0;
      }
    },
    changeTemperature(evt, cell, temp) {
      const checkIfCellExistInCellsToVentilate = obj => obj.id === cell.id;

      const indexCellInArray = this.cells_to_ventilate.findIndex(
        checkIfCellExistInCellsToVentilate
      );

      if (temp == 'T2') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after = parseFloat(evt);
      } else if (temp == 'T2Min') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after_min = parseFloat(evt);
      } else if (temp == 'T2Max') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after_max = parseFloat(evt);
      }
    },
    changeTonnage(evt, cell) {
      cell.new_volume = evt;
    },
    verifyIfCellWasSelected(cell) {
      let indexCellInArray = this.cells_to_ventilate.filter(item => {
        return cell.id == item.id;
      });

      if (indexCellInArray.length > 0) {
        this.cellObjectById = cell;
        return true;
      } else {
        return false;
      }
    },

    cellsNotEmpty(param) {
      if (param != null || param != undefined) {
        if (param == 'Tous les silos') {
         this.silo_id = -1;
        } else {
          this.silo_id = param.value;
        }
      }
      const cells = [];
      this.cellsId.forEach(cell => {
        if (cell.current_volume != 0) {
          cells.push(cell);
        }
      });
      var filteredCells = cells;
      if (this.silo_id != -1) {
        filteredCells = this.filterBySiloId(cells);
      }
      return filteredCells;
    },
    addMultiVent() {
      this.confirmMultiVent();
    },
    confirmMultiVent() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      let cellToSend = [];
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
      this.cells_to_ventilate.forEach(cell => {
        cellToSend.push({
          temperature_before_min: cell.tmi_min ? cell.tmi_min : 0,
          temperature_before: cell.tmi,
          temperature_before_max: cell.tmi_max ? cell.tmi_max : 0,
          temperature_after_min: cell.temperature_after_min
            ? cell.temperature_after_min
            : 0,
          temperature_after: cell.temperature_after,
          temperature_after_max: cell.temperature_after_max,
          volume: cell.current_volume,
          user_id: user.id,
          cell_id: cell.id,
          fan_id: cell.fan_id,
          date: this.multiVentiDate,
          time: parseInt(this.multiVentiTime),
          thermostat: parseInt(this.multiVentiThemo),
          multi_vents_active_volume: cell.new_volume != cell.current_volume ? 1 : 0,
          new_volume: cell.new_volume ? cell.new_volume : 0,
          fans: this.fans_selected
        });
      });

      Api.addVent({ cells_ventilation: cellToSend })
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('silos/loadSilo');
            // this.$store.dispatch('silos/loadCell');
            this.$store.dispatch('listFillings/loadFillings');
            //  this.$store.dispatch('silos/loadFans');
            this.$store.dispatch('indicator/loadIndicatorLocation');
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.btnOk = true;
            this.msg = true;
            this.$refs.btn1.activeLoader = false;
            this.isLoading = false;
          }
          this.$store.dispatch('indicator/loadIndicatorLocation');
          this.$emit('done');
          this.createArr();

          this.closeModal()
          this.$q.notify({
            message: `${data.message}`,
            color: 'positive'
          });
        })
        .catch(error => {
          this.msgError = error.response.data.message;
          this.errorSendDate = true;
          this.isLoading = false;
          this.$refs.btn1.activeLoader = false;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
