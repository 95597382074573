<template>
  <q-card
    class="stats_card"
    ref="statsCard"
    bordered
    style="position:relative;"
    v-if="graphic_title"
  >
    <div class="title">
      <div class="iconContent" :class="iconName">
        <img :src="icon" class="icon" alt="" />
      </div>
      {{ graphic_title }}
    </div>
      <br v-if="br" />
    <div style="position: relative;">
      <VueHighcharts
        type="chart"
        ref="chartComponent"
        :options="chartOptions"
        :redrawOnUpdate="true"
        :oneToOneUpdate="true"
        :animateOnUpdate="true"
      />
      <div class="graph_info">
        <p class="percentage">
          {{ before_unit + (stat !== null ? stat : '-') + after_unit }}
        </p>
      </div>
    </div>
  </q-card>
  <br />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import HighCharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
// import Api from 'src/boot/api/Api.js';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

HighchartsMore(HighCharts);

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ChartAiguille',
  components: { VueHighcharts },
  props: {
    graphic_title: { type: String },
    stat: { type: [Number, null]},
    before_unit: { type: String, default: '' },
    after_unit: { type: String, default: '' },
    colors: { type: Array },
    colorsPositions: { type: Array },
    maxPosition: { type: Number },
    icon: { type: String },
    iconName: { type: String },
    br: { type: Boolean },
    datePeriode: {
      type: Object,
      default() {
        return {
          from: dayjs(new Date())
            .locale('fr')
            .format('DD/MM/YYYY'),
          to: dayjs(new Date())
            .locale('fr')
            .format('DD/MM/YYYY')
        };
      }
    }
  },
  watch: {
    stat() {
      let pos =
        (parseInt(this.stat) / parseInt(this.maxPosition)) * 100 > 100
          ? 100
          : (parseInt(this.stat) / parseInt(this.maxPosition)) * 100;
      this.chartOptions.series[
        this.chartOptions.series.findIndex(value => {
          return value.name === 'EGUILLE';
        })
      ].data = [pos];
    }
  },
  data() {
    return {
      key: 0,
      widthCalculate: 0,
      locationId: null,
      dataReady: false,
      hcInstance: HighCharts,
      chartOptions: {
        chart: {
          type: 'pie'
        },

        title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: -5
        },

        tooltip: {
          enabled: false
        },

        yAxis: {
          backgroundColor: 'white',

          min: 0,
          max: 100,
          lineWidth: 0,
          marginTop: 50,
          tickPositions: [],
          tickInterval: false,
          labels: {
            enabled: false
          }
        },

        plotOptions: {
          pie: {
            colors: [...this.colors], // COULEURS
            dataLabels: {
              enabled: false,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 90
          }
        },

        pane: {
          startAngle: -90,
          endAngle: 90,
          background: []
        },

        series: [
          {
            name: 'EGUILLE',
            showInLegend: false,
            showFirstLabel: false,
            showLastLabel: false,
            type: 'gauge',
            dataLabels: {
              enabled: false
            },
            backgroundColor: 'transparent',
            data: [
              this.stat !== null ? ((parseInt(this.stat) / parseInt(this.maxPosition)) * 100 > 100
                ? 100
                : (parseInt(this.stat) / parseInt(this.maxPosition)) * 100) : 0.00001
            ] //POSITION DE L'EGUILLE
          },
          {
            showInLegend: false,
            showFirstLabel: false,
            showLastLabel: false,
            type: 'pie',
            dataLabels: {
              enabled: false
            },
            innerSize: '90%',
            data: [...this.colorsPositions] // POSTION DES DEUX COULEURS
          }
        ],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        }
      },
      dureeVentil: [
        {
          name: 'Froid disponible',
          hours: this.roundDecimal(12),
          color: '#01b6ef'
        },
        {
          name: 'Ventilation efficace',
          hours: this.roundDecimal(12),
          color: '#5CB44D'
        },
        {
          name: 'Ventilation inefficace',
          hours: this.roundDecimal(12),
          color: '#EA5434'
        },
        {
          name: 'Froid non utilisé',
          hours: this.roundDecimal(12),
          color: '#8A8A8A'
        }
      ],
      Ventilation: [
        {
          className: 'my-card1',
          iconName: 'elect',
          name: 'Efficacité ventilation',
          moyenne: 'Moyenne en amélioration',
          pourcent: 5,
          arrow: 'up',

          color: '#36a139'
        },
        {
          className: 'my-card2',
          iconName: 'temp-cold-line_white',
          name: 'Heures de froid utilisées',
          moyenne: 'Moyenne en baisse',
          pourcent: 6,
          arrow: 'down',
          color: '#ea5434'
        }
      ],
      chart: null
    };
  },
  methods: {
    roundDecimal(nombre, precision) {
      var precision2 = precision || 2;
      var tmp = Math.pow(10, precision2);
      return Math.round(nombre * tmp) / tmp;
    }
  }
});
</script>

<style lang="scss" src="./style.scss"></style>
