<template>
  <div id="login_form" class="page">
    <Meta pageTitle="OptiSilo | Page de connexion" />

    <div class=" container containerForm" v-if="typeLogin == 0">
      <div class="presentation">
        <LogoApp
          subSecondary="Silo"
          size="logo_xl"
          colorApp="silo"
        />
        <img src="/img/logo-solutionsplus.png" style="transform:scale(0.8)" />
      </div>
      <div class="loginForm">
        <form
          id="myform"
          v-on:submit.prevent="onSubmit()"
          enctype="multipart/form-data"
        >
          <!--<h2 class="title">
            Pour bien préparer la saisie des enregistrements
          </h2>
          <p class="mb-5">
            Afin de pouvoir réaliser la saisie des enregistrements et assurer la
            fiabilité des Indicateurs de performance, nous vous conseillons de
            préparer les informations suivantes : cellule ventilée, sa capacité
            (pleine et actuelle), le ventilateur et sa puissance (plaque
            moteur), la température moyenne au remplissage de la cellule, la
            température avant et après chaque ventilation, la consigne du
            thermostat si présent, les temps de ventilation, le coût du
            kilowattheure.
          </p>-->
          <p></p>

          <InputForm
            class="mdp login"
            label="Identifiant ou adresse e-mail"
            placeholder="Ex: Lmarcheciel"
            type="email"
            v-model="email"
            :errorMsg="msg.email"
            :helperMsg="msg.emailInvalid"
            :value="email"
          />
          <!--             forgot
            forgotValue="Identifiant oublié ?"
            @forgot="forgot" -->

          <InputForm
            class="mdp login"
            placeholder="••••••••••••"
            label="Mot de passe"
            type="password"
            v-model="password"
            :errorMsg="msg.password"
            :value="password"
            forgot
            forgotValue="Mot de passe oublié ?"
            @forgot="forgot"
          />
          <!-- <div class="forgot">
          <p @click="() => $router.push({ name: 'forgot' })">
            Mot de passe oublié ?
          </p>
        </div> -->

          <div class="error" v-if="isError">
            {{ msg.msgError }}
          </div>
          <p class="loginRemember" style="padding-left:25px;">
            <label for="rememberme">
              <input
                type="checkbox"
                id="rememberme"
                name="rememberme"
                v-model="checked"
              />&nbsp;Se souvenir de moi</label
            >
          </p>
          <BtnSubmit
            title="Se connecter"
            label="Se connecter"
            textBtn="Se connecter"
            active-loader
            styleBtn="contained"
            colorBtn="primary"
            class="contentBtn"
            typeBtn="submit"
            style="margin-left:25px;"
          />
        </form>
      </div>
    </div>
    <div class=" container containerForm" v-if="typeLogin == 1">
      <div class="presentation">
        <LogoApp
          subSecondary="Silo"
          size="logo_xl"
          colorApp="silo"
        />
        <img src="/img/logo-solutionsplus.png" style="transform:scale(0.8)" />
      </div>
      <div class="loginForm">
        <a style="color:green;text-decoration:none;" href="#" @click="typeLogin = 0;"> &lt; Retour</a>
        <form
          id="myform"
          v-on:submit.prevent="onSubmitForgotMDP()"
          enctype="multipart/form-data"
        >
          <!--<h2 class="title">
            Pour bien préparer la saisie des enregistrements
          </h2>
          <p class="mb-5">
            Afin de pouvoir réaliser la saisie des enregistrements et assurer la
            fiabilité des Indicateurs de performance, nous vous conseillons de
            préparer les informations suivantes : cellule ventilée, sa capacité
            (pleine et actuelle), le ventilateur et sa puissance (plaque
            moteur), la température moyenne au remplissage de la cellule, la
            température avant et après chaque ventilation, la consigne du
            thermostat si présent, les temps de ventilation, le coût du
            kilowattheure.
          </p>-->
          <h5 style="margin-top:10px;margin-bottom: 10px;">J'ai oublié mon mot de passe</h5>

          <InputForm
            class="mdp login"
            label="Identifiant ou adresse e-mail"
            placeholder="Ex: Lmarcheciel"
            type="email"
            v-model="email"
            :errorMsg="msg.email"
            :helperMsg="msg.emailInvalid"
            :value="email"
          />

          <!-- <div class="forgot">
          <p @click="() => $router.push({ name: 'forgot' })">
            Mot de passe oublié ?
          </p>
        </div> -->

          <div v-if="message != null">
            {{ message }}
          </div>
          <BtnSubmit
            title="Renvoyer un mot de passe"
            label="Renvoyer un mot de passe"
            textBtn="Renvoyer un mot de passe"
            active-loader
            styleBtn="contained"
            colorBtn="primary"
            class="contentBtn"
            typeBtn="submit"
            style="margin-left:25px;"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Form/InputForm';
import Meta from '@/components/atoms/Meta';
import LogoApp from '@/components/atoms/LogoApp';
// import bcrypt from 'bcryptjs';
export default {
  name: 'Login',
  components: {
    BtnSubmit,
    LogoApp,
    InputForm,
    Meta
  },
  data() {
    return {
      email: '',
      password: '',
      msg: [],
      isError: false,
      more: false,
      checked: false,
      typeLogin: 0,
      message: null
    };
  },

  methods: {
    forgot(forgotValue) {
      if (forgotValue === 'Identifiant oublié ?') {
        console.log(forgotValue)
      } else if (forgotValue === 'Mot de passe oublié ?') {
        console.log(forgotValue);
        this.typeLogin = 1;
      }
    },
    // validateEmail(value) {
    //   if (
    //     /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
    //     this.email.length <= 0
    //   ) {
    //     this.msg['emailInvalid'] = '';
    //   } else {
    //     this.msg['emailInvalid'] = 'Adresse email invalide.';
    //   }
    // },

    resetMsg() {
      if (this.email.length > 1) {
        this.msg['email'] = '';
      }
      if (this.password.length > 1) {
        this.msg['password'] = '';
      }
    },
    // encryptPassword(password) {
    //   const salt = bcrypt.genSaltSync(10);
    //   return bcrypt.hashSync(password, salt);
    // },
    onSubmitForgotMDP() {
      if (this.email === '') {
        this.msg['email'] = "Merci d'indiquer un email.";
      }
      let data = {
        email: this.email
      };
      let vm = this;
      Api.forgot(data).then(({data}) => {
        console.log(data);
        switch(data.message) {
          case 'Check your email.':
            vm.message = "Un email de récupération vient d'être envoyé.";
            break;
          case 'User doen\'t exists!':
            vm.message = "L'utilisateur n'existe pas.";
            break;
          default:
            vm.message = "Une erreur inconnue est survenue.";
            break;
        }
      }).catch(() => {
        vm.message = "L'utilisateur n'existe pas.";
      });
    },
    onSubmit() {
      if (this.email === '') {
        this.msg['email'] = "Merci d'indiquer un email.";
      }
      if (this.password === '') {
        this.msg['password'] = "Merci d'indiquer un mot de passe.";
      } else {
        let data = {
          email: this.email,
          password: this.password
          // password: this.encryptPassword(this.password)
        };

        Api.login(data)
          .then(({ data }) => {
            if (data) {
                console.log('data')
                localStorage.clear();
                sessionStorage.clear();
              if (this.checked && data.user.role_id != 1) {
                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('rememberMe', true);
              } else {
                sessionStorage.setItem('token', JSON.stringify(data.token));
                sessionStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('rememberMe', false);
              }
              this.$store.commit('user/user', data.user);
              if (data.user.role_id == 1 || data.user.role_id == 3) {
                this.$router.push({ name: 'dashboard_supervisor' });
              } else {
                this.$store.dispatch('inputLocation/loadInputLocation');
                this.$router.push({ name: 'dashboard_operator' });
              }
            }
          })
          .catch(error => {
            console.log(error);
            switch (error.response.status) {
              case 401:
                data.email = this.email;
                this.isError = error;
                this.msg['msgError'] = error.response.data.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 403:
                this.isError = error;
                this.msg['msgError'] = error.response.data.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 404:
                this.isError = error;
                this.msg['msgError'] = error.response.data.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 500:
                this.isError = error;
                this.msg['msgError'] = error.response.data.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 504:
                this.isError = error;
                this.msg['msgError'] = error.response.data.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              default:
                console.log('some other error');
                break;
            }
          });
        // document.getElementById('myform').reset();
      }
    }
  },

  watch: {
    email(value) {
      this.email = value;
      // this.validateEmail(value);
      this.resetMsg(value);
    },

    password(value) {
      this.password = value;
      this.resetMsg(value);
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bellota:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap');
</style>

<style lang="scss" src="./style.scss" scoped></style>
