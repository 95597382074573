<template>
  <div :class="[`pageContainer`, {'operaterPage': operaterPage, 'superviserPage': superviserPage}]">
    <div class="pageTitleGroup">
      <div class="pageTitle">
        <span>Situation au {{date}}</span>
        <h2>{{pageName}}</h2>
      </div>
      <ExportButton v-if="showExportButton"></ExportButton>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import ExportButton from '@/components/atoms/ExportButton';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

export default {
  name: 'BoxBase',
  components: {
    ExportButton
  },
  props: {
    pageName: {
      type: String
    },
    operaterPage: {
      type: Boolean,
      default: false
    },
    superviserPage: {
      type: Boolean,
      default: false
    },
    showExportButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    //
  },
  watch: {
    filterValue(nv) {
      this.$emit('changeFilter', nv)
    }
  },
  data() {
    return {
      date: this.datejs(new Date),
    };
  },
  methods: {
    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('DD MMMM YYYY');
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
