<template>
  <div id="form" class="page">
    <Meta pageTitle="AUTH-PROJECT | récupération mot de passe" />

    <div class="container containerForm">
      <form id="myform" v-on:submit.prevent="onSubmit()">
        <h1 class="title">
          Modification mot de passe
        </h1>
        <InputForm
          label="adresse e-mail"
          type="email"
          v-model="email"
          :errorMsg="msg.email"
          :helperMsg="msg.emailInvalid"
          :value="email"
        />

        <div class="success" v-if="isSuccess">
          Email envoyé, vérifiez votre boite mail.
        </div>
        <div class="error" v-if="isError">
          {{ msg.msgError }}
        </div>
        <BtnSubmit
          title="envoyer"
          label="envoyer"
          textBtn="envoyer"
          textBtnLoader="envoie en cours"
          active-loader
          styleBtn="contained"
          colorBtn="warning"
          class="contentBtn"
          typeBtn="submit"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Form/InputForm';
import Meta from '@/components/atoms/Meta';

export default {
  name: 'Forgot',
  components: {
    BtnSubmit,
    InputForm,
    Meta
  },
  data() {
    return {
      email: '',
      msg: [],
      isSuccess: false,
      isError: false
    };
  },
  methods: {
    validateEmail(value) {
      if (
        /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
        this.email.length <= 0
      ) {
        this.msg['emailInvalid'] = '';
      } else {
        this.msg['emailInvalid'] = 'Adresse email invalide.';
      }
    },

    onSubmit() {
      if (this.email === '') {
        this.msg['email'] = "Merci d'indiquer votre email.";
      } else {
        let data = {
          email: this.email
        };

        Api.forgot(data)
          .then(data => {
            if (data) {
              this.isSuccess = !this.isSuccess;
              localStorage.setItem('token', data.token);
              this.$store.commit('user/user', data.user);
              setTimeout(() => {
                this.$router.push({ path: '/' });
              }, 5000);
            }
          })
          .catch(error => {
            switch (error.response.status) {
              case 400:
                this.isError = error;
                this.msg['msgError'] = error.response.message;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 3000);
                break;
              case 401:
                this.isError = error;
                this.msg['msgError'] = `utilisateur non authentifié.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 3000);
                break;
              case 403:
                this.isError = error;
                this.msg['msgError'] = `accès refusé.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 3000);
                break;
              case 404:
                this.isError = error;
                this.msg['msgError'] = `L'utilisateur n'existe pas.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 3000);
                break;
              case 500:
                this.isError = error;
                this.msg[
                  'msgError'
                ] = `Une erreur 500 serveur s'est produite merci de réessayer ultérieurement.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 3000);
                break;
              case 504:
                this.isError = error;
                this.msg['msgError'] = `le serveur n'a pas répondu.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              default:
                console.log('some other error');
                break;
            }
            console.log('error', error);
          });
      }
    }
  },

  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
