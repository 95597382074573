import Api from '@/classes/api/Api';

export default {
  async loadSilo({ commit }) {
    await Api.getSilo()
      .then(({ data }) => {
        if (data) {
          const silosResult = data[0].silo;
          commit('GET_SILOS', silosResult);
          // console.log('GET_SILOS', silosResult);
          const cellResult = data[0].silo.map(CellList => CellList.cell);
          // if (cellResult !== null) {
          commit('GET_CELL', cellResult);
          // console.log('GET_CELL', cellResult);
          // }
          const fansResult = data[0].silo.map(fanList => fanList.fan);
          commit('GET_FANS', fansResult);
          // console.log('GET_FANS', fansResult);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // async loadCell({ commit }) {
  //   await Api.getSilo()
  //     .then(({ data }) => {
  //       if (data) {
  //         const cellResult = data[0].silo.map(CellList => CellList.cell);
  //         // if (cellResult !== null) {
  //         commit('GET_CELL', cellResult);
  //         // console.log('GET_CELL', cellResult);
  //         // }
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // },

  // async loadFans({ commit }) {
  //   await Api.getSilo()
  //     .then(({ data }) => {
  //       if (data) {
  //         const fansResult = data[0].silo.map(fanList => fanList.fan);
  //         commit('GET_FANS', fansResult);
  //         // console.log('GET_FANS', fansResult);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
};
