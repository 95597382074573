<template>
  <!-- <div v-if="user">
    <div>
      <div class="container  grille-1">
        <div class="row row-cols-1 align-items-center">
          <div class="col-sm-3  col image">
            <router-link to="/">
              <img
                class="logo "
                src="/img/logo-solutionsplus.png"
                alt="Solutions Plus"
              />
            </router-link>
          </div>
          <div class="contentTitle col col-sm-7">
            <h1 class="title" :class="roleId == 1 ? 'text-center' : ''">
              {{ roleId == 2 ? 'Votre tableau de bord OptiSilo' : '' }}
              {{ roleId == 1 ? 'OptiSilo - mode Superviseur' : '' }}
            </h1>
            <p class="subTitle" v-if="roleId == 2">
              Site {{ user.location_group[0].location.name }} – Bienvenue
            </p>
          </div>
          <div class="col-sm-2 col">
            <Menu
              responsive
              :items="roleId == 2 ? menu : menuSuperviseur"
              class="sp-menu"
            >
              <template v-slot="obj">
                <div
                  class="divPadding"
                  @click="
                    () => {
                      obj.item.name === 'configuration' ||
                      obj.item.name === 'superviseur_configuration'
                        ? configuration()
                        : obj.item.name === 'export'
                        ? exportHistories()
                        : obj.item.name === 'import'
                        ? importSiloXls()
                        : obj.item.name === 'logout'
                        ? logout()
                        : '';
                    }
                  "
                >
                  {{ obj.item.label }}
                </div>
              </template>
            </Menu>
          </div>
        </div>
      </div>
    </div>
    <ViewConfiguration :key="key" :roleId="roleId" />
    <ExportModal></ExportModal>
    <ImportXlsOperator :key="key" />
  </div> -->
  <!-- <q-header v-if="user" elevated class="bg-white headerContainer"> -->
  <div v-if="user" class="bg-white headerContainer">
    <q-toolbar class="desktop_nav">
      <!-- MENU SITE + LOGO -->
      <div class="col-xs-10 col-sm-3">
        <div class="menuSite">
          <q-avatar size="55px">
            <img src="/img/solutionPlus.svg" />
          </q-avatar>
          <ChangeAppDropdown v-if="user ? user.access_optisilo : ''"/>
          <LogoApp size="logo_sm" @click="linkHomePage()" />
        </div>
      </div>

      <!-- MIDDLE MENU -->
      <div class="middleMenu col-sm-6 p-desktop">
        <PrimaryMenu :items="navList" />
      </div>

      <!-- USER MENU -->
      <div class="userMenu">
        <UserMenu />
      </div>
      <!-- BURGER MOBILE < 1024px -->
      <div class="burger">
        <!-- <BurgerMenu @open-menu="openMenu" /> -->
      </div>
    </q-toolbar>

    <!-- MENU MOBILE < 1024px -->
    <div v-if="false">
      <div class="mobileMenuContainer">
        <PrimaryMenu mobile :items="navList" />
      </div>
      <div class="mobileMenuContainer">
        <UserMenu mobileApp />
      </div>
    </div>
  </div>
  <!-- </q-header> -->
</template>

<script>
import { mapGetters } from 'vuex';
import LogoApp from '@/components/atoms/LogoApp';
import UserMenu from '@/components/molecules/UserMenu';
import PrimaryMenu from '@/components/atoms/PrimaryMenu';
import admin_menu from '/src/assets/_data/admin_menu.json';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import operateur_menu from '/src/assets/_data/operateur_menu.json';
import ChangeAppDropdown from '@/components/atoms/ChangeAppDropdown';
import superviseur_menu from '/src/assets/_data/superviseur_menu.json';

// import Menu from '@/components/atoms/Menu';
// import ExportModal from '@/components/atoms/ExportModal';
// import ViewConfiguration from '@/components/molecules/ViewConfiguration';
// import ImportXlsOperator from '@/components/molecules/ImportXlsOperator';

export default {
  name: 'NavBar',
  components: {
    LogoApp,
    UserMenu,
    PrimaryMenu,
    ChangeAppDropdown
  },
  data() {
    return {
      superviseurMenu: superviseur_menu,
      adminMenu: admin_menu,
      operateurMenu: operateur_menu,
      modalStore: ModalStore,
      key: 0,
      is_impersonate: false
    };
  },
  methods: {
    logout() {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
      this.$store.commit('user/user', null);
      this.$router.push({ name: 'Home' });
    },
    handleuserAccount() {
      const userId = this.user.id;
      this.$router.push({ path: `/edit/${userId}` });
    },
    configuration() {
      this.key++;
      this.modalStore.showModal('config');
      if (this.roleId == 2) {
        this.$store.dispatch('silos/loadSilo');
      }
    },
    importSiloXls() {
      this.key++;
      this.modalStore.showModal('importSilo');
    },
    exportHistories() {
      this.modalStore.showModal('exportData');
    }
  },
  updated() {
    console.log('updated');
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    if (rememberMe === false) {
      this.is_impersonate = JSON.parse(
        sessionStorage.getItem('impersonate_activated')
      );
    } else {
      this.is_impersonate = JSON.parse(
        localStorage.getItem('impersonate_activated')
      );
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    navList() {
      return !this.is_impersonate
        ? this.user.role_id === 1
          ? this.superviseurMenu
          : this.user.role_id === 2
          ? this.operateurMenu
          : this.user.role_id === 3
          ? this.adminMenu
          : false
        : this.superviseurMenu;
    },
    roleId() {
      return this.user.role_id;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
