<template>
  <Loader v-if="isLoading" />

  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    :okCancel="true"
    @submit="validateConfig()"
    okLabel="Valider"
    title="Température du grain trop elevée"
    size="1000"
    name="ConfigureAlertTemperatureTooHot"
  >
  <br />
    <form
      id="myform"
      class="myForm"
      v-on:submit.prevent="onSubmit()"
      enctype="multipart/form-data"
    >
      <div class="content">
        <div class="dateSelection" style="flex-direction:column; align-items: flex-start;width:45%; margin-left:12px; margin-right:12px;">
          <label for="nbDays">Température maximale</label>
          <InputForm
            type="text"
            placeholder="Jours"
            v-model="alert_real.threshold"
            :value="alert_real.threshold"
            :errorMsg="errorMsg"
            inputModal="classic"
            style="width: -webkit-fill-available;"
          />
        </div>
        <br /><br />
        <div class="dateSelection">
          <div style="width:45%; margin-left:12px; margin-right:12px;">
            <label for="selectedDateType">Envoi de l'alerte</label>
            <br /><br />
            <select
              class="dateTypeLabel"
              v-model="alert_real.selectedDateType"
              id="selectedDateType"
            >
              <option value="quotidiennement">Quotidien</option>
              <option value="hebdomadairement">Hebdomadaire</option>
            </select>
          </div>
          <br />
          <div style="width:20%;margin-left:12px; margin-right:12px;" v-if="alert_real.selectedDateType === 'hebdomadairement'">
            <label class="dateLabel" for="selectedDayOption">Jour</label>
            <br /><br />
            <select
              class="date"
              v-model="alert_real.selectedDayOption"
              id="selectedDayOption"
            >
              <option value="lundi">Lundi</option>
              <option value="mardi">Mardi</option>
              <option value="mercredi">Mercredi</option>
              <option value="jeudi">Jeudi</option>
              <option value="vendredi">Vendredi</option>
              <option value="samedi">Samedi</option>
              <option value="dimanche">Dimanche</option>
            </select>
          </div>
          <div :style="alert_real.selectedDateType === 'hebdomadairement' ? 'width:20%; margin-left:12px; margin-right:12px;' : 'width:45%; margin-left:12px; margin-right:12px;'">
            <label class="dateLabel" for="selectedTimeOption">Heure</label>
            <InputForm
              type="time"
              v-model="alert_real.selectedTimeOption"
              :value="alert_real.selectedTimeOption"
              :errorMsg="errorMsg"
              inputModal="inputIcon"
            />
          </div>
        </div>
        <br />
<div class="dateSelection">
        <div style="width:45%;margin-left:12px; margin-right:12px;">
            <label class="dateLabel" for="selectedDayOption">Mode</label>
            <br /><br />
            <select
              class="date"
              v-model="alert_real.type_envoi"
              id="method"
            >
              <option value="1">Email</option>
            </select>
          </div>
          <div style="width:45%;margin-left:12px; margin-right:12px;">
            <label class="dateLabel" for="selectedTimeOption">{{ alert_real.type_envoi == '1' ? "Adresse Email" : "Numéro de téléphone" }}</label>
            <InputForm
              type="text"
              v-model="alert_real.envoi_value"
              :value="alert_real.envoi_value"
              :errorMsg="errorMsg"
              inputModal="classic"
            />
          </div>
          </div>
        <!-- <input type="time" v-model="selectedTimeOption" id="selectedTimeOption"> -->
        <div class="Btn">
          <BtnSubmit
            title="Confirmer"
            label="Confirmer"
            textBtn="Confirmer"
            textBtnLoader="Confirmer"
            active-loader
            styleBtn="contained"
            colorBtn="warning"
            class="contentBtn"
            typeBtn="submit"
            @click="updateAlert(alert)"
          />
        </div>
        <div class="success" v-if="isSuccess">
          Un email a été envoyé à l'opérateur.
        </div>
        <div class="error" v-if="isError">
          {{ msg.msgError }}
        </div>
      </div>
    </form>
  </AddModal>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import InputForm from '@/components/atoms/Form/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import BtnresetPassword from '@/components/atoms/BtnLoader';

export default {
  name: 'Operators',
  components: {
    // BtnresetPassword,
    InputForm,
    AddModal,
    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    alert: {
      type: Object,
      default: () => {}
    },
    user_prop: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      addOp: false,
      isSuccessPassword: false,
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      locationValue: '',
      modalStore: ModalStore,
      alert_real: this.alert,
      user: this.user_prop
    };
  },
  computed: {
    ...mapGetters('listLocation', ['GET_ACTION_LOCATION'])
  },
  methods: {
    resetPassword() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isSuccessPassword = true;
        this.isLoading = false;
        this.$refs.btn1.activeLoader = false;
        setTimeout(() => {
          this.isSuccessPassword = false;
        }, 2000);
      }, 5000);
    },
    // desactivatedOperator(id) {
    //   Api.disableOperators(id)
    //     .then(({ data }) => {
    //       if (data) {
    //         this.$store.dispatch('listOperator/LoadOperators');
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    validateConfig() {
      var alert = this.alert_real;
      /*if(this.alert_real.method == 2) {
      ApiPhoneValidator.validatePhone(this.alert_real.method_value).then((data) => {
        console.log(data);
      })
      } */

      if (alert.active === false) {
        Api.addAlertToUser(this.user.id, {
          alert_name: alert.alerteType,
          day:
            alert.selectedDateType === 'hebdomadairement'
              ? null
              : alert.selectedDayOption,
          heure: alert.selectedTimeOption,
          sending_mode:
            alert.selectedDateType === 'hebdomadairement' ? false : true,
          threshold: parseInt(alert.threshold, 10),
          type_envoi: alert.type_envoi,
          envoi_value: alert.envoi_value
        }).then(() => {
          this.$emit('updateAlertsActive');
        });
      }
      Api.updateAlertToUser(this.user.id, {
        alert_name: alert.alerteType,
        day: alert.selectedDayOption,
        heure: alert.selectedTimeOption,
        sending_mode:
          alert.selectedDateType === 'hebdomadairement' ? false : true,
        threshold: parseInt(alert.threshold, 10),
        type_envoi: alert.type_envoi,
        envoi_value: alert.envoi_value
      })
        .then(data => {
          this.$q.notify({
            message: data.data.message,
            color: 'positive'
          });
          this.$emit('updateAlertsActive');
          this.isLoading = false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
