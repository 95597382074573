<template>
  <q-btn
    v-for="label in labels"
    :key="label"
    :label="label.title"
    :flat="isMobile === false"
    unelevated
    :outline="isMobile === true"
    :rounded="isMobile === true"
    dense
    no-wrap
    :align="align"
    :hoverable="false"
    class="menuSecondary"
    :class="[
      label.classMenu,
      { notIcon: withIcon === false, currentUser: !user },
    ]"
    @click="$emit('click', label.link)"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SecondaryMenu',
  emits: ['click'],
  props: {
    labels: {
      type: Object,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'right',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
