import GetInputCellLocationGetters from './getters.js';
import GetInputCellLocationMutations from './mutations.js';
import GetInputCellLocationAction from './actions.js';

export default {
  namespaced: true,

  state: {
    inputLocation: []
  },
  getters: GetInputCellLocationGetters,
  mutations: GetInputCellLocationMutations,
  actions: GetInputCellLocationAction
};
