<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      :name="nameModal"
      size="960"
      :okCancel="true"
      @submit="$emit('trash-action', cellId)"
      :okLabel="btnName"
      title="Annuler la derniere saisie"
      @click.stop
      :class="`${nameModal}`"
    >
      <!-- @submit="delCell(cellId)" -->

      <h2>
        ATTENTION !
      </h2>
      {{ textOne + ' ' }}
      {{log(lastSaisieType)}}
      {{log(nameModal)}}
      <template
        v-if="
          lastSaisieType &&
            lastSaisieType.type === 'Multiventilation' &&
            lastSaisieType.ventilations
        "
      >
        <span class="cellName multi">
          <template v-for="vent in lastSaisieType.ventilations" :key="vent.id">
            {{ vent.cell.name }},
          </template>
        </span>
      </template>
      <template v-else>
        <span class="cellName">{{ cellName }}</span>
      </template>
      {{ textTwo }}
      <template v-if="lastSaisieType && checkLastSaisie">
        <!-- Si C'est une multi ventilation avec un ajout de tonnage -->
        <div
          class="info_type"
          v-if="
            lastSaisieType.type === 'fillings' &&
              lastSaisieType.ventilation_with_actions
          "
        >
          <ul>
            <li>
              <span class="title">Cellules:</span>
              <span
                v-for="(cell, index) in lastSaisieType.cell"
                :key="cell.name"
                ><span v-if="index == lastSaisieType.cell.length - 1">{{
                  cell.name
                }}</span
                ><span v-else>{{ cell.name }},</span></span
              >
            </li>
            <!--
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>-->
            <br />
            <li>
              <span class="title">Action:</span> Multi Ventilation avec Remplissage(s) ou/et Vidange(s)
            </li>
            <li>
              <span class="title">Date:</span>
              {{
                datejs(
                  lastSaisieType.ventilation_with_actions[0].ventilation
                    .created_at
                )
              }}
            </li>
            <li><span class="titile">Details des actions:</span></li>
            <div
              v-for="ventilation_with_action in lastSaisieType.ventilation_with_actions"
              :key="ventilation_with_action"
            >
              <div v-if="ventilation_with_action.filling">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Remplissage</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Volume ensilé:</span>
                    {{ ventilation_with_action.filling.volume }}
                  </li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.emptying">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Vidange</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li><span class="title">Volume Vidangé:</span> {{ ventilation_with_action.volume }}</li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.ventilation">
                <ul>
                  <li><span class="title">Action:</span> Ventilation</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.ventilation.cell_id,
                        lastSaisieType.cell
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </ul>
        </div>
        <!-- Si C'est une ventilation avec un ajout de tonnage -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'fillings' &&
              lastSaisieType.cell_ventilation_entity
          "
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Ventilation et Remplissage</li>
            <li>
              <span class="title">Date:</span>
              {{ datejs(lastSaisieType.cell_ventilation_entity.created_at) }}
            </li>
            <li><span class="title">Volume ensilé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li>
              <span class="title">Nouveau volume stocké:</span> {{ lastSaisieType.cell.current_volume }}T
            </li>
            <li><span class="title">Campagne:</span> {{ lastSaisieType.campaign_year }}</li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{
                lastSaisieType.cell_ventilation_entity.temperature_after_min
              }}°C, T°Moy
              {{ lastSaisieType.cell_ventilation_entity.temperature_after }}°C,
              T°Max
              {{
                lastSaisieType.cell_ventilation_entity.temperature_after_max
              }}°C
            </li>
            <li>
              <span class="title">Consigne Thermostat:</span>
              {{ lastSaisieType.cell_ventilation_entity.thermostat }}°C
            </li>
           <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
            <li>
              <span class="title">Durée de ventilation:</span>
              {{ lastSaisieType.cell_ventilation_entity.time }}h
            </li>
          </ul>
        </div>

        <!-- Sinon Si C'est un transilage d'une autre cellule a la cellule actuel -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.action === 'transin' && lastSaisieType.emptying
          "
        >
          <ul>
            <li><span class="title">Action:</span> Transilage</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <li><span class="title">Cellule de départ:</span> {{ lastSaisieType.emptying_cell.name }}</li>
            <li><span class="title">Cellule de destination:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Volume transilé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li v-if="lastSaisieType.filling_details.length > 1">
              <span class="title">Info grains:</span><span
                v-for="(filling_detail,
                index) in lastSaisieType.filling_details"
                :key="filling_detail"
                ><span
                  v-if="index == lastSaisieType.filling_details.length - 1"
                  >{{ filling_detail.name }}</span
                ><span v-else>{{ filling_detail.name }},</span></span
              >
            </li>
            <li v-else-if="lastSaisieType.filling_details[0] != null">
              <span class="title">Infos grain:</span><span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }}</span
              >
            </li>
          </ul>
        </div>

        <!-- Sinon Si C'est un remplissage -->
        <div
          class="info_type"
          v-else-if="lastSaisieType.action === 'Remplissage'"
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Remplissage</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li><span class="title">Volume ensilé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li>
              <span class="title">Nouveau volume stocké:</span> {{ lastSaisieType.cell.current_volume }}T
            </li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{ lastSaisieType.entity.tmi_min }}°C, T°Moy
              {{ lastSaisieType.entity.tmi }}°C, T°Max
              {{ lastSaisieType.entity.tmi_max }}°C
            </li>
            <li><span class="title">Campagne:</span> {{ lastSaisieType.campaign_year }}</li>
            <li v-if="lastSaisieType.filling_details.length > 1">
              <span class="title">Info grains:</span><span
                v-for="(filling_detail,
                index) in lastSaisieType.filling_details"
                :key="filling_detail"
                ><span
                  v-if="index == lastSaisieType.filling_details.length - 1"
                  >{{ filling_detail.name }}</span
                ><span v-else>{{ filling_detail.name }},</span></span
              >
            </li>
            <li v-else-if="lastSaisieType.filling_details[0] != null">
              <span class="title">Infos grain:</span><span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }}</span
              >
            </li>
          </ul>
        </div>

        <!-- Si C'est un transilage de la cellule actuel a une autre cellule -->
        <div
          class="info_type"
          v-if="lastSaisieType.action === 'transout' && lastSaisieType.fillings"
        >
          <ul>
            <li><span class="title">Action:</span> Transilage</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <li><span class="title">Cellule de départ:</span> {{ lastSaisieType.cell.name }}</li>
            <li>
              <span class="title">Cellule de destination:</span> {{ lastSaisieType.fillings_cell.name }}
            </li>
            <li><span class="title">Volume transilé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li v-if="lastSaisieType.filling_details.length > 1">
              <span class="title">Info grains:</span><span
                v-for="(filling_detail,
                index) in lastSaisieType.filling_details"
                :key="filling_detail"
                ><span
                  v-if="index == lastSaisieType.filling_details.length - 1"
                  >{{ filling_detail.name }}</span
                ><span v-else>{{ filling_detail.name }},</span></span
              >
            </li>
            <li v-else-if="lastSaisieType.filling_details[0] != null">
              <span class="title">Infos grain:</span><span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }}</span
              >
            </li>
          </ul>
        </div>

        <!-- Si C'est un transilage de la cellule actuel a une autre cellule -->
        <div
          class="info_type"
          v-if="lastSaisieType.action === 'transin' && lastSaisieType.fillings"
        >
          <ul>
            <li><span class="title">Action:</span> Transilage</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <li><span class="title">Cellule de départ:</span> {{ lastSaisieType.cell.name }}</li>
            <li>
              <span class="title">Cellule de destination:</span> {{ lastSaisieType.fillings_cell.name }}
            </li>
            <li><span class="title">Volume transilé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li v-if="lastSaisieType.filling_details.length > 1">
              <span class="title">Info grains:</span><span
                v-for="(filling_detail,
                index) in lastSaisieType.filling_details"
                :key="filling_detail"
                ><span
                  v-if="index == lastSaisieType.filling_details.length - 1"
                  >{{ filling_detail.name }}</span
                ><span v-else>{{ filling_detail.name }},</span></span
              >
            </li>
            <li v-else-if="lastSaisieType.filling_details[0] != null">
              <span class="title">Infos grain:</span><span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }}</span
              >
            </li>
          </ul>
        </div>

        <!-- Si C'est une multi ventilation avec un ajout de tonnage -->
        <div
          class="info_type"
          v-if="
            lastSaisieType.type === 'emptying' ||
              (lastSaisieType.type === 'Vidange' &&
                lastSaisieType.ventilation_with_actions)
          "
        >
          <ul>
            <li>
              <span class="title">Cellules:</span>
              <span
                v-for="(cell, index) in lastSaisieType.cell"
                :key="cell.name"
                ><span v-if="index == lastSaisieType.cell.length - 1">{{
                  cell.name
                }}</span
                ><span v-else>{{ cell.name }},</span></span
              >
            </li>
            <!--
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>-->
            <br />
            <li>
              <span class="title">Action:</span> Multi Ventilation et Remplissage(s) ou/et vidange(s)
            </li>
            <li>
              <span class="title">Date:</span>
              {{
                datejs(
                  lastSaisieType.ventilation_with_actions[0].ventilation
                    .created_at
                )
              }}
            </li>
            <li><span class="titile">Details des actions:</span></li>
            <div
              v-for="ventilation_with_action in lastSaisieType.ventilation_with_actions"
              :key="ventilation_with_action"
            >
              <div v-if="ventilation_with_action.filling">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Remplissage</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Volume ensilé:</span>
                    {{ ventilation_with_action.filling.volume }}
                  </li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.emptying">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Vidange</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Volume Vidangé:</span>
                    {{ ventilation_with_action.emptying.volume }}
                  </li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.ventilation">
                <ul>
                  <li><span class="title">Action:</span> Ventilation</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.ventilation.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </ul>
        </div>

        <!-- Si C'est une ventilation avec une vidange -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'emptying' ||
              (lastSaisieType.type === 'Vidange' &&
                lastSaisieType.cell_ventilation_entity)
          "
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Ventilation et Vidange</li>
            <li>
              <span class="title">Date:</span>
              {{ datejs(lastSaisieType.cell_ventilation_entity.created_at) }}
            </li>
            <li>Volume vidangé: {{ lastSaisieType.entity.volume }}T</li>
            <li>
              Nouveau volume stocké :
              {{ lastSaisieType.cell.current_volume }}T
            </li>
            <li><span class="title">Campagne:</span> {{ lastSaisieType.campaign_year }}</li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{
                lastSaisieType.cell_ventilation_entity.temperature_after_min
              }}°C, T°Moy
              {{ lastSaisieType.cell_ventilation_entity.temperature_after }}°C,
              T°Max
              {{
                lastSaisieType.cell_ventilation_entity.temperature_after_max
              }}°C
            </li>
            <li>
              <span class="title">Consigne Thermostat:</span>
              {{ lastSaisieType.cell_ventilation_entity.thermostat }}°C
            </li>
           <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
            <li>
              <span class="title">Durée de ventilation:</span>
              {{ lastSaisieType.cell_ventilation_entity.time }}h
            </li>
          </ul>
        </div>

        <!-- Sinon Si C'est une vidange -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'emptying' ||
              lastSaisieType.type === 'Vidange'
          "
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Vidange</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li><span class="title">Volume vidangé:</span> {{ lastSaisieType.entity.volume }}T</li>
            <li>
              <span class="title">Nouveau volume stocké:</span> {{ lastSaisieType.cell.current_volume }}T
            </li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{ lastSaisieType.entity.tmi_min }}°C, T°Moy
              {{ lastSaisieType.entity.tmi }}°C, T°Max
              {{ lastSaisieType.entity.tmi_max }}°C
            </li>
            <li><span class="title">Campagne:</span> {{ lastSaisieType.campaign_year }}</li>
            <li v-if="lastSaisieType.filling_details.length > 1">
              <span class="title">Infos grain:</span><span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }},
              </span>
            </li>
            <li v-else-if="lastSaisieType.filling_details[0] != null">
              Info grain:
              <span
                v-for="filling_detail in lastSaisieType.filling_details"
                :key="filling_detail"
                >{{ filling_detail.name }},
              </span>
            </li>
          </ul>
        </div>

        <!-- Si C'est une multi ventilation avec un ajout de tonnage -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'cell_ventilations' &&
              lastSaisieType.ventilation_with_actions
          "
        >
          <ul>
            <li>
              <span class="title">Cellules:</span>
              <span
                v-for="(cell, index) in lastSaisieType.cell"
                :key="cell.name"
                ><span v-if="index == lastSaisieType.cell.length - 1">{{
                  cell.name
                }}</span
                ><span v-else>{{ cell.name }},</span></span
              >
            </li>
            <!--
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>-->
            <br />
            <li>
              <span class="title">Action:</span> Multi Ventilation et Remplissage(s) ou/et vidange(s)
            </li>
            <li>
              <span class="title">Date:</span>
              {{
                datejs(
                  lastSaisieType.ventilation_with_actions[0].ventilation
                    .created_at
                )
              }}
            </li>
            <li><span class="titile">Details des actions:</span></li>
            <div
              v-for="ventilation_with_action in lastSaisieType.ventilation_with_actions"
              :key="ventilation_with_action"
            >
              <div v-if="ventilation_with_action.filling">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Remplissage</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Volume ensilé:</span>
                    {{ ventilation_with_action.filling.volume }}
                  </li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.filling.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.emptying">
                <ul>
                  <li><span class="title">Action:</span> Ventilation et Vidange</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Volume Vidangé:</span>
                    {{ ventilation_with_action.emptying.volume }}
                  </li>
                  <li>
                    <span class="title">Nouveau volume stocké:</span>
                    {{
                      getCellById(
                        ventilation_with_action.emptying.cell_id,
                        lastSaisieType.cell,
                        'current_volume'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
              <div v-else-if="ventilation_with_action.ventilation">
                <ul>
                  <li><span class="title">Action:</span> Ventilation</li>
                  <li>
                    <span class="title">Cellule:</span>
                    {{
                      getCellById(
                        ventilation_with_action.ventilation.cell_id,
                        lastSaisieType.cell,
                        'name'
                      )
                    }}
                  </li>
                  <li>
                    <span class="title">Températures après ventilation:</span> T°Min
                    {{
                      ventilation_with_action.ventilation.temperature_after_min
                    }}°C, T°Moy
                    {{
                      ventilation_with_action.ventilation.temperature_after
                    }}°C, T°Max
                    {{
                      ventilation_with_action.ventilation.temperature_after_max
                    }}°C
                  </li>
                  <li>
                    <span class="title">Consigne Thermostat:</span>
                    {{ ventilation_with_action.ventilation.thermostat }}°C
                  </li>
                 <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
                  <li>
                    <span class="title">Durée de ventilation:</span>
                    {{ ventilation_with_action.ventilation.time }}h
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </ul>
        </div>

        <!-- Si C'est une ventilation avec une vidange -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'cell_ventilations' &&
              lastSaisieType.emptying
          "
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Ventilation et Vidange</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li>Volume vidangé: {{ lastSaisieType.emptying.volume }}T</li>
            <li>
              Nouveau volume stocké :
              {{ lastSaisieType.cell.current_volume }}T
            </li>
            <li><span class="title">Campagne:</span> {{ lastSaisieType.campaign_year }}</li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{ lastSaisieType.entity.temperature_after_min }}°C, T°Moy
              {{ lastSaisieType.entity.temperature_after }}°C, T°Max
              {{ lastSaisieType.entity.temperature_after_max }}°C
            </li>
            <li>
              <span class="title">Consigne Thermostat:</span> {{ lastSaisieType.entity.thermostat }}°C
            </li>
           <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
            <li><span class="title">Durée de ventilation:</span> {{ lastSaisieType.entity.time }}h</li>
          </ul>
        </div>

        <!-- Sinon Si C'est une ventilation avec un remplissage -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'cell_ventilations' &&
              lastSaisieType.fillings
          "
        >
          Ventilation avec une vidange de
          {{ lastSaisieType.fillings.volume }}T de
          {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }} effectuée le
          {{ datejs(lastSaisieType.entity.created_at) }} en date du
          {{ datejs(lastSaisieType.entity.date) }}<br />
          durée de ventilation de {{ lastSaisieType.entity.time }}h,<br />
          température après ventilation de t°min
          {{ lastSaisieType.entity.temperature_after_min }}°C, t°moy
          {{ lastSaisieType.entity.temperature_after }}°C, t°max
          {{ lastSaisieType.entity.temperature_after_max }}°C,<br />
          consigne Thermostat {{ lastSaisieType.entity.thermostat }}°C
        </div>
        <!-- Sinon Si C'est une ventilation multi cellule -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'Multiventilation' &&
              lastSaisieType.ventilations
          "
        >
          Ventilation multi-cellules effectuée le
          {{ datejs(lastSaisieType.entity.created_at) }} en date du
          {{ datejs(lastSaisieType.entity.date) }}, durée de ventilation de
          {{ lastSaisieType.entity.time }}h, Consigne
          {{ lastSaisieType.entity.thermostat }}°C<br />
          <u>Cellules concernées : </u>
          <ul>
            <li v-for="vent in lastSaisieType.ventilations" :key="vent.id">
              <strong>{{ vent.cell.name }}</strong
              >, température après ventilation de t°min
              {{ vent.temperature_after_min }}°C, t°moy
              {{ vent.temperature_after }}°C, t°max
              {{ vent.temperature_after_max }}°C : <br />
            </li>
          </ul>
          <u>Ventilateurs concernés : </u>
          <ul>
            <li v-for="fan in lastSaisieType.fans" :key="fan.id">
              {{ fan.name }} - {{ fan.power }}kW ({{ fan.type }})
            </li>
          </ul>
        </div>

        <!-- Sinon Si C'est une Multi ventilation -->
        <div
          class="info_type"
          v-else-if="
            lastSaisieType.type === 'cell_ventilations' &&
              lastSaisieType.cell_ventilations
          "
        >
          <ul>
            <li>
              <span class="title">Cellule:</span>
              <span
                v-for="(cell, index) in lastSaisieType.cell"
                :key="cell.name"
                ><span v-if="index == lastSaisieType.cell.length - 1">{{
                  cell.name
                }}</span
                ><span v-else>{{ cell.name }},</span></span
              >
            </li>
            <!--
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>-->
            <br />
            <li><span class="title">Action:</span> Multi Ventilation</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li>
              Volume Stocké:
              <span
                v-for="(cell, index) in lastSaisieType.cell"
                :key="cell.current_volume"
                ><span v-if="index == lastSaisieType.cell.length - 1"
                  >{{ cell.current_volume }}T</span
                ><span v-else>{{ cell.current_volume }}T,</span></span
              >
            </li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{ lastSaisieType.entity.temperature_after_min }}°C, T°Moy
              {{ lastSaisieType.entity.temperature_after }}°C, T°Max
              {{ lastSaisieType.entity.temperature_after_max }}°C
            </li>
            <li>
              <span class="title">Consigne Thermostat:</span> {{ lastSaisieType.entity.thermostat }}°C
            </li>
           <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
            <li><span class="title">Durée de ventilation:</span> {{ lastSaisieType.entity.time }}h</li>
          </ul>
        </div>

        <!-- Sinon Si C'est une ventilation -->
        <div
          class="info_type"
          v-else-if="lastSaisieType.type === 'cell_ventilations'"
        >
          <ul>
            <li><span class="title">Cellule:</span> {{ lastSaisieType.cell.name }}</li>
            <li><span class="title">Espèce:</span> {{ lastSaisieType.new_species ? lastSaisieType.new_species.code + ' - ' + lastSaisieType.new_species.name : lastSaisieType.stored_species.name }}</li>
            <li>
              <span class="title">Sous espèce:</span>
              {{
                lastSaisieType.new_sub_species ? lastSaisieType.new_sub_species.code + ' - ' + lastSaisieType.new_sub_species.name : (lastSaisieType.sub_species ? lastSaisieType.sub_species : '-')
              }}
            </li>
            <br />
            <li><span class="title">Action:</span> Ventilation</li>
            <li><span class="title">Date:</span> {{ datejs(lastSaisieType.entity.created_at) }}</li>
            <li>Volume Stocké: {{ lastSaisieType.cell.current_volume }}T</li>
            <li>
              <span class="title">Températures après ventilation:</span> T°Min
              {{ lastSaisieType.entity.temperature_after_min }}°C, T°Moy
              {{ lastSaisieType.entity.temperature_after }}°C, T°Max
              {{ lastSaisieType.entity.temperature_after_max }}°C
            </li>
            <li>
              <span class="title">Consigne Thermostat:</span> {{ lastSaisieType.entity.thermostat }}°C
            </li>
           <li><span class="title">Ventilateur(s):</span> <span v-if="lastSaisieType.fan_name"> {{ lastSaisieType.fan_name}} </span><ul v-else><li v-for="cv in lastSaisieType.cell_ventilations" v-bind:key="cv.id">{{ cv.fan ? cv.fan.name : cv.name }}</li></ul></li>
            <li><span class="title">Durée de ventilation:</span> {{ lastSaisieType.entity.time }}h</li>
          </ul>
        </div>

        <div
          class="info_type"
          v-if="
            lastSaisieType.type === 'cell_infos' && lastSaisieType.action_grains
          "
        >
          <ul>
            <li>
              Information cellule effectuée le
              {{ datejs(lastSaisieType.entity.created_at) }},
            </li>
            <li>
              avec comme message: "{{
                lastSaisieType.entity.action_grain_description
              }}",
            </li>
            <li v-if="lastSaisieType.action_grains.length > 1">
              et comme actions:<br />
            </li>
            <li v-else-if="lastSaisieType.action_grains[0] != null">
              et comme action:<br />
            </li>
            <li v-else><br /></li>
            <ul
              v-if="
                lastSaisieType.action_grains.length > 1 ||
                  lastSaisieType.action_grains[0] != null
              "
            >
              <li
                v-for="action_grain in lastSaisieType.action_grains"
                :key="action_grain"
              >
                {{ action_grain.name }}
              </li>
            </ul>
          </ul>
        </div>
        <div
          class="info_type"
          v-if="
            lastSaisieType.type === 'cell_infos' && lastSaisieType.action_grain
          "
        >
          <ul>
            <li>
              Information cellule effectuée le
              {{ datejs(lastSaisieType.entity.created_at) }},
            </li>
            <li>
              avec comme message: "{{
                lastSaisieType.entity.action_grain_description
              }}",
            </li>
            <li v-if="lastSaisieType.action_grains.length > 1">
              et comme actions:<br />
            </li>
            <li v-else-if="lastSaisieType.action_grains[0] != null">
              et comme action:<br />
            </li>
            <li v-else><br /></li>
            <ul>
              <li
                v-for="action_grain in lastSaisieType.action_grain"
                :key="action_grain"
              >
                {{ action_grain.name }}
              </li>
            </ul>
          </ul>
        </div>
      </template>
    </AddModal>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import AddModal from '@/components/atoms/Modal';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import Loader from '@/components/atoms/Loader';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default {
  name: 'TrashModal',
  emits: ['trash-action'],
  components: {
    AddModal,

    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    nameModal: {
      type: String,
      default: 'trash'
    },
    textOne: {
      type: String,
      default: 'Vous allez mettre la cellule'
    },
    textTwo: {
      type: String,
      default: 'à la corbeille.'
    },
    cellType: {
      type: String,
      default: 'Inconnu'
    },
    checkLastSaisie: {
      type: Boolean
    },
    lastSaisieType: {
      type: Object
    },
    btnName: {
      type: String,
      default: 'Supprimer la cellule'
    },
    cellId: {
      type: [String, Number]
    },

    cellName: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      silosListsData: [],
      addNewSilo: '',
      locationId: 1
    };
  },
  watch: {
    lastSaisieType() {
      console.log(this.lastSaisieType);
    }
  },
  methods: {
    log(log) {
      console.log(log);
    },
    delCell(id) {
      Api.deleteCell(id).then(({ data }) => {
        if (data) {
          this.$store.dispatch('silos/loadSilo');
          this.$store.dispatch('listFillings/loadFillings');
          this.$store.dispatch('inputLocation/loadInputLocation');
          this.$store.dispatch('indicator/loadIndicatorLocation');
        }
      });
    },
    getCellById(cell_id, cells, itemName) {
      var cell = cells.filter(value => {
        return value.id == cell_id;
      });
      return cell[0][itemName];
    },
    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('DD/MM/YY ');
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
