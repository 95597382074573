<template>
  <div @click="log" class="synth_cell_infos">
    <div class="silo_container">
      <div class="silo">
        <img src="/img/SILO.svg" alt="" />
        <div class="fan_conatiner">
          <div class="fan" v-for="fan in cell.fans" :key="fan">
            <span class="fan_name">{{ fan.name }}</span>
            <span class="fan_power">{{ fan.power }} KW</span>
          </div>
          <br/><br/>
          <div class="temp">
            <svg width="18" height="28" viewBox="0 0 18 28" fill="#2F2F2F" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 5.25C4 3.92392 4.52678 2.65215 5.46447 1.71447C6.40215 0.776784 7.67392 0.25 9 0.25C10.3261 0.25 11.5979 0.776784 12.5355 1.71447C13.4732 2.65215 14 3.92392 14 5.25V11.8187C15.5287 12.8832 16.6778 14.4078 17.2801 16.1706C17.8823 17.9334 17.9063 19.8424 17.3485 21.6197C16.7906 23.397 15.6801 24.95 14.1786 26.0525C12.677 27.1549 10.8628 27.7495 9 27.7495C7.13717 27.7495 5.32296 27.1549 3.82142 26.0525C2.31988 24.95 1.20937 23.397 0.651538 21.6197C0.0937028 19.8424 0.117653 17.9334 0.719903 16.1706C1.32215 14.4078 2.47127 12.8832 4 11.8187V5.25ZM5.43 13.8687C4.33772 14.6289 3.51656 15.7178 3.08607 16.977C2.65558 18.2361 2.63823 19.5999 3.03655 20.8696C3.43487 22.1393 4.22807 23.2488 5.30066 24.0364C6.37326 24.8241 7.66926 25.2488 9 25.2488C10.3307 25.2488 11.6267 24.8241 12.6993 24.0364C13.7719 23.2488 14.5651 22.1393 14.9634 20.8696C15.3618 19.5999 15.3444 18.2361 14.9139 16.977C14.4834 15.7178 13.6623 14.6289 12.57 13.8687L11.5 13.1225V5.25C11.5 4.58696 11.2366 3.95107 10.7678 3.48223C10.2989 3.01339 9.66304 2.75 9 2.75C8.33696 2.75 7.70107 3.01339 7.23223 3.48223C6.76339 3.95107 6.5 4.58696 6.5 5.25V13.1225L5.43 13.8687ZM4 19H14C14 20.3261 13.4732 21.5978 12.5355 22.5355C11.5979 23.4732 10.3261 24 9 24C7.67392 24 6.40215 23.4732 5.46447 22.5355C4.52678 21.5978 4 20.3261 4 19Z" />
            </svg>
            <div class="temp_txt">
              <span class="temp_name">Thermostat</span>
              <span class="temp_value">{{ thermostat || '-' }}°C</span>
            </div>
          </div>
        </div>
        <div class="temps_container">
          <div class="temp">
            <svg width="18" height="28" viewBox="0 0 18 28" fill="#C12937" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 5.25C4 3.92392 4.52678 2.65215 5.46447 1.71447C6.40215 0.776784 7.67392 0.25 9 0.25C10.3261 0.25 11.5979 0.776784 12.5355 1.71447C13.4732 2.65215 14 3.92392 14 5.25V11.8187C15.5287 12.8832 16.6778 14.4078 17.2801 16.1706C17.8823 17.9334 17.9063 19.8424 17.3485 21.6197C16.7906 23.397 15.6801 24.95 14.1786 26.0525C12.677 27.1549 10.8628 27.7495 9 27.7495C7.13717 27.7495 5.32296 27.1549 3.82142 26.0525C2.31988 24.95 1.20937 23.397 0.651538 21.6197C0.0937028 19.8424 0.117653 17.9334 0.719903 16.1706C1.32215 14.4078 2.47127 12.8832 4 11.8187V5.25ZM5.43 13.8687C4.33772 14.6289 3.51656 15.7178 3.08607 16.977C2.65558 18.2361 2.63823 19.5999 3.03655 20.8696C3.43487 22.1393 4.22807 23.2488 5.30066 24.0364C6.37326 24.8241 7.66926 25.2488 9 25.2488C10.3307 25.2488 11.6267 24.8241 12.6993 24.0364C13.7719 23.2488 14.5651 22.1393 14.9634 20.8696C15.3618 19.5999 15.3444 18.2361 14.9139 16.977C14.4834 15.7178 13.6623 14.6289 12.57 13.8687L11.5 13.1225V5.25C11.5 4.58696 11.2366 3.95107 10.7678 3.48223C10.2989 3.01339 9.66304 2.75 9 2.75C8.33696 2.75 7.70107 3.01339 7.23223 3.48223C6.76339 3.95107 6.5 4.58696 6.5 5.25V13.1225L5.43 13.8687ZM4 19H14C14 20.3261 13.4732 21.5978 12.5355 22.5355C11.5979 23.4732 10.3261 24 9 24C7.67392 24 6.40215 23.4732 5.46447 22.5355C4.52678 21.5978 4 20.3261 4 19Z" />
            </svg>
            <div class="temp_txt">
              <span class="temp_name">Max</span>
              <span class="temp_value">{{ cell.tmi_max }}°C</span>
            </div>
          </div>
          <div class="temp">
            <svg width="18" height="28" viewBox="0 0 18 28" fill="#36A139" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 5.25C4 3.92392 4.52678 2.65215 5.46447 1.71447C6.40215 0.776784 7.67392 0.25 9 0.25C10.3261 0.25 11.5979 0.776784 12.5355 1.71447C13.4732 2.65215 14 3.92392 14 5.25V11.8187C15.5287 12.8832 16.6778 14.4078 17.2801 16.1706C17.8823 17.9334 17.9063 19.8424 17.3485 21.6197C16.7906 23.397 15.6801 24.95 14.1786 26.0525C12.677 27.1549 10.8628 27.7495 9 27.7495C7.13717 27.7495 5.32296 27.1549 3.82142 26.0525C2.31988 24.95 1.20937 23.397 0.651538 21.6197C0.0937028 19.8424 0.117653 17.9334 0.719903 16.1706C1.32215 14.4078 2.47127 12.8832 4 11.8187V5.25ZM5.43 13.8687C4.33772 14.6289 3.51656 15.7178 3.08607 16.977C2.65558 18.2361 2.63823 19.5999 3.03655 20.8696C3.43487 22.1393 4.22807 23.2488 5.30066 24.0364C6.37326 24.8241 7.66926 25.2488 9 25.2488C10.3307 25.2488 11.6267 24.8241 12.6993 24.0364C13.7719 23.2488 14.5651 22.1393 14.9634 20.8696C15.3618 19.5999 15.3444 18.2361 14.9139 16.977C14.4834 15.7178 13.6623 14.6289 12.57 13.8687L11.5 13.1225V5.25C11.5 4.58696 11.2366 3.95107 10.7678 3.48223C10.2989 3.01339 9.66304 2.75 9 2.75C8.33696 2.75 7.70107 3.01339 7.23223 3.48223C6.76339 3.95107 6.5 4.58696 6.5 5.25V13.1225L5.43 13.8687ZM4 19H14C14 20.3261 13.4732 21.5978 12.5355 22.5355C11.5979 23.4732 10.3261 24 9 24C7.67392 24 6.40215 23.4732 5.46447 22.5355C4.52678 21.5978 4 20.3261 4 19Z" />
            </svg>
            <div class="temp_txt">
              <span class="temp_name">Moy</span>
              <span class="temp_value">{{ cell.tmi }}°C</span>
            </div>
          </div>
          <div class="temp">
            <svg width="18" height="28" viewBox="0 0 18 28" fill="#01B6EF" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 5.25C4 3.92392 4.52678 2.65215 5.46447 1.71447C6.40215 0.776784 7.67392 0.25 9 0.25C10.3261 0.25 11.5979 0.776784 12.5355 1.71447C13.4732 2.65215 14 3.92392 14 5.25V11.8187C15.5287 12.8832 16.6778 14.4078 17.2801 16.1706C17.8823 17.9334 17.9063 19.8424 17.3485 21.6197C16.7906 23.397 15.6801 24.95 14.1786 26.0525C12.677 27.1549 10.8628 27.7495 9 27.7495C7.13717 27.7495 5.32296 27.1549 3.82142 26.0525C2.31988 24.95 1.20937 23.397 0.651538 21.6197C0.0937028 19.8424 0.117653 17.9334 0.719903 16.1706C1.32215 14.4078 2.47127 12.8832 4 11.8187V5.25ZM5.43 13.8687C4.33772 14.6289 3.51656 15.7178 3.08607 16.977C2.65558 18.2361 2.63823 19.5999 3.03655 20.8696C3.43487 22.1393 4.22807 23.2488 5.30066 24.0364C6.37326 24.8241 7.66926 25.2488 9 25.2488C10.3307 25.2488 11.6267 24.8241 12.6993 24.0364C13.7719 23.2488 14.5651 22.1393 14.9634 20.8696C15.3618 19.5999 15.3444 18.2361 14.9139 16.977C14.4834 15.7178 13.6623 14.6289 12.57 13.8687L11.5 13.1225V5.25C11.5 4.58696 11.2366 3.95107 10.7678 3.48223C10.2989 3.01339 9.66304 2.75 9 2.75C8.33696 2.75 7.70107 3.01339 7.23223 3.48223C6.76339 3.95107 6.5 4.58696 6.5 5.25V13.1225L5.43 13.8687ZM4 19H14C14 20.3261 13.4732 21.5978 12.5355 22.5355C11.5979 23.4732 10.3261 24 9 24C7.67392 24 6.40215 23.4732 5.46447 22.5355C4.52678 21.5978 4 20.3261 4 19Z" />
            </svg>
            <div class="temp_txt">
              <span class="temp_name">Min</span>
              <span class="temp_value">{{ cell.tmi_min }}°C</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filing">
      <h4>Remplissage</h4>
      <div class="group">
        <span class="title">
          Date de début de remplissage
        </span>
        <div class="data">
          <img src="/img/icon/time.svg" alt="" />
          <span>
            {{
              cell.current_volume > 0
                ? firstFilling
                  ? firstFilling[0]
                    ? parseDate(firstFilling[0].date)
                    : '-'
                  : '-'
                : '-'
            }}
          </span>
        </div>
      </div>
      <div class="group">
        <span class="title">
          Tonnage actuel stocké/Capacité de stockage
        </span>
        <div class="data">
          <img src="/img/icon/db.svg" alt="" />
          <span>
            {{ cell.current_volume || '0' }} t / {{ cell.volume || '-' }} t
          </span>
        </div>
      </div>
      <div class="group">
        <span class="title">
          Température moyenne lors du 1er remplissage
        </span>
        <div class="data">
          <img src="/img/icon/temp.svg" alt="" />
          <span>
            {{
              cell.current_volume > 0
                ? firstFillingTemps.tmi || '-'
                : '-'
            }}°C
          </span>
        </div>
      </div>
      <div class="group">
        <span class="title">
          Espèce
        </span>
        <div class="data">
          <img src="/img/icon/lot.svg" alt="" />
          <span> {{ cell.species_id ? cell.species.code + ' ' + cell.species.name : cell.stored_species.name }}&nbsp;</span>
          <span v-if="cell.subspecies || cell.sub_species_id"> - {{ cell.sub_species_id ? cell.sub_species.code + ' ' + cell.sub_species.name : cell.subspecies }}</span>
        </div>
      </div>
      <div class="group">
        <span class="title">
          Lors du remplissage
        </span>
        <div class="data">
          <img src="/img/icon/action_grain.svg" alt="" />
          <span>{{
            cell.current_volume > 0
              ? firstFilling[0]
                ? firstFilling[0].actions_name
                  ? firstFilling[0].actions_name.split(',').join(', ').replace('description: ', '')
                  : ''
                : firstFilling.actions_name ? firstFilling.actions_name.split(',').join(', ').replace('description: ', '')  : ''
              : ''
          }} {{             cell.current_volume > 0
              ? firstFilling[0] ? firstFilling[0].actions_description ? firstFilling[0].actions_description : '' : firstFilling.actions_description ? firstFilling.actions_description  : '' : '' }}</span>
        </div>
      </div>
    </div>
    <div class="actual_data">
      <h4>Données actuelles</h4>
      <div class="cumul">
        <div class="data">
          {{  dureesNormalise ? ( dureesNormalise.dureeCumul ? dureesNormalise.dureeCumul[0].duree_cumul_from_cell : 0 ) : 0 }} h
        </div>
        <div class="label">&nbsp; durée cumulée &nbsp;</div>
        <div class="showMore">
          <img src="/img/icon/show_more.svg" alt="" />
          <q-tooltip
            >Durée de ventilation cumulée en heures sur le lot
            actuel.</q-tooltip
          >
        </div>
      </div>
      <br />
      <div class="gagne">
        <div class="data">
          {{
            cell.fillings &&
            cell.fillings.length > 0 &&
            cell.cell_ventilation &&
            cell.cell_ventilation.length > 0
              ? gainDegrees(cell)
              : 0
          }}
          °C
        </div>
        <div class="label">&nbsp; gagné &nbsp;</div>
        <div class="showMore">
          <img src="/img/icon/show_more.svg" alt="" />
          <q-tooltip
            >Cumul des degrés gagnés sur la température moyenne lors des phases
            de ventilation sur le lot actuel.</q-tooltip
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useQuasar } from 'quasar';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

export default {
  name: 'CellInformations',
  props: {
    cell: {
      type: [Object]
    },
    thermostat: {
      type: [Number]
    },
    firstFilling: {
      type: Object,
      default() {
        return {};
      }
    },
    firstFillingTemps: {
      type: [Object]
    },
    dureesNormalise: {
      type: [Object]
    }
  },
  components: {
    //
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  data() {
    return {
      //
    };
  },
  beforeMount() {
    //
  },
  updated() {
    //
  },
  mounted() {
    //
  },
  created() {
    //
  },
  computed: {
    dureeFromCell() {
      return this.dureesNormalise.dureeCumul && this.dureesNormalise.duree
        ? this.dureesNormalise.dureeCumul[0].duree_cumul_from_cell >
          this.dureesNormalise.duree[0].duree_from_cell
          ? this.dureesNormalise.dureeCumul[0].duree_cumul_from_cell
          : this.dureesNormalise.duree[0].duree_from_cell
        : 0;
    }
  },
  watch: {
    //
  },

  methods: {
    parseDate(date) {
      return dayjs(Date.parse(date))
        .locale('fr')
        .format('DD/MM/YY');
    },
    gainDegrees(cell) {
      if (
        cell.fillings &&
        cell.fillings.length > 0 &&
        cell.cell_ventilation &&
        cell.cell_ventilation.length > 0
      ) {
        let degreesGain = 0.0;
        for (let ventilation of cell.cell_ventilation) {
          degreesGain +=
            ventilation.temperature_after - ventilation.temperature_before;
        }
        return ((-1 * (degreesGain * 100)) / 100).toFixed(2);
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
