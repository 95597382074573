<template>
  <div>
    <AddModal
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      activeFormValidation
      size="960"
      closeBtnCenter
      v-if="cellObject && cellObject.status != 'vide'"
      name="emptying"
      title="Vidange de la Cellule"
      cell_desk
      :silo="siloInfo"
      :cell="cellObject.name"
      :species="
        cellObject.species && cellObject.species.name
          ? cellObject.species.name : 
          cellObject.stored_species && cellObject.stored_species.name
            ? cellObject.stored_species.name
            : ''
      "
      cell_info
      :label_date="'Date de remplissage'"
      :date="cellObject.lastSaisie ? cellObject.lastSaisie : '-'"
      :current_volume="parseInt(cellObject.current_volume)"
      :max_volume="parseInt(cellObject.volume)"
      label_volume_2="Capacité de stockage"
      :actionGrains="actionGrains"
      commentModal
      infoGrainModal
      @submit="vidange"
    >
      <Loader v-if="isLoading" />
      <div class="date_container">
        <InputDate
          :last_input="cellLastInput"
          class="date"
          titleDate="Date de l’opération *"
          :isRange="false"
          :dayValue="dayValue"
          @valuePickerUniq="valuedayUniq"
        />
        <InputForm
          @modelSelected="selectedTonnage"
          type="number"
          class="date"
          tonnage
          :titreInput="`Volume vidangé (max: ${cellObject.current_volume}t) *`"
          :max="cellObject.current_volume"
          ruleCondition="required max notZero"
        />
        <div class="cellInfo">
          Tonnage après opération: <span>{{ emptyingVolume ? (cellObject.current_volume - emptyingVolume).toFixed(2)  : parseInt(cellObject.current_volume)  }}T</span>.
        </div>
      </div>
    </AddModal>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import { mapGetters } from 'vuex';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import ModalHeader from '@/components/atoms/ModalHeader';
// import Tabs from '@/components/atoms/Tabs';
// import InputForm from '@/components/atoms/Form/InputForm';
import InputDate from '@/components/atoms/Inputs/InputDate';
import InputForm from '@/components/atoms/Inputs/InputForm';
// import BtnAdd from '@/components/atoms/BtnLoader';
import dayjs from 'dayjs';
import Loader from '@/components/atoms/Loader';
import { useQuasar } from 'quasar';
// import AlerteModal from '@/components/molecules/AlerteModal';

import 'dayjs/locale/fr';
// import capturesLists from '@/assets/_data/capturesLists.json';

export default {
  name: 'AddEmptying',
  props: {
    isOpen: { type: Boolean, default: false },
    siloInfo: {
      type: String
    },
    cellName: {
      type: String
    },
    saisiesList: {
      type: Object
    },
    cellId: {
      type: [Number, String]
    },
    cellObject: {
      type: Object
    },
    cellDateRemp: { type: String },
    cellSpecies: { type: String },
    cellDateCapture: { type: String }
  },
  components: {
    InputDate,
    // ModalHeader,
    InputForm,
    AddModal,
    // AlerteModal,
    // BtnAdd,
    Loader
    // Tabs
  },
  data() {
    return {
      emptyingDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      isLoading: false,
      error: false,
      errorSendDate: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      currentTab: 'emptying',
      emptyingVolume: 0,
      tabs: {
        emptying: 'Cellule'
      },
      errorDate: false,
      dayValue: new Date().toISOString().substr(0, 10)
    };
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  computed: {
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    actionGrains() {
      if (this.GET_ACTION_GRAIN) {
        return this.GET_ACTION_GRAIN.map(value => {
          return value.name;
        });
      }
      return [];
    },
    cellLastInput() {
      return this.cellObject.lastSaisieTimestamp
        ? new Date(this.cellObject.lastSaisieTimestamp * 1000)
        : null;
    }
  },
  methods: {
    valuedayUniq(e) {
      this.dayValue = e;
    },
    selectedTonnage(nv) {
      this.emptyingVolume = nv;
    },
    dayNow(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY');
    },
    setDate(evt, date) {
      if (evt < this.checkDateInput(date) || evt > this.maxDate) {
        this.errorDate = true;
        this.emptyingDate = '';
        // this.modalStore.showModal('confirmationDate');
      } else {
        this.error = false;
        this.errorSendDate = false;
        this.emptyingDate = evt;
        this.errorDate = false;
      }
    },
    verifMaxVolume(evt) {
      if (parseInt(evt.target.value) > this.cellObject.current_volume) {
        this.emptyingVolume = this.cellObject.current_volume;
      }
    },
    checkDate(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();
      return this.day(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.day(arrSort[arrSort.length - 1])
        : undefined;
    },
    checkDateInput(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      let dateinfoCell = '';
      if (date.infos_cell && date.infos_cell.length > 0) {
        dateinfoCell = date.infos_cell[date.infos_cell.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0 &&
        date.infos_cell &&
        date.infos_cell.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings, dateinfoCell);
      const arrSort = arr.sort();
      return this.dayInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.dayInput(arrSort[arrSort.length - 1])
        : undefined;
    },
    displayDateInput() {
      let dateVenti = '';
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length > 0
      ) {
        dateVenti = this.cellObject.cell_ventilation[
          this.cellObject.cell_ventilation.length - 1
        ].date;
      }
      let dateEmpty = '';
      if (this.cellObject.emptying && this.cellObject.emptying.length > 0) {
        dateEmpty = this.cellObject.emptying[
          this.cellObject.emptying.length - 1
        ].date;
      }
      let datefillings = '';
      if (this.cellObject.fillings && this.cellObject.fillings.length > 0) {
        datefillings = this.cellObject.fillings[
          this.cellObject.fillings.length - 1
        ].date;
      }
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length < 0 &&
        this.cellObject.emptying &&
        this.cellObject.emptying.length < 0 &&
        this.cellObject.fillings &&
        this.cellObject.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();

      return this.dayInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.dayInput(arrSort[arrSort.length - 1])
        : new Date().toISOString().slice(0, 10);
    },
    day(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY');
    },
    dayInput(date) {
      return dayjs(date)
        .locale('fr')
        .format('YYYY-MM-DD');
    },
    vidange(actionGrains, comment) {
      this.isLoading = true;
      let parsedActionGrains = actionGrains.map(actionGrainString => {
        return {
          action_grain_id: this.GET_ACTION_GRAIN.filter(actionGrain => {
            return actionGrain.name == actionGrainString;
          })[0].id
        };
      });

      // this.$refs.btn1.activeLoader = true;
      // this.btnEnvoi = false;
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
      let data = {
        date: this.dayValue,
        user_id: user.id,
        cell_id: this.cellId,
        volume: this.emptyingVolume,
        action_grain: parsedActionGrains,
        action_grain_description: comment,
        action: 'Vidange'
      };

      Api.vidangeCell(data)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('silos/loadSilo');
            // this.$store.dispatch('silos/loadCell');
            this.$store.dispatch('listFillings/loadFillings');
            //  this.$store.dispatch('silos/loadFans');
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.$store.dispatch('indicator/loadIndicatorLocation');

            this.isLoading = false;
            this.isErrorMsg = false;

            // ModalStore.hideModal('emptying');
            this.$emit('update:isOpen', false);
            // this.$refs.btn1.activeLoader = false;
            this.$emit('done');

            this.$q.notify({
              message: `${data.message}`,
              color: 'positive'
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.$q.notify({
            message: `${error}`,
            color: 'negative'
          });
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
