import ApiRequest from './ApiRequest';

/**
 * @param {String} endPoint - API endpoint url
 * @param {?String} method - Request method
 * @param {?Object} params - Request params
 * @return {Promise}
 */
// const templateFn = (endPoint, method, params = {}, options = {}) => new ApiRequest({ url: endPoint, method, data: params, ...options, cancelToken: source.token }).load();
const templateFn = (endPoint, method, params = {}, options = {}) =>
  new ApiRequest({ url: endPoint, method, data: params, ...options }).load();

export default class Api {checkToken
  /* ----- Login ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static login(params) {
    return templateFn('/auth/login', ApiRequest.METHOD_POST, params);
  }
  /* ----- Check Token ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static checkToken() {
    return templateFn('/auth/checkToken', ApiRequest.METHOD_GET, {});
  }
  /**
   * @return {Promise}
   */
  static logout() {
    return templateFn('/logout', ApiRequest.METHOD_POST, {}).catch(() => ({
      status: 401
    }));
  }
  /* ----- Forgot ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static forgot(params) {
    return templateFn('/auth/forgot', ApiRequest.METHOD_POST, params);
  }
  /* ----- Reset ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static reset(params) {
    return templateFn(`/auth/reset`, ApiRequest.METHOD_POST, JSON.stringify(params));
  }

  /* ----- User ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getUser() {
    return templateFn('/user', ApiRequest.METHOD_GET, {});
  }

  /* ----- User ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getUserById(id, params) {
    return templateFn(`/auth/autoload/${id}`, ApiRequest.METHOD_POST, params);
  }
  /* ----- User Profile ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getProfile(params) {
    return templateFn(
      '/user-profile',
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- User Update Profile ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static updateProfile(id, params) {
    return templateFn(
      `/edit/${id}`,
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- User Register ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static register(params) {
    return templateFn('/auth/register', ApiRequest.METHOD_POST, params);
  }

  /**
   * @param {File}
   * @return {Promise}
   */
  static setLogo(params, axiosOption = {}) {
    return templateFn('/setFile', ApiRequest.METHOD_POST, params, axiosOption);
  }

  /* ----- Silo ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getSilo() {
    return templateFn('/operator/silo', ApiRequest.METHOD_GET, {});
  }
  /* ----- Add Silo ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addSilo(params) {
    return templateFn('/operator/silo', ApiRequest.METHOD_POST, params);
  }

  /* ----- Add Fan ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addfan(params) {
    return templateFn('/operator/fan', ApiRequest.METHOD_POST, params);
  }
  /* ----- Add Cell ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addCell(params) {
    return templateFn('/operator/cell', ApiRequest.METHOD_POST, params);
  }
  /* ----- GET Empty CELLS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getEmptyCell(params) {
    return templateFn('/operator/cells/voids', ApiRequest.METHOD_POST, params);
  }
  /* ----- GET Input CELLS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getCellInput(id) {
    return templateFn(`/operator/cell/${id}/input`, ApiRequest.METHOD_GET, {});
  }
  /* ----- GET Deleted Input CELLS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getCellDeletedInput(id) {
    return templateFn(
      `/operator/cell/${id}/input/deleted`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getCellsByLocation() {
    return templateFn('/operator/cells', ApiRequest.METHOD_GET, {});
  }

    /* ----- GET Input CELLS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
   static getCellLots(id) {
    return templateFn(`/operator/cell/${id}/history`, ApiRequest.METHOD_GET, {});
  }

  /* ----- Add infos grain ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addInfosToCell(params) {
    return templateFn('/operator/cell/infos', ApiRequest.METHOD_POST, params);
  }
  /* ----- GET Deleted Input CELLS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getDureesNormalisesFromCell(cell_id) {
    return templateFn(
      `/operator/timeVentilation/cell/${cell_id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  static updateDureesNormalisesFromCell(cell_id) {
    return templateFn(
      `/operator/timeVentilationUpdate/cell/${cell_id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /* ----- GET ALL Input Location ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getCellDate(id) {
    return templateFn(`/operator/cell/${id}/date`, ApiRequest.METHOD_GET, {});
  }
  /* ----- GET ALL Input Location ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getInputCellsByLocationId(location_id) {
    return templateFn(
      `/operator/location/${location_id}/input`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /* ----- GET LOCATION INDICATORS ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getIndicatorsFromLocation(location_id) {
    return templateFn(
      `/operator/location/${location_id}/indicators`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- DELETE Cell ----- */
  /**
   * @param {Object} id
   * @return {Promise}
   */
  static deleteCell(id) {
    return templateFn(`/operator/cell/${id}`, ApiRequest.METHOD_DELETE, {});
  }
  /* ----- Detach Ventillateur  cell ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static detachVent(id) {
    return templateFn(
      `/operator/cell/detach/${id}`,
      ApiRequest.METHOD_POST,
      {}
    );
  }
  /* ----- Attach Ventillateur  cell ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static attachVent(data) {
    return templateFn(
      `/operator/cell/attach/` + data.cell_id,
      ApiRequest.METHOD_POST,
      data
    );
  }
  /* ----- Emptying  cell ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static vidangeCell(params) {
    return templateFn(`/operator/emptying`, ApiRequest.METHOD_POST, params);
  }

  /* ----- Add Ventillateur ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addVent(params) {
    return templateFn('/operator/ventilation', ApiRequest.METHOD_POST, params);
  }
  /* ----- Add Fillings ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static addFillings(params) {
    return templateFn('/operator/fillings', ApiRequest.METHOD_POST, params);
  }
  /* ----- GET Fillings by id----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getFillings(id) {
    return templateFn(`/operator/fillings/${id}`, ApiRequest.METHOD_GET, {});
  }
  /* ----- GET all Fillings ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getAllFillings() {
    return templateFn(`/operator/allfillings`, ApiRequest.METHOD_GET, {});
  }
  /* ----- DELETE capture By type And Id ----- */
  /**
   * @param {Object} id
   * @return {Promise}
   */
  static deleteCapture() {
    return templateFn(`/operator/rollbacklast`, ApiRequest.METHOD_DELETE, {});
  }

  /* ----- DELETE  ----- */
  /**
   * @param {Object} id
   * @return {Promise}
   */
  static deleteLast(id) {
    return templateFn(
      `/operator/rollbacklast/${id}`,
      ApiRequest.METHOD_DELETE,
      {}
    );
  }

  /* ----- GET all storedSpecies ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getStoredSpecies(role) {
    return templateFn(`/${role}/storedSpecies`, ApiRequest.METHOD_GET, {});
  }
  /* ----- GET all storedSpecies by location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getStoredSpeciesByLocation(role) {
    return templateFn(
      `/${role}/storedSpecies/location`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /* ----- GET all storedSpecies by location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getNewStoredSpeciesByLocation() {
    return templateFn(
      `/locationSpecies`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /* ----- POST all storedSpecies by location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static postStoredSpeciesByLocation(params) {
    return templateFn(
      `/manager/storedSpecies/location`,
      ApiRequest.METHOD_POST,
      params
    );
  }
  /* ----- POST all storedSpecies by location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static postStoredSpecies(params) {
    return templateFn(`/manager/storedSpecies`, ApiRequest.METHOD_POST, params);
  }

  /* ----- GET all campaign ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getCampaign() {
    return templateFn(`/operator/campaign`, ApiRequest.METHOD_GET, {});
  }
  /* ----- GET Action Grain ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getActionGrain() {
    return templateFn(`/operator/action_grain`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET Configuration Settings ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getAppSettings() {
    return templateFn(`/operator/app_settings`, ApiRequest.METHOD_GET, {});
  }

  /*****************  ROUTE SUPERVISEUR *****************/

  /* ----- GET ALL INDICATORS FROM ALL LOCATIONS ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getIndicatorsFromAllLocations(ids) {
    return templateFn(
      `/manager/operators/indicators${ids ? '/' + ids : ''}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- GET  LOCATIONS BY SPECIE----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getLocationsBySpecie(role, id) {
    return templateFn(
      `/${role}/location/storedSpecies/${id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }
  /* ----- GET Operator List ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getOperators() {
    return templateFn(`/manager/operators`, ApiRequest.METHOD_GET, {});
  }

    /* ----- GET Api Key List ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getApiKeys() {
    return templateFn(`/manager/apiKeys`, ApiRequest.METHOD_GET, {});
  }

  static createApiKey(params) {
    return templateFn(`/manager/apiKeys`, ApiRequest.METHOD_POST, params);
  }

  static getTemporaryOperators() {
    return templateFn(`/manager/temporaryOperators`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET location List ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getLocation() {
    return templateFn(`/manager/location`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET operator location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getOperatorLocation() {
    return templateFn(`/operator/location`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET operator location ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static export_histories(params) {
    return templateFn(`/export_histories`, ApiRequest.METHOD_POST, params);
  }

  /* ----- GET operator location ----- */
  /**
   */
  static removeHistoryFile() {
    return templateFn(`/removeHistoryFile`, ApiRequest.METHOD_GET, {});
  }

    /* ----- PUT Disable API KEY ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static disableApiKey(id) {
    return templateFn(
      `/manager/apiKey/disabled/${id}`,
      ApiRequest.METHOD_PUT,
      {}
    );
  }
  /* ----- PUT Activated Operator ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static enableApiKey(id) {
    return templateFn(
      `/manager/apiKey/activated/${id}`,
      ApiRequest.METHOD_PUT,
      {}
    );
  }

  /* ----- PUT Disable Operator ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static disableOperators(id) {
    return templateFn(
      `/manager/operator/disabled/${id}`,
      ApiRequest.METHOD_PUT,
      {}
    );
  }

  /* ----- DELETE Operator ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
   static deleteOperator(id) {
    return templateFn(
      `/manager/operator/delete/${id}`,
      ApiRequest.METHOD_DELETE,
      {}
    );
  }

  /* ----- PUT Activated Operator ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static activatedOperators(id) {
    return templateFn(
      `/manager/operator/activated/${id}`,
      ApiRequest.METHOD_PUT,
      {}
    );
  }
  /* ----- Edit Operator----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static editOperator(id, params) {
    return templateFn(
      `/manager/operator/edit/${id}`,
      ApiRequest.METHOD_POST,
      params
    );
  }
  /* ----- Sign-in Operator Send Email Password Operator----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static signIn(params) {
    return templateFn(
      '/manager/inscription/operator',
      ApiRequest.METHOD_POST,
      params
    );
  }

  /* ----- Sign-in Operator with Token  ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static signInTokenOperator(token) {
    return templateFn(
      `/auth/inscription/operator/${token}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- Valid password ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static validPassword(params) {
    return templateFn(
      `/auth/operator`,
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- IMPORT EXCEL ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static importSiloXls(params, axiosOption = {}) {
    return templateFn(
      `/manager/importV2`,
      ApiRequest.METHOD_POST,
      params,
      axiosOption
    );
  }

  /* ----- CHECK FIRST DATE OF LOCATION ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static checkFirstHistory(id) {
    return templateFn(
      `/get_first_action_of_location/${id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- GET IMPERSONATE INFOS ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static impersonate(id) {
    return templateFn(`/manager/impersonate/${id}`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET LAST SAISIE TYPE ----- */
  /**
   * @param {Object} params
   * @return {Promise}
   */
  static getLastSaisie() {
    return templateFn(`/operator/last_saisie`, ApiRequest.METHOD_GET, {});
  }
  static getLastSaisieByCell(id) {
    return templateFn(`/operator/last_saisie/${id}`, ApiRequest.METHOD_GET, {});
  }

  /* ----- GET HIstory Info Grain by Cells ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getHistoryInfoGrainsByCell(id) {
    return templateFn(
      `/operator/cell/${id}/history_info_grains`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- Get Alert Of User ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getAlertOfUser(id) {
    return templateFn(
      `/manager/getAlertOfUser/${id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  /* ----- Add Alert To User ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static addAlertToUser(id, params) {
    return templateFn(
      `/manager/addAlertToUser/${id}`,
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- Update Alert To User ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static updateAlertToUser(id, params) {
    return templateFn(
      `/manager/updateAlertToUser/${id}`,
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- Remove Alert To User ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static removeAlertToUser(id, params) {
    return templateFn(
      `/manager/removeAlertToUser/${id}`,
      ApiRequest.METHOD_POST,
      JSON.stringify(params)
    );
  }

  /* ----- Get Risk By Cell ----- */
  /**
   * @param {Object}
   * @return {Promise}
   */
  static getRiskByCell(id) {
    return templateFn(
      `/operator/getRiskByCell/cell/${id}`,
      ApiRequest.METHOD_GET,
      {}
    );
  }

  static getFansBySilo(id) {
    return templateFn(`/operator/fans/${id}`, ApiRequest.METHOD_GET, {});
  }
}
