<template>
  <q-btn outline dense icon="expand_more" class="btnDropdown">
    <q-menu anchor="bottom middle" self="top middle">
      <q-list class="index">
        <q-item
          v-for="item in listLinks"
          :key="item.subSecondary"
          dense
          clickable
          v-close-popup
          class="content separator"
          @click="item.link == 'silo' ? inside() : outside(item.link)"
        >
          <q-item-section
            ><LogoApp
              :subSecondary="item.subSecondary"
              :size="item.size"
              :colorApp="item.colorApp"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
// import { toRefs } from 'vue';
import { useRouter } from 'vue-router';
import LogoApp from '@/components/atoms/LogoApp';
import { useQuasar } from 'quasar';
const listItem = [
  {
    subFirst: '',
    subSecondary: 'Ventil',
    size: "logo_sm",
    colorApp: 'ventil',
    link: 'login',
  },
  {
    subFirst: '',
    subSecondary: 'Elec',
    size: "logo_sm",
    colorApp: 'elec',
    link: 'elec',
  },
  {
    subFirst: '',
    subSecondary: 'Silo',
    size: "logo_sm",
    colorApp: 'silo',
    link: 'silo',
  },
];

export default {
  name: 'ChangeAppDropdown',
  props: {},
  components: {
    LogoApp,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
    const router = useRouter();

    return {
      listLinks: listItem,
      inside() {
        router.push({ name: 'Home' });
      },
    };
  },
  methods: {
    outside(redirect_link) {
      const remember = JSON.parse(localStorage.getItem('rememberMe'));

      const token = remember
        ? JSON.parse(localStorage.getItem('token'))
        : JSON.parse(sessionStorage.getItem('token'));

      const user = remember
        ? JSON.parse(localStorage.getItem('user'))
        : JSON.parse(sessionStorage.getItem('user'));

      let url;

      if (redirect_link == 'elec') {
        if (user.access_optitalon) {
          url = process.env.VUE_APP_OPTITALON_FRONT_URL + `login?access_token=${token}`;
        }
      } else {
        if (user.access_optiventil) {
          url = process.env.VUE_APP_OPTIVENTIL_FRONT_URL + `login?access_token=${token}`;
        }
      }

      if (url) {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        window.open(link, '_blank');
      } else {
        this.$q.notify({
          message: `Vous n'avez pas accès a cette application`,
          color: 'negative'
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
