import Api from '@/classes/api/Api';

export default {
  async loadInputLocation({ commit }) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'));
    await Api.getInputCellsByLocationId(user.location_group[0].location_id)
      .then(({ data }) => {
        if (data) {
          commit('GET_INPUT_CELL_LOCATION', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
