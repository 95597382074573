import Api from '@/classes/api/Api';

export default {
  async getIndicatorsFromAllLocations({ commit }, storedSpeciesIds = null) {
    await Api.getIndicatorsFromAllLocations(storedSpeciesIds)
      .then(({ data }) => {
        if (data) {
          commit('GET_INDICATORS_FROM_ALL_LOCATIONS', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  async getLocationSpecies({ commit }, id) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'));
    let role = user.role.id === 2 ? 'operator' : 'manager';
    await Api.getLocationsBySpecie(role, id)
      .then(({ data }) => {
        if (data) {
          commit('GET_LOCATIONS_BY_SPECIE_ID', data);
          console.log('GET_LOCATIONS_BY_SPECIE_ID', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
