import Api from '@/classes/api/Api';

export default {
  async loadIndicatorLocation({ commit }) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user')); // console.log("yoooooop")
    // console.log(user)
    await Api.getIndicatorsFromLocation(user.location_group[0].location_id)
      .then(({ data }) => {
        if (data) {
          commit('GET_INDICATOR_LOCATION', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
