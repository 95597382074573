<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="ConfigManager">
      <div class="contentForm" v-if="addOp">
        <h4>Créer un nouvel opérateur</h4>
        <form
          id="myform"
          class="myForm"
          v-on:submit.prevent="onSubmit()"
          enctype="multipart/form-data"
        >
          <InputForm
            icon
            nameIcon="user"
            prefixIcon="far"
            type="text"
            placeholder="Prenom"
            v-model="firstName"
            :value="firstName"
            :errorMsg="msg.firstName"
            inputModal="inputIcon"
          />
          <InputForm
            icon
            nameIcon="user"
            prefixIcon="far"
            type="text"
            placeholder="Nom"
            v-model="lastName"
            :value="lastName"
            :errorMsg="msg.lastName"
            inputModal="inputIcon"
          />
          <InputForm icon iconCircle nameIcon="paper-plane" />
          <br />
          <div>
            <select v-model="locationValue">
              <option value="" selected>Sélectionnez le site</option>
              <option
                v-for="location in GET_ACTION_LOCATION"
                :key="location.id"
                :value="location.location_id"
                >{{ location.location.name }}</option
              >
            </select>
            <span v-if="errorMsg" class="error-message"
              ><icon :icon="['far', 'times-circle']" class="icon" />{{
                msg.locationValue
              }}</span
            >
          </div>
          <br />
          <div class="Btn">
            <BtnSubmit
              title="Envoyer"
              label="Envoyer"
              textBtn="Envoyer"
              textBtnLoader="Envoi en cours"
              active-loader
              styleBtn="contained"
              colorBtn="primary"
              class="contentBtn"
              typeBtn="submit"
              style="margin-left:25px;"
              icon
              iconCircle
              nameIcon="paper-plane"
            />
          </div>

          <div class="success" v-if="isSuccess">
            Un email a été envoyé à l'opérateur.
          </div>
          <div class="error" v-if="isError">
            {{ msg.msgError }}
          </div>
        </form>
      </div>

      <!-- Table -->
      <div class="titleHeaders">
        <h4>Liste des opérateurs</h4>
      </div>
      <div class="list_operators">
        <!-- search bar -->
        <div class="container" style="position:relative;width: 500px;">
                                    <InputForm
                  class="search_input"
                  @modelSelected="searchOperatorInput"
                  prependIcon="/img/icon/search_icon.svg"
                />
          <!--<img src="/img/icon/search-icon.svg"/>
          <input
            class="form-control me-2 search"
            type="search"
            v-model="searchOperator"
            placeholder="Rechercher un opérateur"
            aria-label="Search"
          />-->
        </div>
        <span class="addOperator" style="margin-top:16px;">
          <p  @click="updateModalAddOperator()">
            Ajouter un opérateur
          </p>
          <icon size="lg" :icon="['fas', 'user-plus']" class="addIcon" />
        </span>
        <div class=" tableContent table-responsive-sm ">
          <table
            v-if="GET_ACTION_LIST_OPERATOR && GET_ACTION_LIST_OPERATOR.length"
            class="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th
                  v-for="([key, value], i) in Object.entries(columns)"
                  :key="i"
                  scope="col"
                >
                  <button
                    v-if="!isSortDisabled(key)"
                    class="sortBtn"
                    @click="sortBy(key)"
                  >
                    <icon
                      size="lg"
                      style="fill:white;"
                      :icon="['fas', getSortType(key)]"
                    />
                    <i :class="`icon-${getSortType(key)}`"></i>
                    {{ value.name }}
                  </button>
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="operator in filteredOperator" :key="operator.id">
                <td :class="{ disabled: operator.active === 0 }">
                  {{ operator.firstname }}
                </td>
                <td :class="{ disabled: operator.active === 0 }">
                  {{ operator.lastname }}
                </td>
                <td :class="{ disabled: operator.active === 0 }">
                  {{ operator.email }}
                </td>
                <td :class="{ disabled: operator.active === 0 }">
                  {{ operator.location_name }}
                </td>
                <td class="tooltips">
                  <icon
                    size="lg"
                    :icon="['fas', 'user-edit']"
                    class="iconTooltip edit"
                    @click="updateModalOperator(operator)"
                  />
                  <span class="tooltiptext">Modifier opérateur</span>
                </td>
                <td class="tooltips">
                  <icon
                    size="lg"
                    :icon="['fas', 'user-times']"
                    class="iconTooltip times"
                    @click="updateModalDeleteOperator(operator)"
                  />
                  <span class="tooltiptext">Supprimer opérateur</span>
                </td>
                <td class="tooltips">
                  <icon
                    v-if="operator.active !== 0"
                    size="lg"
                    :icon="['fas', 'lock']"
                    class="iconTooltip lock"
                    :style="{ color: 'green' }"
                    @click="desactivatedOperator(operator.id)"
                  /><icon
                    v-if="operator.active === 0"
                    size="lg"
                    :icon="['fas', 'unlock']"
                    class="iconTooltip lock"
                    :style="{ color: 'orange' }"
                    @click="reactivateOperator(operator.id)"
                  />
                  <span v-if="operator.active !== 0" class="tooltiptext"
                    >Bloquer opérateur</span
                  >
                  <span v-else class="tooltiptext">L'opérateur est bloqué</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--<div class="titleHeaders">
        <h4>Liste des opérateurs invités</h4>
      </div>
            <div class="container" style="position:relative;width: 500px;">
                            <InputForm
                  class="search_input"
                  @modelSelected="searchOperatorTempInput"
                  prependIcon="/img/icon/search_icon.svg"
                />-->
          <!--<img src="/img/icon/search-icon.svg"/>
          <input
            class="form-control me-2 search"
            type="search"
            v-model="searchFilteredOperator"
            placeholder="Rechercher un opérateur invité"
            aria-label="Search"
          />-->
        <!--</div>
      <div class=" tableContent table-responsive-sm ">
          <table
            v-if="GET_ACTION_LIST_TEMP_OPERATOR && GET_ACTION_LIST_TEMP_OPERATOR.length"
            class="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th
                  v-for="([key, value], i) in Object.entries(columnsTemp)"
                  :key="i"
                  scope="col"
                >
                  <button
                    v-if="!isSortTempDisabled(key)"
                    class="sortBtn"
                    @click="sortByTemp(key)"
                  >
                    <icon
                      size="lg"
                      style="fill:white;"
                      :icon="['fas', getSortTypeTemp(key)]"
                    />
                    <i :class="`icon-${getSortTypeTemp(key)}`"></i>
                    {{ value.name }}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="operatorTemp in filteredTempOperator" :key="operatorTemp.id">
                <td>
                  {{ operatorTemp.firstname }}
                </td>
                <td>
                  {{ operatorTemp.lastname }}
                </td>
                <td>
                  {{ operatorTemp.email }}
                </td>
                <td>
                  {{ operatorTemp.location_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>-->
      </div>
      <EditOperatorModal 
        :isOpen="updateOperatorModal"
        @update:isOpen="nv => updateOperatorModal = nv"
        :op="op" 
        name="updateOperator" 
        @click.stop>
      </EditOperatorModal>
      <AddOperatorModal 
        :isOpen="addOperatorModal"
        @update:isOpen="nv => addOperatorModal = nv"
        name="addOperator" 
        @click.stop
      />
      <DeleteOperatorModal 
        :isOpen="deleteOperatorModal"
        @update:isOpen="nv => deleteOperatorModal = nv"
        :op="op" 
        name="deleteOperator" 
        @click.stop 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Inputs/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import EditOperatorModal from '@/components/molecules/EditOperatorModal';
import AddOperatorModal from '@/components/molecules/AddOperatorModal';
import DeleteOperatorModal from '@/components/molecules/DeleteOperatorModal';
import SortingUtils from '@/classes/utils/SortingUtils';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default {
  name: 'Operators',
  components: {
    BtnSubmit,
    InputForm,
    AddOperatorModal,
    EditOperatorModal,
    DeleteOperatorModal,
    Loader
  },
  data: function() {
    return {
      updateOperatorModal: false,
      addOperatorModal: false,
      deleteOperatorModal: false,
      searchOperator: '',
      searchFilteredOperator: '',
      closeAddOp: 'closeAddOp',
      addOp: false,
      firstName: '',
      lastName: '',
      email: '',
      locationValue: '',
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      modalStore: ModalStore,
      columns: [
        {
          name: 'Prénom',
          field: 'firstname',
          sortFn: SortingUtils.compareString('firstname')
        },
        {
          name: 'Nom',
          field: 'lastname',
          sortFn: SortingUtils.compareString('lastname')
        },
        {
          name: 'Email',
          field: 'email',
          sortFn: SortingUtils.compareString('email')
        },
        {
          name: 'Site',
          field: 'location_name',
          sortFn: SortingUtils.compareString('location_name')
        }
      ],
      columnsTemp: [
        {
          name: 'Prénom',
          field: 'firstname',
          sortFn: SortingUtils.compareString('firstname')
        },
        {
          name: 'Nom',
          field: 'lastname',
          sortFn: SortingUtils.compareString('lastname')
        },
        {
          name: 'Email',
          field: 'email',
          sortFn: SortingUtils.compareString('email')
        },
        {
          name: 'Site',
          field: 'location_name',
          sortFn: SortingUtils.compareString('location_name')
        }
      ],
      sorting: {
        name: null,
        type: 'sort'
      },
      sortingTemp: {
        name: null,
        type: 'sort'
      },
      op: []
    };
  },
  computed: {
    ...mapGetters('listOperator', ['GET_ACTION_LIST_OPERATOR']),
    ...mapGetters('listTempOperator', ['GET_ACTION_LIST_TEMP_OPERATOR']),
    ...mapGetters('listLocation', ['GET_ACTION_LOCATION']),
    filteredOperator() {
      let operator = this.GET_ACTION_LIST_OPERATOR;
      // Process search input
      if (this.searchOperator != '' && this.searchOperator) {
        return operator.filter(item => {
          return (
            item.firstname
              .toLowerCase()
              .includes(this.searchOperator.toLowerCase()) ||
            item.lastname
              .toLowerCase()
              .includes(this.searchOperator.toLowerCase()) ||
            item.email.toLowerCase().includes(this.searchOperator.toLowerCase()) ||
            item.location_name.toLowerCase().includes(this.searchOperator.toLowerCase())
          );
        });
      }

      // Show sorted array in descending or ascending order

      const { type, name } = this.sorting;
      if (!name || type === 'sort') {
        return operator;
      }
      return operator
        .slice()
        .sort((a, b) => this.columns[name].sortFn(a, b, type === 'sort-desc'));
    },
    filteredTempOperator() {
      let operatorTemp = this.GET_ACTION_LIST_TEMP_OPERATOR;
      // Process search input
      if (this.searchFilteredOperator != '' && this.searchFilteredOperator) {
        return operatorTemp.filter(item => {
          return (
            item.firstname
              .toLowerCase()
              .includes(this.searchFilteredOperator.toLowerCase()) ||
            item.lastname
              .toLowerCase()
              .includes(this.searchFilteredOperator.toLowerCase()) ||
            item.email.toLowerCase().includes(this.searchFilteredOperator.toLowerCase()) ||
            item.location_name.toLowerCase().includes(this.searchFilteredOperator.toLowerCase())
          );
        });
      }

      // Show sorted array in descending or ascending order

      const { type, name } = this.sortingTemp;
      if (!name || type === 'sort') {
        return operatorTemp;
      }
      // console.log(operatorTemp);
      return operatorTemp
        .slice()
        .sort((a, b) => this.columnsTemp[name].sortFn(a, b, type === 'sort-desc'));
    }
  },
  methods: {
    searchOperatorInput(nv) {
      this.searchOperator = nv;
    },
    searchOperatorTempInput(nv) {
      this.searchFilteredOperator = nv;
    },
    updateModalOperator(op) {
      this.op = op;
      this.modalStore.showModal('updateOperator');
      this.updateOperatorModal = true;
    },
    updateModalAddOperator() {
      this.modalStore.showModal('addOperator');
      this.addOperatorModal = true;
    },
    updateModalDeleteOperator(op) {
      this.op = op;
      this.modalStore.showModal('deleteOperator');
      this.deleteOperatorModal = true;
    },
    desactivatedOperator(id) {
      Api.disableOperators(id)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('listOperator/LoadOperators');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    reactivateOperator(id) {
      Api.activatedOperators(id)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('listOperator/LoadOperators');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    validateEmail(value) {
      if (
        /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
        this.email.length <= 0
      ) {
        this.msg['emailInvalid'] = '';
      } else {
        this.msg['emailInvalid'] = 'Adresse email invalide.';
      }
    },
    day(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY');
    },

    onSubmit() {
      if (this.firstName === '') {
        this.msg['firstName'] = 'Merci de renseigner votre prenom.';
      }
      if (this.lastName === '') {
        this.msg['lastName'] = 'Merci de renseigner votre nom.';
      }

      if (this.email === '') {
        this.msg['email'] = "Merci d'indiquer un email.";
      }

      if (this.locationValue === '') {
        this.msg['locationValue'] = 'Merci de sélectionnez un site.';
        this.errorMsg = true;
      } else {
        this.isLoading = true;
        let data = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          location_id: this.locationValue
        };
        Api.signIn(data)
          .then(({ data }) => {
            if (!data) {
              this.msg = [];
              this.errorMsg = false;
              this.isError = false;
              this.isSuccess = true;
              this.isLoading = false;
              setTimeout(() => {
                // this.$store.commit('listOperator/UPDATE_LIST_OPERATOR',
                // {
                //   firstname: this.firstName,
                //   lastname: this.lastName,
                //   email: this.email,
                //   location_id: this.locationValue
                // });
                this.addOp = false;
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.locationValue = '';
                this.isSuccess = false;
              }, 3000);
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.isError = error;
            this.msg['msgError'] = `${error.response.data.message}`;
          });
      }
    },

    getSortType(key) {
      if (this.sorting.name && this.sorting.name === key) {
        return this.sorting.icon || 'sort';
      }
      return 'sort';
    },
    sortBy(key) {
      const sortType = this.getSortType(key);
      this.sorting = {
        name: key,
        icon: `arrow-${
          sortType === 'sort' || sortType === 'arrow-up' ? 'down' : 'up'
        }`,
        type: `sort-${
          sortType === 'sort' || sortType === 'arrow-up' ? 'asc' : 'desc'
        }`
      };
    },
    getSortTypeTemp(key) {
      if (this.sortingTemp.name && this.sortingTemp.name === key) {
        return this.sortingTemp.icon || 'sort';
      }
      return 'sort';
    },
    sortByTemp(key) {
      const sortType = this.getSortTypeTemp(key);
      this.sortingTemp = {
        name: key,
        icon: `arrow-${
          sortType === 'sort' || sortType === 'arrow-up' ? 'down' : 'up'
        }`,
        type: `sort-${
          sortType === 'sort' || sortType === 'arrow-up' ? 'asc' : 'desc'
        }`
      };
    },
    isSortDisabled(key) {
      return !this.columns[key].sortFn;
    },
    isSortTempDisabled(key) {
      return !this.columnsTemp[key].sortFn;
    },
    resetMsg() {
      if (this.email.length > 1) {
        this.msg['email'] = '';
      }
      if (this.firstName.length > 1) {
        this.msg['firstName'] = '';
      }
      if (this.lastName.length > 1) {
        this.msg['lastName'] = '';
      }
      if (this.locationValue !== '') {
        this.msg['locationValue'] = '';
      }
    }
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
      this.resetMsg(value);
    },

    firstName(value) {
      this.firstName = value;
      this.resetMsg(value);
    },
    lastName(value) {
      this.lastName = value;
      this.resetMsg(value);
    },
    locationValue(value) {
      this.locationValue = value;
      this.resetMsg(value);
      this.errorMsg = false;
    }
  },
  created() {
    this.$store.dispatch('listOperator/LoadOperators');
    this.$store.dispatch('listTempOperator/LoadTempOperators');
    this.$store.dispatch('listLocation/LoadLocation');
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
