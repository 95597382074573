<template>
  <div class="content q-py-md col-xs-12 col-12">
    <div class="title">{{ titleDate }}</div>

    <div color="green" outlined class="inputContainerDate bg-white">
      <q-icon
        name="event"
        size="sm"
        class="eventIcon cursor-pointer"
        color="green"
        @click="showCalendar()"
      >
        <q-popup-proxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            :locale="myLocale"
            v-if="isRange"
            range
            v-model="date"
            :options="test"
            mask="DD/MM/YYYY"
            color="green"
            text-color="white"
          >
            <div class="row items-center justify-end">
              <q-btn
                @click="valuePicker"
                v-close-popup
                label="Fermer"
                color="primary"
                flat
              />
            </div>
          </q-date>
          <q-date
            :locale="myLocale"
            v-else
            v-model="uniqDate"
            :options="test"
            no-unset
            mask="DD-MM-YYYY"
            color="green"
            text-color="white"
            @change="setDate($event)"
            @update:model-value="setDateMore()"
          >
            <div class="row items-center justify-end">
              <q-btn
                @click="valuePicker"
                v-close-popup
                label="Valider"
                color="primary"
                flat
              />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
      <div class="inputDate self-center full-width no-outline" tabindex="0">
        {{ isRange && date ? `Du ${date.from} au ${date.to}` : uniqDate }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

export default {
  name: 'InputDate',
  emits: ['default-period', 'value-picker', 'value-picker-uniq'],
  props: {
    dayValue: {
      type: String,
      default: ''
    },
    dayPersonate: {
      type: Object,
      default: () => {}
    },

    isRange: {
      type: Boolean,
      default: true
    },
    withoutYear: {
      type: Boolean,
      default: false
    },
    titleDate: {
      type: String,
      default: 'Dates'
    },
    last_input: {
      type: Date,
      default: null
    }
  },

  setup() {
    /* Aujourd'hui */
    const today = dayjs(new Date())
      .locale('fr')
      .format('DD-MM-YYYY');

    /* + 7 jours */
    // const sevenDays = dayjs(new Date())
    //   .add(7, 'days')
    //   .locale('fr')
    //   .format('DD/MM/YYYY');

    /* Dernier Mois  */
    let lastMonth = dayjs(new Date()).add(-30, 'days');

    let lastDayMonth = lastMonth
      .add(30, 'days')
      .locale('fr')
      .format('YYYY/MM/DD');

    lastMonth = lastMonth.locale('fr').format('YYYY/MM/DD');
    return {
      date: ref({ from: lastMonth, to: lastDayMonth }),
      uniqDate: ref(today),
      // options: [sevenDays],
      myLocale: {
        /* starting with Sunday */
        days: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
        daysShort: 'Dim_Lun_Mar_Mer_Jeu_Ven_Sam'.split('_'),
        months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
        monthsShort: 'Jan_Fév_Mar_Avr_Mai_Juin_Juil_Aoû_Sep_Oct_Nov_Déc'.split('_'),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: 'Jours'
      }
    };
  },
  methods: {
    test(date) {
      const today = dayjs(new Date())
        .locale('fr')
        .format('YYYY/MM/DD');
      const last_input_parsed = this.last_input
        ? dayjs(this.last_input)
            .locale('fr')
            .format('YYYY/MM/DD')
        : null;
      return Date.parse(date) >= (last_input_parsed ? Date.parse(this.last_input) : null) && Date.parse(date) <= Date.parse(today)
    },
    setDateMore() {
      if (this.isRange) {
        if (this.date && this.date.from && this.date.to) {
          this.$emit('value-picker', this.date);
        }
      } else {
        this.$emit('value-picker-uniq', this.uniqDate.split('-').reverse().join('-'), this.uniqDate.split('-').reverse().join('-'));
      }
    },
    valuePicker() {
      if (this.isRange) {
        if (this.date && this.date.from && this.date.to) {
          this.$emit('value-picker', this.date);
        }
      } else {
        this.$emit('value-picker-uniq', this.uniqDate.split('-').reverse().join('-'));
      }
    },
    // On applique en js le style, parce que le style est appliqué avant que la fenêtre ne soit générée
    showCalendar() {
      // if (this.withoutYear) {
      //   setTimeout(() => {
      //     var q_date_navigation = document.getElementsByClassName(
      //       'q-date__navigation'
      //     )[0];
      //     q_date_navigation.children[3].style = 'display:none !important';
      //     q_date_navigation.children[4].style = 'display:none !important';
      //     q_date_navigation.children[5].style = 'display:none !important';
      //     var q_header = document.getElementsByClassName(
      //       'q-date__header-subtitle'
      //     )[0];
      //     q_header.style = 'display:none !important';
      //     this.$forceUpdate();
      //   }, 100);
      // }
    },
    // On lock l'année de selection dans le input date pour une date unique, evite le décalage des jours avec les dates
    navigationMinYearMonth() {
      return new Date().getFullYear().toString() + '/01';
    },
    navigationMaxYearMonth() {
      return new Date().getFullYear().toString() + '/12';
    }
  },
  watch: {
    // uniqDate(n) {
    //   this.uniqDate = n.split('/').join('-');
    // },
    // dayValue(newValue) {
    //   this.uniqDate = newValue.split('/').join('-');
    // },
    dayPersonate(newValue) {
      this.date = newValue;
    }
  },
  data() {
    return {
      //
    };
  },
  created() {
    //Affichage d'une date par défaut sans notion d'année
    // if (this.withoutYear) {
    //   this.uniqDate = this.uniqDate.substr(0, 5);
    // }
    // this.date;
    this.$emit('default-period', this.date);
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
