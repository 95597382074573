import Api from '@/classes/api/Api';

export default {
  async loadCumul({ commit }, id) {
    await Api.updateDureesNormalisesFromCell(id)
      .then(data => {
        commit('GET_CUMUL', data.data);
        // console.log('GET_CUMUL', data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
};
