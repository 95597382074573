<template>
  <Loader v-if="isLoading" />
  <div>
    <Meta
      pageTitle="OptiSilo DashBoard Supervisor | Indicateur | Saisies | Silos"
    />
    <section>
      <PageContainer superviserPage showExportButton pageName="Tableau de bord">
        <BoxBase class="mesSilos" manager titleBox="Indicateurs clés">
          <div class="last_input">
            <img src="/img/icon/last_input_icon.svg" alt="">
            <div class="txt">
              <span class="label">date dernière saisie</span>
              <span class="date">{{ lastInputDate }}</span>
            </div>
          </div>
          <Indicator
            primary_title="Température maximal en °C"
            secondary_title="Risque insecte maximal"
            tertiary_title="Performance moyenne énergétique en Wh/Tonne/°C"
            :primary_stat="getMaxTemp"
            :secondary_stat="getMaxRisk"
            :tertiary_stat="getMaxPerf"
          />
        </BoxBase>

        <BoxBase class="mesSites" manager titleBox="Détails par site">
          <div class="list_location bg-w">
            <div class="filters">
              <q-select
                class="inputContainerOption"
                color="green"
                bg-color="white"
                outlined
                multiple
                v-model="locationSelected"
                :options="computedLocation"
              />
              <q-select
                class="inputContainerOption"
                color="green"
                bg-color="white"
                outlined
                :loading="loading"
                :disable="loading"
                multiple
                v-model="storedSpeciesSelected"
                :options="storedSpecies"
              />
            </div>
            <ListeContainer
              :columns="columns"
              :locations="locationFilteredByLocationTo"
              @impersonate="impersonate"
            />
          </div>
        </BoxBase>
      </PageContainer>
    </section>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
import Meta from '@/components/atoms/Meta';
import BoxBase from '@/components/atoms/BoxBase';
import Indicator from '@/components/molecules/Indicator';
import PageContainer from '@/components/molecules/PageContainer';
import ListeContainer from '@/components/molecules/listeContainer';
import Loader from '@/components/atoms/Loader';

const columnsObject = [
  {
    value: 'Site',
    sortName: 'name',
    sortType: 'string',
    sortIcon: true
  },
  {value: 'Tonnage', sortName: 'species_tonnages', sortType: 'float', sortIcon: true},
  {
    value: '°C max',
    sortName: 'max_temp_in_cell',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: '°C moy',
    sortName: 'moy_temp_in_cell',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: '°C min',
    sortName: 'min_temp_in_cell',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: 'Risque insecte',
    sortName: 'maxRiskScore',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: 'Dernière saisie',
    sortName: 'last_input',
    sortType: 'date',
    sortIcon: true
  },
  {
    value: '',
    sortName: '',
    sortType: '',
    sortIcon: false
  }
];

export default {
  name: 'dashboard_supervisor',
  components: {
    PageContainer,
    Indicator,
    ListeContainer,
    BoxBase,
    Meta,
    Loader
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      columns: columnsObject,
      locationSearch: '',
      storedSpeciesSelected: [],
      locationSelected: [],
      filtredSilos: null,
      arrowCounter: -1,
      showList: false,
      locations: [],
      locationFiltered: [],
      speciesFiltered: [],
      speciesList: [],
      searchLocation: '',
      specieValue: '',
      specieNameValue: '',
      location_to_display: {
        id: null
      },
      sorting: {
        name: null,
        type: 'sort'
      },
      species: {
        speciesList: null
      }
      // specieValueSearch: []
    };
  },
  computed: {
    ...mapGetters('indicatorsLocations', ['GET_INDICATORS_FROM_ALL_LOCATIONS']),
    ...mapGetters('indicatorsLocations', ['GET_LOCATIONS_BY_SPECIE_ID']),
    ...mapGetters('species', ['GET_SPECIES']),
    ...mapGetters('species', ['GET_SPECIES_BY_LOCATION_NAME', 'GET_NEW_SPECIES_BY_LOCATION']),
    lastInputDate() {
      let date = null;
      if (this.locationFilteredByLocationTo.length > 0) {
        this.locationFilteredByLocationTo.forEach(location => {
          const locationDate = new Date(location.last_input);
          if (locationDate > date) {
            date = locationDate;
          }
        });
      }
      return date !== null
        ? dayjs(date)
            .locale('fr')
            .format('D MMMM YYYY')
        : '-';
    },
    computedLocation() {
      let locations = this.locations.map(value => {
        value.label = value.name;
        value.value = value.name;
        return value;
      });
      locations.unshift({ label: 'Tous sites', value: 'Tous sites' });
      return locations;
    },
    getMaxTemp() {
      let maxTemp = 0;
      if (this.locationFilteredByLocationTo.length > 0) {
        this.locationFilteredByLocationTo.forEach(location => {
          if (parseFloat(location.max_temp_in_cell) > maxTemp) {
            maxTemp = parseFloat(location.max_temp_in_cell);
          }
        });
      }
      return maxTemp;
    },
    getMaxRisk() {
      let maxRisk = 0;
      if (this.locationFilteredByLocationTo.length > 0) {
        this.locationFilteredByLocationTo.forEach(location => {
          location.silo.forEach(silo => {
            silo.cell.forEach(cell => {
              const cellRisk = parseInt(cell.cjours && cell.cjours != 0 ? Math.ceil(cell.cjours / (400 - 1)) : '1')
              if (cellRisk > parseInt(maxRisk)) maxRisk = cellRisk; 
            });
          });
        });
      }
      return maxRisk;
    },
    getMaxPerf() {
      return null;
    },
    locationFilteredByLocationTo() {
      let locations = this.locationFiltered;
      
      // SI on utilise le filtre par espèce alors on ne gardes que lignes de Site qui on des température
      if (this.storedSpeciesSelected[0] && this.storedSpeciesSelected[0].label !== 'Toutes espèces') {
        locations = this.locationFiltered.filter(location_search => location_search.min_temp_in_cell !== null);
      }
      if (this.locationSelected.length > 0) {
        if (this.locationSelected[0].label !== 'Tous sites') {
          locations = this.locationFiltered.filter(location_search => {
            return (
              this.locationSelected.find(location => {
                return location.name === location_search.name;
              }) !== undefined
            );
          });
        }
      }
      if (this.locationSearch !== '') {
        locations = this.locationFiltered.filter(location_search => {
          return location_search.name
            .toLowerCase()
            .includes(this.locationSearch.toLowerCase());
        });
      }
      locations.forEach(location => {
        let maxRiskScore = 0;
        location.silo.forEach(silo => {
          silo.cell.forEach(cell => {
            if (parseInt(cell.cjours) > parseInt(maxRiskScore)) {
              maxRiskScore = parseInt(cell.cjours);
            }
          });
        });
        location.maxRiskScore =
          maxRiskScore && maxRiskScore != 0
            ? Math.ceil(maxRiskScore / (400 - 1))
            : '1';
      });
      
      return locations;
    },
    storedSpecies() {
      let speciesSpecies = this.sortedSpecieByName(this.species.speciesList);
      speciesSpecies.unshift({
        label: 'Toutes espèces',
        value: 'Toutes espèces'
      });
      return speciesSpecies;
    }
    // filteredlocationBySort() {
    //   let location = this.locationFiltered;
    //   const { type, name } = this.sorting;
    //   if (!name || type === 'sort') {
    //     console.log('ici', location, type, name);
    //     return location;
    //   }
    //   console.log('ici', location, type, name);

    //   // let vm = this;
    //   return location
    //     .slice()
    //     .sort((a, b) => this.columns[name].sortFn(a, b, type === 'sort-desc'));
    // }
  },
  watch: {
    locationSelected(nv) {
      if (
        nv[nv.length - 1] &&
        nv.length > 1 &&
        nv[nv.length - 1].label === 'Tous sites'
      ) {
        this.locationSelected = [
          JSON.parse(JSON.stringify(this.computedLocation[0]))
        ];
      } else {
        if (nv.length < 1) {
          this.locationSelected = [
            JSON.parse(JSON.stringify(this.computedLocation[0]))
          ];
        } else {
          nv.forEach((location, index, array) => {
            if (array.length > 1) {
              if (array.findIndex(l => l.label === 'Tous sites') !== -1) {
                array.splice(
                  array.findIndex(l => l.label === 'Tous sites'),
                  1
                );
              } else if (nv.length === this.computedLocation.length - 1) {
                this.locationSelected = [
                  JSON.parse(JSON.stringify(this.computedLocation[0]))
                ];
              }
            }
          });
        }
      }
    },
    GET_INDICATORS_FROM_ALL_LOCATIONS(nv) {
      this.locationFiltered = JSON.parse(JSON.stringify(nv));
    },
    storedSpeciesSelected(nv) {
      if (!(nv[nv.length - 1] && nv[nv.length - 1].label === 'Toutes espèces')) {
        this.columns = [
          { value: 'Site', sortName: 'name', sortType: 'string', sortIcon: true },
          { value: 'Tonnage', sortName: 'species_tonnages', sortType: 'float', sortIcon: true },
          { value: '°C max', sortName: 'max_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: '°C moy', sortName: 'moy_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: '°C min', sortName: 'min_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: 'Risque insecte', sortName: 'maxRiskScore', sortType: 'float', sortIcon: true },
          { value: 'Dernière saisie', sortName: 'last_input', sortType: 'date', sortIcon: true },
          { value: '', sortName: '', sortType: '', sortIcon: false }
        ]
      } else {
        this.columns = [
          { value: 'Site', sortName: 'name', sortType: 'string', sortIcon: true },
          { value: '°C max', sortName: 'max_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: '°C moy', sortName: 'moy_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: '°C min', sortName: 'min_temp_in_cell', sortType: 'float', sortIcon: true },
          { value: 'Risque insecte', sortName: 'maxRiskScore', sortType: 'float', sortIcon: true },
          { value: 'Dernière saisie', sortName: 'last_input', sortType: 'date', sortIcon: true },
          { value: '', sortName: '', sortType: '', sortIcon: false }
        ]
      }
      this.loading = true;
      if (
        nv[nv.length - 1] &&
        nv.length > 1 &&
        nv[nv.length - 1].label === 'Toutes espèces'
      ) {
        this.storedSpeciesSelected = [
          JSON.parse(JSON.stringify(this.storedSpecies[0]))
        ];
        this.$store
          .dispatch('indicatorsLocations/getIndicatorsFromAllLocations')
          .then(() => (this.loading = false));
      } else {
        if (nv.length < 1) {
          this.storedSpeciesSelected = [
            JSON.parse(JSON.stringify(this.storedSpecies[0]))
          ];

          this.$store
            .dispatch('indicatorsLocations/getIndicatorsFromAllLocations')
            .then(() => (this.loading = false));
        } else {
          nv.forEach((location, index, array) => {
            if (array.length > 1) {
              if (array.findIndex(l => l.label === 'Toutes espèces') !== -1) {
                array.splice(
                  array.findIndex(l => l.label === 'Toutes espèces'),
                  1
                );
              } else if (nv.length === this.storedSpecies.length - 1) {
                this.storedSpeciesSelected = [
                  JSON.parse(JSON.stringify(this.storedSpecies[0]))
                ];
              }
            }
          });

          this.$store
            .dispatch(
              'indicatorsLocations/getIndicatorsFromAllLocations',
              this.storedSpeciesSelected.map(v => v.id).join(',')
            )
            .then(() => (this.loading = false));
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    isToLong(string, char = 15) {
      return string.length > char ? string.substring(0, char) + '...' : string;
    },
    searchLocationInput(nv) {
      this.locationSearch = nv;
    },
    impersonate(id) {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        sessionStorage.setItem(
          'token_rollback',
          sessionStorage.getItem('token')
        );
        sessionStorage.setItem('user_rollback', sessionStorage.getItem('user'));
      } else {
        localStorage.setItem('token_rollback', localStorage.getItem('token'));
        localStorage.setItem('user_rollback', localStorage.getItem('user'));
      }
      Api.impersonate(id).then(data => {
        let response = data.data;
        if (!response.error) {
          if (rememberMe === false) {
            sessionStorage.setItem('token', JSON.stringify(response.token));
            sessionStorage.setItem('user', JSON.stringify(response.user));
            sessionStorage.setItem('impersonate_activated', true);
          } else {
            localStorage.setItem('token', JSON.stringify(response.token));
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('impersonate_activated', true);
          }
          this.$store.commit('user/user', response.user);
          this.$router.push({ name: 'dashboard_operator' });
          this.$q.notify({
            message: `Changement de site réussi`,
            color: 'positive'
          });
        } else {
          this.$q.notify({
            message: `${response.error}`,
            color: 'negative'
          });
        }
      });
    },
    handleClickOutside(event) {
      if (event.target.id == 'selectGrains') {
        this.showList = !this.showList;
      } else {
        this.showList = false;
      }
    },

    // filter species
    async filteredlocationBySpecie(sp) {
      this.specieValue = sp;
      if (this.specieValue !== undefined) {
        await this.$store.dispatch(
          `indicatorsLocations/getLocationSpecies`,
          this.specieValue.id
        );
      } else {
        this.specieValue = -1;
        this.speciesFiltered = this.speciesList;
      }

      if (this.specieValue !== '' && this.specieValue.id !== 0) {
        this.locationFiltered = this.GET_LOCATIONS_BY_SPECIE_ID;
        this.showList = false;
        this.specieNameValue = '';
      }
      // reset list and display all location
      if (this.specieValue.id === 0 || this.specieValue === undefined) {
        this.locationFiltered = this.locations;
        this.showList = false;
        this.specieNameValue = '';
      }
      if (this.specieValue.id == 0 && this.specieNameValue == '') {
        this.specieValue = '';
        this.speciesFiltered = this.speciesList;
      }
    },
    // onArrowDown() {
    //   if (this.arrowCounter < this.speciesFiltered.length) {
    //     this.arrowCounter++;
    //   }
    //   this.showList = true;
    // },
    // onArrowUp() {
    //   if (this.arrowCounter > 0) {
    //     this.arrowCounter--;
    //   }
    // },
    // onEnter() {
    //   this.specieValue = this.speciesFiltered[this.arrowCounter];
    //   this.arrowCounter = -1;
    //   this.showList = false;
    //   this.filteredlocationBySpecie(this.specieValue);
    // },

    // // search species by name
    async filteredBySpeciesName() {
      this.speciesList = this.GET_SPECIES_BY_LOCATION_NAME;
      this.$emit('input', this.specieNameValue);
      this.showList = true;
      this.speciesFiltered = this.speciesList.filter(item => {
        return item.name
          .toLowerCase()
          .includes(this.specieNameValue.toLowerCase());
      });
      if (this.specieNameValue == '') {
        this.specieValue = '';
      }
      // if search is empty
      if (
        (this.specieNameValue == '' && this.specieValue == '') ||
        (this.specieNameValue == '' && this.specieValue.id == 0)
      ) {
        this.locationFiltered = this.locations;
        this.speciesFiltered = this.GET_SPECIES_BY_LOCATION_NAME;
        this.speciesList = this.GET_SPECIES_BY_LOCATION_NAME;
      }
    },
    // Sort table
    filteredlocationBySort(key) {
      const { type, name } = this.sorting;
      if (!name || type === 'sort') {
        this.locationFiltered;
      }

      this.locationFiltered = this.locationFiltered
        .slice()
        .sort((a, b) => this.columns[key].sortFn(a, b, type === 'sort-desc'));
    },
    // search Bar
    filteredlocation() {
      if (
        (this.searchLocation != '' &&
          this.searchLocation &&
          this.searchLocation != '') ||
        (this.searchLocation != '' &&
          this.searchLocation &&
          this.searchLocation != '' &&
          this.specieValue == -1)
      ) {
        this.locationFiltered = this.locations.filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchLocation.toLowerCase());
        });
      }
      // if search is empty
      if (
        (this.searchLocation == '' && this.specieValue == '') ||
        (this.searchLocation == '' && this.specieValue == -1)
      ) {
        this.locationFiltered = this.locations;
      }

      // if specie selected
      if (this.specieValue != '' && this.specieValue.id != 0) {
        this.locationFiltered = this.GET_LOCATIONS_BY_SPECIE_ID.filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchLocation.toLowerCase());
        });
      }
    },
    day(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D/MM/YY');
    },
    setLocationToDisplay(location) {
      this.location_to_display = location;
    },
    sortedLocationByName(locations) {
      return locations.sort(function(a, b) {
        return a.name - b.name;
      });
    },

    sortedSpecieByName(arrays) {
      return _.orderBy(arrays, 'name', 'asc');
    },
    getAlertColorMarker(number) {
      return number <= 0
        ? '#a9a9a9'
        : number > 6
        ? '#a8323e'
        : number > 3 && number < 6
        ? '#d09f3e'
        : '#7fb22d';
    },
    getAlertColor(number) {
      return number > 6
        ? '#a8323e'
        : number > 3 && number < 6
        ? '#d09f3e'
        : '#7fb22d';
    },
    getSortType(key) {
      if (this.sorting.name && this.sorting.name === key) {
        return this.sorting.type || 'sort';
      }
      return 'sort';
    },
    sortBy(key) {
      this.filteredlocationBySort(key);
      const sortType = this.getSortType(key);
      this.sorting = {
        name: key,
        type: `sort-${
          sortType === 'sort' || sortType === 'sort-desc' ? 'asc' : 'desc'
        }`
      };
    },
    isSortDisabled(key) {
      return !this.columns[key].sortFn;
    }
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(
      'indicatorsLocations/getIndicatorsFromAllLocations'
    );

    this.locations = this.GET_INDICATORS_FROM_ALL_LOCATIONS;
    this.locationFiltered = this.locations;
    this.locationSelected = [
      JSON.parse(JSON.stringify(this.computedLocation[0]))
    ];

    this.$store.dispatch('species/loadSpeciesByLocation');

    await this.$store.dispatch('species/loadSpecies');

    this.speciesList = this.GET_SPECIES_BY_LOCATION_NAME;
    this.speciesFiltered = this.speciesList;
    this.storedSpeciesSelected = [
      JSON.parse(JSON.stringify(this.storedSpecies[0]))
    ];


    await this.$store.dispatch('species/loadNewSpeciesByLocation');
    // Assignations aux vairable et ajout de données label et de value pour QUASAR
    this.species.speciesList = this.GET_NEW_SPECIES_BY_LOCATION.map(species => {
      species.label = species.code + ' - '  + this.isToLong(species.name) + ' (' + species.species_type.name + ')';
      species.value = species.id;
      return species
    });

    this.isLoading = false;
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
