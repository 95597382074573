<template>
  <div class="btnDeleted" style="display: flex; justify-content: flex-end; align-self: center;">
    <ExportHistoriesBtn
      v-if="true"
      title="Exporter"
      textBtn="Exporter"
      textBtnLoader="Exportation en cours..."
      class="contentBtn"
      typeBtn="button"
      @click="showModal()"
      active-loader
      ref="btn1"
      :disabled="disabled"
    />
    <ExportModal :isOpen="exportModalOpen" @update:isOpen="nv => exportModalOpen = nv"></ExportModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ExportModal from '@/components/atoms/ExportModal';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import ExportHistoriesBtn from '@/components/atoms/BtnLoader';

export default {
  name: 'ExportButton',
  components: {
    ExportModal,
    ExportHistoriesBtn
  },
  props: {
    //
  },
  computed: {
    ...mapGetters('inputLocation', ['GET_INPUT_CELL_LOCATION'])
  },
  watch: {
    //
  },
  data() {
    return {
      exportModalOpen: false,
      disabled: true,
      modalStore: ModalStore
    };
  },
  methods: {
    showModal() {
      this.exportModalOpen = true;
    }
  },
  created() {
    setTimeout(() => {
      this.disabled = false;
    }, 5000);
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
