<template>
  <div
    class="userMenuContainer"
    :class="{ mobileApp: mobileApp, user: !user }"
  >
    <template v-if="user">
      <FullName
        :lastName="user.lastname"
        :firstName="user.firstname"
      />
      <span></span>
      <SecondaryMenu
        @click="links"
        :isMobile="mobileApp"
        :align="mobileApp ? 'center' : 'right'"
        :labels="menuUser"
      />

      <Avatar
        class="avatar"
        :lastName="user.lastname"
        :firstName="user.firstname"
      />
    </template>
    <template v-else>
      <SecondaryMenu
        @click="links"
        :isMobile="mobileApp"
        :align="mobileApp ? 'center' : 'right'"
        :labels="menuNotUser"
      />
    </template>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar';
import SecondaryMenu from '@/components/atoms/SecondaryMenu';
import FullName from '@/components/atoms/FullName';
import { mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

const listMenuUser = [
  {
    title: 'Se déconnecter',
    icon: 'logout',
    classMenu: 'logout',
    link: 'Logout',
  }
];
const listMenuNotUser = [
  {
    title: 'connexion',
    icon: 'login',
    classMenu: 'login',
    link: 'Login',
  },
  // {
  //   title: "s'inscrire",
  //   icon: 'person_add',
  //   classMenu: 'register',
  //   link: 'Register',
  // },
];
export default {
  name: 'UserMenu',
  components: {
    Avatar,
    FullName,
    SecondaryMenu,
  },
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    mobileApp: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      menuUser: listMenuUser,
      menuNotUser: listMenuNotUser
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods : {
    logout() {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('user_rollback');
        sessionStorage.removeItem('token_rollback');
        sessionStorage.removeItem('impersonate_activated');
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_rollback');
        localStorage.removeItem('token_rollback');
        localStorage.removeItem('impersonate_activated');
      }
      this.$store.commit('user/user', null);
      this.$router.push({ name: 'login' });
    },
    links(e) {
      const router = useRouter();
      if (e === 'Login') {
        router.push({ name: 'Login' });
      } else if (e === 'Register') {
        router.push({ name: 'Register' });
      } else if (e === 'Logout') {
        this.logout();
      } else if (e === 'Params') {
        router.push({ name: 'params' });
      }
    },
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
