<template>
  <Loader v-if="isLoading" />

  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    :okCancel="true"
    okLabel="Valider"
    size="Light"
    title="Supprimer un opérateur"
    name="deleteOperator"
    :displaySendDataButton="false"
  >
    <br />
    <div class="content">
      <p>
        Voulez vous vraiment supprimer l'opérateur {{ op.firstname }} {{ op.lastname }} ?
      </p>
        <br />
        <div class="Btn">
           <q-btn @click="deleteOperator(op.id)" unelevated rounded style="background: #A13639; color: #FFFFFF;margin-right:25px;" label="Oui" v-close-popup />
          <q-btn unelevated rounded style="background: #36A139; color: #FFFFFF;" label="Non" v-close-popup />
        
      </div>

      <div class="success" v-if="isSuccess">
        Un email a été envoyé à l'opérateur.
      </div>
      <div class="error" v-if="isError">
        {{ msg.msgError }}
      </div>
    </div>
  </AddModal>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import BtnSubmit from '@/components/atoms/Button';
// import BtnresetPassword from '@/components/atoms/BtnLoader';

export default {
  name: 'Operators',
  components: {
    // BtnresetPassword,
    AddModal,
    Loader,
    // BtnSubmit
  },
  props: {
    isOpen: { type: Boolean, default: false },
    op: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      addOp: false,
      isSuccessPassword: false,
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      locationValue: '',
      modalStore: ModalStore
    };
  },
  computed: {
    ...mapGetters('listLocation', ['GET_ACTION_LOCATION'])
  },
  methods: {
    resetPassword() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isSuccessPassword = true;
        this.isLoading = false;
        this.$refs.btn1.activeLoader = false;
        setTimeout(() => {
          this.isSuccessPassword = false;
        }, 2000);
      }, 5000);
    },
    deleteOperator(id) {
      Api.deleteOperator(id)
         .then(({ data }) => {
           if (data) {
             this.$store.dispatch('listOperator/LoadOperators');
           }
         })
         .catch(error => {
           console.log(error);
         });
    }
    // desactivatedOperator(id) {
    //   Api.disableOperators(id)
    //     .then(({ data }) => {
    //       if (data) {
    //         this.$store.dispatch('listOperator/LoadOperators');
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
