<template>
  <div class="items-center q-py-md col-12 col-xs-12 contentPeriod InputOption">
    <div class="title">{{ titreInput }}</div>
    <div v-if="useInput">
      <q-select
        class="inputContainerOption"
        outlined
        v-model="modelSelected"
        :options="options"
        color="green"
        bg-color="white"
        :rules="[val => checkValue(val) || ruleMessage]"
        :option-disable="disableOption"
        :disable="disable"
        :use-input="true"
        @filter="filterFn"
      />
    </div>
    <div v-else>
      <q-select
        class="inputContainerOption"
        outlined
        v-model="modelSelected"
        :options="optionList"
        color="green"
        bg-color="white"
        :rules="rule"
        :option-disable="disableOption"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'InputOption',
  emits: ['model-selected'],
  props: {
    useInput: {
      type: Boolean
    },
    disable: {
      type: Boolean
    },
    ruleCondition: {
      type: String
    },
    optionList: {
      type: Object
    },
    currentOption: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    titreInput: {
      type: String,
      default: ''
    },
    rule: {
      type: Array
    },
    valueSelected: {
      type: [String, Object],
      default: ''
    },
    disableOption: {
      type: String
    }
  },
  setup() {
    return {
      modelSelected: ref(null),
      ruleMessage: ref('')
    }
  },
  data() {
    return {
      options: ref(this.optionList)
    }
  },
  watch: {
    modelSelected(newValue) {
      this.$emit('model-selected', newValue);
    },
    currentOption(n) {
      this.modelSelected = n;
    },
    valueSelected(nv) {
      this.modelSelected = nv;
    }
  },
  methods: {
    checkValue(val) {
      let pass = true;
      this.ruleCondition.split(' ').forEach(value => {
        if (value === 'required') {
          this.ruleMessage = pass ? 'Champ requis' : this.ruleMessage;
          pass = pass ? !!val : false;
        } else if (value === 'max') {
          this.ruleMessage = pass ? 'Valeur trop élevée' : this.ruleMessage;
          pass = pass ? val <= this.max : false;
        } else if (value === 'notZero') {
          this.ruleMessage = pass
            ? 'La valeur ne peut pas être zéro'
            : this.ruleMessage;
          pass = pass ? !(val == 0) : false;
        } else {
          pass = pass ? true : false;
        }
      });
      return pass;
    },
    filterFn (val, update) {
      update(() => {
        console.log(val);
        const needle = val.toLowerCase()
        this.options = this.optionList.filter(v => {console.log(v);return v.label.toLowerCase().indexOf(needle) > -1})
      })
    }
  },
  created() {
    this.modelSelected = this.valueSelected;
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
