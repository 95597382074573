<template>
  <div class="avatar">
    <div class="initialContent">
      <span v-if="!avatar" class="initialUser">{{ name }}</span>
      <q-avatar v-if="avatar" size="32px" class="imgAvatar">
        <img :src="avatarImg" />
      </q-avatar>
    </div>
  </div>
</template>

<script>
// import { toRefs } from 'vue';
export default {
  name: 'Avatar',
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    avatarImg: {
      type: String,
      default: 'false',
    },
  },

  //   data() {
  //     return {
  //       firstName: 'lidian',
  //       lastName: 'manoha',
  //     };
  //   },

  computed: {
    name() {
      return `${this.firstName[0]}${this.lastName[0]}  `;
    },
  },
  //   setup(props) {
  //     const name = toRef(props, 'name');
  //     return {
  //       name,
  //     };
  //   },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
