import Api from '@/classes/api/Api';

export default {
  async loadCampaign({ commit }) {
    await Api.getCampaign()
      .then(({ data }) => {
        if (data) {
          commit('GET_CAMPAIGN', data);
          // console.log('GET_CAMPAIGN', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
