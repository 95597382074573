import Api from '@/classes/api/Api';

export default {
  async loadDateLastActionCell({ commit }, id) {
    await Api.getCellDate(id)
      .then(({ data }) => {
        if (data) {
          commit('GET_LAST_DATE_ACTION_CELL', data);
          // console.log('GET_LAST_DATE_ACTION_CELL', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
