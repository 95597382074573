<template>
  <div>
    <AddModal
      activeFormValidation
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      size="960"
      closeBtnCenter
      v-if="cellObject"
      name="filling"
      title="Remplissage"
      cell_desk
      :silo="siloInfo"
      :cell="cellObject.name"
      :species="
      defaultSpecies.species ? defaultSpecies.species.name :
        cellObject.stored_species && cellObject.stored_species.name
          ? cellObject.stored_species.name
          : ''
      "
      cell_info
      :label_date="'Date de dernière saisie'"
      :date="cellObject.lastSaisie ? cellObject.lastSaisie : '-'"
      :current_volume="parseInt(cellObject.current_volume)"
      :max_volume="parseInt(cellObject.volume)"
      label_volume_2="Capacité de stockage"
      :actionGrains="actionGrains"
      commentModal
      infoGrainModal
      @submit="submitForm"
    >
      <Loader v-if="isLoading" />
      <div v-if="cellObject.stored_species && !cellObject.sub_species">
        <div class="date_container">
          <InputDate
            class="date"
            titleDate="Date de l’opération *"
            :isRange="false"
            :last_input="cellLastInput"
            :dayValue="dayValue"
            @valuePickerUniq="valuedayUniq"
          />
        </div>
        <div class="species_container">
          <InputOption
            v-if="storedSpecies"
            :optionList="storedSpecies"
            disableOption="disable"
            @modelSelected="filteredlocationBySpecie"
            class="species_item"
            titreInput="Espèce stockée *"
            :modelSelectedDefault="true"
            :valueSelected="firstStoredSpeciesNotDisable"
          />
          <InputForm
            @modelSelected="subSpeciesSelected"
            class="species_item"
            titreInput="Sous espèce"
          />
          <InputOption
            v-if="campaign"
            :optionList="campaign"
            @modelSelected="selectCampaign"
            class="species_item"
            titreInput="Campagne*"
            :modelSelectedDefault="true"
            valueSelected="2022"
          />
        </div>
        <div class="species_container">
          <InputForm
            @modelSelected="selectedfillingTmiMini"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi_min > 0 ? cellObject.tmi_min : ''"
            class="species_item"
            temp
            titreInput="Température minimum *"
            ruleCondition="required"
          />
          <InputForm
            @modelSelected="selectedfillingTmi"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi > 0 ? cellObject.tmi : ''"
            class="species_item"
            temp
            titreInput="Température moyenne *"
            ruleCondition="required"
          />
          <InputForm
            @modelSelected="selectedfillingTmiMax"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi_max > 0 ? cellObject.tmi_max : ''"
            class="species_item"
            temp
            titreInput="Température maximum *"
            ruleCondition="required"
          />
        </div>
      </div>
      <div v-else>
        <div class="date_campagne_container">
          <InputDate
            class="date"
            titleDate="Date de l’opération *"
            :isRange="false"
            :last_input="cellLastInput"
            :dayValue="dayValue"
            @valuePickerUniq="valuedayUniq"
          />
          <InputOption
            v-if="campaign"
            :optionList="campaign"
            @modelSelected="selectCampaign"
            class="species_item"
            titreInput="Campagne*"
            :modelSelectedDefault="true"
            valueSelected="2022"
          />
        </div>
        <div class="species_container">
          <InputOption
            :optionList="filteredSpecies.speciesTypeList"
            disableOption="disable"
            @modelSelected="speciesType => selected.speciesType = speciesType"
            class="species_item"
            titreInput="Type d'espèce *"
            :modelSelectedDefault="true"
            :valueSelected="defaultSpecies.speciesType || filteredSpecies.speciesTypeList[0]"
            :disable="defaultSpecies.speciesType ? true : false"
            useInput
            ruleCondition="required"
          />
          <InputOption
            :optionList="filteredSpecies.speciesList"
            disableOption="disable"
            @modelSelected="species => selected.species = species"
            class="species_item"
            titreInput="Espèce stockée *"
            :modelSelectedDefault="true"
            :valueSelected="defaultSpecies.species || filteredSpecies.speciesList[0]"
            :disable="defaultSpecies.species ? true : false"
            useInput
            ruleCondition="required"
          />
          <InputOption
            :optionList="filteredSpecies.subSpeciesList"
            disableOption="disable"
            @modelSelected="subSpecies => selected.subSpecies = subSpecies"
            class="species_item"
            titreInput="Sous espèce *"
            :modelSelectedDefault="true"
            :valueSelected="defaultSpecies.subSpecies || filteredSpecies.subSpeciesList && filteredSpecies.subSpeciesList[0]"
            :disable="defaultSpecies.subSpecies ? true : false"
            useInput
            ruleCondition="required"
          />
            <!-- :valueSelected="filteredSpecies.subSpeciesList[0]" -->
        </div>
        <div class="species_container">
          <InputForm
            @modelSelected="selectedfillingTmiMini"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi_min > 0 ? cellObject.tmi_min : ''"
            class="species_item"
            temp
            titreInput="Température minimum *"
            ruleCondition="required"
          />
          <InputForm
            @modelSelected="selectedfillingTmi"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi > 0 ? cellObject.tmi : ''"
            class="species_item"
            temp
            titreInput="Température moyenne *"
            ruleCondition="required"
          />
          <InputForm
            @modelSelected="selectedfillingTmiMax"
            type="number"
            subTitle="Après l’opération de remplissage."
            :valueSelected="cellObject.tmi_max > 0 ? cellObject.tmi_max : ''"
            class="species_item"
            temp
            titreInput="Température maximum *"
            ruleCondition="required"
          />
        </div>
      </div>
      <div class="tonnage_container">
        <InputForm
          @modelSelected="selectedTonnage"
          type="number"
          subTitle="Tonnage ajouté à la cellule."
          class="date"
          tonnage
          titreInput="Tonnage *"
          :max="cellObject.volume - cellObject.current_volume + (cellObject.volume * 30 / 100)"
          ruleCondition="required max notZero"
        />

        <div class="cellInfo">
          Tonnage après opération: <span>{{ fillingVs ? (parseInt(cellObject.current_volume) + parseInt(fillingVs)).toFixed(2) : parseInt(cellObject.current_volume)  }}T</span>.
        </div>
      </div>
    </AddModal>
  </div>
</template>

<script>
import _ from 'lodash';
import 'dayjs/locale/fr';
import { mapGetters } from 'vuex';
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import InputForm from '@/components/atoms/Inputs/InputForm';
import InputDate from '@/components/atoms/Inputs/InputDate';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import InputOption from '@/components/atoms/Inputs/InputOption';

export default {
  name: 'AddFilling',
  props: {
    isOpen: { Boolean, default: false },
    cellId: {
      type: [String, Number]
    },
    siloInfo: {
      type: String
    },
    cellName: {
      type: String
    },
    cellCuVol: {
      type: [String, Number]
    },
    cellObject: {
      type: [Object, String]
    }
  },
  components: {
    InputDate,
    InputOption,
    InputForm,
    AddModal,
    Loader
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  data() {
    return {
      dayValue: new Date().toISOString().substr(0, 10),
      arrowCounter: -1,
      showList: false,
      activeGrain: false,
      errorSendDate: false,
      isLoading: false,
      vide: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      currentTab: 'traceability',
      tabs: {
        traceability: 'Traçabilité',
        grain: 'Grain',
        validation: 'Validation'
      },
      infoGrain: [
        { name: 'Nettoyage cellule' },
        { name: 'Désinsectisation grain ( produit dose)' },
        { name: 'Nettoyage gaine/galerie' },
        { name: 'Lot brut' },
        { name: 'Lot nettoyé' },
        { name: 'Lot nettoyé 2 fois' },
        { name: 'Lot calibré' }
      ],
      selectedCampaign: '',
      selectSpecies: '',

      fillingDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      specieNameValue:
        this.cellObject && this.cellObject.stored_species != null
          ? this.cellObject.stored_species.name
          : '',
      speciesFiltered: [],
      speciesList: [],
      species: {
        speciesTypeList: [],
        speciesList: [],
      },
      filteredSpecies: {
        speciesTypeList: [],
        speciesList: [],
        subSpeciesList: [],
      },
      defaultSpecies: {
        speciesType: null,
        species: null,
        subSpecies: null
      },
      selected: {
        speciesType: null,
        species: null,
        subSpecies: null
      },
      fillingSpeciesId:
        this.cellObject && this.cellObject.stored_species != null
          ? this.cellObject.stored_species
          : '',
      fillingSubSpecies:
        this.cellObject && this.cellObject.subspecies
          ? this.cellObject.subspecies
          : '',
      fillingTmiMini: 0,
      fillingTmi: 0,
      fillingTmiMax: 0,
      fillingVcp: this.cellObject && this.cellObject.volume,
      fillingVs: 0
    };
  },
  beforeMount() {
    this.cellCuVol;
  },
  updated() {
    this.speciesFiltered = this.GET_SPECIES_BY_LOCATION_NAME;
  },
  mounted() {
    this.$store.dispatch('campaign/loadCampaign');
    this.$store.dispatch('grain/loadActionGrain');
  },
  async created() {
    await this.$store.dispatch('species/loadSpeciesByLocation');
    await this.$store.dispatch('species/loadNewSpeciesByLocation');
    this.speciesList = this.GET_SPECIES_BY_LOCATION_NAME;
    this.speciesFiltered = this.speciesList;
    this.fillingSpeciesId = this.speciesFiltered.filter(value => {
      return value.name == 'Blé tendre';
    })[0];

    // Assignations aux vairable et ajout de données label et de value pour QUASAR
    this.species.speciesList = this.GET_NEW_SPECIES_BY_LOCATION.map(species => {
      species.label = species.code + ' - '  + this.isToLong(species.name);
      species.value = species.name;
      species.species_type.label = species.species_type.name;
      species.species_type.value = species.species_type.name;
      species.sub_species.map(subSpecies => {
        subSpecies.label = subSpecies.code + ' - ' + this.isToLong(subSpecies.name);
        subSpecies.value = subSpecies.name;
        return subSpecies
      });
      return species
    });

    // On enlève les type d'espèces en doublon
    const speciesTypeList = [];
    this.species.speciesTypeList = this.species.speciesList.map(species => species.species_type);
    this.filteredSpecies.speciesTypeList = this.species.speciesTypeList.filter(
        element => 
          !speciesTypeList.includes(element.id) ? 
            speciesTypeList.push(element.id) && true :
            false
      );
    console.log(this.species.speciesList);
  },
  computed: {
    ...mapGetters('species', ['GET_SPECIES_BY_LOCATION_NAME', 'GET_NEW_SPECIES_BY_LOCATION']),
    ...mapGetters('campaign', ['GET_CAMPAIGN']),
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    cellLastInput() {
      return this.cellObject.lastSaisieTimestamp
        ? new Date(this.cellObject.lastSaisieTimestamp*1000)
        : null;
    },
    actionGrains() {
      if (this.GET_ACTION_GRAIN) {
        return this.GET_ACTION_GRAIN.map(value => {
          return value.name;
        });
      }
      return [];
    },
    campaign() {
      return this.GET_CAMPAIGN.map(value => {
        return value.year;
      });
    },
    storedSpecies() {
      return this.sortedSpecieByName(this.speciesFiltered).map(value => {
        value.value = value.name;
        value.label = value.name;
        value.disable = this.cellObject.stored_species_id
          ? !(this.cellObject.stored_species_id === value.id)
          : false;
        return value;
      });
    },
    firstStoredSpeciesNotDisable() {
      const result = this.storedSpecies.filter(value => {
        return this.cellObject.stored_species_id
          ? !value.disable
          : value.name === 'Blé tendre';
      });
      if (result) {
        if (!this.cellObject.stored_species_id) {
          if (!result[0]) {
            return this.storedSpecies.filter(value => {
              return this.cellObject.stored_species_id ? !value.disable : true;
            })[0];
          }
        }
      }
      return result[0] ? result[0] : '';
    }
  },
  watch: {
    'selected.speciesType'(speciesType) {
      this.filteredSpecies.speciesList = this.species.speciesList.filter(species => species?.species_type_id === speciesType?.id);
    },
    'selected.species'(species) {
      this.filteredSpecies.subSpeciesList = species?.sub_species;
    },

    'filteredSpecies.speciesList'() {
      console.log(this.filteredSpecies.speciesList);
      if (this.cellObject['species_id'], this.cellObject['sub_species_id']) {
        if (this.filteredSpecies.speciesList) {
          const speciesSearch = this.filteredSpecies.speciesList?.filter(v => v.id === this.cellObject['species_id'])[0];
          this.defaultSpecies.species = speciesSearch || null
          this.defaultSpecies.speciesType = speciesSearch?.species_type || null
          this.filteredSpecies.subSpeciesList = this.defaultSpecies.species?.sub_species || null;
          this.defaultSpecies.subSpecies = this.filteredSpecies.subSpeciesList?.filter(v => v.id === this.cellObject['sub_species_id'])[0] || null
        }
      }
    }
  },
  methods: {
    isToLong(string, char = 15) {
      return string.length > char ? string.substring(0, char) + '...' : string;
    },
    selectedfillingTmiMini(nv) {
      this.fillingTmiMini = nv;
    },
    selectedfillingTmi(nv) {
      this.fillingTmi = nv;
    },
    selectedfillingTmiMax(nv) {
      this.fillingTmiMax = nv;
    },
    selectedTonnage(nv) {
      this.fillingVs = nv;
    },
    subSpeciesSelected(s) {
      this.fillingSubSpecies = s;
    },
    valuedayUniq(e) {
      console.log(e)
      this.dayValue = e;
    },
    // filter species
    filteredlocationBySpecie(sp) {
      this.fillingSpeciesId = sp;
    },
    sortedSpecieByName(arrays) {
      return _.orderBy(arrays, 'name', 'asc');
    },
    submitForm(actionGrains, comment) {
      this.isLoading = true;
      

      this.isLoading = true;
      // this.$refs.btn1.activeLoader = true;
      this.btnEnvoi = false;

      let parsedActionGrains = actionGrains.map(actionGrainString => {
        return {
          action_grain_id: this.GET_ACTION_GRAIN.filter(actionGrain => {
            return actionGrain.name == actionGrainString;
          })[0].id
        };
      });

      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
          
      let data = {
        date: this.dayValue,
        stored_species_id: this.fillingSpeciesId?.id || null,
        subspecies: this.fillingSubSpecies || null,
        species_id: this.selected.species?.id || null,
        sub_species_id: this.selected.subSpecies?.id || null,
        tmi_min: this.fillingTmiMini,
        tmi: this.fillingTmi,
        tmi_max: this.fillingTmiMax,
        vcp: this.fillingVcp,
        volume: this.fillingVs,
        campaign_id: this.selectedCampaign,
        action_grain: parsedActionGrains,
        action_grain_description: comment,
        user_id: user.id,
        cell_id: this.cellId
      };
      Api.addFillings(data)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('silos/loadSilo');

            this.isLoading = false;
            this.isErrorMsg = false;

            this.fillingDate = '';
            this.fillingSpeciesId = {};
            this.fillingSubSpecies = '';
            this.fillingTmiMini = 0;
            this.fillingTmi = 0;
            this.fillingTmiMax = 0;
            this.fillingVcp = '';
            this.fillingVs = 0;
            this.fillingCampaignId = '';
            this.fillingGrain = [];
            this.fillingInfoGrain = '';
            this.$emit('update:isOpen', false);
            this.$q.notify({
              message: `${data.message}`,
              color: 'positive'
            });
          }
          this.$store.dispatch('inputLocation/loadInputLocation');
          this.$store.dispatch('indicator/loadIndicatorLocation');
          this.$store.dispatch('silos/loadSilo');
          this.$store.dispatch('listFillings/loadFillings');
          this.$emit('done');
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.errorSendDate = true;
          this.$q.notify({
            message: `Impossible de creer le remplissage`,
            color: 'negative'
          });
        });
    },
    selectCampaign(c) {
      this.GET_CAMPAIGN.forEach(value => {
        if (value.year == c) {
          this.selectedCampaign = value.id;
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
