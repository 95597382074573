<template>
  <div class="page">
    <div class="container containerNotFound">
      <Meta pageTitle="AUTH-PROJECT | Page non trouvé" />
      <div>
        <div>
          <h1 class="errorTemplate">Oops!</h1>
          <h2 class="errorTemplate">404 Page non trouvée</h2>
          <div class="errorDetails mb-4">
            Désolé, une erreur s'est produite, la page demandée n'a pas été
            trouvée!
          </div>
          <div class="errorActions">
            <Btn
              @click="() => $router.push({ name: 'Home' })"
              title="Page d'accueil "
              label="Page d'accueil "
              textBtn="Page d'accueil "
              styleBtn="contained"
              colorBtn="warning"
              class="contentBtn"
              typeBtn="button"
              icon
              iconCircle
              nameIcon="home"
            />
            <Btn
              @click="() => $router.push({ name: 'login' })"
              title="Connexion"
              label="Connexion"
              textBtn="Connexion"
              styleBtn="contained"
              colorBtn="warning"
              class="contentBtn"
              typeBtn="button"
              icon
              iconCircle
              nameIcon="sign-in-alt"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/atoms/Button';
import Meta from '@/components/atoms/Meta';
export default {
  name: 'NotFound',
  components: {
    Btn,
    Meta
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
