<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      :okCancel="true"
      @submit="validateAdd()"
      okLabel="Valider"
      size="Light"
      title="Ajouter un opérateur"
      name="addOperator"
      ref="popup"
      :displaySendDataButton="!isLoading"
    >
    <br />
      <div class="content">
        <InputForm
          icon
          nameIcon="user"
          prefixIcon="far"
          type="text"
          placeholder="Prenom"
          v-model="firstName"
          :value="firstName"
          :errorMsg="msg.firstName"
          inputModal="inputIcon"
        />
        <InputForm
          icon
          nameIcon="user"
          prefixIcon="far"
          type="text"
          placeholder="Nom"
          v-model="lastName"
          :value="lastName"
          :errorMsg="msg.lastName"
          inputModal="inputIcon"
        />
        <InputForm
          icon
          nameIcon="envelope"
          prefixIcon="far"
          type="email"
          placeholder="email"
          v-model="email"
          :value="email"
          :errorMsg="msg.email"
          :helperMsg="msg.emailInvalid"
          inputModal="inputIcon"
        />
        <br />
        <div>
          <select v-model="locationValue">
            <option value="" selected>Sélectionnez le site</option>
            <option
              v-for="location in GET_ACTION_LOCATION"
              :key="location.id"
              :value="location.location_id"
              >{{ location.location.name }}</option
            >
          </select>
          <span v-if="errorMsg" class="error-message"
            ><icon :icon="['far', 'times-circle']" class="icon" />{{
              msg.locationValue
            }}</span
          >
        </div>
        <br />
        <!--<div class="Btn">
          <BtnSubmit
            title="Envoyer"
            label="Envoyer"
            textBtn="Envoyer"
            textBtnLoader="Envoi en cours"
            active-loader
            styleBtn="contained"
            colorBtn="primary"
            class="contentBtn"
            typeBtn="submit"
            style="margin-left:25px;"
            icon
            iconCircle
            nameIcon="paper-plane"
          />
        </div>-->

        <div class="success" v-if="isSuccess">
          Un email a été envoyé à l'opérateur.
        </div>
        <div class="error" v-if="isError">
          {{ msg.msgError }}
        </div>
      </div>
        <Loader v-if="isLoading" />
    </AddModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import Loader from '@/components/atoms/Loader';
import InputForm from '@/components/atoms/Form/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import BtnresetPassword from '@/components/atoms/BtnLoader';

export default {
  name: 'Operators',
  components: {
    // BtnresetPassword,
    InputForm,
    AddModal,
    Loader
  },
  props: {
    isOpen: { type: Boolean, default: false },
    op: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      addOp: false,
      isSuccessPassword: false,
      msg: [],
      isSuccess: false,
      isError: false,
      errorMsg: false,
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      locationValue: '',
      modalStore: ModalStore
    };
  },
  computed: {
    ...mapGetters('listLocation', ['GET_ACTION_LOCATION'])
  },
  methods: {
    resetPassword() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isSuccessPassword = true;
        this.isLoading = false;
        this.$refs.btn1.activeLoader = false;
        setTimeout(() => {
          this.isSuccessPassword = false;
        }, 2000);
      }, 5000);
    },
    // desactivatedOperator(id) {
    //   Api.disableOperators(id)
    //     .then(({ data }) => {
    //       if (data) {
    //         this.$store.dispatch('listOperator/LoadOperators');
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    validateAdd() {
      if (this.firstName === '') {
        this.msg['firstName'] = 'Merci de renseigner votre prenom.';
      }
      if (this.lastName === '') {
        this.msg['lastName'] = 'Merci de renseigner votre nom.';
      }

      if (this.email === '') {
        this.msg['email'] = "Merci d'indiquer un email.";
      }

      if (this.locationValue === '') {
        this.msg['locationValue'] = 'Merci de sélectionnez un site.';
        this.errorMsg = true;
      } else {
        this.isLoading = true;
        let data = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          location_id: this.locationValue
        };
        Api.signIn(data)
          .then(({ data }) => {
            if (!data) {
              this.msg = [];
              this.errorMsg = false;
              this.isError = false;
              this.isSuccess = true;
              this.isLoading = false;
              setTimeout(() => {
                // this.$store.commit('listOperator/UPDATE_LIST_OPERATOR',
                // {
                //   firstname: this.firstName,
                //   lastname: this.lastName,
                //   email: this.email,
                //   location_id: this.locationValue
                // });
                this.addOp = false;
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.locationValue = '';
                this.isSuccess = false;
                this.isLoading = false;
                this.$refs.popup.hide();
              }, 3000);
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.isError = error;
            this.msg['msgError'] = `${error.response.data.message}`;
          });
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
