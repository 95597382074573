<template>
  <div class="cfg ">
    <h2>Éléments constitutifs du site</h2>

    <ul>
      <div v-for="silo in GET_SILOS" :key="silo.id">
        <li class="silo">
          {{ silo.name }}
        </li>
        <li class="ventl" v-for="fan in silo.fan" :key="fan.id">
          {{ fan.name }} - {{ fan.power }} kW - {{ fan.type }}
        </li>
        <li class="cell" v-for="cell in silo.cell" :key="cell.id">
          {{ cell.name }} - {{ cell.current_volume }} t
        </li>

        <div v-if="!ifExistCellsWithNoFan(silo.cell)">
          <h5>Cellules non rattachées à un ventilateur</h5>
          <li class="cell" v-for="cell in silo.cell" :key="cell.id">
            <div v-if="cell.fan_id === null">
              {{ cell.name }} - {{ cell.current_volume }} t
            </div>
          </li>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ElementsSite',
  computed: {
    ...mapGetters('silos', ['GET_SILOS'])
  },
  methods: {
    ifExistCellsWithNoFan(cells) {
      let res = true;
      cells.forEach(cell => {
        if (!cell['fan_id']) {
          res = false;
        }
      });
      return res;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
