import GetSpeciesGetters from './getters.js';
import GetSpeciesMutations from './mutations.js';
import GetSpeciesAction from './actions.js';

export default {
  namespaced: true,

  state: {
    storedSpecies: [],
    storedSpeciesByLocationName: [],
    storedSpeciesByLocation: [],
    newSpeciesByLocation: []
  },
  getters: GetSpeciesGetters,
  mutations: GetSpeciesMutations,
  actions: GetSpeciesAction
};
