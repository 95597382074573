<template style="overflow:visible;">
  <vue-highcharts
    type="chart"
    ref="chart"
    :options="chartOptions"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
  />
</template>
<script>
import VueHighcharts from 'vue3-highcharts';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

export default {
  name: 'SimpleChart',
  props: {
    cellInput: {
      type: Object
    }
  },
  components: {
    VueHighcharts
  },
  data() {
    return {
      YMeanAxis: [],
      XMeanAxis: [],
      YMaxAxis: [],
      YMinAxis: [],
      XMaxAxis: [],
      YThermostatAxis: [],
      chartOptions: {
        chart: {
          type: 'line',
          backgroundColor: '#fffbe8',
          height: 300
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          x: 0,
          y: 0,
          itemStyle: {
            fontWeight: 'normal'
          }
        },
        title: {
          enabled: false,
          text: ''
        },
        xAxis: {
          reversed: true,
          categories: [],
          labels: {
            rotation: -45
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true,
            color: '#d77d37'
          },
          series: {
            marker: {
              enabled: true,
              symbol: 'circle'
            }
          }
        },
        series: [
          {
            name: '°C Max',
            data: [],
            color: '#c32838'
          },
          {
            name: '°C Moy',
            data: [],
            color: '#FFD700'
          },
          {
            name: '°C Min',
            data: [],
            color: '#71bf49'
          },
          {
            name: 'Thermostat',
            data: [],
            color: '#2480ff'
          }
        ]
      }
    };
  },
  watch: {
    cellInput: {
      deep: true,
      handler: function() {
        this.createSeries();
      }
    }
  },
  methods: {
    createSeries() {
      this.cellInput.forEach(input => {
        if (input.type.substr(0, 11) == 'ventilation') {
          this.YMeanAxis.push(input.T2);
          this.YMaxAxis.push(input.Tmax);
          this.YMinAxis.push(input.Tmin);
          this.YThermostatAxis.push(input.Thermostat);
          this.XMeanAxis.push(this.datejs(input.date) + '');
        } else if (input.type == 'remplissage') {
          this.YMeanAxis.push(input.T1);
          this.XMeanAxis.push(this.datejs(input.date));
          this.YMaxAxis.push(input.Tmax);
          this.YMinAxis.push(input.Tmin);
        }
      });

      this.chartOptions.xAxis.categories = this.XMeanAxis;
      this.chartOptions.series[0].data = this.YMaxAxis;
      this.chartOptions.series[1].data = this.YMeanAxis;
      this.chartOptions.series[2].data = this.YMinAxis;
      this.chartOptions.series[3].data = this.YThermostatAxis;
      this.$refs.chart.chart.reflow();
      this.$forceUpdate();
      this.$refs.chart.chart.reflow();
      // this.chartOptions.series.push({
      //   name: 'Température max en °C',
      //   data: this.YMeanAxis
      // })
    },
    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('DD/MM/YYYY');
    }
  },
  mounted() {
    this.createSeries();
  },
  setup() {
    const onRender = () => {};
    return {
      onRender
    };
  }
};
</script>
