import GetListOperatorGetters from './getters.js';
import GetListOperatorMutations from './mutations.js';
import GetListOperatorAction from './actions.js';

export default {
  namespaced: true,

  state: {
    operator: []
  },
  getters: GetListOperatorGetters,
  mutations: GetListOperatorMutations,
  actions: GetListOperatorAction
};
