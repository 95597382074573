import { createApp } from 'vue';
import App from './App.vue';
import router from './router/routes';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import 'leaflet/dist/leaflet.css';
import Highcharts from 'highcharts';
import Toaster from '@meforma/vue-toaster';

library.add(fas, fab, far);
window.Highcharts = Highcharts;
import axios from './classes/api/Axios';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import VueHtml2Canvas from 'vue-html2canvas';

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.config.devtools = true;
axios.interceptors.response.use(
  response => {
    // Return a successful response back to the calling service
    return response;
  },
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status === 401) {
      return new Promise((resolve, reject) => {
        // alert('Votre session, à expirer veuillez vous reconnecter.');
        router.push({
          path: '/login'
          // query: { redirect: router.currentRoute.fullPath }
        });
        console.log('interceptor error', error);

        reject(error);
      });
    } else {
      return Promise.reject(error);
    }
  }
);
app
  .component('icon', FontAwesomeIcon)
  .use(Toaster, { position: 'bottom-right' })
  .use(router)
  .use(VueHtml2Canvas)
  .use(store)
  .use(Quasar, quasarUserOptions)
  .mount('#app');
