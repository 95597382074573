<template>
  <div class="home page">
    <Meta pageTitle="OptiSilo Home Page" />

    <div class="container ">
      <div class="content">
        <h1 class="title">OptiSilo</h1>
        <p>
          Enregistrer vos informations de traçabilité de ventilation et de
          thermométrie : espèces, volume cellules, relevés températures, coût
          kWh. Évaluer et maîtrisez les performances temporelles et économiques
          de vos installations de ventilation.
        </p>
        <p>
          OptiSilo permet d’établir sa performance dans la majorité des
          situations silos. Pour les autres situations non prévues, vous pouvez
          contacter notre assistance.
        </p>
        <BtnConnexion
          title="Connexion"
          label="Connexion"
          textBtn="Connexion"
          active-loader
          styleBtn="contained"
          colorBtn="warning"
          class="contentBtn"
          typeBtn="button"
          @click="$router.push({ name: 'login' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Meta from '@/components/atoms/Meta';
import BtnConnexion from '@/components/atoms/Button';

export default {
  name: 'Home',
  components: {
    Meta,
    BtnConnexion
  },
  mounted() {
    //
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
