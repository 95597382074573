import { createStore } from 'vuex';
import userModules from './modules/User';
import loadingModules from './modules/Loading';
import getSilosModules from './modules/GetSilos';
import getActionGrainModules from './modules/GetActionGrain';
import GetSettingsModules from './modules/GetSettings';
import GetInputCellLocationModules from './modules/GetInputCellLocation';
import GetIndicatorLocationModules from './modules/GetIndicatorLocation';
import GetIndicatorsFromAllLocationsModules from './modules/GetIndicatorsFromAllLocations';
import GetListOperatorModules from './modules/GetListOperator';
import GetLocationModules from './modules/GetLocation';
import GetLastDateActionCellModules from './modules/GetLastDateActionCell';
import GetCampaignModules from './modules/GetCampaign';
import GetSpeciesModules from './modules/GetSpecies';
import GetFillingsModules from './modules/GetFillings';
import GetTimeVentilationModules from './modules/GetTimeVentilation';
import GetApiKeysModules from './modules/GetListApiKeys';
import GetListTempOperatorModules from './modules/GetListTemporaryOperator';

export default createStore({
  modules: {
    user: userModules,
    loading: loadingModules,
    silos: getSilosModules,
    grain: getActionGrainModules,
    setting: GetSettingsModules,
    inputLocation: GetInputCellLocationModules,
    indicator: GetIndicatorLocationModules,
    indicatorsLocations: GetIndicatorsFromAllLocationsModules,
    listOperator: GetListOperatorModules,
    listTempOperator: GetListTempOperatorModules,
    listLocation: GetLocationModules,
    lastDateCell: GetLastDateActionCellModules,
    campaign: GetCampaignModules,
    species: GetSpeciesModules,
    listFillings: GetFillingsModules,
    time: GetTimeVentilationModules,
    apiKeys: GetApiKeysModules
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {}
});
