<template>
  <div ref="printMe" class="background" :style="comptutedBackgroud">
    <NavBar v-if="this.$route.name !== 'login'"></NavBar>
    <q-layout view="lHh lpr lFf">
      <router-view />
    </q-layout>
  </div>
  <!-- <button :onclick="print">screen</button>
  <img :src="output" /> -->
</template>

<script>
import NavBar from '@/components/molecules/NavBar';
export default {
  name: 'LayoutDefault',
  components: {
    NavBar
  },
  computed: {
    comptutedBackgroud() {
      if (this.$route.name === 'login' || this.$route.name === 'reset' || this.$route.name === 'valid-password') {
        return "background-color: initial; background-image: url('/img/optisilo_login_bg.jpeg');  background-size: cover;  overflow: hidden;";
      } else {
        return 'background-color: #F4F2EF; background-image: initial; background-size: initial; overflow: initial;';
      }
    }
  }
  // data() {
  //   return {
  //     output: null
  //   }
  // },
  // methods: {
  //   async print() {
  //     const el = this.$refs.printMe;
  //     // add option type to get the image version
  //     // if not provided the promise will return 
  //     // the canvas.
  //     const options = {
  //       type: 'dataURL'
  //     }
  //     this.output = await this.$html2canvas(el, options);
  //   }
  // }
};
</script>
