import GetSettingsGetters from './getters.js';
import GetSettingsMutations from './mutations.js';
import GetSettingsAction from './actions.js';

export default {
  namespaced: true,

  state: {
    settings: []
  },
  getters: GetSettingsGetters,
  mutations: GetSettingsMutations,
  actions: GetSettingsAction
};
