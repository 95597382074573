import GetIndicatorLocationGetters from './getters.js';
import GetIndicatorLocationMutations from './mutations.js';
import GetIndicatorLocationAction from './actions.js';

export default {
  namespaced: true,

  state: {
    indicatorLocation: []
  },
  getters: GetIndicatorLocationGetters,
  mutations: GetIndicatorLocationMutations,
  actions: GetIndicatorLocationAction
};
