<template>
<div class="LoaderContainer">
  <img
    v-if="!pourcentage"
    :class="[`Loader small-${smallLoader}`]"
    src="/img/icon/loader.svg"
    alt="Loader"
  />

  <div class="contentLoader" v-if="pourcentage">
    <img
      :class="[`Loader small-${smallLoader}`]"
      src="/img/icon/loader.svg"
      alt="Loader"
    />
    <span class="pourcent">{{ pourcent }}<span class="pourc">%</span></span>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    smallLoader: { type: String, default: 'default' },
    pourcent: { type: String },
    pourcentage: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
