import { nextTick } from 'vue';
class ModalStore {
  constructor() {
    this.storedObjList = {};
  }

  /**
   * @param {Modal} component
   */
  add(component) {
    this.storedObjList[component.name] = component;
  }

  /**
   * @param {Modal} component
   */
  remove(component) {
    if (this.exists(component)) {
      delete this.storedObjList[component.name];
    }
  }

  /**
   * @param {Modal} component
   */
  exists(component) {
    return !!this.storedObjList[component.name];
  }

  /**
   * @param {String} name
   */
  showModal(name) {
    if (this.storedObjList[name]) {
      nextTick(() => {
        this.storedObjList[name].show();
      });
    }
  }

  /**
   * @param {String} name
   */
  hideModal(name) {
    if (this.storedObjList[name]) {
      nextTick(() => {
        this.storedObjList[name].hide();
      });
    }
  }
}

export default new ModalStore();
