<template>
  <div class="mesSilos">
    <template v-if="loading">
      <InnerLoader />
    </template>
    <template v-else>
      <div class="bg-w">
        <div v-if="filtredSilos" class="mesSilos">
          <!-- Boucle des silos -->
          <template v-if="checkForCells(silos) === 0">
            Aucun silo n'est disponible pour ce niveau d'alerte.
          </template>
          <div class="siloRow" v-for="s in silos" :key="s.id">
            <template v-if="s.cell.length > 0">
              <h3>{{ s.name }}</h3>
              <div class="cells">
                <!-- Boucle des cellules -->
                <div :id="`square-cell-${c.id}`" :class="[`cell ${getCellColor(c)}`]" v-for="c in s.cell" :key="c.id" >
                  <!-- Titre et espèce de la cellule -->
                  <div :id="`square-cell-${c.id}`">
                    <h4 :id="`square-cell-${c.id}`">{{ c.name }}</h4>
                    <span :id="`square-cell-${c.id}`">{{
                      c.species_id ? c.species.name : (c.stored_species ? c.stored_species.name : '')
                    }}</span>
                    <br>
                    <span :id="`square-cell-${c.id}`">{{
                      c.species_id ? c.current_volume + ' T' : (c.stored_species ? c.current_volume + ' T' : '')
                    }}</span>
                  </div>
                  <!-- Indicateurs de la cellule -->
                  <div :id="`square-cell-${c.id}`" class="cellIndicators">
                    <div :id="`square-cell-${c.id}`" :class="`cellIndicator ${getTempColor(c)}`">
                      <svg :id="`square-cell-${c.id}`" width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :id="`square-cell-${c.id}`" d="M3.66668 4.41634C3.66668 3.26707 4.12323 2.16487 4.93588 1.35221C5.74854 0.539554 6.85074 0.0830078 8.00001 0.0830078C9.14929 0.0830078 10.2515 0.539554 11.0641 1.35221C11.8768 2.16487 12.3333 3.26707 12.3333 4.41634V10.1093C13.6582 11.0318 14.6541 12.3531 15.1761 13.8808C15.698 15.4086 15.7188 17.063 15.2353 18.6034C14.7519 20.1438 13.7895 21.4896 12.4881 22.4451C11.1868 23.4006 9.61446 23.9159 8.00001 23.9159C6.38557 23.9159 4.81325 23.4006 3.51191 22.4451C2.21057 21.4896 1.24814 20.1438 0.764679 18.6034C0.281222 17.063 0.301979 15.4086 0.823929 13.8808C1.34588 12.3531 2.34178 11.0318 3.66668 10.1093V4.41634ZM4.90601 11.8859C3.95937 12.5447 3.2477 13.4884 2.87461 14.5797C2.50151 15.671 2.48648 16.8529 2.83169 17.9533C3.1769 19.0537 3.86434 20.0153 4.79392 20.6979C5.72351 21.3805 6.84671 21.7486 8.00001 21.7486C9.15332 21.7486 10.2765 21.3805 11.2061 20.6979C12.1357 20.0153 12.8231 19.0537 13.1683 17.9533C13.5135 16.8529 13.4985 15.671 13.1254 14.5797C12.7523 13.4884 12.0407 12.5447 11.094 11.8859L10.1667 11.2392V4.41634C10.1667 3.84171 9.93841 3.2906 9.53208 2.88428C9.12575 2.47795 8.57465 2.24967 8.00001 2.24967C7.42538 2.24967 6.87428 2.47795 6.46795 2.88428C6.06162 3.2906 5.83335 3.84171 5.83335 4.41634V11.2392L4.90601 11.8859ZM3.66668 16.333H12.3333C12.3333 17.4823 11.8768 18.5845 11.0641 19.3971C10.2515 20.2098 9.14929 20.6663 8.00001 20.6663C6.85074 20.6663 5.74854 20.2098 4.93588 19.3971C4.12323 18.5845 3.66668 17.4823 3.66668 16.333Z" fill="#7C7870"/>
                      </svg>
                      <span :id="`square-cell-${c.id}`">{{ c.current_volume > 0 ? Math.floor(c.tmi_max) : '-'}}</span>
                    </div>
                    <div :id="`square-cell-${c.id}`" :class="`cellIndicator ${getRiskColor(c.cjours && c.cjours != 0 ? Math.ceil(c.cjours / (400 - 1)) : '1')}`">
                      <svg :id="`square-cell-${c.id}`" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :id="`square-cell-${c.id}`" d="M4.056 6.3C4.11975 6.19831 4.18611 6.09828 4.255 6H15.745C15.814 6.098 15.88 6.199 15.944 6.3L17.964 5.134L18.964 6.866L16.751 8.144C16.913 8.734 17 9.357 17 10V11H20V13H17C17 13.953 16.81 14.862 16.464 15.69L18.964 17.134L17.964 18.866L15.438 17.408C14.3292 18.7775 12.7442 19.6779 11 19.929V12H9V19.93C7.2557 19.6787 5.67064 18.7779 4.562 17.408L2.036 18.866L1.036 17.134L3.536 15.691C3.18102 14.8383 2.99884 13.9236 3 13H0V11H3V10C3 9.357 3.087 8.735 3.249 8.144L1.036 6.866L2.036 5.134L4.056 6.3ZM6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4H6Z" fill="#7C7870"/>
                      </svg>
                      <span :id="`square-cell-${c.id}`">{{c.current_volume > 0 ? c.cjours && c.cjours != 0 ? Math.ceil(c.cjours / (400 - 1)) : '1' : '-'}}</span>
                    </div>
                    <div :id="`square-cell-${c.id}`" class="cellIndicator ">
                      <svg :id="`square-cell-${c.id}`" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :id="`square-cell-${c.id}`" d="M2 0V16H18V18H0V0H2ZM16.94 2.94L19.06 5.06L13 11.122L10 8.122L6.06 12.062L3.94 9.94L10 3.88L13 6.88L16.94 2.94Z" fill="#7C7870"/>
                      </svg>
                      <span :id="`square-cell-${c.id}`">{{ c.pem ? fix(c.pem) : '-' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'dayjs/locale/fr';
import Api from '@/classes/api/Api';
import InnerLoader from '@/components/atoms/InnerLoader';

export default {
  name: 'MesSilos',
  components: {
    InnerLoader,
  },
  emits: ['refreshed'],
  props: {
    isOpen: { type: Boolean, default: false },
    filter: { type: String, default: 'Tous niveaux d’alertes' },
    needRefresh: { type: Boolean, default: false }
  },
  watch: {
    needRefresh() {
      if (this.needRefresh === true) {
        this.fetchData();
      }
    },
    filter() {
      // Object.assign(this.filtredSilos, this.silosWithoutFilter)
    }
  },
  data() {
    return {
      filtredSilos: null,
      cells: [],
      openAlerteModal: false,
      sortType: 'cellName',
      orderType: 'desc',
      loading: true,
      modalStateSelectSiloForMulti: false,
      workedCells: []
    };
  },

  async created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    ...mapGetters('silos', ['GET_CELL']),
    ...mapGetters('listFillings', ['GET_FILLINGS']),
    ...mapGetters('silos', ['GET_FANS']),
    ...mapGetters('time', ['GET_CUMUL']),
    ...mapGetters('user', ['user']),
    pages() {
      return Math.ceil(this.workedCells.length / this.pageSize)
    },
    sworkedCells() {
      return  this.workedCells.filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    silos() {
      if (this.filter === 'Tous niveaux d’alertes') {
        return this.filtredSilos[0].silo;
      } else if (this.filter === 'Alerte niveau 1 et plus') {
        let azerty = JSON.parse(JSON.stringify(this.filtredSilos[0].silo))
        azerty.forEach(silo => {
          const cellules = silo.cell.filter(cell => {
            const cellColor = this.getCellColor(cell);
            return cellColor === 'greenAlertCell' || cellColor === 'lightGreenAlertCell' || cellColor === 'yellowAlertCell' || cellColor === 'orangeAlertCell' || cellColor === 'redAlertCell' ? true : false;
          })
          silo.cell = cellules;
        })
        return azerty;
      } else if (this.filter === 'Alerte niveau 2 et plus') {
        let azerty = JSON.parse(JSON.stringify(this.filtredSilos[0].silo))
        azerty.forEach(silo => {
          const cellules = silo.cell.filter(cell => {
            const cellColor = this.getCellColor(cell);
            return cellColor === 'lightGreenAlertCell' || cellColor === 'yellowAlertCell' || cellColor === 'orangeAlertCell' || cellColor === 'redAlertCell'  ? true : false;
          })
          silo.cell = cellules;
        })
        return azerty;
      } else if (this.filter === 'Alerte niveau 3 et plus') {
        let azerty = JSON.parse(JSON.stringify(this.filtredSilos[0].silo))
        azerty.forEach(silo => {
          const cellules = silo.cell.filter(cell => {
            const cellColor = this.getCellColor(cell);
            return cellColor === 'yellowAlertCell' || cellColor === 'orangeAlertCell' || cellColor === 'redAlertCell' ? true : false;
          })
          silo.cell = cellules;
        })
        return azerty;
      } else if (this.filter === 'Alerte niveau 4 et plus') {
        let azerty = JSON.parse(JSON.stringify(this.filtredSilos[0].silo))
        azerty.forEach(silo => {
          const cellules = silo.cell.filter(cell => {
            const cellColor = this.getCellColor(cell);
            return cellColor === 'orangeAlertCell' || cellColor === 'redAlertCell' ? true : false;
          })
          silo.cell = cellules;
        })
        return azerty;
      } else if (this.filter === 'Alerte niveau 5') {
        let azerty = JSON.parse(JSON.stringify(this.filtredSilos[0].silo))
        azerty.forEach(silo => {
          const cellules = silo.cell.filter(cell => {
            const cellColor = this.getCellColor(cell);
            return cellColor === 'redAlertCell' ? true : false;
          })
          silo.cell = cellules;
        })
        return azerty;
      } else {
        return this.filtredSilos[0].silo;
      }
    }
  },
  methods: {
    fix(num) {
      return parseInt(num).toFixed(0);
    },
    checkForCells(silos) {
      var nbrCells = 0;
      silos.forEach((silo) => {
        nbrCells += silo.cell.length;
      });
      return nbrCells;
    },
    getTempColor(c) {
      return c.tmi_max > 20
        ? 'redAlertCellIndicator'
        : c.tmi_max > 15
        ? 'orangeAlertCellIndicator'
        : c.tmi_max > 10
        ? 'yellowAlertCellIndicator'
        : c.tmi_max > 5
        ? 'lightGreenAlertCellIndicator'
        : c.tmi_max > 0 ? 'greenAlertCellIndicator' : ''
    },
    getRiskColor(risk) {
      return risk >= 5
        ? 'redAlertCellIndicator'
        : risk > 4
        ? 'orangeAlertCellIndicator'
        : risk > 3
        ? 'yellowAlertCellIndicator'
        : risk > 2
        ? 'lightGreenAlertCellIndicator'
        : risk > 1 ? 'greenAlertCellIndicator' : ''
    },
    getCellColor(c) {
      const tempColor = this.getTempColor(c);
      const riskColor = this.getRiskColor(
        c.cjours && c.cjours != 0 ? Math.ceil(c.cjours / (400 - 1)) : '1'
      );
      const redColor =
        riskColor === 'redAlertCellIndicator'
          ? true
          : tempColor === 'redAlertCellIndicator'
          ? true
          : false;
      const orangeColor =
        riskColor === 'orangeAlertCellIndicator'
          ? true
          : tempColor === 'orangeAlertCellIndicator'
          ? true
          : false;
              const yellowColor =
        riskColor === 'yellowAlertCellIndicator'
          ? true
          : tempColor === 'yellowAlertCellIndicator'
          ? true
          : false;
              const lightGreen =
        riskColor === 'lightGreenAlertCellIndicator'
          ? true
          : tempColor === 'lightGreenAlertCellIndicator'
          ? true
          : false;
            const green =
        riskColor === 'greenAlertCellIndicator'
          ? true
          : tempColor === 'greenAlertCellIndicator'
          ? true
          : false;
      return c.current_volume > 0
        ? redColor
          ? 'redAlertCell'
          : orangeColor
          ? 'orangeAlertCell'
          : yellowColor ? 'yellowAlertCell' : lightGreen ? 'lightGreenAlertCell' : green ? 'greenAlertCell' : 'notEmptyCell'
        : '';
    },
    changePage(page) {
      this.currentPage = page;
    },
    fetchData() {
      this.loading = true;
      this.workedCells = [];
      Api.getSilo().then(({ data }) => {
        this.filtredSilos = data
        this.loading = false;
      });
      this.$emit('refreshed');
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
