<template>
  <span :class="`title ${size}`">
    <span class="subFirst">{{ subFirst }}</span
    ><span class="subSecondary" :class="`colorApp-${colorApp}`">{{
      subSecondary
    }}</span>
  </span>
</template>

<script>
// import { toRefs } from 'vue';
export default {
  name: 'LogoApp',

  props: {
    subFirst: {
      type: String,
      default: 'opti',
    },
    subSecondary: {
      type: String,
      default: 'Silo',
    },
    size: {
      type: String,
      default: "sm",
    },
    colorApp: {
      type: String,
      default: 'silo',
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
