<template>
  <button
    :type="typeBtn"
    :class="[
      `btn-iconed-${icon} btn-${colorBtn}-${styleBtn} btnLoader btnDisabled-${disabled} ${imgClass}`,
      {
        'btn-iconed': icon,
        'btn-iconed-invert': iconInvert
      }
    ]"
    :aria-label="label"
    :title="title"
  >
    <div v-if="icon || iconInvert" :class="[icon ? `iconCircle-${iconCircle}` : '']">
      <icon
        :icon="[prefixIcon, nameIcon]"
        class="icon"
        :size="size"
      />
    </div>
    <span v-if="activeLoader" class="followingText">{{ textBtnLoader }}</span>
    <span v-else>{{ textBtn }}</span>
    <img v-if="imgSrc" :src="imgSrc" alt="icon" />
  </button>
</template>

<script>
export default {
  name: 'Btnform',
  props: {
    textBtn: { type: String, required: true },
    textBtnLoader: { type: String, required: false },
    title: { type: String, required: true },
    label: { type: String, required: false },
    loader: { type: Boolean, default: false },
    // Add logo
    icon: { type: Boolean },
    img: { type: Boolean },
    imgSrc: { type: String, default: '' },
    iconInvert: { type: Boolean },
    // Add name icon
    nameIcon: { type: String },
    prefixIcon: { type: String, default: 'fas', required: true },
    styleBtn: { type: String, required: true },
    colorBtn: { type: String, required: true },
    typeBtn: { type: String, required: false, default: 'button' },
    size: { type: String, required: false, default: 'xs' },
    iconCircle: {
      type: [String, Boolean],
      default: 'darklight'
    },
    disabled: { type: String, default: '' }
  },
  data() {
    return {
      activeLoader: this.loader
    };
  },
  computed: {
    imgClass() {
      return this.img ? 'img' : ''
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
