<template>
  <div>
    <div class="siteNav" v-if="is_impersonate">
      <span>Site</span>
      <InputOption
        v-if="impersonate_locations"
        :optionList="impersonate_locations"
        @modelSelected="changeLocation"
        :modelSelectedDefault="true"
        :valueSelected="actualLocation"
      />
    </div>
    <section>
      <PageContainer operaterPage showExportButton pageName="Tableau de bord">
        <Meta pageTitle="OptiSilo DashBoard | Indicateur | Saisies | Silos" />
        <BoxBase class="mesSilos" titleBox="Indicateurs clés">
          <Indicator
            primary_title="TEMPERATURE MAXIMALE en °C"
            :primary_stat="getMaxTemp"
            secondary_title="Risque insecte MAXIMAL"
            :secondary_stat="getMaxRisk"
            tertiary_title="MOYENNE DE LA PERFORMANCE énergétique en Wh/Tonne/°C"
            :tertiary_stat="getMaxPerf"
          />
        </BoxBase>
        <div>
          <BoxBase
            filter
            v-if="renderComponent"
            @change-filter="changeFilter"
            class="mesSilos"
            titleBox="Mes silos"
          >
            <MesSilos 
              :filter="filter"
              :needRefresh="needRefresh"
              @refreshed="needRefresh = false" 
            />
          </BoxBase>
        </div>
        <div>
          <BoxBase class="mesSilos" titleBox="Détails par cellule">
            <Cells v-if="renderComponent" @needRefresh="needRefresh = true" />
          </BoxBase>
        </div>
      </PageContainer>
    </section>
  </div>
</template>

<script>
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
import Meta from '@/components/atoms/Meta';
import Cells from '@/components/molecules/Cells';
import BoxBase from '@/components/atoms/BoxBase';
import MesSilos from '@/components/molecules/MesSilos';
import Indicator from '@/components/molecules/Indicator';
import InputOption from '@/components/atoms/Inputs/InputOption';
import PageContainer from '@/components/molecules/PageContainer';

export default {
  name: 'dashboard_operator',
  components: {
    Meta,
    Cells,
    BoxBase,
    MesSilos,
    Indicator,
    InputOption,
    PageContainer
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  data() {
    return {
      needRefresh: false,
      renderComponent: true,
      actualLocation: null,
      selectedLocation: null,
      manager_locations: null,
      filtredSilos: null,
      is_impersonate: false,
      filter: 'all'
    };
  },
  watch: {
    needRefresh() {
      Api.getSilo().then(({ data }) => {
        this.filtredSilos = data; 
      });
      if(this.needRefresh === true) {
        this.initPage();
        this.forceRerender();
      }
    },
    filtredSilos() {
      if (this.manager_locations) {
        this.actualLocation = this.manager_locations.find(location_group => {
          return (
            location_group.location.id === this.filtredSilos[0].location_id
          );
        });
      }
    },
    manager_locations() {
      if (this.filtredSilos) {
        this.actualLocation = this.manager_locations.find(location_group => {
          return (
            location_group.location.id === this.filtredSilos[0].location_id
          );
        });
      }
    },
    async selectedLocation(nv) {
      //si la page n'est pas charger on ne prend pas en compte le watcher
      if (
        this.actualLocation &&
        this.actualLocation.location_id != nv.location.id
      ) {
        // sortie de l'impersonate actuel
        const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        if (rememberMe === false) {
          sessionStorage.setItem(
            'token',
            sessionStorage.getItem('token_rollback')
          );
          sessionStorage.setItem(
            'user',
            sessionStorage.getItem('user_rollback')
          );
          sessionStorage.setItem('impersonate_activated', false);
          this.$store.commit(
            'user/user',
            JSON.parse(sessionStorage.getItem('user_rollback'))
          );
        } else {
          localStorage.setItem('token', localStorage.getItem('token_rollback'));
          localStorage.setItem('user', localStorage.getItem('user_rollback'));
          localStorage.setItem('impersonate_activated', false);
          this.$store.commit(
            'user/user',
            JSON.parse(localStorage.getItem('user_rollback'))
          );
        }

        // this.$router.push({ name: '' });

        // impersanate pour la seconde location
        if (rememberMe === false) {
          sessionStorage.setItem(
            'token_rollback',
            sessionStorage.getItem('token')
          );
          sessionStorage.setItem(
            'user_rollback',
            sessionStorage.getItem('user')
          );
        } else {
          localStorage.setItem('token_rollback', localStorage.getItem('token'));
          localStorage.setItem('user_rollback', localStorage.getItem('user'));
        }
        await Api.impersonate(nv.location.id).then(data => {
          let response = data.data;
          if (!response.error) {
            if (rememberMe === false) {
              sessionStorage.setItem('token', JSON.stringify(response.token));
              sessionStorage.setItem('user', JSON.stringify(response.user));
              sessionStorage.setItem('impersonate_activated', true);
            } else {
              localStorage.setItem('token', JSON.stringify(response.token));
              localStorage.setItem('user', JSON.stringify(response.user));
              localStorage.setItem('impersonate_activated', true);
            }
            // this.$router.push({ name: 'dashboard_operator' });
            this.$store.commit('user/user', response.user);
            this.$q.notify({
              message: `Changement de site réussi`,
              color: 'positive'
            });
            this.initPage();
            this.forceRerender();
          } else {
            console.log(response.error);
            this.$q.notify({
              message: `${response.error}`,
              color: 'negative'
            });
            localStorage.setItem('pass', true);
            this.$router.push({ name: 'dashboard_supervisor' });
          }
        });
      }
    }
  },
  computed: {
    impersonate_locations() {
      if (this.manager_locations) {
        return this.manager_locations.filter(location_group => {
          location_group.label = location_group.location.name;
          location_group.value = location_group.location.name;
          return true;
          })
          .sort((a, b) =>
            a.label > b.label ? 1 : a.label === b.label ? 0 : -1
          );
      } else {
        return {};
      }
    },
    getMaxTemp() {
      let maxTemp = 0;
      if (this.filtredSilos !== null) {
        this.filtredSilos[0].silo.forEach(silo => {
          silo.cell.forEach(cell => {
            if (parseFloat(cell.tmi_max) > parseFloat(maxTemp)) {
              maxTemp = parseFloat(cell.tmi_max);
            }
          });
        });
      }
      return maxTemp;
    },
    getMaxRisk() {
      let maxRisk = 0;
      if (this.filtredSilos !== null) {
        this.filtredSilos[0].silo.forEach(silo => {
          silo.cell.forEach(cell => {
            if (
              parseInt(
                cell.cjours && cell.cjours != 0
                  ? Math.ceil(cell.cjours / (400 - 1))
                  : '1'
              ) > parseInt(maxRisk)
            ) {
              maxRisk = parseInt(
                cell.cjours && cell.cjours != 0
                  ? Math.ceil(cell.cjours / (400 - 1))
                  : '1'
              );
            }
          });
        });
      }
      return maxRisk;
    },
    getMaxPerf() {
      return null;
    }
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    initPage() {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        this.is_impersonate = JSON.parse(
          sessionStorage.getItem('impersonate_activated')
        );
      } else {
        this.is_impersonate = JSON.parse(
          localStorage.getItem('impersonate_activated')
        );
      }

      // v3
      this.workedCells = [];
      Api.getSilo().then(({ data }) => {
        this.filtredSilos = data;
        this.loading = false;
      });

      // get location for manager
      if (this.is_impersonate) {
        const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        if (rememberMe === false) {
          const operateur_token = sessionStorage.getItem('token');
          const manager_token = sessionStorage.getItem('token_rollback');
          sessionStorage.setItem('token', manager_token);
          Api.getLocation().then(response => {
            this.manager_locations = response.data;
          });
          sessionStorage.setItem('token', operateur_token);
        } else {
          const operateur_token = sessionStorage.getItem('token');
          const manager_token = sessionStorage.getItem('token_rollback');
          localStorage.setItem('token', manager_token);
          Api.getLocation().then(response => {
            this.manager_locations = response.data;
          });
          localStorage.setItem('token', operateur_token);
        }
      }
    },
    changeLocation(nv) {
      //change la valeur de la location selectionnée ce qui va déclencher le watcher qui va changer l'utilisateur de location
      this.selectedLocation = nv;
    },
    changeFilter(v) {
      this.filter = v;
    },
    leave_impersonate() {
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      if (rememberMe === false) {
        sessionStorage.setItem(
          'token',
          sessionStorage.getItem('token_rollback')
        );
        sessionStorage.setItem('user', sessionStorage.getItem('user_rollback'));
        sessionStorage.setItem('impersonate_activated', false);
        this.$store.commit(
          'user/user',
          JSON.parse(sessionStorage.getItem('user_rollback'))
        );
      } else {
        localStorage.setItem('token', localStorage.getItem('token_rollback'));
        localStorage.setItem('user', localStorage.getItem('user_rollback'));
        localStorage.setItem('impersonate_activated', false);
        this.$store.commit(
          'user/user',
          JSON.parse(localStorage.getItem('user_rollback'))
        );
      }

      this.$router.push({ name: 'dashboard_supervisor' });
    }
  },
  async created() {
    this.initPage();
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
