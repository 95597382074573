import GetSilosGetters from './getters.js';
import GetSilosMutations from './mutations.js';
import GetSilosAction from './actions.js';

export default {
  namespaced: true,

  state: {
    silosList: [],
    cellList: [],
    fansList: []
  },
  getters: GetSilosGetters,
  mutations: GetSilosMutations,
  actions: GetSilosAction
};
