import GetFillingsGetters from './getters.js';
import GetFillingsMutations from './mutations.js';
import GetFillingsAction from './actions.js';

export default {
  namespaced: true,

  state: {
    fillings: []
  },
  getters: GetFillingsGetters,
  mutations: GetFillingsMutations,
  actions: GetFillingsAction
};
