<template>
  <nav role="navigation" :id="cellId">
    <div class="app-menu">
      <div class="menu-cell">
        <span
          :id="`menu-cell-${cellId}`"
          v-if="ellipsis"
          :class="{ ellipsis: ellipsis, cells: cells }"
        ></span>
      </div>
      <div class="menu-princ">
        <span :id="`menu-princ-${cellId}`" v-if="ellipsis === false && !icon"
          ><b></b
        ></span>
      </div>
      <div class="menu-cells" v-if="icon">
        <span @click.stop="showModal = !showModal"
          >Ajouter
          <icon :icon="[prefixIcon, nameIcon]" class="icon" :size="'sm'"
        /></span>
      </div>
      <div
        class="menu-modal "
        :class="[
          {
            open: showModal
          },
          { menuEllipsisPosition: ellipsis, responsiveCenter: responsive }
        ]"
        v-show="showModal"
      >
        <div class="wrap">
          <header class="menuHeader" :class="{ ellipsisMenu: ellipsis }">
            <h6
              :class="{
                ellipsisTitle: ellipsis,
                ellipsisTitleAfter: ellipsis
              }"
            >
              {{ titleMenu }}
            </h6>

            <!--<u :class="{ ellipsisSubTitle: ellipsis }">{{ subTitleMenu }}</u>-->
            <button
              :id="`menu-princ-close-${cellId}`"
              class="detailBtn"
              :class="{ ellipsisDetailBtn: ellipsis }"
              @click.stop="showModal = !showModal"
            ></button>
          </header>
          <section>
            <ul v-if="disableMenuLi">
              <li
                @click.stop="showModal = !showModal"
                v-for="([key, value], j) in Object.entries(items)"
                :key="`item${j}`"
                :data-label="value.label"
                class="menuLiContent"
                :class="{
                  ellipsisBorderBottom: ellipsis,
                  ellipsisMenuLiContent: ellipsis,
                  ellipsisliHover: ellipsis,
                  disable: disable
                }"
              >
                <slot @click.stop="showModal = !showModal" :item="value">{{
                  item[key]
                }}</slot>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { uuid } from 'vue-uuid';
// import { nextTick } from 'vue';
export default {
  name: 'Menu',
  props: {
    cellId: {
      type: Number,
      default: null
    },
    ellipsis: {
      type: Boolean,
      default: false
    },
    cells: {
      type: Boolean,
      default: false
    },
    titleMenu: {
      type: String,
      default: 'Menu principal'
    },
    subTitleMenu: {
      type: String,
      default: ''
    },
    icon: { type: Boolean },
    disable: {
      type: Boolean
    },
    disableMenuLi: {
      type: Boolean,
      default: true
    },
    nameIcon: { type: String },
    prefixIcon: { type: String, default: 'fas', required: true },
    responsive: {
      type: Boolean,
      default: false
    },
    items: { type: Object, required: true },
    cellHasSaisies: { type: Boolean }
  },
  data() {
    return {
      showModal: false,
      hideModal: false,

      uuid: uuid.v4()
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (
        event.target.id == `menu-cell-${this.cellId}` ||
        event.target.id == `menu-princ-${this.cellId}`||
        event.target.id == `square-cell-${this.cellId}`
      ) {
        this.showModal = !this.showModal;
      } else if (
        event.target.id != `menu-cell-${this.cellId}` ||
        event.target.id != `menu-princ-${this.cellId}` ||
        event.target.id == `square-cell-${this.cellId}`
      ) {
        this.showModal = false;
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
