<template>
  <teleport to="head">
    <title>{{ pageTitle }}</title>
    <meta name="description" :content="pageDescription" />
    <meta name="keywords" :content="keywords" />
  </teleport>
</template>

<script>
export default {
  name: 'Meta',

  props: {
    pageTitle: {
      type: String,
      default: 'OptiSilo | La coopération agricole | Solutions Plus'
    },
    pageDescription: {
      type: String,
      default: ''
    },
    keywords: { type: String, default: '' }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
