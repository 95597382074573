<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      activeFormValidation
      size="960"
      closeBtnCenter
      v-if="cellObject && cellObject.status != 'vide'"
      name="cellinfos"
      title="Ajout d'information cellule"
      cell_desk
      :silo="siloInfo"
      :cell="cellObject.name"
      :species="
        cellObject.species && cellObject.species.name
          ? cellObject.species.name : 
          cellObject.stored_species && cellObject.stored_species.name
            ? cellObject.stored_species.name
            : ''
      "
      cell_info
      :label_date="'Date de dernière saisie'"
      :date="cellObject.lastSaisie || '-'"
      :current_volume="parseInt(cellObject.current_volume)"
      label_volume_2="Capacité de stockage"
      :max_volume="parseInt(cellObject.volume)"
      :actionGrains="actionGrains"
      commentModal
      infoGrainModal
      @submit="addCellInfos"
    >
      <div class="date_container">
        <InputDate
          class="date"
          :last_input="cellLastInput"
          titleDate="Date de l’opération *"
          :isRange="false"
          :dayValue="dayValue"
          @valuePickerUniq="valuedayUniq"
        />
      </div>
    </AddModal>
  </div>
</template>

<script>
import AddModal from '@/components/atoms/Modal';
import Loader from '@/components/atoms/Loader';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import { mapGetters } from 'vuex';
import InputDate from '@/components/atoms/Inputs/InputDate';
import Api from '@/classes/api/Api';
import dayjs from 'dayjs';
// import AlerteModal from '@/components/molecules/AlerteModal';
export default {
  name: 'AddCellInfos',
  components: {
    Loader,
    AddModal,
    InputDate
  },
  props: {
    isOpen: { type: Boolean, default: false },
    cellObject: {
      type: Object,
      default: null
    },
    siloInfo: {
      type: String
    }
  },
  data() {
    return {
      modalStore: ModalStore,
      tabs: {
        grain: 'Cellule'
      },
      currentTab: 'grain',
      infos_grain: [],
      infos_grain_id: [],
      info_cell_description: '',
      disableBtn: true,
      errorDate: false,
      errorSendDate: false,
      isLoading: false,
      infos_cell_date: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      dayValue: new Date().toISOString().substr(0, 10),
      error: ''
    };
  },
  watch: {
    info_cell_description() {
      if (this.info_cell_description !== '') {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    }
  },
  methods: {
    setDate(evt, date) {
      if (evt < this.checkDateInput(date) || evt > this.maxDate) {
        this.errorDate = true;
        this.infos_cell_date = '';
        // this.modalStore.showModal('confirmationDate');
      } else {
        this.errorSendDate = false;
        this.error = false;
        this.infos_cell_date = evt;
        this.errorDate = false;
      }
    },
    checkDate(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();
      return this.datejs(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejs(arrSort[arrSort.length - 1])
        : undefined;
    },
    valuedayUniq(e) {
      this.dayValue = e;
    },
    checkDateInput(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      let dateinfoCell = '';
      if (date.infos_cell && date.infos_cell.length > 0) {
        dateinfoCell = date.infos_cell[date.infos_cell.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0 &&
        date.infos_cell &&
        date.infos_cell.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings, dateinfoCell);
      const arrSort = arr.sort();
      return this.datejsInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejsInput(arrSort[arrSort.length - 1])
        : undefined;
    },

    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY ');
    },
    datejsInput(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    handleClicks(obj, id) {
      const index = this.infos_grain.indexOf(obj);
      if (index > -1) {
        this.infos_grain.splice(index, 1);
        this.infos_grain_id.splice(index, 1);
      } else {
        this.disableBtn = false;
        this.infos_grain.push(obj);
        this.infos_grain_id.push(id);
      }

      if (this.infos_grain.length == 0) {
        this.disableBtn = true;
      }

      // PUSH ID DATA
    },

    changeDate(event) {
      this.infos_cell_date = event.target.value;
    },
    infosGrainEnable(grain) {
      const index = this.infos_grain.indexOf(grain);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    addCellInfos(actionGrains, comment) {
      this.isLoading = true;

      let parsedActionGrains = actionGrains.map(actionGrainString => {
        return this.GET_ACTION_GRAIN.filter(actionGrain => {
          return actionGrain.name == actionGrainString;
        })[0].id;
      });
      // this.$refs.btn1.activeLoader = true;
      let data = {
        infos_cell: parsedActionGrains,
        infos_cell_description: comment,
        infos_cell_date: this.dayValue,
        cell_id: this.cellObject.id
      };
      if (this.infos_cell_date == '' || this.infos_cell_date == undefined) {
        this.isLoading = false;
        // this.$refs.btn1.activeLoader = false;
        this.error = true;
        this.errorSendDate = true;
      } else {
        Api.addInfosToCell(data)
          .then(() => {
            this.$q.notify({
              message: 'Création de l\'info cellule réussi',
              color: 'positive'
            });
            this.$store.dispatch('silos/loadSilo');
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.$store.dispatch('indicator/loadIndicatorLocation');
            this.isLoading = false;
            // this.$refs.btn1.activeLoader = false;
            // ModalStore.hideModal('cellinfos');
            this.$emit('update:isOpen', false);
            this.$emit('done');
          })
          .catch(() => {
            this.$q.notify({
              message: 'Création de l\'info cellule impossible',
              color: 'negative'
            });
            this.errorSendDate = true;
          });
      }
    }
  },
  computed: {
    ...mapGetters('species', ['GET_SPECIES_BY_LOCATION_NAME']),
    ...mapGetters('campaign', ['GET_CAMPAIGN']),
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    cellLastInput() {
      return this.cellObject.lastSaisieTimestamp
        ? new Date(this.cellObject.lastSaisieTimestamp*1000)
        : null;
    },
    actionGrains() {
      if (this.GET_ACTION_GRAIN) {
        return this.GET_ACTION_GRAIN.map(value => {
          return value.name;
        });
      } else {
        return [];
      }
    },
    campaign() {
      return this.GET_CAMPAIGN.map(value => {
        return value.year;
      });
    },
    storedSpecies() {
      return this.sortedSpecieByName(this.speciesFiltered).map(value => {
        return value.name;
      });
    },
    ...mapGetters('grain', ['GET_ACTION_GRAIN']),
    filterInfiGrainLot() {
      const arr = this.GET_ACTION_GRAIN.filter(grain => {
        const text = 'Lot';
        return !grain.name.toLowerCase().includes(text.toLowerCase());
      });
      return arr;
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
