import GetTimeVentilationGetters from './getters.js';
import GetTimeVentilationMutations from './mutations.js';
import GetTimeVentilationAction from './actions.js';

export default {
  namespaced: true,

  state: {
    cumul: []
  },
  getters: GetTimeVentilationGetters,
  mutations: GetTimeVentilationMutations,
  actions: GetTimeVentilationAction
};
