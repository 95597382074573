import GetLocationGetters from './getters.js';
import GetLocationMutations from './mutations.js';
import GetLocationAction from './actions.js';

export default {
  namespaced: true,

  state: {
    locationList: []
  },
  getters: GetLocationGetters,
  mutations: GetLocationMutations,
  actions: GetLocationAction
};
