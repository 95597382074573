/**
 * @param {String} key
 * @return {Function}
 */
const compareString = key => (a, b, desc) =>
  desc
    ? (b[key] || '').localeCompare(a[key] || '')
    : (a[key] || '').localeCompare(b[key] || '');

/**
 * @param {String} key
 * @return {Function}
 */
const compareNumber = key => (a, b, desc) => {
  // if (desc) {
  //   if (a[key] != null && b[key] != null) {
  //     return a[key] - b[key];
  //   } else if (a[key] == null && b[key] != null) {
  //     return a['T2'] - b[key];
  //   } else if (a[key] != null && b[key] == null) {
  //     return a[key] - b['T2'];
  //   } else if (a[key] == null && b[key] == null) {
  //     return a['T2'] - b['T2'];
  //   }
  // } else {
  //   if (a[key] != null && b[key] != null) {
  //     return b[key] - a[key];
  //   } else if (a[key] == null && b[key] != null) {
  //     return b[key] - a['T2'];
  //   } else if (a[key] != null && b[key] == null) {
  //     return b["T2"] - a[key];
  //   } else if (a[key] == null && b[key] == null) {
  //     return b['T2'] - a['T2'];
  //   }
  // }

  return desc ? a[key] - b[key] : b[key] - a[key];
};

/**
 * @param {String} key
 * @return {Function}
 */
const compareDate = key => (a, b, desc) => {
  const dateA = new Date(a[key]);
  const dateB = new Date(b[key]);

  return desc
    ? dateA.getTime() - dateB.getTime()
    : dateB.getTime() - dateA.getTime();
};

export default {
  compareString,
  compareNumber,
  compareDate
};
