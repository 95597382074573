import GetCampaignGetters from './getters.js';
import GetCampaignMutations from './mutations.js';
import GetCampaignAction from './actions.js';

export default {
  namespaced: true,

  state: {
    campaign: []
  },
  getters: GetCampaignGetters,
  mutations: GetCampaignMutations,
  actions: GetCampaignAction
};
