import UserGetters from './getters.js';
import UserMutations from './mutations.js';
import UserAction from './actions.js';

export default {
  namespaced: true,

  state: { user: null },
  getters: UserGetters,
  mutations: UserMutations,
  action: UserAction
};
