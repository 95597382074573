<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
     size="960"
      closeBtnCenter
      name="ventilation"
      @click.stop
      @close="closeModalCross"
    >
      <ModalHeader
        :disableInfo="true"
        fieldBackground="name"
        :enableBackground="true"
        imgModal="/img/icon/icone-fonct-remplissage-mono.svg"
        subTitle="Ventilation monocellule"
        :siloInfo="siloInfo"
        :cellName="cellName"
        :cellObject="cellObject"
        :fanObject="fanObject"
      />

      <section class="ecos-ong wrap ">
        <Tabs :tabs="tabs" :active="currentTab" :init="updateFans()">
          <template #ventilation>
            <div class="wrap2">
              <h1>Ventilation</h1>
              <dl>
                <dt class="date">
                  Date <i>*</i>
                  <p>
                    la date doit être comprise entre le
                    <i>{{ checkDateInfo(cellObject) }}</i> et aujourd’hui.
                  </p>
                  <p></p>
                </dt>
                <dd>
                  <InputForm
                    class="mt-4 test"
                    inputModal="classic"
                    tabindex="1"
                    id="rdat"
                    type="date"
                    :min="checkDateInput(cellObject)"
                    :max="maxDate"
                    v-model="ventDate"
                    :value="ventDate"
                    @getBlur="setDate($event, cellObject)"
                  />
                  <span v-if="errorDate" class="errorDate"
                    >La date doit être entre comprise entre
                    {{ dayNow(checkDateInput(cellObject)) }} et
                    {{ dayNow(maxDate) }}
                  </span>
                </dd>
                <dt class="date">
                  Volume stocké <i>*</i>
                  <p>
                    Capacité totale <i>{{ cellObject.volume }}t</i> .
                    <!-- + <i>30%</i> soit un volume maximum de
                    <i>
                      {{
                        parseInt(
                          (cellObject.volume * 30) / 100 + cellObject.volume
                        )
                      }}t </i
                    >. -->
                  </p>
                  <!-- <p>
                    Volume disponible dans la cellule actuellement
                    <i
                      >{{
                        parseInt(
                          (cellObject.volume * 30) / 100 + cellObject.volume
                        ) - cellObject.current_volume
                      }}t</i
                    >.
                  </p> -->
                  <!-- <p>
                    Volume dans la cellule actuellement
                    <i>{{ cellObject.current_volume }}t</i>.
                  </p> -->
                </dt>
                <dd class="volume" data-v="t">
                  <div>
                    <InputForm
                      class="inputVolume"
                      inputModal="classic"
                      type="number"
                      tabindex="4"
                      id="rvs"
                      :min="0"
                      :max="
                        cellObject
                          ? parseInt(
                              (cellObject.volume * 30) / 100 + cellObject.volume
                            ) - cellObject.current_volume
                          : ''
                      "
                      :modelValue="fillingVs"
                      @getBlur="verifyVolume($event)"
                      v-model="fillingVs"
                      :readonly="!activeVolume"
                      :read="!activeVolume"
                    />
                  </div>
                  <div class="toggleVol">
                    <div>Mettre à jour</div>
                    <div class=" form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="volumeToggle"
                        :checked="activeVolume"
                        v-model="activeVolume"
                      />
                    </div>
                  </div>
                </dd>
                <dt>
                  Durée de ventilation <i>*</i>
                  <p>Durée de ventilation depuis la dernière saisie.</p>
                </dt>

                <dd>
                  <InputForm
                    inputModal="classic"
                    type="number"
                    tabindex="2"
                    id="rvent"
                    :min="0"
                    :max="999"
                    v-model="ventTime"
                    :modelValue="ventTime"
                    :readonly="cellObject.fans === null"
                    :read="cellObject.fans === null"
                  />
                </dd>
                <dt class="vent_used">
                  Ventilateurs utilisés :
                </dt>
                <dd class="chooseVent">
                  <template
                    v-if="
                      fans_can_be_selected && fans_can_be_selected.length > 0
                    "
                  >
                    <div
                      v-for="(fan, indxFan) in fans_can_be_selected"
                      :key="indxFan"
                      :data-label="indxFan"
                    >
                      <label :for="fan.id">{{ fan.name }}</label>
                      <input
                        type="checkbox"
                        :id="fan.id"
                        :value="fan"
                        v-model="fans_selected"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <pre>
                    {{ cellObject.fans }}
                    </pre>
                    Aucun ventilateur n’est commun à l’ensemble des cellules
                    sélectionnées
                  </template>
                </dd>
                <dt>
                  Température avant ventilation <i>*</i>
                  <!-- <p>Température Moyenne et Maximale avant ventilation.</p> -->
                </dt>
                <div class="temperature">
                  <dd class="tMin" data-u="t°min - °C">
                    <InputForm
                      inputModal="classic"
                      type="number"
                      tabindex="3"
                      id="rt1"
                      readonly
                      read
                      :min="-100"
                      :max="999"
                      :modelValue="
                        cellObject.tmi_min ? parseFloat(cellObject.tmi_min) : 0
                      "
                    />
                  </dd>
                  <dd class="tMoy" data-u="t°moy - °C">
                    <InputForm
                      inputModal="classic"
                      type="number"
                      tabindex="3"
                      id="rt1"
                      readonly
                      read
                      :min="-100"
                      :max="999"
                      :modelValue="
                        cellObject.tmi ? parseFloat(cellObject.tmi) : 0
                      "
                      @change="setT1($event)"
                    />
                  </dd>
                  <dd class="tMax" data-u="t°max - °C">
                    <InputForm
                      :inputModal="'classic'"
                      type="number"
                      tabindex="4"
                      id="rt2"
                      read
                      readonly
                      :min="-100"
                      :max="999"
                      :modelValue="
                        cellObject.tmi_max
                          ? parseFloat(cellObject.tmi_max)
                          : parseFloat(cellObject.tmi)
                          ? parseFloat(cellObject.tmi)
                          : 0
                      "
                    />
                  </dd>
                </div>
                <dt>
                  Température après ventilation <i>*</i>
                  <!-- <p>Température Moyenne et Maximale après ventilation.</p> -->
                </dt>
                <div class="temperature">
                  <dd class="tMin" data-u="t°min - °C">
                    <InputForm
                      inputModal="classic"
                      type="number"
                      tabindex="3"
                      id="rt1"
                      :min="ventTemperatureBeforeMin"
                      :max="999"
                      v-model="ventTemperatureAfterMin"
                      :modelValue="ventTemperatureAfterMin"
                      :step="0.1"
                    />
                  </dd>
                  <dd class="tMoy" data-u="t°moy - °C">
                    <InputForm
                      :inputModal="'classic'"
                      type="number"
                      tabindex="4"
                      id="rt2"
                      :min="0"
                      :max="999"
                      v-model="ventTemperatureAfter"
                      :modelValue="ventTemperatureAfter"
                      :step="0.1"
                    />
                  </dd>

                  <dd class="tMax" data-u="t°max - °C">
                    <InputForm
                      :inputModal="'classic'"
                      type="number"
                      tabindex="4"
                      id="rt2"
                      :min="ventTemperatureAfter"
                      :max="999"
                      v-model="temperatureMaxT2"
                      :modelValue="temperatureMaxT2"
                      :step="0.1"
                    />
                  </dd>
                </div>
                <dt>Consigne Thermostat <i>*</i></dt>
                <dd class="thermo" data-t="°C">
                  <InputForm
                    inputModal="classic"
                    type="number"
                    tabindex="5"
                    id="rct"
                    :min="-999"
                    :max="80"
                    :value="ventThermostat"
                    v-model="ventThermostat"
                  />
                </dd>
                <dd></dd>
                <dd>
                  <div class="btn-stuff">
                    <!-- ventTemperatureAfter <
                          ventTemperatureBefore && -->
                    <BtnAdd
                      v-if="btnEnvoi"
                      tabindex="6"
                      title="Envoyer les données"
                      textBtn="Envoyer les données"
                      textBtnLoader="Envoi en cours..."
                      styleBtn="contained"
                      colorBtn="warning"
                      class="contentBtn"
                      typeBtn="submit"
                      @click="() => addVentilation()"
                      active-loader
                      ref="btn1"
                    />
                    <BtnAdd
                      tabindex="6"
                      title="FERMER"
                      textBtn="FERMER"
                      styleBtn="contained"
                      colorBtn="success"
                      typeBtn="button"
                      id="envoiDV"
                      v-if="btnOk"
                      @click="closeModal"
                    />

                    <span v-if="msg" class="message"
                      >Vos données de ventilation sont enregistrées.</span
                    >
                  </div>
                  <span v-if="msgError" class="errorDate">
                    <!-- La date doit être entre comprise entre {{ dayNow(checkDate(cellObject)) }} et {{ dayNow(maxDate) }} -->
                    {{ msgError }}
                  </span>
                </dd>
              </dl>
            </div>
          </template>
        </Tabs>
      </section>
    </AddModal>
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      size="960"
      name="confirmVentilation"
      okCancel
      :okOnly="true"
      okLabel="valider"
      @submit="confirmAddVentilation()"
    >
      <AlerteModal :text1="`Souhaitez-vous valider l'envoi des données ?`">
        <ul class="message ">
          <li
            v-if="
              parseInt(ventThermostat) == 0 &&
                parseInt(ventThermostat).toFixed(2)
            "
          >
            <i>*</i> La consigne thermostat est à 0°C.
          </li>

          <li v-if="ventTemperatureAfter == 0 || temperatureMaxT2 == 0">
            <i>*</i> Valeur de température à 0°C.
          </li>

          <li
            v-if="
              ventTemperatureAfter >= ventTemperatureBefore && temperatureMaxT1
            "
          >
            <i>*</i> T2 supérieure ou égale à T1.
          </li>
        </ul>
      </AlerteModal>
    </AddModal>
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)" size="960" name="alerteEnsile" :okOnly="true" okLabel="valider">
      <AlerteModal
        :text1="'Le Volume ensilé dépasse le volume stockable de 30%.'"
      >
      </AlerteModal>
    </AddModal>
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      size="960"
      name="confirmationDate"
      :okOnly="true"
      okLabel="confirmer"
    >
      <AlerteModal
        :text1="
          'Attention vous allez saisir une date antérieur à la dernière saisie'
        "
      >
      </AlerteModal>
    </AddModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/classes/api/Api';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
import ModalHeader from '@/components/atoms/ModalHeader';
import Tabs from '@/components/atoms/Tabs';
import InputForm from '@/components/atoms/Form/InputForm';
import BtnAdd from '@/components/atoms/BtnLoader';
import Loader from '@/components/atoms/Loader';
import AlerteModal from '@/components/molecules/AlerteModal';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default {
  name: 'AddVentilation',
  props: {
    isOpen: { type: Boolean, default: false },
    cellId: {
      type: [String, Number]
    },
    fanId: {
      type: [String, Number]
    },
    siloInfo: {
      type: String
    },
    cellName: {
      type: String
    },
    cellObject: { type: Object, default: null },
    key: Number
  },
  components: {
    ModalHeader,
    AlerteModal,
    InputForm,
    AddModal,
    BtnAdd,
    Loader,
    Tabs
  },
  watch: {
    cellObject() {
      this.updateFans();
    },
    currentTab() {
      this.updateFans();
    }
  },
  data() {
    return {
      warning: [],
      btnEnvoi: true,
      fillingVs: this.cellObject.current_volume,
      activeVolume: false,
      fans_can_be_selected: [],
      fans_selected: [],
      btnOk: false,
      fanObject: '',
      msg: false,
      msgError: false,
      errorSendDate: false,
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      currentTab: 'ventilation',
      tabs: {
        ventilation: 'Ventilation'
      },
      tmi: 0,
      ventDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      errorDate: false,
      ventTime: 0,
      ventTemperatureBefore: this.cellObject.tmi ? this.cellObject.tmi : 0,
      ventTemperatureAfter: 0,
      ventTemperatureAfterMin: 0,
      ventTemperatureBeforeMin: this.cellObject.tmi_min
        ? this.cellObject.tmi_min
        : 0,
      ventThermostat: 0,
      temperatureMaxT1: this.cellObject.tmi_max ? this.cellObject.tmi_max : 0,
      temperatureMaxT2: 0
    };
  },

  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    ...mapGetters('time', ['GET_CUMUL'])
  },
  methods: {
    updateFans() {
      this.fans_can_be_selected =
        this.cellObject.fans && this.cellObject.fans.length > 0
          ? this.cellObject.fans
          : [];
      let precheckedFan = [];
      let fanIdToCheck = [];
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length > 0
      ) {
        if (
          this.cellObject.cell_ventilation[
            this.cellObject.cell_ventilation.length - 1
          ].code
        ) {
          //La derniere est une multi on va chercher tous les fans utilisées pour cette multi
          let tmp = this.cellObject.cell_ventilation.filter(v => {
            return (
              v.code ==
              this.cellObject.cell_ventilation[
                this.cellObject.cell_ventilation.length - 1
              ].code
            );
          });
          if (tmp.length > 0) {
            tmp.forEach(cv => {
              fanIdToCheck.push(cv.fan_id);
            });
          }
        } else {
          fanIdToCheck.push(
            this.cellObject.cell_ventilation[
              this.cellObject.cell_ventilation.length - 1
            ].fan_id
          );
        }
        fanIdToCheck = [...new Set(fanIdToCheck)];
        precheckedFan = this.fans_can_be_selected.filter(f => {
          return fanIdToCheck.includes(f.id);
        });
      }
      this.fans_selected = precheckedFan;
      if (this.fans_can_be_selected.length > 0) {
        this.fanObject = this.fans_can_be_selected[0];
      }
    },
    closeModalCross() {
      if (this.btnOk == true) {
        this.closeModal();
      }
    },
    dayNow(date) {
      return dayjs(date)
        .locale('fr')
        .format('D MMMM YYYY');
    },
    verifyVolume(evt) {
      if (
        evt >
        parseInt((this.cellObject.volume * 30) / 100 + this.cellObject.volume)
      ) {
        this.modalStore.showModal('alerteEnsile');
        this.fillingVs = this.cellObject.current_volume;
      }
    },
    setDate(evt, date) {
      if (evt < this.checkDateInput(date) || evt > this.maxDate) {
        this.errorDate = true;
        this.ventDate = '';
      } else {
        this.errorSendDate = false;
        this.ventDate = evt;
        this.errorDate = false;
      }
    },
    checkDateInfo(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();
      return this.dayInfo(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.dayInfo(arrSort[arrSort.length - 1])
        : undefined;
    },
    checkDate(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();
      return this.dayInfo(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.dayInfo(arrSort[arrSort.length - 1])
        : undefined;
    },
    checkDateInput(date) {
      let dateVenti = '';
      if (date.cell_ventilation && date.cell_ventilation.length > 0) {
        dateVenti =
          date.cell_ventilation[date.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (date.emptying && date.emptying.length > 0) {
        dateEmpty = date.emptying[date.emptying.length - 1].date;
      }
      let datefillings = '';
      if (date.fillings && date.fillings.length > 0) {
        datefillings = date.fillings[date.fillings.length - 1].date;
      }
      if (
        date.cell_ventilation &&
        date.cell_ventilation.length < 0 &&
        date.emptying &&
        date.emptying.length < 0 &&
        date.fillings &&
        date.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();

      return this.datejsInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejsInput(arrSort[arrSort.length - 1])
        : undefined;
    },
    displayDateInput() {
      let dateVenti = '';
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length > 0
      ) {
        dateVenti = this.cellObject.cell_ventilation[
          this.cellObject.cell_ventilation.length - 1
        ].date;
      }
      let dateEmpty = '';
      if (this.cellObject.emptying && this.cellObject.emptying.length > 0) {
        dateEmpty = this.cellObject.emptying[
          this.cellObject.emptying.length - 1
        ].date;
      }
      let datefillings = '';
      if (this.cellObject.fillings && this.cellObject.fillings.length > 0) {
        datefillings = this.cellObject.fillings[
          this.cellObject.fillings.length - 1
        ].date;
      }
      if (
        this.cellObject.cell_ventilation &&
        this.cellObject.cell_ventilation.length < 0 &&
        this.cellObject.emptying &&
        this.cellObject.emptying.length < 0 &&
        this.cellObject.fillings &&
        this.cellObject.fillings.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings);
      const arrSort = arr.sort();

      return this.datejsInput(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? this.datejsInput(arrSort[arrSort.length - 1])
        : new Date().toISOString().slice(0, 10);
    },
    dayInfo(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D MMMM YYYY');
    },
    datejsInput(date) {
      return dayjs(date)
        .locale('fr')
        .format('YYYY-MM-DD');
    },
    closeModal() {
      this.$store.dispatch('indicator/loadIndicatorLocation');
      this.$store.dispatch('inputLocation/loadInputLocation');
      this.$store.dispatch('silos/loadSilo');
      //  this.$store.dispatch('silos/loadFans');
      // ModalStore.hideModal('ventilation');

              this.$emit('update:isOpen', false);
      this.ventTime = 0;
      this.ventTemperatureBefore = this.tmi;
      this.ventTemperatureAfter = 0;
      this.ventTemperatureAfterMin = 0;
      this.ventThermostat = 0;
      this.btnOk = false;
      this.msg = false;
    },

    saveCell(cell) {
      this.tmi = cell.fillings[cell.fillings.length - 1].tmi;
      this.ventTemperatureBefore = cell.fillings[cell.fillings.length - 1].tmi;
      return cell.fillings[cell.fillings.length - 1].tmi;
    },
    setT1(evt) {
      if (evt.target.value) {
        this.ventTemperatureBefore = parseInt(evt.target.value);
      } else {
        this.ventTemperatureBefore = 0;
      }
    },
    addVentilation() {
      if (
        (parseInt(this.ventThermostat) == 0 &&
          parseInt(this.ventThermostat).toFixed(2)) ||
        this.ventTemperatureAfter == 0 ||
        this.temperatureMaxT2 == 0 ||
        (parseInt(this.ventTemperatureAfter) >=
          parseInt(this.ventTemperatureBefore) &&
          this.temperatureMaxT1)
      ) {
        this.modalStore.showModal('confirmVentilation');
      } else {
        this.confirmAddVentilation();
      }
    },
    confirmAddVentilation() {
      // this.isLoading = true;
      this.$refs.btn1.activeLoader = true;

      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
      let data = {
        cells_ventilation: [
          {
            date: this.ventDate,
            time: this.ventTime,
            temperature_before: this.ventTemperatureBefore,
            temperature_after: this.ventTemperatureAfter,
            temperature_before_min: this.ventTemperatureBeforeMin,
            temperature_after_min: this.ventTemperatureAfterMin,
            temperature_before_max: this.temperatureMaxT1,
            temperature_after_max: this.temperatureMaxT2,
            thermostat: this.ventThermostat,
            volume: this.cellObject.current_volume,
            active_volume: this.activeVolume,
            new_volume: this.activeVolume
              ? this.fillingVs
              : this.current_volume,
            user_id: user.id,
            cell_id: this.cellId,
            fan_id: this.fans_selected
          }
        ]
      };

      Api.addVent(data)
        .then(({ data }) => {
          if (data) {
            this.btnOk = true;
            this.btnEnvoi = false;
            this.msg = true;
            this.isLoading = false;
            this.isErrorMsg = false;
            this.$refs.btn1.activeLoader = false;
            this.$store.dispatch('indicator/loadIndicatorLocation');
          }

          this.$store.dispatch('silos/loadSilo');
          this.$store.dispatch('listFillings/loadFillings');
          this.$store.dispatch('indicator/loadIndicatorLocation');
          this.$emit('done');
          this.createArr();
        })
        .catch(error => {
          this.msgError = error.response.data.message;
          this.$refs.btn1.activeLoader = false;
        });
    },
    createArr() {
      let newArrSilo = [];
      this.GET_SILOS.map(silo => {
        this.silo_id = silo.id;
        newArrSilo.push(this.silo_id);
      });
      newArrSilo.map(id => {
        this.$store.dispatch('time/loadCumul', id);
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
