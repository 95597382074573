import GetIndicatorsFromAllLocationsGetters from './getters.js';
import GetIndicatorsFromAllLocationsMutations from './mutations.js';
import GetIndicatorsFromAllLocationsAction from './actions.js';

export default {
  namespaced: true,

  state: {
    indicatorsLocations: [],
    speciesLocation: []
  },
  getters: GetIndicatorsFromAllLocationsGetters,
  mutations: GetIndicatorsFromAllLocationsMutations,
  actions: GetIndicatorsFromAllLocationsAction
};
