<template>
  <div class="indicators">
    <div class="bg-w indicator">
      <!-- indicateur 1 -->
      <ChartAiguille
        :graphic_title="primary_title"
        :stat="primary_stat"
        :maxPosition="25"
        after_unit=" °C"
        :colors="colors"
        :colorsPositions="colorsPositions"
        br
      />
    </div>
    <div class="bg-w indicator">
      <!-- indicateur 2 -->
      <ChartAiguille
        :graphic_title="secondary_title"
        :stat="secondary_stat"
        :maxPosition="5"
        before_unit="Niv. "
        :colors="colors"
        :colorsPositions="colorsPositions"
        br
      />
    </div>
    <!-- <div class="bg-w indicator">
      indicateur 3
      <ChartAiguille
        :graphic_title="tertiary_title"
        :stat="tertiary_stat"
        :maxPosition="24"
        :colors="colors"
        :colorsPositions="colorsPositions"
      />
    </div> -->
  </div>
</template>

<script>
import ChartAiguille from '../ChartAiguille';
// import Api from '@/classes/api/Api';

export default {
  name: 'Indicator',
  components: {
    ChartAiguille
  },
  props: {
    primary_title: { type: String, default: '' },
    primary_stat: { type: Number, default: 0 },
    secondary_title: { type: String, default: '' },
    secondary_stat: { type: Number, default: 0 },
    tertiary_title: { type: String, default: '' },
    tertiary_stat: { type: [Number, null], default: 0 }
  },
  data() {
    return {
      colors: ['#36A139', '#8ACE46', '#F1B314', '#F17E14', '#C12937'],
      colorsPositions: [
        ['', 100],
        ['', 100],
        ['', 100],
        ['', 100],
        ['', 100]
      ]
    };
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
