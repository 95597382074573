<template>
  <header>
    <div class="wrap" :class="{ oneFr: oneFr }">
      <img
        v-if="notImg"
        class="imgHeader"
        :src="imgModal"
        alt="image modal action"
      />
      <div class="headerContent">
        <h1 class="title" :class="{ big: bigTitle }">
          {{ cellName
          }}<span class="subTitle" v-if="notSubTitle">{{ subTitle }} </span>
        </h1>
        <ul>
          <li v-if="notSilo && siloInfo != ''">
            <span class="silo">
              <img src="/img/icon/icone-item-silo.svg" alt="icone-item-silo" />

              {{ siloInfo }}</span
            >
          </li>
          <li
            v-if="
              disableInfo &&
                cellObject.current_volume != 0 &&
                cellObject &&
                cellObject.stored_species &&
                cellObject.stored_species !== null
            "
            class="grain"
          >
            <div
              class="grain-color"
              :style="{
                border: ` 1px solid ${cellObject.stored_species.color}`,
                'background-color': cellObject.stored_species.color
              }"
            ></div>
            <div
              v-if="
                cellObject &&
                  cellObject.stored_species &&
                  cellObject.stored_species === null &&
                  cellObject.stored_species != 0
              "
              class="background-empty"
            ></div>
            {{
              cellObject && cellObject.stored_species
                ? cellObject.stored_species.name
                : 'vide'
            }}
          </li>
          <li
            v-if="
              (cellObject && !cellObject.stored_species) ||
                (cellObject && cellObject.stored_species === null)
            "
          >
            Vide
          </li>
          <li
            v-if="
              disableInfo &&
                fanObject !== '' &&
                fanObject !== undefined &&
                cellObject.fan !== null
            "
          >
            <span class="vent">
              <img
                class="ambiant"
                v-if="
                  fanInfo && fanInfo.type === 'Air ambiant'
                    ? fanInfo.type === 'Air ambiant'
                    : ''
                "
                src="/img/icon/icone-item-ventilateur-ambiant.svg"
                :alt="fanInfo && fanInfo.name ? fanInfo.name : ''"
              />
              <img
                v-else
                class="groupeFroid"
                src="/img/icon/icone-item-ventilateur-groupe-froid.svg"
                :alt="fanInfo && fanInfo.name ? fanInfo.name : ''"
              />
            </span>

            <span>
              {{ fanInfo && fanInfo.name ? fanInfo.name : '' }}
              -
              {{ fanInfo && fanInfo.power ? fanInfo.power : '' }}
              kW
            </span>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
// import { mapState } from 'vuex';
// import Species from '@/classes/data/Species.js';
export default {
  name: 'BoxBase',

  props: {
    cellName: {
      type: String,
      default: null
    },
    cellObject: { type: Object },
    siloInfo: {
      type: String,
      default: null
    },
    fanObject: {
      type: [Object, String],
      default: () => {}
    },

    imgModal: {
      type: String,
      default: null
    },

    disableInfo: { type: Boolean, default: false },
    subTitle: { type: String, default: null },

    fieldBackground: { type: String },
    enableBackground: { type: Boolean, default: false },
    notSilo: { type: Boolean, default: true },
    notImg: { type: Boolean, default: true },
    notSubTitle: { type: Boolean, default: true },
    bigTitle: { type: Boolean, default: true },
    oneFr: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    fanInfo: {
      get() {
        return this.fanObject;
      },
      set(value) {
        this.$emit('update:fanObject', value);
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
