<template>
  <span class="userName">{{ name }}</span>
</template>

<script>
// import { toRefs } from 'vue';
export default {
  name: 'FullName',
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },

  computed: {
    name() {
      return `${this.firstName} ${this.lastName}  `;
    },
  },
  //   setup(props) {
  //     const name = toRef(props, 'name');
  //     return {
  //       name,
  //     };
  //   },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
