<template>
  <div class="Tabs">
    <ul class="TabsNav" v-if="Object.keys(tabs).length >= 1">
      <li
        class="TabsNavItem"
        :class="{ active: activeTab === key }"
        v-for="([key, value], i) in Object.entries(tabs)"
        :key="i"
        @click="() => setActiveTab(key)"
      >
        <span class="pagination-bullet" :data-svg="key">{{ value }}</span>
      </li>
    </ul>
    <!-- <ResponsiveList
      :items="tabs"
      v-model="activeTab"
      v-if="activeResponsiveList"
    /> -->
    <div class="TabsList">
      <div
        :class="[`Tab ${key}`, { active: activeTab === key }]"
        v-show="activeTab === key"
        v-for="(key, i) in Object.keys(tabs)"
        :key="i"
      >
        <slot :name="key"></slot>
      </div>
      <!-- <button
        class="swiper-button"
        role="button"
        aria-label="Next slide"
        @click="() => next(idxTab)"
      >
        <icon
          :icon="['fas', 'chevron-right']"
          size="1x"
          class="swiper-button-next "
          :class="{ 'swiper-button-disabled': arrowActiveNext }"
        />
      </button>
      <button
        class="swiper-button"
        role="button"
        aria-label="Previous slide"
        @click="() => prev(idxTab)"
      >
        <icon
          :icon="['fas', 'chevron-left']"
          size="1x"
          class="swiper-button-prev"
          :class="{
            'swiper-button-disabled': arrowActivePrev
          }"
        />
      </button> -->
    </div>
  </div>
</template>

<script>
// import ResponsiveList from '../ResponsiveList/index';

export default {
  name: 'Tabs',
  // components: { ResponsiveList },
  props: {
    tabs: { type: Object, required: true },
    active: { type: String, default: null },
    isActive: { type: Boolean, default: false }
  },
  data() {
    return {
      activeTab: this.active || Object.keys(this.tabs)[0],
      activeResponsiveList: false,
      // arrowActive: false
      index: 0,
      indexTab: ''
    };
  },
  watch: {
    active() {
      this.activeTab = this.active;
    }
  },
  methods: {
    setActiveTab(key) {
      if (key !== this.activeTab) {
        // this.$router.push({ query: { tab: key } });
        this.activeTab = key;
        this.$emit('change-tab', key);
      }
    },

    next() {
      if (this.index === Object.keys(this.tabs).length) {
        this.index = Object.keys(this.tabs).length - 1;
      } else {
        this.index++;
      }
    },

    prev() {
      if (this.index === 0) {
        this.index = Object.keys(this.tabs).length - 1;
      } else {
        this.index--;
      }
    }
  },
  computed: {
    arrowActiveNext() {
      const last = Object.keys(this.tabs).length - 1;
      return this.activeTab === Object.keys(this.tabs)[last]
        ? this.isActive === false
        : this.isActive === true;
    },
    arrowActivePrev() {
      return this.activeTab === Object.keys(this.tabs)[0]
        ? this.isActive === false
        : this.isActive === true;
    }
  }
  // mounted() {
  //   if (this.$route.query.tab) {
  //     this.activeTab = this.$route.query.tab;
  //   } else {
  //     this.$router.push({ query: { tab: this.active } });
  //   }
  //   if (Object.keys(this.tabs).length > 1) {
  //     this.activeResponsiveList = true;
  //   }
  // },
  // updated() {
  //   if (Object.keys(this.tabs).length > 1) {
  //     this.activeResponsiveList = true;
  //   }
  // }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
