import GetActionGrainGetters from './getters.js';
import GetActionGrainMutations from './mutations.js';
import GetActionGrainAction from './actions.js';

export default {
  namespaced: true,

  state: {
    actionGrain: []
  },
  getters: GetActionGrainGetters,
  mutations: GetActionGrainMutations,
  actions: GetActionGrainAction
};
