<template>
  <div>
    <AddModal
      :isOpen="isOpen"
      @update:isOpen="nv => $emit('update:isOpen', nv)"
      size="960"
      closeBtnCenter
      v-if="cellObject"
      name="synth"
      title="Synthèse cellule"
      cell_desk
      :silo="siloInfo"
      :cell="cellObject.name"
      :displaySendDataButton="false"
      noMarginBot
    >
      <q-tabs
        v-model="tab"
        dense
        class="tabs"
        active-color="dark"
        indicator-color="greenLink"
        align="justify"
        narrow-indicator
        inline-label
      >
        <div
          @click="tab = 'etat'"
          :class="[{ selecetedTab: tab === 'etat' }, 'tab']"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.25 12.125C3.25 12.5162 3.82625 13.1975 5.1625 13.8662C6.8925 14.7312 9.34625 15.25 12 15.25C14.6537 15.25 17.1075 14.7312 18.8375 13.8662C20.1737 13.1975 20.75 12.5162 20.75 12.125V9.41125C18.6875 10.6863 15.5337 11.5 12 11.5C8.46625 11.5 5.3125 10.685 3.25 9.41125V12.125ZM20.75 15.6613C18.6875 16.9363 15.5337 17.75 12 17.75C8.46625 17.75 5.3125 16.935 3.25 15.6613V18.375C3.25 18.7663 3.82625 19.4475 5.1625 20.1162C6.8925 20.9812 9.34625 21.5 12 21.5C14.6537 21.5 17.1075 20.9812 18.8375 20.1162C20.1737 19.4475 20.75 18.7663 20.75 18.375V15.6613ZM0.75 18.375V5.875C0.75 2.76875 5.7875 0.25 12 0.25C18.2125 0.25 23.25 2.76875 23.25 5.875V18.375C23.25 21.4813 18.2125 24 12 24C5.7875 24 0.75 21.4813 0.75 18.375ZM12 9C14.6537 9 17.1075 8.48125 18.8375 7.61625C20.1737 6.9475 20.75 6.26625 20.75 5.875C20.75 5.48375 20.1737 4.8025 18.8375 4.13375C17.1075 3.26875 14.6537 2.75 12 2.75C9.34625 2.75 6.8925 3.26875 5.1625 4.13375C3.82625 4.8025 3.25 5.48375 3.25 5.875C3.25 6.26625 3.82625 6.9475 5.1625 7.61625C6.8925 8.48125 9.34625 9 12 9Z"
            />
          </svg>
          <span>Etat de la cellule</span>
        </div>
        <div
          @click="tab = 'histo'"
          :class="[{ selecetedTab: tab === 'histo' }, 'tab']"
        >
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0C19.9037 0 25.5 5.59625 25.5 12.5C25.5 19.4037 19.9037 25 13 25C6.09625 25 0.5 19.4037 0.5 12.5H3C3 18.0225 7.4775 22.5 13 22.5C18.5225 22.5 23 18.0225 23 12.5C23 6.9775 18.5225 2.5 13 2.5C9.5625 2.5 6.53 4.23375 4.73125 6.875H8V9.375H0.5V1.875H3V5C5.28 1.9625 8.91125 0 13 0ZM14.25 6.25V11.9812L18.3037 16.035L16.535 17.8037L11.75 13.0163V6.25H14.25Z"
            />
          </svg>
          <span>Historique</span>
        </div>
        <div
          @click="tab = 'perf'"
          :class="[{ selecetedTab: tab === 'perf' }, 'tab']"
        >
          <svg
            width="25"
            height="23"
            viewBox="0 0 25 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.25 0.25V20.25H23.25V22.75H0.75V0.25H3.25ZM22.3662 4.36625L24.1338 6.13375L17 13.2675L13.25 9.51875L7.88375 14.8837L6.11625 13.1163L13.25 5.9825L17 9.73125L22.3662 4.36625Z"
            />
          </svg>
          <span>Performance</span>
        </div>
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="etat">
          <CellInformations
            v-if="cellObject"
            :cell="cellObject"
            :thermostat="cellThermostat"
            :firstFilling="firstFilling"
            :firstFillingTemps="firstFillingTemps"
            :dureesNormalise="dureesNormalise"
          />
        </q-tab-panel>

        <q-tab-panel name="histo">
          <template v-if="loading">
            <InnerLoader />
          </template>
          <div style="display: flex; width: 100%; justify-content: end;">
            <q-btn v-if="showCancelLastInput"
              outline
              :disable="!showCancelLastInput"
              style="color: #C12937; font-weight: 600; border-radius: 30px;"
              label="Annuler la dernière saisie"
              @click="trashLastByCell()"
            />
          </div>
          <template v-if="showCancelLastInput">
          <template v-for="(lot, index) in lots" :key="index">
            <div v-if="lot[0]">
              <div style="float:left;">
                <q-toggle color="green" v-model="lot.visible" keep-color />
              </div>
              <div>
                Lot n°{{ lots.length - index }}
                <b
                  v-if="
                    orderGlobal == 'du plus récent au plus ancien'
                      ? lots[index][lots[index].length - 1].volume > 0
                      : lots[index][0].volume > 0
                  "
                >
                  en cours</b
                >
                  <span style="margin-left: 30px; font-weight: bold;">
                    {{
                      orderGlobal == 'du plus récent au plus ancien'
                        ? lots[index][lots[index].length - 1].new_species || lots[index][lots[index].length - 1].species
                        : lots[index][0].new_species || lots[index][0].species
                    }}
                  </span>
                  <span style="margin-left: 10px; font-weight: bold;">
                    {{
                      orderGlobal == 'du plus récent au plus ancien'
                        ? lots[index][lots[index].length - 1].new_sub_species || lots[index][lots[index].length - 1].subspecies
                        : lots[index][0].new_sub_species || lots[index][0].subspecies
                    }}
                  </span>
              </div>
              <div style="display:flex;justify-content: space-between;">
                <span
                  v-if="
                    orderGlobal == 'du plus récent au plus ancien'
                      ? lots[index][lots[index].length - 1].volume === 0
                      : lots[index][0].volume === 0
                  "
                >
                  Stocké du
                  {{
                    orderGlobal == 'du plus récent au plus ancien'
                      ? datejs(lots[index][0].date)
                      : datejs(lots[index][lots[index].length - 1].date)
                  }}
                  au
                  {{
                    orderGlobal == 'du plus récent au plus ancien'
                      ? datejs(lots[index][lots[index].length - 1].date)
                      : datejs(lots[index][0].date)
                  }} </span
                ><span v-else
                  >Début de stockage le
                  {{
                    orderGlobal == 'du plus récent au plus ancien'
                      ? datejs(lots[index][0].date)
                      : datejs(lots[index][lots[index].length - 1].date)
                  }}</span
                >
                <span> {{ getHeuresVentil(lot) }} h de ventilation </span>
                <div>
                  <q-select
                    v-if="index == 0"
                    class="inputContainerOption"
                    outlined
                    v-model="lot.order"
                    :options="orders"
                    @update:model-value="changeOrder(lot)"
                    color="green"
                    bg-color="white"
                  />
                </div>
              </div>
            </div>
            <table v-if="lot.visible && lot[0]" :id="'table-' + index" class="table table-responsive table-striped">
              <thead class="thead-dark">
                <tr>
                  <th
                    align="left"
                    v-for="(column, indexCol) in columns"
                    v-bind:key="column.name"
                    @click="order(column.sortName, column.sortType)"
                    :style="indexCol == 0 ? 'min-width: 110px;max-width: 110px;' : indexCol == columns.length -1 ? 'padding:0px;' : ''"
                  >
                    <svg
                      v-if="column.sortIcon"
                      :class="sortType === column.sortName ? orderType : ''"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.83334 10.4763L12.3033 6.00634L13.4817 7.18467L7.00001 13.6663L0.518341 7.18467L1.69667 6.00634L6.16667 10.4763V0.333008H7.83334V10.4763Z"
                        fill="white"
                      />
                    </svg>
                    <span v-if="column.icon == null">{{ column.name }}</span
                    ><span v-html="column.icon"></span>
                  </th>
                </tr>
              </thead>
              <tbody class="table-striped">
                <tr v-for="action in lot" :key="action.id">
                  <td>
                    <!-- Le cell_infos provient directement de la procédure, et dans la mesure où je ne sait pas trop si c'est utilisé ailleurs avec ce nom, je rename dans la vue, et non dans la procédure -->
                    {{ capitalizeFirstLetter(
                      action.type === 'cell_infos' ? 'Infos Cellule' : action.type
                    ) }}<br />
                    {{ action.volume }}
                    {{ action.type !== 'cell_infos' ? ' tonnes' : '' }}
                    <br />{{ datejs(action.date) }}
                  </td>
                  <td>{{ action.type !== 'vidange' && action.type !== 'transilage' ? action.Tmax : '' }}</td>
                  <td>
                    {{ action.type !== 'vidange' && action.type !== 'transilage' ? action.type !== 'remplissage' ? action.T2 : action.T1 : '' }}
                  </td>
                  <td>{{ action.type !== 'vidange' && action.type !== 'transilage' ? action.Tmin : '' }}</td>
                  <td>
                    {{
                      isNaN(parseFloat(action.diffT).toFixed(2))
                        ? ''
                        : parseFloat(action.diffT).toFixed(2)
                    }}
                  </td>
                  <td>{{ action.Thermostat }}</td>
                  <td>{{ action.fan_name }}</td>
                  <td>
                    {{ action.ventilation_time ? action.ventilation_time : 0 }}
                    h
                  </td>
                  <td>
                    {{
                      action.ventilation_time_cumul
                        ? action.ventilation_time_cumul
                        : 0
                    }}
                    h
                  </td>
                  <td>
                    {{
                      action.risk[0] && action.risk[0] != 0
                        ? Math.ceil(action.risk[0] / (400 - 1))
                        : '1'
                    }}
                  </td>
                  <td> {{ action.PE || '-'}}</td>
                  <td style='padding:0px;' v-if="action.actions_name != null">
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99935 16.8332C4.39685 16.8332 0.666016 13.1023 0.666016 8.49984C0.666016 3.89734 4.39685 0.166504 8.99935 0.166504C13.6018 0.166504 17.3327 3.89734 17.3327 8.49984C17.3327 13.1023 13.6018 16.8332 8.99935 16.8332ZM8.99935 15.1665C10.7675 15.1665 12.4632 14.4641 13.7134 13.2139C14.9636 11.9636 15.666 10.2679 15.666 8.49984C15.666 6.73173 14.9636 5.03604 13.7134 3.78579C12.4632 2.53555 10.7675 1.83317 8.99935 1.83317C7.23124 1.83317 5.53555 2.53555 4.2853 3.78579C3.03506 5.03604 2.33268 6.73173 2.33268 8.49984C2.33268 10.2679 3.03506 11.9636 4.2853 13.2139C5.53555 14.4641 7.23124 15.1665 8.99935 15.1665ZM8.16602 4.33317H9.83268V5.99984H8.16602V4.33317ZM8.16602 7.6665H9.83268V12.6665H8.16602V7.6665Z"
                        fill="#36A139"
                      />
                    </svg>
                    <q-tooltip>
                      {{ action.actions_name ? action.actions_name + (action.actions_description ? ' - ' + action.actions_description : '') :  (action.actions_description ? action.actions_description : '') }}
                    </q-tooltip>
                  </td>
                  <td v-else-if="action.actions_name == null && action.actions_description">
                                        <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99935 16.8332C4.39685 16.8332 0.666016 13.1023 0.666016 8.49984C0.666016 3.89734 4.39685 0.166504 8.99935 0.166504C13.6018 0.166504 17.3327 3.89734 17.3327 8.49984C17.3327 13.1023 13.6018 16.8332 8.99935 16.8332ZM8.99935 15.1665C10.7675 15.1665 12.4632 14.4641 13.7134 13.2139C14.9636 11.9636 15.666 10.2679 15.666 8.49984C15.666 6.73173 14.9636 5.03604 13.7134 3.78579C12.4632 2.53555 10.7675 1.83317 8.99935 1.83317C7.23124 1.83317 5.53555 2.53555 4.2853 3.78579C3.03506 5.03604 2.33268 6.73173 2.33268 8.49984C2.33268 10.2679 3.03506 11.9636 4.2853 13.2139C5.53555 14.4641 7.23124 15.1665 8.99935 15.1665ZM8.16602 4.33317H9.83268V5.99984H8.16602V4.33317ZM8.16602 7.6665H9.83268V12.6665H8.16602V7.6665Z"
                        fill="#36A139"
                      />
                    </svg>
                    <q-tooltip>
                      {{ action.actions_description }}
                    </q-tooltip>
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </table>
            <br />
          </template>
          </template>
          <template v-else>
            Aucun historique n'est disponible pour cette cellule.
          </template>
        </q-tab-panel>

        <q-tab-panel name="perf" style="height:750px;">
          <div class="wrap2">
            <div v-if="actionsListGraph">
              <b>Evolution des températures</b>
              <Chart :cellInput="filteredGraph" />
              <br />
              <b>Performance énergétique de la ventilation (Wh/Tonnes/°C)</b>
              <br />
              <br />
              <PerfChart :cellInput="filteredGraph" />
              <!-- </div> -->
            </div>
            <div v-else>
              {{ cellObject.cell_ventilation }}
              <p>
                Cette cellule ne comporte aucune saisie de données de
                ventilation.
              </p>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </AddModal>
    <TrashModal
      :isOpen="trashModal"
      @update:isOpen="nv => trashModal = nv"
      :cellName="cellName"
      :cellId="cellObject.id"
      :lastSaisieType="lastSaisieType"
      checkLastSaisie
      :nameModal="'trashLast'"
      textOne="Vous allez annuler la derniere saisie sur"
      textTwo="à la corbeille."
      @trashAction="delLastCell($event)"
      btnName="Annuler la dernière saisie"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import 'dayjs/locale/fr';
import Api from '@/classes/api/Api';
import Chart from '@/components/atoms/Chart/Performance';
import PerfChart from '@/components/atoms/Chart/Perf';
import dayjs from 'dayjs';
import { ref } from 'vue';
import AddModal from '@/components/atoms/Modal';
import TrashModal from '@/components/molecules/TrashModal';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import SortingUtils from '@/classes/utils/SortingUtils';
import { useQuasar } from 'quasar';
import { mapGetters } from 'vuex';
import CellInformations from '@/components/atoms/CellInformations';


export default {
  name: 'SynthModal',
  props: {
    isOpen: { type: Boolean, default: false },
    siloInfo: {
      type: String
    },
    cellName: {
      type: String
    },
    saisiesList: {
      type: Object
    },
    cellObject: {
      type: Object,
      default() {
        return {};
      }
    },
    fanObject: {
      type: Object
    },
    dureesNormalise: {
      type: Object
    }
  },
  components: {
    Chart,
    AddModal,
    PerfChart,
    TrashModal,
    CellInformations
  },
  data() {
    return {
      loading: false,
      trashModal: false,
      firstFilling: {},
      cellThermostat: ref(null),
      tab: ref('etat'),
      newGen: '',
      popInfo: false,
      specieValue: '',
      arrowCounter: -1,
      showList: false,
      specieNameValue: '',
      speciesFiltered: [],
      speciesList: [],
      lots: [],
      indexTable: 0,
      orders: ['du plus ancien au plus récent','du plus récent au plus ancien'],
      orderHistory: 'du plus ancien au plus récent',
      orderGlobal: 'du plus ancien au plus récent',
      isLoading: false,
      // commentaireFillingsDetail: null,
      modalStore: ModalStore,
      isErrorMsg: false,
      currentTab: 'etat',
      showTrash: false,
      actionsList: [],
      actionsListDeleted: [],
      actionsListGraph: [],
      tabs: {
        etat: 'État',
        saisies: 'Saisies',
        performance: 'Performance'
      },
      time: {
        c1: 80,
        c2: 120
      },
      nameTab: '',
      columns: [
        { name: 'Action', sortFn: SortingUtils.compareString('type') },
        {
          name: '°C Max',
          sortFn: SortingUtils.compareNumber('Tmax')
        },
        {
          name: '°C Moy',
          sortFn: SortingUtils.compareString('diffT')
        },
        { name: '°C Min', sortFn: SortingUtils.compareDate('Tmin') },
        {
          name: '°C gagnés',
          sortFn: SortingUtils.compareNumber('diffT')
        },
        {
          name: 'Thermostat',
          sortFn: SortingUtils.compareNumber('Thermostat')
        },
        { name: 'Ventilateur', sortFn: SortingUtils.compareNumber('fan_name') },
        {
          name: 'Durée',
          sortFn: SortingUtils.compareNumber('ventilation_time')
        },
        {
          name: 'Cumul',
          sortFn: SortingUtils.compareNumber('ventilation_time_cumul')
        },
        {
          name: 'Espèce',
          icon:
            '<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.04602 5.4165C4.09914 5.33176 4.15444 5.2484 4.21185 5.1665H13.7868C13.8443 5.24817 13.8994 5.33234 13.9527 5.4165L15.636 4.44484L16.4693 5.88817L14.6252 6.95317C14.7602 7.44484 14.8327 7.964 14.8327 8.49984V9.33317H17.3327V10.9998H14.8327C14.8327 11.794 14.6743 12.5515 14.386 13.2415L16.4693 14.4448L15.636 15.8882L13.531 14.6732C12.607 15.8145 11.2861 16.5648 9.83268 16.774V10.1665H8.16602V16.7748C6.71243 16.5654 5.39155 15.8148 4.46768 14.6732L2.36268 15.8882L1.52935 14.4448L3.61268 13.2423C3.31687 12.5318 3.16505 11.7695 3.16602 10.9998H0.666016V9.33317H3.16602V8.49984C3.16602 7.964 3.23852 7.44567 3.37352 6.95317L1.52935 5.88817L2.36268 4.44484L4.04602 5.4165ZM5.66602 3.49984C5.66602 2.61578 6.01721 1.76794 6.64233 1.14281C7.26745 0.517693 8.11529 0.166504 8.99935 0.166504C9.8834 0.166504 10.7313 0.517693 11.3564 1.14281C11.9815 1.76794 12.3327 2.61578 12.3327 3.49984H5.66602Z" fill="#2F2F2F"/></svg>',
          sortFn: SortingUtils.compareNumber('3')
        },
        {
          name: 'Electricité',
          icon:
            '<svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.83398 7.8335H13.6673L6.16732 18.6668V11.1668H0.333984L7.83398 0.333496V7.8335Z" fill="#2F2F2F"/></svg>',
          sortFn: SortingUtils.compareString('12')
        },
        { name: '' }
      ],
      sorting: {
        name: null,
        type: 'sort'
      },
      lastSaisieType: null,
      showCancelLastInput: false
    };
  },
  computed: {
    ...mapGetters('listFillings', ['GET_FILLINGS']),
    ...mapGetters('inputLocation', [
      'GET_INPUT_CELL_LOCATION',
      'GET_INPUT_CELL_LOCATION_SPECIES_LIST'
    ]),
    filteredGraph() {
      const dates_list = this.lots.filter(v => v.visible).map(lot => {
        return this.orderGlobal === 'du plus récent au plus ancien' ? [lot[0].date, lot[lot.length - 1].date] : [lot[lot.length - 1].date, lot[0].date]
      }).reverse()

      console.log(this.actionsListGraph);

      return this.actionsListGraph.filter(action => {
        let isIn = false;
        const action_date = Date.parse(action.date);

        dates_list.forEach(dates => {
          const start = Date.parse(dates[0]);
          const end = Date.parse(dates[1]);
          
          if (action_date >= start && action_date <= end) {
            isIn = true
          }
        })

        return isIn;
      })
    },
    firstFillingTemps() {
      if (this.cellObject && this.cellObject.fillings && this.cellObject.fillings[0]) {
        let firstFilling = JSON.parse(JSON.stringify(this.cellObject.fillings)).sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        }).filter((value, index, array) => {
          return value.date === array[array.length - 1].date
        });
        if (firstFilling.length > 1) {
          console.log(firstFilling.sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          })[0])
          return firstFilling.sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          })[firstFilling.length - 1];
        } else {
          return firstFilling[0];
        }
      }
      return {};
    },
    dureeFromCell() {
      return this.dureesNormalise.dureeCumul && this.dureesNormalise.duree
        ? this.dureesNormalise.dureeCumul[0].duree_cumul_from_cell >
          this.dureesNormalise.duree[0].duree_from_cell
          ? this.dureesNormalise.dureeCumul[0].duree_cumul_from_cell
          : this.dureesNormalise.duree[0].duree_from_cell
        : 0;
    },
    moyenne() {
      let result = 0;
      let total = 0;
      const nb = this.nbVentilationTotal();
      if (this.cellObject.cell_ventilation != null) {
        this.cellObject['cell_ventilation'].forEach(element => {
          result += element.time;
          total = result / nb;
        });
        return total.toFixed(1);
      } else {
        return 0;
      }
    },
    numberOfCellVentilation() {
      let hour_ventilation_tot = 0;
      if (this.cellObject.cell_ventilation != null) {
        this.cellObject['cell_ventilation'].forEach(vent => {
          hour_ventilation_tot += vent.time;
        });
        return hour_ventilation_tot;
      } else {
        return 0;
      }
    },
    maximumTime() {
      let maxTime = 0;
      let res = [];
      if (this.cellObject.cell_ventilation != null) {
        this.cellObject['cell_ventilation'].map(time => {
          res.push(time.time);
          maxTime = Math.max(...res);
        });
        return maxTime;
      } else {
        return 0;
      }
    },
    heightBar() {
      let totalHeight = 0;
      let res = 100 / this.maximumTime;
      totalHeight = res;
      return Math.round(totalHeight * 100) / 100;
    },
    filteradtype() {
      return _.uniqBy(this.speciesFiltered, function(u) {
        return u.species;
      });
    }
  },
  mounted() {
    if (document.getElementById('modal')) {
      document
        .getElementById('modal')
        .addEventListener('click', this.handleClickOutside);
    }
    if (this.cellObject.id) {
      this.actionsList = this.actionsListDeleted = [];
      Api.getCellInput(this.cellObject.id).then(data => {
        this.actionsList = data.data;

        this.addCumulVentilationTime();

        Api.getCellLots(this.cellObject.id).then(data => {
          // data.data est un array d'array
          // On va donc checker si on en a au minimum 1 et si oui, on check sa longueur
          if (data.data.length > 0) {
            this.showCancelLastInput = true
          }
          this.lots = data.data;
          this.lots.forEach((value) => {
            value.visible = true;
            value.order = 'du plus ancien au plus récent';
          });
        });

        Api.getCellDeletedInput(this.cellObject.id).then(data => {
          this.actionsListDeleted = data.data;
          this.filteredBySpeciesName();
          this.actionsListGraph = this.actionsList.concat(
            this.actionsListDeleted
          );
          let filling = this.actionsList
            .filter(value => {
              return (
                value.type === 'transilage' || value.type === 'remplissage'
              );
            })
            .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
            .filter((value, index, array) => {
              return value.date === array[array.length - 1].date;
            })
            .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            filling.forEach((fill, index) => {
              if(fill.actions_description != null && fill.actions_description.startsWith("A destination")) {
                filling.splice(index);
              }
            });
          this.firstFilling = filling[filling.length - 1];
          let thermostat = this.actionsListGraph
            .filter(value => {
              return (
                value.type === 'ventilation' ||
                value.type === 'ventilation - remplissage' ||
                value.type === 'ventilation - vidange'
              );
            })
            .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
            .filter((value, index, array) => {
              return value.date === array[0].date;
            });
          if (thermostat != null && thermostat.length > 1) {
            thermostat = thermostat.sort(
              (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
            );
          }
          this.cellThermostat = thermostat && thermostat.length > 0 ? thermostat[0].Thermostat : null;
        });
      });
    }
    this.initSynthModal();
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  unmounted() {
    this.actionsList = [];
    if (
      document.removeEventListener('click', this.handleClickOutside) != null
    ) {
      document
        .getElementById('modal')
        .removeEventListener('click', this.handleClickOutside);
    }
  },
  updated() {
    this.newGen = Math.floor(
      (Math.ceil(this.cellObject.cjours / (400 - 1)) * 400 -
        this.cellObject.cjours) /
        (this.cellObject.tmi_max - 14 < 0 ? 0 : this.cellObject.tmi_max - 14)
    );
  },
  watch: {
    dureesNormalise() {
      this.addCumulVentilationTime();
    }
  },
  methods: {
    initSynthModal() {
      if (this.cellObject.id) {
        this.actionsList = this.actionsListDeleted = [];
        Api.getCellInput(this.cellObject.id).then(data => {
          this.actionsList = data.data;

          this.addCumulVentilationTime();

          Api.getCellDeletedInput(this.cellObject.id).then(data => {
          this.actionsListDeleted = data.data;
          this.filteredBySpeciesName();
          this.actionsListGraph = this.actionsList.concat(
            this.actionsListDeleted
          );
          let filling = this.actionsList
            .filter(value => {
              return (
                value.type === 'transilage' || value.type === 'remplissage'
              );
            })
            .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
            .filter((value, index, array) => {
              return value.date === array[array.length - 1].date;
            })
            .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
            filling.forEach((fill, index) => {
              if(fill.actions_description != null && fill.actions_description.startsWith("A destination")) {
                filling.splice(index);
              }
            });
            this.firstFilling = filling;
            let thermostat = this.actionsListGraph
              .filter(value => {
                return (
                  value.type === 'ventilation' ||
                  value.type === 'ventilation - remplissage' ||
                  value.type === 'ventilation - vidange'
                );
              })
              .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
              .filter((value, index, array) => {
                return value.date === array[0].date;
              });
            if (thermostat.length > 1) {
              thermostat = thermostat.sort(
                (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
              );
            }
            this.cellThermostat = thermostat[0] ? thermostat[0].Thermostat : null;
          });
        });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    gainDegrees(cell) {
      if (
        cell.fillings &&
        cell.fillings.length > 0 &&
        cell.cell_ventilation &&
        cell.cell_ventilation.length > 0
      ) {
        let degreesGain = 0.0;
        for (let ventilation of cell.cell_ventilation) {
          degreesGain +=
            ventilation.temperature_after - ventilation.temperature_before;
        }
        return ((-1 * (degreesGain * 100)) / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    addCumulVentilationTime() {
      for (let i = 0; i < Object.keys(this.actionsList).length; i++) {
        let dureeVentilation =
          this.dureesNormalise.dureeCumul &&
          this.dureesNormalise.dureeCumul.length > 0
            ? this.dureeFromCell
            : 0;
        for (let [key, value] of Object.entries(this.actionsList)) {
          if (key < i) {
            dureeVentilation -= value.ventilation_time;
          } else {
            break;
          }
        }
        this.actionsList[i].ventilation_time_cumul = dureeVentilation;
      }
    },
    trashLastByCell() {
      Api.getLastSaisieByCell(this.cellObject.id).then(({ data }) => {
        if (
          data.success === false &&
          data.message === 'Cette cellule ne dispose pas de saisies'
        ) {
          this.$toast.error(data.message);
        } else {
          this.lastSaisieType = data;
          // console.log(this.lastSaisieType);
          // this.modalStore.showModal('trashLast');
          this.trashModal = true;
        }
      });
    },
    changeOrder(lot) {
      console.log(lot);
      this.orderGlobal = lot.order;
      this.lots.forEach((lot) => {
 if(this.orderGlobal == "du plus récent au plus ancien") {
      lot.sort((a, b) => {
            return new Date(a.date) - new Date(b.date) === 0 ? new Date(a.created_at) - new Date(b.created_at) : new Date(a.date) - new Date(b.date);
        });
      } else {
        lot.sort((a, b) => {
            return new Date(b.date) - new Date(a.date) === 0 ? new Date(b.created_at) - new Date(a.created_at) : new Date(b.date) - new Date(a.date);
        });
      }
      })
      this.$forceUpdate();
    },
    getHeuresVentil(lot) {
      var nbr_heures = 0;
      lot.forEach((value) => {
        if(value.ventilation_time != null && value.deleted == 0) {
          nbr_heures += value.ventilation_time;
        }
      });
      return nbr_heures;
    },
    hideTable(event, index) {
      console.log(event);
      console.log(index);
    },
    delLastCell() {
      Api.deleteLast(this.cellObject.id)
        .then(({ data }) => {
          if (data) {
            // this.modalStore.hideModal('trashLast');
            this.trashModal = false;
            this.loading = true;
            Api.getCellLots(this.cellObject.id).then(data => {
              this.lots = data.data;
              this.lots.forEach((value) => {
                value.visible = true;
                value.order = 'du plus ancien au plus récent';
              });
              this.loading = false;
            }).catch((value) => {
              console.log(value)
              this.loading = false;
            });
            
            this.$q.notify({
              message: data.message,
              color: 'positive'
            });
            this.initSynthModal();
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.$store.dispatch('indicator/loadIndicatorLocation');
            this.$store.dispatch('silos/loadSilo');
            this.$store.dispatch('listFillings/loadFillings');
            this.actionsList = this.actionsListDeleted = [];
            Api.getCellInput(this.cellObject.id).then(data => {
              this.actionsList = data.data;
              Api.getCellDeletedInput(this.cellObject.id).then(data => {
                this.actionsListDeleted = data.data;
                this.filteredBySpeciesName();
              });
            });
            this.$emit('need-refresh');
          }
        })
        .catch(error => {
          this.$q.notify({
            message: error.response.data.message,
            color: 'negative'
          });
        });
    },
    listActionGrainEtat(grain) {
      if (grain == null) {
        return (grain = '');
      }
      return grain.name;
    },
    listActionGrain(action) {
      if (action) {
        const describe = action.split(',');
        return describe;
      }
      return action;
    },
    filterInfiGrainDesinsectisation(val) {
      // const arr = this.speciesFiltered.filter(action => {

      if (val.type === 'remplissage' && val.actions_name) {
        const text = 'Lot désinsectisé (date, produit, dose)';
        const describe = val.actions_name.split(
          'Lot désinsectisé (date, produit, dose) description: '
        );

        const remplaceTextRemplissage = val.actions_name.replace(
          text,
          `Lot désinsectisé (${describe}),`
        );
        return remplaceTextRemplissage;
      }
      if (val.actions_description != null && val.actions_name != null) {
        const text = 'Désinsectisation (date, dose, produit)';
        const remplaceText = val.actions_name.replace(
          text,
          `Désinsectisation (${val.actions_description})`
        );

        return remplaceText;
      } else {
        return '';
      }
    },
    compare(a, b, sort) {
      let bandA, bandB;
      if (a.type == 'remplissage') {
        bandA = a.T1;
      } else {
        bandA = a.T2;
      }
      if (b.type == 'remplissage') {
        bandB = b.T1;
      } else {
        bandB = b.T2;
      }

      let comparison = 0;
      if (sort) {
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
      } else {
        if (bandA > bandB) {
          comparison = -1;
        } else if (bandA < bandB) {
          comparison = 1;
        }
      }

      return comparison;
    },
    handleClickOutside(event) {
      if (event.target.id == 'selectspecies') {
        this.showList = !this.showList;
      } else {
        this.showList = false;
      }
    },
    statusTab(evt) {
      this.nameTab = evt;
      return this.nameTab;
    },
    heightBarMax(max) {
      let totalHeightMax = 0;
      let int = max - 120;
      let res = max - 100;
      totalHeightMax = max - res - int;
      return totalHeightMax;
    },
    nbVentilationTotal() {
      if (
        this.cellObject['cell_ventilation'] != null &&
        this.cellObject['cell_ventilation'] != 'undefined'
      ) {
        return this.cellObject['cell_ventilation'].length;
      } else {
        return 0;
      }
    },
    firstEmptyingByDate(arrays) {
      return _.orderBy(arrays, 'date', 'asc');
    },

    datejs(date) {
      return dayjs(date)
        .locale('fr')
        .format('DD/MM/YY ');
    },
    monthjs(date) {
      return dayjs(date)
        .locale('fr')
        .format('MMMM');
    },

    filteredBySort(key) {
      const { type, name } = this.sorting;
      if (!name || type === 'sort') {
        this.speciesFiltered;
      }
      // 6 correspond au tmoy, a changer si demain cela vient à se déplacer
      if (key == '6') {
        this.speciesFiltered = this.speciesFiltered
          .slice()
          .sort((a, b) => this.compare(a, b, type === 'sort-desc'));
      } else {
        this.speciesFiltered = this.speciesFiltered
          .slice()
          .sort((a, b) => this.columns[key].sortFn(a, b, type === 'sort-desc'));
      }
    },
    getSortType(key) {
      if (this.sorting.name && this.sorting.name === key) {
        return this.sorting.type || 'sort';
      }
      return 'sort';
    },
    sortBy(key) {
      this.filteredBySort(key);
      const sortType = this.getSortType(key);
      this.sorting = {
        name: key,
        type: `sort-${
          sortType === 'sort' || sortType === 'sort-desc' ? 'asc' : 'desc'
        }`
      };
    },
    isSortDisabled(key) {
      return !this.columns[key].sortFn;
    },
    // filter species
    filteredBySpecie(specie) {
      this.specieValue = specie;

      //  filter list if species equal species
      const listItems = this.speciesFiltered.filter(
        f => f.species == this.specieValue.species
      );

      if (this.specieValue.id != 0 && this.specieValue != '') {
        this.speciesFiltered = listItems;
        this.showList = false;
        this.specieNameValue = this.specieValue.species;
      } else {
        this.speciesFiltered = this.actionsList;
      }

      // reset list and display all species
      if (this.specieValue.id === 0) {
        this.speciesFiltered = this.actionsList;
        this.showList = false;
        this.specieNameValue = '';
        this.specieValue = '';
      }
    },

    filteredBySpeciesName() {
      this.speciesList = this.actionsList.concat(this.actionsListDeleted);
      this.speciesFiltered = this.speciesList;

      this.$emit('input', this.specieNameValue);

      if (this.specieNameValue != '') {
        this.showList = true;
      } else {
        this.showList = false;
      }

      this.speciesFiltered = this.speciesList.filter(item => {
        if (item.type == 'cell_infos') {
          item.species = '';
        }
        return item.species
          .toLowerCase()
          .includes(this.specieNameValue.toLowerCase());
      });

      if (this.specieValue.id == 0 && this.specieNameValue == '') {
        this.specieValue = '';
        this.showList = false;
        this.speciesFiltered = this.speciesList;
      }
    },
    sortedSpecieByName(arrays) {
      return _.orderBy(arrays, 'species', 'asc');
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
