import Api from '@/classes/api/Api';

export default {
  async loadSpecies({ commit }) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'));
    let role = user.role_id === 2 ? 'operator' : 'manager';
    await Api.getStoredSpecies(role)
      .then(({ data }) => {
        if (data) {
          commit('GET_SPECIES', data);
          // console.log('GET_SPECIES', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  async loadSpeciesByLocation({ commit }) {
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const user =
      rememberMe === false
        ? JSON.parse(sessionStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'));
    let role = user.role_id === 2 ? 'operator' : 'manager';
    await Api.getStoredSpeciesByLocation(role)
      .then(({ data }) => {
        if (data) {
          commit('GET_SPECIES_BY_LOCATION', data);
          commit(
            'GET_SPECIES_BY_LOCATION_NAME',
            data.map(e => e.stored_species)
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  async loadNewSpeciesByLocation({ commit }) {
    await Api.getNewStoredSpeciesByLocation()
      .then(({ data }) => {
        if (data) {
          commit('GET_NEW_SPECIES_BY_LOCATION', data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
