// import LoadingGetters from './getters.js';
import LoadingMutations from './mutations.js';
import LoadingAction from './actions.js';

export default {
  namespaced: true,

  state: { isLoading: true },
  // getters: LoadingGetters,
  mutations: LoadingMutations,
  action: LoadingAction
};
