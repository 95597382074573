<template>
  <div class="mesSilos">
    <template v-if="loading">
      <InnerLoader />
    </template>
    <template v-else>
      <div class="bg-w">
        <div
          style="display: flex; flex-direction: column; justify-content: space-between; align-items: flex-end;"
        >
          <MenuBox
            v-if="is_impersonate && user && env === 'development'"
            style="display: block;"
            :items="addFanAndCell"
            headerMenuColor
            icon
            nameIcon="plus"
            :titleMenu="user.location_group[0].location.name"
            subTitleMenu=""
            responsive
          >
            <template v-slot="obj">
              <div
                class="divPadding "
                @click="obj.item.name === 'silo' ? openAddSilo = true : obj.item.name === 'cell' ? openAddCell = true : obj.item.name === 'fan' ? openAddFan = true : ''"
                :class="{
                  withBorder: obj.item.name === 'multicell',
                  disable:
                    // Object.keys(value.fan).length <= 0 &&
                    // obj.item.name === 'cell'
                    //  ? isActive
                    //  : ''
                    false
                }"
              >
                {{ obj.item.label }}
              </div>
            </template>
          </MenuBox>

          <BtnAdd
            title="Ventilation multi-cellules"
            textBtn="Ventilation multi-cellules"
            styleBtn="link"
            colorBtn="white"
            class="contentBtn"
            img
            imgSrc="/img/icon/multivent_icon.svg"
            size="sm"
            iconCircle="warning"
            @click="showModalMultiVentilationCellsFan()"
          />
        </div>

        <table class="table table-responsive table-striped">
          <thead class="thead-dark">
            <tr>
              <th
                align="left"
                v-for="column in columns"
                v-bind:key="column.value"
                @click="order(column.sortName, column.sortType)"
              >
                <svg
                  v-if="column.sortIcon"
                  :class="sortType === column.sortName ? orderType : ''"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.83334 10.4763L12.3033 6.00634L13.4817 7.18467L7.00001 13.6663L0.518341 7.18467L1.69667 6.00634L6.16667 10.4763V0.333008H7.83334V10.4763Z"
                    fill="white"
                  />
                </svg>
                {{ column.value }}
              </th>
            </tr>
          </thead>
          <!--<tfoot class="tfoot-dark">-->
            <!-- PAGINATION -->
            <!--<tr>
              <td colspan="11">
                <div class="pagination">
                  <span
                    :class="[`pages`, { selected: page === currentPage }]"
                    @click="changePage(page)"
                    v-for="page in pages"
                    v-bind:key="page"
                    >{{ page }}</span
                  >
                </div>
              </td>
            </tr>
          </tfoot>-->
          <tbody class="table-striped">
            <tr v-for="c in sworkedCells" :key="c.id">
              <!-- <td>
                <span
                  class="grain-color"
                  v-if="c.stored_species"
                  :style="{
                    border: ` 1px solid ${c.stored_species.color}`,
                    'background-color': c.stored_species.color
                  }"
                ></span>
                <div
                  v-if="c.stored_species === null && c.current_volume == 0"
                  class="background-empty"
                ></div>
              </td> -->
              <td>
                {{ c.siloName }}
              </td>
              <td>{{ c.cellName }}</td>
              <td>{{ c.current_volume }}t</td>
              <td>{{ c?.species?.name || c.stored_species_name }}</td>
              <td>{{ c?.sub_species?.name || c.subspecies }}</td>
              <td>{{ c.tmi_max == '0' ? '' : c.tmi_max }}</td>
              <td>{{ c.tmi == '0' ? '' : c.tmi }}</td>
              <td>{{ c.tmi_min == '0' ? '' : c.tmi_min }}</td>
              <td>{{ c.dureeCumul == null ? '' : c.dureeCumul }}</td>
              <td>{{ c.lastSaisie }}</td>
              <td
                class="color_gen"
                :style="`--genColor: ${riskColor(c.cjours)}`"
              >
                {{
                  c.cjours && c.cjours != 0
                    ? Math.ceil(c.cjours / (400 - 1))
                    : '1'
                }}
              </td>
              <td>
                <MenuBox
                  :items="menuCellElement"
                  ellipsis
                  cells
                  headerMenuColor
                  :titleMenu="`${c.siloName} - ${c.cellName} - ${c.volume} t`"
                  :cellId="c.id"
                  subTitleMenu="cellule"
                >
                  <template v-slot="obj">
                    <div
                      class="divPadding"
                      @click="
                        obj.item.name === 'synth'
                          ? modalSynthOpen(c.silo.name, c.cellName, c)
                          : obj.item.name === 'filling' &&
                            parseInt((c.volume * 30) / 100 + c.volume) -
                              c.current_volume >
                              0
                          ? modalFillingOpen(
                              c.silo.name,
                              c.cellName,
                              c.id,
                              c.volume,
                              c
                            )
                          : obj.item.name === 'ventilation' &&
                            c.current_volume != 0
                          ? showModalMultiVentilationCellsFan()
                          : obj.item.name === 'emptying' &&
                            c.current_volume != 0
                          ? modalVidangeOpen(
                              c.silo.name,
                              c.cellName,
                              c.id,
                              c.created_at,
                              c.stored_species.name,
                              c.updated_at,
                              c
                            )
                          : obj.item.name === 'transilage' &&
                            c.current_volume != 0
                          ? modalTransilageOpen(
                              c.silo.name,
                              c.cellName,
                              c.id,
                              c
                            )
                          : obj.item.name === 'trash' && c.current_volume === 0
                          ? trashCell(c.id, c.cellName)
                          : obj.item.name === 'cells_infos'
                          ? addCellsInfos(c, c.silo.name)
                          : obj.item.name === 'undo'
                          ? trashLastByCell(c.id)
                          : ''
                      "
                      :class="
                        obj.item.name === 'filling' &&
                        parseInt((c.volume * 30) / 100 + c.volume) -
                          c.current_volume >
                          0
                          ? ''
                          : (obj.item.name === 'ventilation' ||
                              obj.item.name === 'emptying' ||
                              obj.item.name === 'transilage') &&
                            c.current_volume != 0
                          ? ''
                          : obj.item.name === 'detach' ||
                            (obj.item.name === 'trash' &&
                              c.current_volume === 0) ||
                            obj.item.name === 'synth' ||
                            obj.item.name === 'seed_infos' ||
                            obj.item.name === 'cells_infos'
                          ? ''
                          : obj.item.name === 'undo' && c.hasSaisies === true ? '' 
                          : 'disable'"
                    >
                      <img
                        v-if="obj.item.logo.length > 0"
                        class="MenulogoCell"
                        :src="`/img/icon/icone-menu-${obj.item.logo}.svg`"
                        :alt="obj.item.name"
                      />
                      {{ obj.item.label }}
                    </div>
                  </template>
                </MenuBox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <AddFan
      :isOpen="openAddFan"  
      :key="key" 
      @fanAdded="fetchData('update')" 
      @update:isOpen="nv => openAddFan = nv"
    />
    <AddSilo
      :isOpen="openAddSilo" 
      :key="key" 
      @siloAdded="fetchData('update')" 
      @update:isOpen="nv => openAddSilo = nv"
    />
    <AddCell
      :isOpen="openAddCell" 
      :key="key" :siloData="siloData" 
      @cellAdded="fetchData('update')" 
      @update:isOpen="nv => openAddCell = nv"
    />
    <Synthesis
      :isOpen="openSynthesis"
      :key="key"
      :siloInfo="siloInfo"
      :cellName="cellName"
      :cellObject="cellObject"
      :cellInput="cellInput"
      :dureesNormalise="dureesNormalise"
      :cellDeletedInput="cellDeletedInput"
      fieldBackground="name"
      @update:isOpen="nv => openSynthesis = nv"
    />
    <AddFilling
      :isOpen="openFilling"
      :key="key"
      :cellId="cellId"
      :siloInfo="siloInfo"
      :cellName="cellName"
      :cellCuVol="cellCuVol"
      :cellObject="cellObject"
      @done="fetchData('update')"
      @update:isOpen="nv => openFilling = nv"
    />
    <AddVentilation
      :key="key"
      :siloInfo="siloInfo"
      :cellName="cellName"
      :fanId="fanId"
      :cellId="cellId"
      :cellObject="cellObject"
      @done="fetchData('update')"
    />
    <AddEmptying
      :isOpen="openEmptying"
      :key="key"
      :siloInfo="siloInfo"
      :cellName="cellName"
      :cellId="cellId"
      :cellDateRemp="cellDateRemp"
      :cellSpecies="cellSpecies"
      :cellDateCapture="cellDateCapture"
      :cellObject="cellObject"
      @done="fetchData('update')"
      @update:isOpen="nv => openEmptying = nv"
    />
    <AddTransilage
      :isOpen="openTransilage"
      :key="key"
      :siloInfo="siloInfo"
      :cellName="cellName"
      :cellId="cellId"
      :cellObject="cellObject"
      :emptyCell="emptyCell"
      @done="fetchData('update')"
      @update:isOpen="nv => openTransilage = nv"
    />
    <AddMultiVentilation
      :key="key"
      :siloData="siloData"
      :siloInfo="siloInfo"
      :cellsId="cellsId"
      :fanName="fanName"
      @done="fetchData('update')"
    />
    <AddMultiVentilationCellsFan
      :isOpen="multiVentilation"
      :key="key"
      :cellsId="cellsId"
      @done="fetchData('update')"
      @update:isOpen="nv => multiVentilation = nv"
    />
    <AddCellsInfos
      :isOpen="openCellsInfos"
      :key="key"
      :siloInfo="siloInfo"
      :cellObject="cellObject"
      @done="fetchData('update')"
      @update:isOpen="nv => openCellsInfos = nv"
    />
    <!-- <AddSeedInfos :key="key" :siloInfo="siloInfo" :cellObject="cellObject" /> -->
    <!-- <TrashModal :cellName="cellName" :cellId="cellId" /> -->

    <!-- <TrashModal
      :cellName="cellName"
      :cellId="cellId"
      :lastSaisieType="lastSaisieType"
      checkLastSaisie
      :nameModal="'confirmCap'"
      textOne="Vous allez annuler la derniere saisie sur"
      textTwo=""
      @trashAction="delLastCell()"
      btnName="Annuler la dernière saisie"
    /> -->

    <AddModal
      :isOpen="openTrash"
      @update:isOpen="nv => openTrash = nv"
      size="960"
      :okCancel="true"
      :okLabel="'Annuler la dernière saisie'"
      title="Annuler la derniere saisie"
      @click.stop
      class="confirmCap"
      name="confirmCap"
      @submit="delLastCell()"
    >
      <AlerteModal
        :text1="`Vous allez mettre la dernière saisie `"
        :textValue="'cellName'"
        :lastSaisieType="lastSaisieType"
        :text2="'à la corbeille.'"
        :checkLastSaisie="true"
        :key="key"
        :subTextH3="'Celle-ci sera définitivement supprimée.'"
      >
      </AlerteModal>
    </AddModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Api from '@/classes/api/Api';
import BtnAdd from '@/components/atoms/Button';
import InnerLoader from '@/components/atoms/InnerLoader';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddFan from '@/components/molecules/AddFan';
import AddSilo from '@/components/molecules/AddSilo';
import AddCell from '@/components/molecules/AddCell';
import AddFilling from '@/components/molecules/AddFilling';
import AddEmptying from '@/components/molecules/AddEmptying';
import AddVentilation from '@/components/molecules/AddVentilation';
import AddCellsInfos from '@/components/molecules/AddCellsInfos';
import AddTransilage from '@/components/molecules/AddTransilage';
import AddMultiVentilation from '@/components/molecules/AddMultiVentilation';
import AddMultiVentilationCellsFan from '@/components/molecules/AddMultiVentilationCellsFan';
import Synthesis from '@/components/molecules/SynthModal';
// import TrashModal from '@/components/molecules/TrashModal';
import MenuBox from '@/components/atoms/Menu';
import AddModal from '@/components/atoms/Modal';
import AlerteModal from '@/components/molecules/AlerteModal';

const columnsObject = [
  {
    value: 'Silo',
    sortName: 'siloName',
    sortType: 'string',
    sortIcon: true
  },
  {
    value: 'Cellule',
    sortName: 'cellName',
    sortType: 'string',
    sortIcon: true
  },
  {
    value: 'Tonnage stocké',
    sortName: 'current_volume',
    sortType: 'string',
    sortIcon: true
  },
  {
    value: 'Espèce',
    sortName: 'stored_species_name',
    sortType: 'string',
    sortIcon: true
  },
  {
    value: 'Sous-espèce',
    sortName: 'subspecies',
    sortType: 'string',
    sortIcon: true
  },
  {
    value: '°C Max',
    sortName: 'tmi_max',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: '°C Moy',
    sortName: 'tmi',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: '°C Min',
    sortName: 'tmi_min',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: 'Cumul ventilation',
    sortName: 'dureeCumul',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: 'Dernière opération',
    sortName: 'lastSaisieTimestamp',
    sortType: 'date',
    sortIcon: true
  },
  {
    value: 'Risque insecte',
    sortName: 'cjours',
    sortType: 'float',
    sortIcon: true
  },
  {
    value: '',
    sortName: '',
    sortType: '',
    sortIcon: false
  }
];

export default {
  name: 'Cells',
  components: {
    InnerLoader,
    MenuBox,
    AddFan,
    AddSilo,
    AddCell,
    BtnAdd,
    AddFilling,
    AddEmptying,
    AddVentilation,
    AddCellsInfos,
    AddTransilage,
    AddMultiVentilation,
    AddMultiVentilationCellsFan,
    // TrashModal,
    Synthesis,
    AlerteModal,
    AddModal
  },
  props: {},
  emits: ['need-refresh'],
  data() {
    return {
      env: null,
      openFilling: false,
      openSynthesis: false,
      openEmptying: false,
      openTransilage: false,
      openCellsInfos: false,
      openTrash: false,
      openAddCell: false,
      openAddSilo: false,
      openAddFan: false,
      multiVentilation: false,
      prompt: false,
      pageSize: 20,
      currentPage: 1,
      columns: columnsObject,
      modalStore: ModalStore,
      cells: [],
      openAlerteModal: false,
      sortType: 'cellName',
      orderType: 'desc',
      loading: true,
      modalStateSelectSiloForMulti: false,
      addFanAndCell: [
        { label: 'Ajouter un silo', name: 'silo' },
        { label: 'Ajouter un ventilateur', name: 'fan' },
        { label: 'Ajouter une cellule', name: 'cell' }
      ],
      workedCells: [],
      menuCellElementWithoutCancel: [
        { label: 'Synthèse', logo: '', name: 'synth' },
        {
          label: 'Remplissage',
          logo: 'remplissage',
          name: 'filling'
        },
        {
          label: 'Ventilation',
          logo: 'ventilation-mono',
          name: 'ventilation'
        },
        {
          label: 'Vidange',
          logo: 'vidange',
          name: 'emptying'
        },
        { label: 'Transilage', logo: '', name: 'transilage' },
        // { label: 'Infos grains', logo: '', name: 'seed_infos' },
        { label: 'Infos cellule', logo: '', name: 'cells_infos' }
      ],
      menuCellElement: [
        { label: 'Synthèse', logo: '', name: 'synth' },
        {
          label: 'Remplissage',
          logo: 'remplissage',
          name: 'filling'
        },
        {
          label: 'Ventilation',
          logo: 'ventilation-mono',
          name: 'ventilation'
        },
        {
          label: 'Vidange',
          logo: 'vidange',
          name: 'emptying'
        },
        { label: 'Transilage', logo: '', name: 'transilage' },
        // { label: 'Infos grains', logo: '', name: 'seed_infos' },
        { label: 'Infos cellule', logo: '', name: 'cells_infos' },
        {
          label: 'Annuler la dernière saisie',
          logo: 'undo',
          name: 'undo'
        }
      ],
      idSilo: '',
      fanId: '',
      fanName: '',
      siloData: [],
      siloInfo: '',
      cellName: '',
      emptyCell: '',
      cellId: '',
      cellSpecies: '',
      cellDateRemp: '',
      cellDateCapture: '',
      species: '',
      cellObject: {
        type: Object,
        default: null
      },
      fanChoose: '',
      fanObject: [],
      cellsId: [],
      cellCuVol: '',
      cellInput: [],
      cellDeletedInput: [],
      open: false,
      dureesNormalise: {},
      cumuldureesNormalise: [],
      arrayDuree: [],
      key: 0,
      is_impersonate: false,
      cellIdSelected: null
    };
  },

  async created() {
    this.fetchData();
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    if (rememberMe === false) {
      this.is_impersonate = JSON.parse(
        sessionStorage.getItem('impersonate_activated')
      );
    } else {
      this.is_impersonate = JSON.parse(
        localStorage.getItem('impersonate_activated')
      );
    }
    this.env = process.env.VUE_APP_ENV;
  },

  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    ...mapGetters('silos', ['GET_CELL']),
    ...mapGetters('listFillings', ['GET_FILLINGS']),
    ...mapGetters('silos', ['GET_FANS']),
    ...mapGetters('time', ['GET_CUMUL']),
    ...mapGetters('user', ['user']),
    pages() {
      return Math.ceil(this.workedCells.length / this.pageSize);
    },
    sworkedCells() {
      console.log(this.workedCells);
      return this.workedCells/*.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });*/
    }
  },
  methods: {
    riskColor(cJours) {
      return cJours
        ? Math.ceil(cJours / (400 - 1)) <= 1
          ? '#71BF4B'
          : Math.ceil(cJours / (400 - 1)) <= 2
          ? '#ff9000'
          : Math.ceil(cJours / (400 - 1)) <= 3
          ? '#e53232'
          : '#382a2a'
        : '#71BF4B';
    },
    changePage(page) {
      this.currentPage = page;
    },
    async showOption(id) {
      if (id !== this.showOptionDetail.PrimaryKey) {
        this.showOptionDetail.show = false;
      }
      this.showOptionDetail.show = !this.showOptionDetail.show;
      this.showOptionDetail.PrimaryKey = id;
    },
    showModal(type) {
      switch (type) {
        case 'silo':
          this.modalSiloOpen();
          break;
        case 'fan':
          this.modalFanOpen();
          break;
        case 'cell':
          this.modalCellOpen();
          break;
        case 'multicell':
          this.showModalMultiVentilationCellsFan();
          break;
      }
    },
    addCellsInfos(cell, silo) {
      this.siloInfo = silo;
      this.cellObject = cell;
      this.isLoading = true;
      this.openCellsInfos = true;
      // this.modalStore.showModal('cellinfos');
      this.isLoading = false;
      this.key++;
    },
    showModalMultiVentilationCellsFan() {
      this.cellsId = this.workedCells;
      console.log(this.workedCells);
      this.multiVentilation = true;
      // this.modalStore.showModal('multiVentilationCellsFan');
    },
    delLastCell() {
      Api.deleteLast(this.cellIdSelected)
        .then(({ data }) => {
          if (data) {
            this.openTrash = false
            // this.modalStore.hideModal('confirmCap');
            this.$emit('update:isOpen', false);
            this.$q.notify({
              message: data.message,
              color: 'positive'
            });
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.$store.dispatch('indicator/loadIndicatorLocation');
            this.$store.dispatch('silos/loadSilo');
            this.$store.dispatch('listFillings/loadFillings');
            this.actionsList = this.actionsListDeleted = [];
            Api.getCellInput(this.cellIdSelected).then(data => {
              this.actionsList = data.data;
              Api.getCellDeletedInput(this.cellIdSelected).then(data => {
                this.actionsListDeleted = data.data;
                this.filteredBySpeciesName();
              });
            });
            this.fetchData('update')
          }
        })
        .catch(error => {
          this.openTrash = false
          this.$q.notify({
            message: error.response.data.message,
            color: 'negative'
          });
        });
    },
    trashLastByCell(cellId) {
      this.cellIdSelected = cellId;
      Api.getLastSaisieByCell(cellId).then(({ data }) => {
        if (
          data.success === false &&
          data.message === 'Cette cellule ne dispose pas de saisies'
        ) {
          this.$toast.error(data.message);
        } else {
          this.lastSaisieType = data;
          this.openTrash = true
          // this.modalStore.showModal('confirmCap');
        }
      });
    },
    modalMultiVentilateurOpen(obj, siloName, cellsId, fanName) {
      this.isLoading = true;
      this.siloData = obj;
      this.siloInfo = siloName;
      this.cellsId = cellsId;
      this.fanName = fanName;
      this.isLoading = false;
      this.modalActive = false;
      this.modalStore.showModal('multiVent');
      this.key++;
    },
    modalSiloOpen() {
      // this.modalStore.showModal('silo');
      this.openAddSilo = true;
    },
    modalFanOpen() {
      this.key++;
      // this.modalStore.showModal('fan');
      this.openAddFan = true;
    },
    modalCellOpen() {
      this.key++;
      // this.modalStore.showModal('cell');
      this.openAddCell = true;
    },
    async modalSynthOpen(obj, obj2, cellData) {
      this.isLoading = true;
      this.key++;
      this.siloInfo = obj;
      this.cellName = obj2;
      this.cellObject = cellData;

      // this.fanObject = fanData;
      await Api.getCellInput(cellData.id).then(data => {
        this.cellInput = data.data;
      });
      await Api.getCellDeletedInput(cellData.id).then(data => {
        this.cellDeletedInput = data.data;
      });
      Api.getDureesNormalisesFromCell(cellData.id).then(data => {
        this.dureesNormalise = data.data;
      });
      // this.modalStore.showModal('synth');
      this.openSynthesis = true
      this.isLoading = false;
      this.modalActive = false;
      this.$store.dispatch('inputLocation/loadInputLocation');
      this.$store.dispatch('indicator/loadIndicatorLocation');
    },
    modalFillingOpen(siloName, cellName, cellId, cellCuVol, cell) {
      this.isLoading = true;
      this.siloInfo = siloName;
      this.cellName = cellName;
      this.cellId = cellId;
      this.cellCuVol = cellCuVol;
      this.cellObject = cell;
      this.openFilling = true
      this.isLoading = false;
      this.modalActive = false;
      this.key++;
    },
    modalVentilationOpen(siloName, cellName, cellId, cellData) {
      // this.isLoading = true;
      this.siloInfo = siloName;
      this.cellName = cellName;
      this.cellId = cellId;
      this.cellObject = cellData;
      this.key++;
      this.modalStore.showModal('ventilation');
      // this.isLoading = false;
    },
    modalVidangeOpen(
      siloName,
      cellName,
      cellId,
      cellDateRemp,
      cellSpecies,
      cellDateCapture,
      cellObject
    ) {
      this.isLoading = true;
      this.siloInfo = siloName;
      this.cellName = cellName;
      this.cellId = cellId;
      this.cellDateRemp = cellDateRemp;
      this.cellSpecies = cellSpecies;
      this.cellDateCapture = cellDateCapture;
      this.cellObject = cellObject;
      this.isLoading = false;
      this.modalActive = false;
      this.openEmptying = true;
      // this.modalStore.showModal('emptying');
      this.key++;
    },
    modalTransilageOpen(siloName, cellName, cellId, cellObject) {
      Api.getEmptyCell({
        cell_id: cellObject.id,
        location_id: this.user.location_group[0].location_id,
        stored_species_id: cellObject.stored_species_id,
        species_id: cellObject.species_id,
        sub_species_id: cellObject.sub_species_id
      })
        .then(({ data }) => {
          if (data) {
            this.emptyCell = data;
          }
        })
        .catch(error => {
          console.log(error);
        });
      this.isLoading = true;
      this.siloInfo = siloName;
      this.cellName = cellName;
      this.cellId = cellId;
      this.cellObject = cellObject;
      this.isLoading = false;
      this.modalActive = false;
      this.openTransilage = true;
      // this.modalStore.showModal('transilage');
      this.key++;
    },
    trashCell(cellId, cellName) {
      this.cellName = cellName;
      this.cellId = cellId;
      this.openTrash = true
      // this.modalStore.showModal('trash');
      this.key++;
    },
    order(column, type) {
      if (this.sortType === column) {
        this.orderType = this.orderType === 'asc' ? 'desc' : 'asc';
      }
      switch (type) {
        case 'string':
          this.workedCells.sort((a, b) =>
            {
              return !(a[column] === "" && b[column] === "") ? !(a[column] === "" && this.orderType === "desc") ? !(b[column] === "" && this.orderType === "desc") ? !(a[column] === "") ? !(b[column] === "") ? (a[column] > b[column] ? 1 : (b[column] > a[column] ? -1 : 0)) : -1 : 1 : 1 : -1 : 0
            }
          );
          break;
        case 'date':
          this.workedCells.sort((a, b) =>
            parseInt(a[column]) > parseInt(b[column])
              ? 1
              : parseInt(b[column]) > parseInt(a[column])
              ? -1
              : 0
          );
          break;
        case 'float':
          this.workedCells.sort((a, b) => {
            if (column === 'cjours') {
              if (b[column] === null) {
                b[column] = 1;
              } else if (a[column] === null) {
                a[column] = 1;
              }
              if (this.orderType === 'desc') {
                return parseFloat(a[column]) > parseFloat(b[column])
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column])
                  ? -1
                  : 0;
              } else {
                return parseFloat(a[column]) > parseFloat(b[column])
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column])
                  ? -1
                  : 0;
              }
            } else {
              if (this.orderType === 'desc') {
                return parseFloat(a[column]) > parseFloat(b[column]) ||
                  b[column] === null ||
                  b[column] === 0
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column]) ||
                    a[column] === null ||
                    a[column] === 0
                  ? -1
                  : 0;
              } else {
                return parseFloat(a[column]) > parseFloat(b[column]) ||
                  a[column] === null ||
                  a[column] === 0
                  ? 1
                  : parseFloat(b[column]) > parseFloat(a[column]) ||
                    b[column] === null ||
                    b[column] === 0
                  ? -1
                  : 0;
              }
            }
          });
          break;
        default:
        console.log("pass default");
          this.workedCells.sort((a, b) =>
            a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0
          );
          break;
      }
      this.sortType = column;
      if (this.orderType == 'desc') {
        this.workedCells.reverse();
      }
    },
    dayInfo(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D MMMM YYYY');
    },
    timestamp(date) {
      return date ? dayjs(date).unix() : 0;
    },
    checkLastDateCapture(cell) {
      let dateVenti = '';
      if (cell.cell_ventilation && cell.cell_ventilation.length > 0) {
        dateVenti =
          cell.cell_ventilation[cell.cell_ventilation.length - 1].date;
      }
      let dateEmpty = '';
      if (cell.emptying && cell.emptying.length > 0) {
        dateEmpty = cell.emptying[cell.emptying.length - 1].date;
      }
      let datefillings = '';
      if (cell.fillings && cell.fillings.length > 0) {
        datefillings = cell.fillings[cell.fillings.length - 1].date;
      }
      let dateinfoCell = '';
      if (cell.cell_details && cell.cell_details.length > 0) {
        dateinfoCell = cell.cell_details[cell.cell_details.length - 1].date;
      }

      if (
        cell.cell_ventilation &&
        cell.cell_ventilation.length < 0 &&
        cell.emptying &&
        cell.emptying.length < 0 &&
        cell.fillings &&
        cell.fillings.length < 0 &&
        cell.cell_details &&
        cell.cell_details.length < 0
      ) {
        return '';
      }
      let arr = [];
      arr.push(dateVenti, dateEmpty, datefillings, dateinfoCell);
      const arrSort = arr.sort();
      return this.dayInfo(arrSort[arrSort.length - 1]) !== 'Invalid Date'
        ? arrSort[arrSort.length - 1]
        : 0;
    },
    fetchData(when) {
      if (when === 'update') this.$emit('need-refresh');
      this.workedCells = [];
      Api.getCellsByLocation().then(({ data }) => {
        this.cells = data;
        let vm = this;
        this.cells.forEach(async c => {
          vm.workedCells.push({
            campaign_id: c.campaign_id,
            cell_ventilation: c.cell_ventilation,
            description: c.description,
            emptying: c.emptying,
            fans: c.fans,
            fillings: c.fillings,
            infos_cell: c.infos_cell,
            name: c.name,
            silo_id: c.silo_id,
            stored_species_id: c.stored_species_id,
            siloName: c.silo.name,
            cellName: c.name,
            current_volume: c.current_volume,
            stored_species_name: c.species ? c.species.name : c.stored_species ? c.stored_species.name : '',
            stored_species: c.stored_species ? c.stored_species : '',
            subspecies: c.sub_species ? c.sub_species.name : c.subspecies ? c.subspecies : '',
            species: c.species,
            species_id: c.species_id,
            sub_species: c.sub_species,
            sub_species_id: c.sub_species_id,
            tmi_min: c.tmi_min ? c.tmi_min : 0,
            tmi: c.tmi ? c.tmi : 0,
            tmi_max: c.tmi_max ? c.tmi_max : 0,
            lastSaisie:
              this.checkLastDateCapture(c) != 0
                ? this.dayInfo(this.checkLastDateCapture(c))
                : '',
            lastSaisieTimestamp:
              this.checkLastDateCapture(c) != 0
                ? this.timestamp(this.checkLastDateCapture(c))
                : 0,
            silo: c.silo,
            volume: c.volume,
            id: c.id,
            cjours: c.cjours,
            hasSaisies: c.hasSaisies,
            dureeCumul: c.dureeCumul,
            created_at: c.created_at,
            updated_at: c.updated_at
          });
        });
        this.loading = false;
      });
      this.$store.dispatch('silos/loadSilo');
      this.$store.dispatch('indicator/loadIndicatorLocation');
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
