<template>
  <div>
    <Loader v-if="isLoading" />
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      closeBtnCenter
      name="multiVentilationCellsFan"
      @click.stop
      size="960"
      :enableBackground="true"
      fieldBackground="name"
      :widthPlus="nameTab === 'cellule'"
      title="Ventilation Multicellules"
    >
      <!--<ModalHeader
        imgModal="/img/icon/icone-item-ventilateur-ambiant.svg"
        subTitle="Ventilation multicellules"
        :bigTitle="true"
        :siloInfo="''"
        :cellName="''"
        :enableBackground="true"
        fieldBackground="name"
      />-->

      <section class="ecos-ong wrap ">
        <Tabs :tabs="tabs" :active="currentTab" @change-tab="statusTab">
          <template #cellule>
            <div class="wrap2">
              <h1>Cellules ventilées</h1>
              <p class="gauche small">
                {{ cellsNotEmpty(cellsId).length }} cellules remplies sont
                actuellement associées à ce ventilateur. <br />La saisie de
                données de ventilation multi-cellules implique la saisie de la
                température après ventilation (t2) de chaque cellule concernée.
              </p>

              <ul class="lst-cell" v-if="cellsId && cellsId.length > 0">
                <li class="specieDefault">
                  <b>Sélection</b>
                  <b>Cellule</b>
                  <b>Dernière ventilation</b>
                  <b>t1 Min (°C)</b>
                  <b>t1 Moy(°C)</b>
                  <b>t1 Max (°C)</b>
                  <b>t2 Min (°C)</b>
                  <b>t2 Moy(°C)</b>
                  <b>t2 Max (°C)</b>
                  <b>Tonnage</b>
                </li>
                <li
                  v-for="(item, i) in cellsNotEmpty(cellsId)"
                  :key="i"
                  :data-label="i"
                >
                  <div
                    class="grain-color"
                    :style="{
                      border: ` 1px solid ${item.stored_species.color}`,
                      'background-color': item.stored_species.color
                    }"
                  ></div>
                  <div
                    v-if="
                      item &&
                        item.stored_species &&
                        item.stored_species === null &&
                        item.stored_species != 0
                    "
                    class="background-empty"
                  ></div>
                  <div class="select-cells text-center">
                    <div>
                      <input
                        type="checkbox"
                        :value="item"
                        v-model="cells_to_ventilate"
                      />
                    </div>
                  </div>
                  <div class="titre-cell">
                    <span class="titre">
                      {{ item.name }} - {{ item.stored_species.name }}</span
                    ><br />

                    <span v-if="item.fillings">{{
                      item.fillings[item.fillings.length - 1]
                        ? day(item.fillings[item.fillings.length - 1].date)
                        : '-'
                    }}</span>
                  </div>

                  <span class="details"
                    >t1 :
                    {{
                      item.fillings[item.fillings.length - 1]
                        ? item.fillings[item.fillings.length - 1].tmi
                        : '-'
                    }}
                    °C
                    <i>|</i>
                    {{
                      item.cell_ventilation && item.cell_ventilation.length
                        ? 't2 : ' +
                          item.cell_ventilation[
                            item.cell_ventilation.length - 1
                          ].temperature_after +
                          '°C.'
                        : ''
                    }}
                    <br />

                    <span
                      v-if="
                        item.cell_ventilation && item.cell_ventilation.length
                      "
                      >Saisie
                      {{
                        day(
                          item.cell_ventilation[
                            item.cell_ventilation.length - 1
                          ].date
                        )
                      }}
                      <i>|</i
                      >{{
                        item.cell_ventilation[item.cell_ventilation.length - 1]
                          .time
                      }}
                      h
                      <br />
                      {{
                        item.cell_ventilation.length > 0
                          ? `CT ${
                              item.cell_ventilation[
                                item.cell_ventilation.length - 1
                              ].thermostat
                            } °C |`
                          : '-'
                      }}
                    </span>
                    <span v-else>
                      {{
                        item.fillings[item.fillings.length - 1]
                          ? day(item.fillings[item.fillings.length - 1].date)
                          : '-'
                      }}
                      <i>|</i>
                      {{
                        item.fillings[item.fillings.length - 1]
                          ? item.fillings[item.fillings.length - 1]
                              .come_from_emptying
                            ? 'transin'
                            : 'rempl'
                          : '-'
                      }}
                    </span>
                  </span>
                  <!-- T1 Min -->
                  <dd>
                    <InputForm
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="item.tmi_min ? item.tmi_min : 0"
                      read
                      readonly
                    />
                  </dd>
                  <!-- T1 Moy -->
                  <dd>
                    <InputForm
                      inputModal="classic"
                      type="number"
                      :max="item.tmi ? item.tmi : 0"
                      :modelValue="item.tmi ? item.tmi : 0"
                      read
                      readonly
                    />
                  </dd>
                  <!-- T1 Max -->
                  <dd class="tMoy" data-u="t°max">
                    <InputForm
                      read
                      readonly
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="item.tmi_max ? item.tmi_max : 0"
                    />
                  </dd>
                  <!-- T2 Mini -->
                  <dd>
                    <InputForm
                      :read="!verifyIfCellWasSelected(item)"
                      :readonly="!verifyIfCellWasSelected(item)"
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="getTemperature(item, 'T2Min')"
                      @change="changeTemperature($event, item, 'T2Min')"
                      :step="0.1"
                    />
                  </dd>
                  <!-- T2 Moy -->
                  <dd>
                    <InputForm
                      :read="!verifyIfCellWasSelected(item)"
                      :readonly="!verifyIfCellWasSelected(item)"
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="getTemperature(item, 'T2')"
                      @change="changeTemperature($event, item, 'T2')"
                      :step="0.1"
                    />
                  </dd>

                  <!-- T2 Max -->
                  <dd>
                    <InputForm
                      :read="!verifyIfCellWasSelected(item)"
                      :readonly="!verifyIfCellWasSelected(item)"
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="getTemperature(item, 'T2Max')"
                      @change="changeTemperature($event, item, 'T2Max')"
                      :step="0.1"
                    />
                  </dd>
                  <!-- TONNAGE  -->
                  <dd>
                    <InputForm
                      :read="!verifyIfCellWasSelected(item)"
                      :readonly="!verifyIfCellWasSelected(item)"
                      inputModal="classic"
                      type="number"
                      :max="999"
                      :modelValue="getCellInputValue(item.id)"
                      @change="changeTonnage($event, item)"
                      :step="0.1"
                    />
                  </dd>
                </li>
              </ul>
            </div>
          </template>
          <template #ventilation>
            <div class="wrap2">
              <h1>Ventilation</h1>
              <dl>
                <dt class="date">
                  Date
                  <p>Date du relevé de température.</p>
                </dt>
                <dd class="ventDd">
                  <InputForm
                    inputModal="classic"
                    tabindex="1"
                    id="rdat"
                    type="date"
                    v-model="multiVentiDate"
                    :value="multiVentiDate"
                    :min="checkDateInput()"
                    :max="maxDate"
                    @getBlur="setDate($event)"
                    :read="!verifyIfCellWasSelected(cellObjectById)"
                    :readonly="!verifyIfCellWasSelected(cellObjectById)"
                  />
                  <span v-if="errorDate" class="errorDate"
                    >La date doit être entre comprise entre
                    {{ day(checkDateInput()) }} et
                    {{ day(maxDate) }}
                  </span>
                </dd>
                <dt class="vent_used">
                  Ventilateurs utilisés :
                </dt>
                <dd class="chooseVent">
                  <template v-if="fans_can_be_selected.length > 0">
                    <div
                      v-for="(fan, indxFan) in fans_can_be_selected"
                      :key="indxFan"
                      :data-label="indxFan"
                    >
                      <label :for="fan.id">{{ fan.name }}</label>
                      <input
                        type="checkbox"
                        :id="fan.id"
                        :value="fan"
                        v-model="fans_selected"
                      />
                    </div>
                  </template>
                  <template v-else>
                    Aucun ventilateur n’est commun à l’ensemble des cellules
                    sélectionnées
                  </template>
                </dd>
                <dt>
                  Durée de ventilation <i>*</i>
                  <p>Durée de ventilation depuis la dernière saisie.</p>
                </dt>
                <dd class="ventDd">
                  <InputForm
                    inputModal="classic"
                    type="number"
                    :min="0"
                    :max="999"
                    v-model="multiVentiTime"
                    :value="multiVentiTime"
                  />
                </dd>

                <dt>Consigne Thermostat <i>*</i></dt>
                <dd class="ventDd" data-u="°C">
                  <InputForm
                    inputModal="classic"
                    type="number"
                    :min="-999"
                    :max="80"
                    v-model="multiVentiThemo"
                    :value="multiVentiThemo"
                  />
                </dd>

                <div class="btn-stuff">
                  <p>
                    <BtnAdd
                      v-if="btnEnvoi"
                      title="Envoyer les données"
                      textBtn="Envoyer les données"
                      textBtnLoader="Envoie en cours"
                      :styleBtn="isLoading ? 'outlined' : 'contained'"
                      colorBtn="warning"
                      class="contentBtn"
                      typeBtn="submit"
                      activeLoader
                      ref="btn1"
                      @click="() => addMultiVent()"
                      :disabled="
                        cells_to_ventilate.length <= 0 ? 'disabled' : ''
                      "
                    />
                    <BtnAdd
                      tabindex="6"
                      title="FERMER"
                      textBtn="FERMER"
                      styleBtn="contained"
                      colorBtn="success"
                      typeBtn="button"
                      id="envoiDV"
                      v-if="btnOk"
                      @click="closeModal"
                    />
                  </p>
                  <p v-if="msg" class="mesg">
                    Vos données de ventilation sont enregistrées
                  </p>
                  <p class="message">
                    <span v-if="!cells_to_ventilate.length">
                      <u></u>Au moins une cellule doit comporter une température
                      après ventilation (t2) et (t2-Max).</span
                    >

                    <br />
                    <!-- <span v-if="multiVentiTime <= 0"
                      ><u></u>La durée de ventilation doit être supérieure à
                      0.</span> 
                      <br />-->
                    <!-- <span v-if="multiVentiThemo <= 0"
                      ><u></u>La consigne thermostat doit être supérieure à
                      0.</span
                    > -->
                  </p>
                  <span v-if="errorSendDate" class="errorDate"
                    >{{ msgError }}
                  </span>
                </div>
              </dl>
            </div>
          </template>
        </Tabs>
      </section>
    </AddModal>
    <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
      size="960"
      name="confirm"
      okCancel
      :okOnly="true"
      okLabel="valider"
      @submit="confirmMultiVent()"
    >
      <AlerteModal
        :text1="'La consigne thermostat est à 0°C.'"
        :subTextH3="'Souhaiter vous valider envoie des données.'"
      >
      </AlerteModal>
    </AddModal>
    <!-- <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)" size="small" name="confirmationDate" :okOnly="true" okLabel="confirmer">
      <AlerteModal
        :text1="'Attention vous allez saisir une date antérieur à la dernière saisie'"
      >
      </AlerteModal>
    </AddModal> -->
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import ModalStore from '@/components/atoms/Modal/ModalStore';
import AddModal from '@/components/atoms/Modal';
// import ModalHeader from '@/components/atoms/ModalHeader';
import Tabs from '@/components/atoms/Tabs';
import InputForm from '@/components/atoms/Form/InputForm';
import { mapGetters } from 'vuex';
import BtnAdd from '@/components/atoms/BtnLoader';
import dayjs from 'dayjs';
import Loader from '@/components/atoms/Loader';
import AlerteModal from '@/components/molecules/AlerteModal';

export default {
  name: 'AddMultiVentilationCellsFan',
  props: {
    isOpen: { type: Boolean, default: false },
    cellsId: {
      type: Object
    }
  },
  components: {
    // ModalHeader,
    AlerteModal,
    InputForm,
    AddModal,
    BtnAdd,
    Loader,
    Tabs
  },
  data() {
    return {
      msgError: '',
      closeAlerte: true,
      errorDate: false,
      errorSendDate: false,
      isLoading: false,
      btnEnvoi: true,
      btnOk: false,
      fieldBackground: { type: String },
      enableBackground: { type: Boolean, default: false },
      modalStore: ModalStore,
      isErrorMsg: false,
      msg: false,
      currentTab: 'cellule',
      tabs: {
        cellule: 'Cellules',
        ventilation: 'Ventilation'
      },
      multiVentiDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      multiVentiTmaxt1: 0,
      ventTemperatureBefore: 0,
      ventTemperatureAfter: 0,
      multiVentiTmaxt2: 0,
      multiVentiTime: 0,
      multiVentiThemo: 0,
      cells_to_ventilate: [],
      fans_can_be_selected: [],
      cells_to_ventilate_filtred: [],
      cellObjectById: [],
      fans_selected: [],
      cellInput: [],
      cellTonnageChange: [],
      cellTonnageInput: [],
      resultId: '',
      goodDate: '',
      nameTab: 'cellule'
    };
  },

  watch: {
    async cells_to_ventilate() {
      this.resultId = this.cells_to_ventilate.map(d => d.id);
      if (this.cells_to_ventilate.length === 0) {
        //Pas de cellules pas de fans
        this.fans_can_be_selected = [];
      } else if (this.cells_to_ventilate.length === 1) {
        //Ajout de la premiere cellule donc on verifie pas les fans
        this.fans_can_be_selected = this.cells_to_ventilate[0].fans;
      } else {
        this.cells_to_ventilate.forEach(c => {
          // On prend les fanid a verifier de la cellule en cours
          let idFanToCheck = c.fans.map(f => f.id);
          // On prend les fanid deja stocké des autres celulles
          //Intersection des deux arrays
          const filteredArray = idFanToCheck.filter(value => {
            return idFanToCheck.includes(value);
          });
          //On reattribue les objets fans par rapport aux IDS
          this.fans_can_be_selected = this.fans_can_be_selected.filter(f =>
            filteredArray.includes(f.id)
          );
        });
      }
      let precheckedFan = [];
      let fanIdToCheck = [];
      //On va chercher si la cellule coché fait partie de la derniere saisie pour la pré-coché
      this.fans_can_be_selected.forEach(() => {
        this.cellsId.forEach(c => {
          if (
            this.resultId.includes(c.id) &&
            c.cell_ventilation &&
            c.cell_ventilation.length > 0
          ) {
            if (c.cell_ventilation[c.cell_ventilation.length - 1].code) {
              //La derniere est une multi on va chercher tous les fans utilisées pour cette multi
              let tmp = c.cell_ventilation.filter(v => {
                return (
                  v.code ==
                  c.cell_ventilation[c.cell_ventilation.length - 1].code
                );
              });
              if (tmp.length > 0) {
                tmp.forEach(cv => {
                  fanIdToCheck.push(cv.fan_id);
                });
              }
            } else {
              fanIdToCheck.push(
                c.cell_ventilation[c.cell_ventilation.length - 1].fan_id
              );
            }
            fanIdToCheck = [...new Set(fanIdToCheck)];
            precheckedFan = this.fans_can_be_selected.filter(f => {
              return fanIdToCheck.includes(f.id);
            });
          }
        });
      });
      this.fans_selected = precheckedFan;
      if (this.resultId.length > 0) {
        await this.$store.dispatch(
          `lastDateCell/loadDateLastActionCell`,
          this.resultId
        );
      } else {
        return '';
      }
    }
  },
  computed: {
    ...mapGetters('silos', ['GET_SILOS']),
    ...mapGetters('time', ['GET_CUMUL']),
    ...mapGetters('silos', ['GET_CELL']),
    ...mapGetters('lastDateCell', ['GET_LAST_DATE_ACTION_CELL'])
  },
  created() {
    const cellsNotEmpty = this.cellsNotEmpty(this.cellsId);
    if (cellsNotEmpty) {
      cellsNotEmpty.forEach(cell => {
        this.cellTonnageInput.push({
          cell_id: cell.id,
          input: cell.current_volume
        });
      });
    }
  },
  updated() {
    const cellsNotEmpty = this.cellsNotEmpty(this.cellsId);
    if (cellsNotEmpty) {
      cellsNotEmpty.forEach(cell => {
        this.cellTonnageInput.push({
          cell_id: cell.id,
          input: cell.current_volume
        });
      });
    }
  },
  methods: {
    getCellInputValue(cell_id) {
      const cell = this.cellTonnageInput.filter(cell => {
        return cell.cell_id == cell_id;
      });
      return cell[0] ? cell[0].input : [];
    },
    setCellInputValue(cell_id, newValue) {
      const cell = this.cellTonnageInput.filter(cell => {
        return cell.cell_id == cell_id;
      });

      if (cell.length > 0) {
        const isSameObject = element => element.cell_id == cell_id;
        this.cellTonnageInput[
          this.cellTonnageInput.findIndex(isSameObject)
        ].input = newValue;
      } else {
        const isSameObject = element => element == cell[0];
        this.cellTonnageInput.splice(
          this.cellTonnageInput.findIndex(isSameObject),
          1,
          { cell_id: cell[0].id, input: newValue }
        );
      }
    },
    createArr() {
      let newArrSilo = [];
      this.GET_SILOS.map(silo => {
        this.silo_id = silo.id;
        newArrSilo.push(this.silo_id);
      });
      newArrSilo.map(id => {
        this.$store.dispatch('time/loadCumul', id);
      });
    },
    statusTab(evt) {
      this.nameTab = evt;
      return this.nameTab;
    },
    setDate(evt) {
      if (evt < this.checkDateInput(this.goodDate)) {
        this.errorDate = true;
        this.multiVentiDate = '';
        // this.modalStore.showModal('confirmationDate');
      } else {
        this.errorDate = false;
        this.errorSendDate = false;
        this.multiVentiDate = evt;
        this.errorDate = false;
      }
    },

    checkDateInput() {
      if (this.GET_LAST_DATE_ACTION_CELL.length > 0) {
        this.goodDate = this.datejsInput(
          this.GET_LAST_DATE_ACTION_CELL[0].date
        );
        return this.goodDate;
      } else {
        return '';
      }
    },

    day(date) {
      return dayjs(date)
        .locale('fr')
        .format(' D MMMM YYYY');
    },
    datejsInput(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    closeModal() {
      // ModalStore.hideModal('multiVentilationCellsFan');
      this.$emit('update:isOpen', false);
      this.btnOk = false;
      this.msg = false;
      this.isErrorMsg = false;
      this.multiVentiDate = new Date().toISOString().substr(0, 10);
      this.multiVentiTime = 0;
      this.multiVentiThemo = 0;
    },

    getTemperature(cell, temp) {
      const checkIfCellExistInCellsToVentilate = obj => obj.id === cell.id;
      const indexCellInArray = this.cells_to_ventilate.findIndex(
        checkIfCellExistInCellsToVentilate
      );

      if (temp == 'T1Max' && indexCellInArray == -1) {
        return cell.tmi_max ? cell.tmi_max : 0;
      } else if (temp == 'T2Min' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after_min
          ? this.cells_to_ventilate[indexCellInArray].temperature_after_min
          : 0;
      } else if (temp == 'T2' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after
          ? this.cells_to_ventilate[indexCellInArray].temperature_after
          : 0;
      } else if (temp == 'T2Min' && indexCellInArray == -1) {
        return 0;
      } else if (temp == 'T2' && indexCellInArray == -1) {
        return 0;
      } else if (temp == 'T2Max' && indexCellInArray != -1) {
        return this.cells_to_ventilate[indexCellInArray].temperature_after_max
          ? this.cells_to_ventilate[indexCellInArray].temperature_after_max
          : 0;
      } else if (temp == 'T2Max' && indexCellInArray == -1) {
        return 0;
      }
    },
    changeTemperature(evt, cell, temp) {
      const checkIfCellExistInCellsToVentilate = obj => obj.id === cell.id;

      const indexCellInArray = this.cells_to_ventilate.findIndex(
        checkIfCellExistInCellsToVentilate
      );

      if (temp == 'T2') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after = parseFloat(evt.target.value);
      } else if (temp == 'T2Min') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after_min = parseFloat(evt.target.value);
      } else if (temp == 'T2Max') {
        this.cells_to_ventilate[
          indexCellInArray
        ].temperature_after_max = parseFloat(evt.target.value);
      }
    },
    changeTonnage(evt, cell) {
      if (
        parseInt(evt.target.value, 10) > cell.volume ||
        parseInt(evt.target.value, 10) < 1
      ) {
        if (parseInt(evt.target.value, 10) > cell.volume) {
          evt.target.value = cell.volume;
          this.setCellInputValue(cell.id, parseInt(cell.volume, 10));
        } else if (parseInt(evt.target.value, 10) < 1) {
          evt.target.value = evt.target._value;
          this.setCellInputValue(cell.id, parseInt(evt.target.value, 10));
        }
      } else {
        const cellTonnageChange = this.cellTonnageChange.filter(value => {
          return value.cell.id === cell.id;
        });
        if (!(cellTonnageChange.length > 0)) {
          this.cellTonnageChange.push({
            cell: cell,
            value: parseInt(evt.target.value, 10)
          });
          this.setCellInputValue(cell.id, parseInt(evt.target.value, 10));
        } else {
          if (
            cellTonnageChange[0].value != parseInt(evt.target.value, 10) &&
            parseInt(evt.target.value, 10) != parseInt(evt.target._value, 10)
          ) {
            const isEqualCellId = value => value.cell.id == cell.id;
            this.cellTonnageChange.splice(
              this.cellTonnageChange.findIndex(isEqualCellId),
              1,
              {
                cell: cell,
                value: parseInt(evt.target.value, 10)
              }
            );
            this.setCellInputValue(cell.id, parseInt(evt.target.value, 10));
          } else {
            if (
              parseInt(evt.target.value, 10) == parseInt(evt.target._value, 10)
            ) {
              const isEqualCellId = value => value.cell.id == cell.id;
              const isEqualCellId2 = value => value.cell_id == cell.id;
              this.cellTonnageChange.splice(
                this.cellTonnageChange.findIndex(isEqualCellId),
                1
              );
              this.cellTonnageInput.splice(
                this.cellTonnageInput.findIndex(isEqualCellId2),
                1
              );
            }
          }
        }
      }
    },
    verifyIfCellWasSelected(cell) {
      let indexCellInArray = this.cells_to_ventilate.filter(item => {
        return cell.id == item.id;
      });

      if (indexCellInArray.length > 0) {
        this.cellObjectById = cell;
        return true;
      } else {
        return false;
      }
    },

    cellsNotEmpty(cellsObj) {
      const cells = [];
      cellsObj.forEach(cell => {
        if (cell.current_volume != 0) {
          cells.push(cell);
        }
      });
      return cells;
    },
    addMultiVent() {
      if (
        parseInt(this.multiVentiThemo) == 0 &&
        parseInt(this.multiVentiThemo).toFixed(2)
      ) {
        //OPEN THE MODAL TO CONFIRM THERMOSTAT
        // IF OK: faire ce qu'il a dans le else(VOIR CI-DESSOUS) DANS LA MODAL DE VENTILATION ET PAS DANS LA MODAL D'AVERTISSEMENT
        // IF CANCEL: close modal d'avertissement et ne rien faire.
        // vérifier la couleur de la police du bouton quand celui ci n'est plus disable
        this.modalStore.showModal('confirm');
      } else {
        this.confirmMultiVent();
      }
    },
    confirmMultiVent() {
      this.$refs.btn1.activeLoader = true;
      this.isLoading = true;
      let cellToSend = [];
      const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      const user =
        rememberMe === false
          ? JSON.parse(sessionStorage.getItem('user'))
          : JSON.parse(localStorage.getItem('user'));
      this.cells_to_ventilate.forEach(cell => {
        let newTonnage = null;
        const cellTonnageChange = this.cellTonnageChange.filter(value => {
          return value.cell.id === cell.id;
        });
        if (cellTonnageChange.length > 0) {
          newTonnage = cellTonnageChange[0].value;
        }
        cellToSend.push({
          temperature_before_min: cell.tmi_min ? cell.tmi_min : 0,
          temperature_before: cell.tmi,
          temperature_before_max: cell.tmi_max ? cell.tmi_max : 0,
          temperature_after_min: cell.temperature_after_min
            ? cell.temperature_after_min
            : 0,
          temperature_after: cell.temperature_after,
          temperature_after_max: cell.temperature_after_max,
          volume: cell.current_volume,
          user_id: user.id,
          cell_id: cell.id,
          fan_id: cell.fan_id,
          date: this.multiVentiDate,
          time: parseInt(this.multiVentiTime),
          thermostat: parseInt(this.multiVentiThemo),
          multi_vents_active_volume: newTonnage ? 1 : 0,
          new_volume: newTonnage ? newTonnage : 0,
          fans: this.fans_selected
        });
      });

      Api.addVent({ cells_ventilation: cellToSend })
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('silos/loadSilo');
            // this.$store.dispatch('silos/loadCell');
            this.$store.dispatch('listFillings/loadFillings');
            //  this.$store.dispatch('silos/loadFans');
            this.$store.dispatch('indicator/loadIndicatorLocation');
            this.$store.dispatch('inputLocation/loadInputLocation');
            this.btnOk = true;
            this.msg = true;
            this.btnEnvoi = false;
            this.$refs.btn1.activeLoader = false;
            this.isLoading = false;
          }
          this.$store.dispatch('indicator/loadIndicatorLocation');
          this.$emit('done');
          this.createArr();
        })
        .catch(error => {
          this.msgError = error.response.data.message;
          this.errorSendDate = true;
          this.isLoading = false;
          this.$refs.btn1.activeLoader = false;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
