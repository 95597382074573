<template>
  <div class="inputForm" :id="`input${uuid}`">
    <b><label :for="`input${uuid}`" class="mb-3">{{ label }}</label></b>
    <br />
    <input
      class="custom-file-input"
      :class="[` inputType typeInput-${inputModal}`, { read: read }]"
      :type="computedType"
      :placeholder="placeholder"
      :pattern="pattern"
      :required="required"
      :id="`input${uuid}`"
      :value="modelValue"
      :readonly="readonly"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="Input"
      :accept="acceptFile"
      :min="min"
      :max="max"
      :step="step"
      @blur="$emit('get-blur', $event.target.value)"
    />
    <i
      :class="`adornment password icon-${passwordIcon}`"
      v-if="type === 'password'"
      @mousedown="passwordIcon = 'eye-blocked'"
      @mouseup="passwordIcon = 'eye'"
    ></i>
    <icon v-if="icon" :icon="[prefixIcon, nameIcon]" class="iconPlaceholder" />
    <p v-if="forgot && forgotValue">
      <span v-if="forgot && forgotValue" @click="$emit('forgot', forgotValue)" class="forgot-message">
        {{forgotValue}}
      </span>
    </p>

    <p v-if="(state === null && !state && errorMsg) || helperMsg || successMsg">
      <span v-if="!state && errorMsg" class="error-message">
        {{
          errorMsg
        }}
      </span>

      <span v-if="helperMsg" class="error-message invalid"
        ><icon :icon="['far', 'times-circle']" class="icon" />{{
          helperMsg
        }}</span
      >
      <span v-if="successMsg" class="success-message"
        ><icon :icon="['fas', 'check']" class="icon" />{{ successMsg }}</span
      >
    </p>

    <span class="adornment" v-if="iconText">{{ iconText }}</span>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
export default {
  name: 'FormInput',
  emits: ['update:modelValue', 'get-blur', 'forgot'],
  props: {
    modelValue: {
      type: [String, Number, Function, Object]
    },
    placeholder: { type: [String, Number], default: null },
    required: { type: Boolean, default: false },
    label: { type: String, default: null },
    type: { type: String, default: 'text' },
    pattern: { type: String, default: null },
    errorMsg: { type: String, default: null },
    helperMsg: { type: String, default: null },
    successMsg: { type: String, default: null },
    icon: { type: Boolean },
    // icone: { type: Boolean },
    nameIcon: { type: [String, Number] },
    prefixIcon: { type: String, default: 'fas' },
    iconText: { type: String, default: null },
    classes: { type: String },
    getFocus: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    inputModal: { type: String, default: 'inputBox' },
    acceptFile: { type: [String, Object, Number, Function] },
    min: { type: [String, Number] },
    max: { type: [String, Number] },
    error: { type: Boolean },
    readonly: { type: Boolean },
    read: { type: Boolean },
    step: { type: Number },
    size: { type: String, default: null },
    forgot: { type: Boolean, default: false},
    forgotValue: { type: String, default: ''}

  },
  data() {
    return {
      uuid: uuid.v4(),
      passwordIcon: 'eye'
    };
  },
  computed: {
    computedType() {
      if (this.type === 'password') {
        return this.passwordIcon === 'eye' ? 'password' : 'text';
      }
      return this.type;
    },
    computedClass() {
      let className = '';
      if (this.state !== null) {
        className += this.state ? ' valid' : ' invalid';
      }
      return className;
    }
  },
  mounted() {
    if (this.getFocus) {
      this.$refs.Input.focus();
    }
    if (this.size != null) {
      const input = document.querySelector(`#input${this.uuid} input`);
      if (input) {
        input.classList.add(`input${this.size}`);
      }
      if (this.inputModal === 'inputIcon') {
        const svg = document.querySelector(`#input${this.uuid} svg`);
        if (svg) {
          svg.classList.add(`iconPlaceholder${this.size}`);
        }
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
