<template>
  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)"
    size="660"
    title="Exporter l'historique des données"
    name="exportData"
    @submit="exportFile"
  >
    <Loader v-if="isLoading" />
    <div class="export_container">
      <q-select
        class="inputContainerOption"
        outlined
        v-model="locationSelected"
        multiple
        :options="computedLocation"
        color="green"
        bg-color="white"
      />
      <q-select
        bg-color="white"
        class="inputContainerOption"
        color="green"
        multiple
        outlined
        :options="storedSpecies"
        v-model="selectedSpecies"
      />
      <q-select
        bg-color="white"
        class="inputContainerOption"
        color="green"
        multiple
        outlined
        :options="SubSpecies"
        v-model="selectedSubSpecies"
      />
      <div color="green" outlined class="inputContainerDate bg-white">
        <q-icon
          name="event"
          size="sm"
          class="eventIcon cursor-pointer"
          color="green"
        >
          <!-- @click="showCalendar()" -->

          <q-popup-proxy
            ref="qDateProxy"
            transition-show="scale"
            transition-hide="scale"
          >
            <q-splitter>
              <template v-slot:before>
                <div class="temp_nav">
                  <div
                    @click="selectedDateOption = 'Dernière 24h'"
                    :class="[
                      'nav_link',
                      { selected: selectedDateOption == 'Dernière 24h' }
                    ]"
                  >
                    Dernière 24h
                  </div>
                  <div
                    @click="selectedDateOption = 'Dernière semaine'"
                    :class="[
                      'nav_link',
                      { selected: selectedDateOption == 'Dernière semaine' }
                    ]"
                  >
                    Dernière semaine
                  </div>
                  <div
                    @click="selectedDateOption = 'Dernier mois'"
                    :class="[
                      'nav_link',
                      { selected: selectedDateOption == 'Dernier mois' }
                    ]"
                  >
                    Dernier mois
                  </div>
                  <div
                    :class="[
                      'nav_link',
                      { selected: selectedDateOption == 'Personnalisé' }
                    ]"
                  >
                    Personnalisé
                  </div>
                </div>
              </template>
              <template v-slot:after>
                <div>
                  <q-date
                    :locale="myLocale"
                    v-model="datemax"
                    color="green"
                    range
                    no-unset
                    mask="DD/MM/YYYY"
                  />
                </div>
              </template>
            </q-splitter>
          </q-popup-proxy>
        </q-icon>
        <div class="inputDate self-center full-width no-outline" tabindex="0">
          {{ `Du ${datemax.from} au ${datemax.to}` }}
        </div>
      </div>
    </div>
  </AddModal>
</template>

<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
import { ref } from '@vue/reactivity';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';

export default {
  name: 'ExportModal',
  components: {
    Loader,
    AddModal
  },
  props: {
    isOpen: { type: Boolean, default: false }
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
    const store = useStore();
    const today = dayjs(new Date())
      .locale('fr')
      .format('DD/MM/YYYY');
    const lastMonth = dayjs(new Date())
      .add(-30, 'days')
      .locale('fr')
      .format('DD/MM/YYYY');

    // Récuperation de la date minimun pour l'exportation
    const location_ids = store.state.user.user.location_group;
    let firstDate = new Date();
    if (location_ids != undefined) {
      for (const location_group of Object.entries(location_ids)) {
        Api.checkFirstHistory(location_group[1].location_id)
          .then(data => {
            const responseDate = new Date(data.data.firstDate);
            if (responseDate && responseDate < firstDate) {
              firstDate = responseDate;
            }
          })
          .catch(() => {
            if (
              this.locations.filter(
                location =>
                  location.location_id == location_group[1].location_id
              ).length > 0
            ) {
              this.locations.splice(
                this.locations.indexOf(
                  this.locations.filter(
                    location =>
                      location.location_id == location_group[1].location_id
                  )[0]
                ),
                1
              );
            }
          });
      }
    }

    // console.log(firstDate);
    const minDate = dayjs(firstDate)
      .add(-30, 'days')
      .locale('fr')
      .format('DD/MM/YYYY');
      
    return {
      datemax: ref({ from: lastMonth, to: today }),
      optionsFn(date) {
        return date >= minDate && date <= today;
      },
      myLocale: {
        /* starting with Sunday */
        days: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
        daysShort: 'Dim_Lun_Mar_Mer_Jeu_Ven_Sam'.split('_'),
        months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
        monthsShort: 'Jan_Fév_Mar_Avr_Mai_Juin_Juil_Aoû_Sep_Oct_Nov_Déc'.split('_'),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: 'Jours'
      }
    };
  },
  computed: {
    ...mapGetters('species', ['GET_SPECIES']),
    ...mapGetters('species', [
      'GET_SPECIES_BY_LOCATION_NAME',
      'GET_NEW_SPECIES_BY_LOCATION'
    ]),
    ...mapGetters('indicatorsLocations', ['GET_LOCATIONS_BY_SPECIE_ID']),
    ...mapGetters('user', ['user']),
    computedLocation() {
      let locations = Object.entries(this.locations).map(value => {
        value[1].label = value[1].location.name;
        value[1].value = value[1].location.name;
        return value[1];
      });
      if (this.user.role_id == 1 || this.user.role_id == 3) {
        locations.unshift({ label: 'Tous sites', value: 'Tous sites' });
      }
      return locations;
    },
    storedSpecies() {
      let speciesSpecies = this.sortedSpecieByName(this.species.speciesList);
      speciesSpecies.unshift({
        label: 'Toutes espèces',
        value: 'Toutes espèces'
      });
      return speciesSpecies;
    },
    SubSpecies() {
      let speciesSpecies = this.sortedSpecieByName(this.species.subSpeciesList);
      speciesSpecies.unshift({
        label: 'Toutes sous espèces',
        value: 'Toutes sous espèces'
      });
      return speciesSpecies;
    }
  },
  watch: {
    selectedSpecies(species) {
      if (
        species[species.length - 1] &&
        species.length > 1 &&
        species[species.length - 1].label === 'Toutes espèces'
      ) {
        this.selectedSpecies = [
          JSON.parse(JSON.stringify(this.storedSpecies[0]))
        ];
          console.log(this.selectedSpecies, 1);
      } else {
        if (species.length < 1) {
          this.selectedSpecies = [
            JSON.parse(JSON.stringify(this.storedSpecies[0]))
          ];
          console.log(this.selectedSpecies, 2);
        } else {
          console.log(this.selectedSpecies, 3);
          species.forEach((location, index, array) => {
            if (array.length > 1) {
              if (array.findIndex(l => l.label === 'Toutes espèces') !== -1) {
                array.splice(
                  array.findIndex(l => l.label === 'Toutes espèces'),
                  1
                );
              } else if (species.length === this.storedSpecies.length - 1) {
                this.selectedSpecies = [
                  JSON.parse(JSON.stringify(this.storedSpecies[0]))
                ];

              }
            }
          });
          if (this.selectedSpecies[species.length - 1].label === 'Toutes espèces') {
            let subSpecies = [];
            this.species.speciesList.forEach(s =>
              s.sub_species.forEach(sub => subSpecies.push(sub))
            );
            this.species.subSpeciesList = subSpecies;
          } else {
            let subSpecies = [];
            this.selectedSpecies.forEach(s =>
              s.sub_species.forEach(sub => subSpecies.push(sub))
            );
            this.species.subSpeciesList = subSpecies;
          }
        }
      }
    },
    datemax() {
      this.checkIfTheSelectedDateMatchesASelection();
    },
    locationSelected(nv) {
      if (
        nv[nv.length - 1] &&
        nv.length > 1 &&
        nv[nv.length - 1].label === 'Tous sites'
      ) {
        this.locationSelected = [
          JSON.parse(JSON.stringify(this.computedLocation[0]))
        ];
      } else {
        if (nv.length < 1) {
          this.locationSelected = [
            JSON.parse(JSON.stringify(this.computedLocation[0]))
          ];
        } else {
          nv.forEach((location, index, array) => {
            if (array.length > 1) {
              if (array.findIndex(l => l.label === 'Tous sites') !== -1) {
                array.splice(
                  array.findIndex(l => l.label === 'Tous sites'),
                  1
                );
              } else if (nv.length === this.computedLocation.length - 1) {
                this.locationSelected = [
                  JSON.parse(JSON.stringify(this.computedLocation[0]))
                ];
              }
            }
          });
        }
      }
    },
    selectedDateOption() {
      const today = dayjs(new Date())
        .locale('fr')
        .format('DD/MM/YYYY');

      /* Dernières 24h  */
      const lastDay = dayjs(new Date())
        .add(-1, 'days')
        .locale('fr')
        .format('DD/MM/YYYY');

      /* + 7 jours */
      const sevenDays = dayjs(new Date())
        .add(-7, 'days')
        .locale('fr')
        .format('DD/MM/YYYY');

      /* Dernier Mois  */
      let lastMonth = dayjs(new Date())
        .add(-30, 'days')
        .locale('fr')
        .format('DD/MM/YYYY');

      switch (this.selectedDateOption) {
        case 'Dernière 24h':
          this.datemax.to = today;
          if (lastDay < new Date(this.minDate)) {
            this.datemax.from = this.minDate;
          } else {
            this.datemax.from = lastDay;
          }
          this.datemax.from = lastDay;
          break;
        case 'Dernière semaine':
          this.datemax.to = today;
          if (sevenDays < new Date(this.minDate)) {
            this.datemax.from = this.minDate;
          } else {
            this.datemax.from = sevenDays;
          }
          this.datemax.from = sevenDays;
          break;
        case 'Dernier mois':
          this.datemax.to = today;
          if (lastMonth < new Date(this.minDate)) {
            this.datemax.from = this.minDate;
          } else {
            this.datemax.from = lastMonth;
          }
          this.datemax.from = lastMonth;
          break;
        case 'Personnalisé':
          break;
      }
    },
    minExportDate() {
      this.checkIfTheSelectedDateMatchesASelection();
    },
    maxExportDate() {
      this.checkIfTheSelectedDateMatchesASelection();
    }
  },
  data() {
    return {
      selected: false,
      date: {
        from: new Date().toISOString().substr(0, 10),
        to: new Date().toISOString().substr(0, 10)
      },
      storedSpeciesSelected: [],
      locationSelected: [],
      selectedDateOption: ref('Dernière 24h'),
      isLoading: false,
      isErrorMsg: false,
      errMsgText: ref(''),
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      minExportDate: new Date().toISOString().substr(0, 10),
      maxExportDate: new Date().toISOString().substr(0, 10),
      locations: [],
      locationsSelected: [
        {
          label: 'Tous sites',
          value: 'Tous sites'
        }],
      species: {
        speciesList: null,
        subSpeciesList: null
      },
      selectedSpecies: [
        {
          label: 'Toutes espèces',
          value: 'Toutes espèces'
        }
      ],
      selectedSubSpecies: [
        {
          label: 'Toutes sous espèces',
          value: 'Toutes sous espèces'
        }
      ]
    };
  },
  methods: {
    isToLong(string, char = 15) {
      return string.length > char ? string.substring(0, char) + '...' : string;
    },
    sortedSpecieByName(arrays) {
      return _.orderBy(arrays, 'name', 'asc');
    },
    showModal() {
      this.modalStore.showModal('exportData');
    },

    async exportFile() {
      this.isLoading = true;
      this.isErrorMsg = false;
      this.errMsgText = ref("");
      await Api.export_histories({
        locations:
          this.locationSelected[0].label === 'Tous sites'
            ? this.user.location_group.map(l => l.location_id)
            : this.locationSelected.map(l => l.location_id),
        species_list:
          this.selectedSpecies[0].label === 'Tous sites'
            ? null
            : this.selectedSpecies.map(s => s.id).join(', '),
        sub_species_list:
          this.selectedSubSpecies[0].label === 'Tous sites'
            ? null
            : this.selectedSubSpecies.map(s => s.id).join(', '),
        first_date:
          this.datemax.from.split('/')[2] +
          '-' +
          this.datemax.from.split('/')[1] +
          '-' +
          this.datemax.from.split('/')[0],
        second_date:
          this.datemax.to.split('/')[2] +
          '-' +
          this.datemax.to.split('/')[1] +
          '-' +
          this.datemax.to.split('/')[0]
      })
        .then(data => {
          const link = document.createElement('a');
          link.href = data.data.file_url;
          link.setAttribute('download', 'historique.xlsx');

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.$emit('update:isOpen', false);

          // Api.removeHistoryFile();
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status == 400) {
            if (error.response.data.message == 'Export Impossible') {
              this.isErrorMsg = true;
              if (this.locationsSelected.length > 1) {
                this.$q.notify({
                  message: `Aucune saisie ne correspond aux critères selectionné`,
                  color: 'negative'
                });
              } else {

                this.$q.notify({
                  message: `Aucune saisie ne correspond aux critères selectionné`,
                  color: 'negative'
                });
              }
            }
          } else if (error.response.status == 500) {
            this.isErrorMsg = true;
            this.errMsgText = ``;
          }
        });

      this.isLoading = false;
      return;
    },

    checkIfTheSelectedDateMatchesASelection() {
      const date = new Date();
      const oneDay = new Date();
      oneDay.setDate(date.getDate() - 1);
      const oneWeek = new Date();
      oneWeek.setDate(date.getDate() - 7);
      const oneMonth = new Date();
      oneMonth.setDate(date.getDate() - 30);
      const lastHarvest = new Date();
      const checkDate = new Date();
      checkDate.setMonth(5);
      checkDate.setDate(1);
      if (checkDate > lastHarvest) {
        lastHarvest.setFullYear(lastHarvest.getFullYear() - 1);
        lastHarvest.setMonth(5);
        lastHarvest.setDate(1);
      } else {
        lastHarvest.setMonth(5);
        lastHarvest.setDate(1);
      }
      let compareDate = new Date(
        this.datemax.from.split('/')[2] +
          '-' +
          this.datemax.from.split('/')[1] +
          '-' +
          this.datemax.from.split('/')[0]
      );
      let maxDate = new Date(
        this.datemax.to.split('/')[2] +
          '-' +
          this.datemax.to.split('/')[1] +
          '-' +
          this.datemax.to.split('/')[0]
      );
      if (
        compareDate.toISOString().substr(0, 10) ===
          oneDay.toISOString().substr(0, 10) &&
        maxDate.toISOString().substr(0, 10) === date.toISOString().substr(0, 10)
      ) {
        this.selectedDateOption = 'Dernière 24h';
      } else if (
        compareDate.toISOString().substr(0, 10) ===
          oneWeek.toISOString().substr(0, 10) &&
        maxDate.toISOString().substr(0, 10) === date.toISOString().substr(0, 10)
      ) {
        this.selectedDateOption = 'Dernière semaine';
      } else if (
        compareDate.toISOString().substr(0, 10) ===
          oneMonth.toISOString().substr(0, 10) &&
        maxDate.toISOString().substr(0, 10) === date.toISOString().substr(0, 10)
      ) {
        this.selectedDateOption = 'Dernier mois';
      } else if (
        compareDate.toISOString().substr(0, 10) ===
          lastHarvest.toISOString().substr(0, 10) &&
        maxDate.toISOString().substr(0, 10) === date.toISOString().substr(0, 10)
      ) {
        this.selectedDateOption = 'Depuis la moisson';
      } else {
        this.selectedDateOption = 'Personnalisé';
      }
    }
  },
  async created() {
    await this.$store.dispatch('species/loadSpecies');
    await this.$store.dispatch('species/loadSpeciesByLocation');
    this.speciesList = this.GET_SPECIES_BY_LOCATION_NAME;
    this.speciesFiltered = this.speciesList;
    this.storedSpeciesSelected = [
      JSON.parse(JSON.stringify(this.storedSpecies[0]))
    ];
    // crétation des date dans les input a l'ouverture de la modal
    let date = new Date();
    this.maxExportDate = date.toISOString().substr(0, 10);
    date.setDate(date.getDate() - 1);
    this.minExportDate = date.toISOString().substr(0, 10);

    // Récuperation des location de l'utilisateur connecté
    if (this.user.role_id == 1 || this.user.role_id == 3) {
      await Api.getLocation()
        .then(response => {
          this.locations = response.data;
          this.locationSelected = [
            JSON.parse(JSON.stringify(this.computedLocation[0]))
          ];
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.isLoading = false;
            this.isErrorMsg = true;
            this.errMsgText = error.response.message;
          }
        });
    } else {
      await Api.getOperatorLocation()
        .then(response => {
          this.locations = response.data;
          this.locationSelected = [
            JSON.parse(JSON.stringify(this.computedLocation[0]))
          ];
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.isLoading = false;
            this.isErrorMsg = true;
            this.errMsgText = error.response.message;
          }
        });
    }

    // Récuperation de la date minimun pour l'exportation
    const location_ids = this.user.location_group;
    console.log(location_ids);
    let firstDate = new Date();
    if (location_ids != undefined) {
      for (const location_group of Object.entries(location_ids)) {
        console.log(location_group[1].location.firstAction)
        if (location_group[1].location.firstAction) {
          let date = new Date(location_group[1].location.firstAction.date);
          if (date < firstDate) {
            firstDate = date;
          }
        }
      }
    }
    this.minDate = firstDate.toISOString().substr(0, 10);

    await this.$store.dispatch('species/loadNewSpeciesByLocation');
    // Assignations aux vairable et ajout de données label et de value pour QUASAR
    this.species.speciesList = this.GET_NEW_SPECIES_BY_LOCATION.map(species => {
      species.label =
        species.code +
        ' - ' +
        this.isToLong(species.name) +
        ' (' +
        species.species_type.name +
        ')';
      species.value = species.id;
      species.sub_species.forEach(sub_species => {
        sub_species.label =
          sub_species.code + ' - ' + this.isToLong(sub_species.name);
        sub_species.value = sub_species.id;
      });
      return species;
    });

    let subSpecies = [];
    this.species.speciesList.forEach(s =>
      s.sub_species.forEach(sub => subSpecies.push(sub))
    );
    this.species.subSpeciesList = subSpecies;
    console.log(this.species.speciesList);
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
