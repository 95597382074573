<template>
  <div id="login_form" class="page">
    <Meta pageTitle="OptiSilo | Page de connexion" />

    <div class=" container containerForm">
      <div class="presentation">
        <LogoApp
          subSecondary="Silo"
          size="logo_xl"
          colorApp="silo"
        />
        <img src="/img/logo-solutionsplus.png" style="transform:scale(0.8)" />
      </div>
      <div class="loginForm">
        <h5 style="margin-top:10px;margin-bottom: 10px;">Changement du mot de passe</h5>
        <form id="myform" v-on:submit.prevent="onSubmit()">
        <InputForm
          icon
          nameIcon="key"
          prefixIcon="fas"
          type="password"
          inputModal="inputIcon"
          placeholder="Mot de passe"
          v-model="password"
          :value="password"
          :errorMsg="msg.password"
        />
        <InputForm
          icon
          nameIcon="key"
          prefixIcon="fas"
          type="password"
          inputModal="inputIcon"
          placeholder="Confirmation mot de passe"
          v-model.lazy="checkPassword"
          :value="checkPassword"
          :errorMsg="msg.checkPassword"
          :helperMsg="msg.passwordInvalid"
          :successMsg="msg.passwordSuccess"
        />

        <div class="success" v-if="isSuccess">
          Mot de passe changé avec succès.
        </div>
        <div class="error" v-if="isError">
          {{ msg.msgError }}
        </div>
        <div class="error" v-if="notSamePasswords">
          <p>Les mots de passe ne sont pas identiques</p>
        </div>
        <br/>
        <BtnSubmit
          title="Envoyer"
          label="Envoyer"
          textBtn="Envoyer"
          textBtnLoader="Envoi en cours"
          active-loader
          styleBtn="outlined"
          colorBtn="light"
          class="contentBtn"
          typeBtn="submit"
        />
      </form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/classes/api/Api';
import BtnSubmit from '@/components/atoms/Button';
import InputForm from '@/components/atoms/Form/InputForm';
import Meta from '@/components/atoms/Meta';

import LogoApp from '@/components/atoms/LogoApp';

export default {
  name: 'Reset',
  components: {
    BtnSubmit,
    InputForm,
    Meta,
    LogoApp
  },
  data() {
    return {
      password: '',
      checkPassword: '',
      msg: [],
      isSuccess: false,
      isError: false
    };
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== '' && this.checkPassword !== '';
    }
  },
  methods: {
    onSubmit() {
      if (this.password === '') {
        this.msg['password'] = "Merci d'indiquer un mot de passe.";
      }
      if (this.checkPassword === '') {
        this.msg['checkPassword'] = 'Merci de confirmer votre mot de passe.';
      } else {
        let data = {
          password: this.password,
          password_confirm: this.checkPassword,
          token: this.$route.params.token
        };

        Api.reset(data)
          .then(({ data }) => {
            if (data) {
              this.isSuccess = !this.isSuccess;
              setTimeout(() => {
                this.$router.push({ name: 'login' });
              }, 2000);
            }
          })
          .catch(error => {
            switch (error.response.status) {
              case 403:
                this.isError = error;
                this.msg['msgError'] = `accès refusé.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 404:
                this.isError = error;
                this.msg['msgError'] = `page non trouvée.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 500:
                this.isError = error;
                this.msg[
                  'msgError'
                ] = `Une erreur 500 serveur s'est produite merci de réessayer ultérieurement.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              case 504:
                this.isError = error;
                this.msg['msgError'] = `le serveur n'a pas répondu.`;
                setTimeout(() => {
                  this.isError = !this.isError;
                }, 5000);
                break;
              default:
                console.log('some other error');
                break;
            }
            console.log('error', error);
          });
      }
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
