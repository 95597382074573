<template>
  <div class="CustomRadio" :class="{ vertical: isActive }">
    <div v-if="radioInline" class="radioInline">
      <input
        :disabled="disabled"
        :name="group"
        type="radio"
        :id="`radio${uuid}`"
        :value="val"
        :checked="modelValue === val"
        @click="$emit('update:modelValue', val)"
      />
      <label :for="`radio${uuid}`">{{ label }}</label>
    </div>
    <div v-else>
      <label :for="`radio${uuid}`">
        <input
          :disabled="disabled"
          :name="group"
          type="radio"
          :id="`radio${uuid}`"
          :value="val"
          :checked="modelValue === val"
          @click="$emit('update:modelValue', val)"
        />
        {{ label }}</label
      >
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
export default {
  name: 'InputRadio',
  emits: ['update:modelValue'],
  props: {
    val: { type: [String, Number], required: true },
    modelValue: { type: [String, Number], required: true },
    group: { type: String, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    radioInline: { type: Boolean, default: false }
  },
  data() {
    return {
      uuid: uuid.v4()
    };
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
