<template>
  <AddModal
    :isOpen="isOpen"
    @update:isOpen="nv => $emit('update:isOpen', nv)" activeFormValidation name="silo" size="500" title="Ajout de silo" @submit="addSilo">
    <Loader v-if="isLoading" />
    <div class="silo_container">
      <InputForm
        @modelSelected="selectedSilo"
        titreInput="Nom du silo"
        ruleCondition="required"
      />
      <p v-if="siloNameAlreadyExist" class="message">
        Ce nom de silo existe déja !
      </p>
    </div>
  </AddModal>
</template>

<script>
import { useQuasar } from 'quasar';
import Api from '@/classes/api/Api';
// import { mapGetters } from 'vuex';
import Loader from '@/components/atoms/Loader';
import AddModal from '@/components/atoms/Modal';
import InputForm from '@/components/atoms/Inputs/InputForm';
// import InputForm from '@/components/atoms/Form/InputForm';
import ModalStore from '@/components/atoms/Modal/ModalStore';

export default {
  name: 'AddSilo',
  emits: ['siloAdded'],
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components: {
    InputForm,
    AddModal,
    Loader
  },
  data() {
    return {
      isLoading: false,
      modalStore: ModalStore,
      isErrorMsg: false,
      siloNameAlreadyExist: false,
      silosListsData: [],
      addNewSilo: '',
      locationId: 1
    };
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const $q = useQuasar();
  },
  methods: {
    selectedSilo(nv) {
      this.addNewSilo = nv;
    },
    keyEvent() {
      this.addSilo();
    },

    async addSilo() {
      if (this.addNewSilo === '' || this.addNewSilo === null) {
        this.isErrorMsg = true;
      } else if (this.addNewSilo && this.addNewSilo.length > 0) {
        // this.isLoading = true;

        const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        const user =
          rememberMe === false
            ? JSON.parse(sessionStorage.getItem('user'))
            : JSON.parse(localStorage.getItem('user'));
        let data = {
          name: this.addNewSilo,
          location_id: user.location_id
        };

        Api.addSilo(data)

          .then(({ data }) => {
            if (data) {
              this.$store.dispatch('silos/loadSilo');
              this.isErrorMsg = false;
              // ModalStore.hideModal('silo');
              this.addNewSilo = '';
              this.isLoading = false;
              this.$emit('silo-added');

              this.$q.notify({
                message: 'Création du silo réussi',
                color: 'positive'
              });
            }
          })
          .catch(error => {
            if (error.response.status == 409) {
              this.isLoading = false;
              this.$refs.btn1.activeLoader = false;
              this.siloNameAlreadyExist = true;

              this.$q.notify({
                message: 'Ce nom de silo existe déja',
                color: 'negative'
              });
            }
          });
          this.$emit('update:isOpen', false);
      }
    },
    resetMsg() {
      if (this.addNewSilo.length > 1) {
        this.isErrorMsg = false;
      }
    }
  },
  // computed: {
  //   ...mapGetters('silos', ['GET_SILOS'])
  // },
  watch: {
    addNewSilo(value) {
      this.addNewSilo = value;
      this.resetMsg(value);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
