<template>
  <button
    :class="[
      `btn-iconed-${nameIcon} btn-${colorBtn}-${styleBtn} btnLoader btnDisabled-${disabled}`,
      { reverse: iconInvert }
    ]"
    :aria-label="title"
    :title="title"
    :disabled="disabled"
  >
    <img
      class="spinner"
      v-if="activeLoader"
      :class="[`Loader small-${smallLoader}`]"
      src="/img/icon/loader.svg"
      alt="Loader"
    />
    <icon
      v-if="icon || iconInvert"
      :icon="[prefixIcon, nameIcon]"
      :size="size"
      :class="[
        {
          'btn-iconed': icon,
          'btn-iconed-invert': iconInvert,
          disableIcon: activeLoader
        }
      ]"
    />
    <span v-if="activeLoader" class="followingText">{{ textBtnLoader }}</span>
    <span v-else>{{ textBtn }}</span>
  </button>
</template>

<script>
export default {
  name: 'BtnLoader',
  props: {
    textBtn: { type: String, required: true },
    textBtnLoader: { type: String, required: true },
    title: { type: String, required: true },
    loader: { type: Boolean, default: false },

    // Add logo
    icon: { type: Boolean },
    iconInvert: { type: Boolean },
    // Add name icon
    nameIcon: { type: String },
    prefixIcon: { type: String, default: 'fas', required: true },
    styleBtn: { type: String, required: true },
    colorBtn: { type: String, required: true },
    typeBtn: { type: String, required: false, default: 'button' },
    size: { type: String, required: false, default: 'xs' },
    iconCircle: {
      type: [String, Boolean],
      default: 'darklight'
    },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      activeLoader: this.loader
    };
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
